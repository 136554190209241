import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GenericInput from '../../components/inputs/GenericInput';
import { KanbanCounterColor } from '../../constants/orderKanban';
import Counter from '../OrdersManagement/components/Counter';
import ScheduleDashboard from './components/ScheduleDashboard';
import { usePreOrdersDelegate } from './controllers/delegate';

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 36px;
  margin: 16px 26px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderSection = styled.span`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #3b5a6b;
  margin-right: 16px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 28px 28px 28px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  width: 280px;
`;

const ClearInputButton = styled.button`
  background-color: unset;
  border: unset;
  color: #3b5a6b;
  padding: 24px;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  cursor: pointer;
`;

const FilterLabel = styled.label`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  padding: 0 16px;
`;
const PreOrders: FC = () => {
  const { t } = useTranslation();

  const {
    clearTextField,
    filterText,
    scheduledOrdersQuantity,
    getPreOrdersPerInterval,
    setTextField,
  } = usePreOrdersDelegate();

  const orders = useMemo(getPreOrdersPerInterval, [filterText, scheduledOrdersQuantity]);

  return (
    <Container>
      <Header>
        <HeaderSection>
          <Title>{t('components.Navbar.preOrders')}</Title>
          <Counter
            type={KanbanCounterColor.REGULAR}
            quantity={scheduledOrdersQuantity}
            supportTestId="pre-orders"
          />
        </HeaderSection>
        <HeaderSection>
          <FilterLabel>{t('pages.PreOrders.header.filter')}</FilterLabel>
          <InputContainer>
            <GenericInput
              inputHeight={30}
              type="search"
              name="search"
              placeholder={t('pages.PreOrders.header.search.placeholder')}
              label=""
              value={filterText}
              onChange={(value: string) => setTextField(value)}
              onKeyBoardEnter={() => getPreOrdersPerInterval()}
            />
          </InputContainer>
          <ClearInputButton onClick={clearTextField}>
            {t('pages.PreOrders.header.search.clearFilter')}
          </ClearInputButton>
        </HeaderSection>
      </Header>
      <Body>
        <ScheduleDashboard orders={orders} filterText={filterText} />
      </Body>
    </Container>
  );
};

export default PreOrders;
