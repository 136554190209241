import { Moped, Phone } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Quadrant } from '../../@types/quadrant';
import { DeliveryMode } from '../../constants/deliveryMode';
import { OrderTypeColor } from '../../constants/orderTypeColor';
import Payment from '../../pages/OrdersManagement/components/Payment';
import SvgIcon from '../SvgIcon';

export interface IOrderDetailsHeader {
  quadrant: Quadrant;
  customerName: string;
  customerPhone: string;
  channel: string;
  displayOrderId: string;
  formattedAmountToBePaid: string;
  paymentMethod: string;
  createdHour: string | null;
  statusColor: OrderTypeColor;
  isPrintable?: boolean;
  deliveryMode?: DeliveryMode;
  customerPhoneNumberCode?: string;
}

const QuadrantStyle = styled.div<{ statusColor: OrderTypeColor }>`
  background: ${(props) => props.statusColor};
  width: 56px;
  height: 55px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ColumnMaxWidth = styled(FlexColumn)`
  max-width: 70%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Header = styled(FlexRow)`
  justify-content: space-between;
`;

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Order = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
`;

const Customer = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const TextWeight400 = styled.span`
  font-weight: 400;
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
`;

const PhoneNumber = styled.div`
  margin-top: 3px;
  white-space: nowrap;
`;

const PhoneIcon = styled.div`
  margin: 0 3px -8px 3px;
`;

const CustomerName = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 600;
  word-break: break-word;
`;

const PhoneInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const HeaderInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const OrderDetailsHeader: FC<IOrderDetailsHeader> = ({
  quadrant,
  customerName,
  customerPhone,
  channel,
  displayOrderId,
  formattedAmountToBePaid,
  paymentMethod,
  createdHour,
  statusColor,
  isPrintable,
  deliveryMode = DeliveryMode.MANAGED_BY_STORE,
  customerPhoneNumberCode,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderStyle>
      <Header>
        <Main>
          <QuadrantStyle id="quadrant" data-testid="quadrant" statusColor={statusColor}>
            {deliveryMode === DeliveryMode.MANAGED_EXTERNALLY ? (
              <SvgIcon children={<Moped />} width={36} height={36} color="#ffffff" />
            ) : (
              quadrant.name
            )}
          </QuadrantStyle>
          <HeaderInfo>
            <PhoneInfo>
              <ColumnMaxWidth>
                <Order>#{displayOrderId}</Order>
                <Customer>
                  <TextWeight400>
                    <CustomerName>{customerName}</CustomerName>
                    <span>at {createdHour}</span>
                  </TextWeight400>
                </Customer>
              </ColumnMaxWidth>

              <FlexColumn>
                <Payment
                  formattedAmountToBePaid={formattedAmountToBePaid}
                  channel={channel}
                  paymentMethod={paymentMethod}
                />
              </FlexColumn>
            </PhoneInfo>
            {!isPrintable && deliveryMode !== DeliveryMode.MANAGED_EXTERNALLY && (
              <FlexRow>
                <PhoneIcon>
                  <Phone sx={{ color: '#92B0BD', fontSize: '16px' }} />
                </PhoneIcon>
                <PhoneNumber>
                  <TextWeight400>
                    {customerPhone || '-'}
                    {customerPhoneNumberCode &&
                      ` / ${t(
                        'pages.OrderManagement.OrderDetails.PhoneNumberCode',
                      )} ${customerPhoneNumberCode}`}
                  </TextWeight400>
                </PhoneNumber>
              </FlexRow>
            )}
          </HeaderInfo>
        </Main>
      </Header>
    </HeaderStyle>
  );
};

export default OrderDetailsHeader;
