import { Delegate } from '../../../../../@types/handlers/delegate';
import useStoreIdentificationConnector from './connector';

interface IStoreIdentificationDelegate {
  /**
   * Name of the user's restaurant
   */
  restaurant: string;
}

type StoreIdentificationConnector = ReturnType<typeof useStoreIdentificationConnector>;

export class StoreIdentificationDelegate
  extends Delegate<StoreIdentificationConnector>
  implements IStoreIdentificationDelegate
{
  get restaurant(): string {
    return this.connector.authContext.restaurant?.name ?? '';
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useStoreIdentificationDelegate = () =>
  new StoreIdentificationDelegate(useStoreIdentificationConnector());
