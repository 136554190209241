import { createContext, FC, useContext, useEffect } from 'react';

import { Languages } from '../../constants/languages';
import { ILanguageContextDelegate, useLanguageContextDelegate } from './controllers/delegate';

export const LanguageContext = createContext<
  Pick<ILanguageContextDelegate, 'language' | 'setLanguage'>
>({
  language: Languages.EN,
  setLanguage: () => ({}),
});

LanguageContext.displayName = 'Language';

interface ILanguageProvider {
  children: JSX.Element;
}

const LanguageProvider: FC<ILanguageProvider> = ({ children }) => {
  const { language, initializeLanguage, setLanguage } = useLanguageContextDelegate();

  useEffect(() => {
    initializeLanguage();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);

export default LanguageProvider;
