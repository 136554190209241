import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { env } from '../../constants/env';
import { StatusModalType } from '../../constants/modalType';
import GenericButton from '../buttons/GenericButton';

interface IResponseStatusModal {
  message: string;
  type: StatusModalType;
  observation?: string;
  highlightedObservation?: string;
  onClick: () => void;
}

const Body = styled.div<{ color: string }>`
  width: 716px;
  height: 643px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => props.color};
`;

const Message = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 536px;
`;

const TextContainer = styled.div`
  width: 450px;
  text-align: center;
  margin-bottom: 45px;
`;

const Observation = styled.span`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;

  color: #ffffff;
`;

const HighlightedObservation = styled(Observation)`
  font-weight: 600;
`;

const Button = styled.div`
  width: 370px;
`;

type StatusModalPattern = Record<StatusModalType, string>;

const responseStatusModalColor: StatusModalPattern = {
  error: '#dd3712',
  neutral: '#97B0BD',
  success: '#79BF1F',
};

const responseStatusModalImg: StatusModalPattern = {
  error: `${env.assetsPath}/modal-error.svg`,
  neutral: `${env.assetsPath}/modal-neutral.svg`,
  success: `${env.assetsPath}/modal-success.svg`,
};

const ResponseStatusModal: FC<IResponseStatusModal> = ({
  message,
  type,
  onClick,
  observation,
  highlightedObservation,
}) => {
  const { t } = useTranslation();

  return (
    <Body color={responseStatusModalColor[type]}>
      <img src={responseStatusModalImg[type]} alt="modal-img" />
      <Message>{t(message)}</Message>
      {observation && (
        <TextContainer>
          <Observation>
            {observation}
            {highlightedObservation && (
              <>
                <br />
                <HighlightedObservation>{highlightedObservation}</HighlightedObservation>
              </>
            )}
          </Observation>
        </TextContainer>
      )}
      <Button>
        <GenericButton title={t('components.ResponseStatusModal.dismiss')} onClick={onClick} />
      </Button>
    </Body>
  );
};

export default ResponseStatusModal;
