import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDeliveryContext } from '../../../../../contexts/DeliveryContext';
import { useModalContext } from '../../../../../contexts/ModalContext';
import { useOrdersContext } from '../../../../../contexts/OrdersContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import ordersService from '../../../../../services/OrdersService';

const useLastMileTrackerConnector = () => {
  return {
    authContext: useAuthContext(),
    deliveryContext: useDeliveryContext(),
    modalContext: useModalContext(),
    ordersContext: useOrdersContext(),
    ordersService,
    toastContext: useToastContext(),
  };
};

export default useLastMileTrackerConnector;
