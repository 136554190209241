import { Delegate } from '../../../../@types/handlers/delegate';
import { ToastTypes } from '../../../../constants/toasts';
import useGenericPrintButtonConnector from './connector';

type GenericPrintButtonConnector = ReturnType<typeof useGenericPrintButtonConnector>;

interface IGenericPrintButtonDelegate {
  /**
   * Display new toast on screen
   *
   * @param {string} message Message to be displayed in toast
   * @param {ToastTypes} type Toast type: success, warning or error
   */
  addToast: (message: string, type: ToastTypes) => void;

  /**
   * Print page details from iframe
   *
   * @param {string} iframeId iframe unique identifier
   */
  printPage(iframeId: string): void;
}

export class GenericPrintButtonDelegate
  extends Delegate<GenericPrintButtonConnector>
  implements IGenericPrintButtonDelegate
{
  addToast = (message: string, type: ToastTypes): void => {
    this.connector.toastContext.addToast(message, type);
  };

  printPage = (iframeId: string): void => {
    this.connector.printUtils.printIframe(iframeId);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useGenericPrintButtonDelegate = () =>
  new GenericPrintButtonDelegate(useGenericPrintButtonConnector());
