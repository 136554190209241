import { Delegate } from '../../../@types/handlers/delegate';
import { IToast } from '../../../@types/toast';
import { TOAST_TTL, ToastTypes } from '../../../constants/toasts';
import { useToastContextConnector } from './connector';

export interface IToastContextDelegate {
  /**
   * Display new toast on screen
   *
   * @param {string} message Message to be displayed in toast
   * @param {ToastTypes} type Toast type: success, warning or error
   */
  addToast: (message: string, type: ToastTypes) => void;
}

type ToastContextConnector = ReturnType<typeof useToastContextConnector>;

export class ToastContextDelegate
  extends Delegate<ToastContextConnector>
  implements IToastContextDelegate
{
  addToast = this.connector.addToastCallback((message: string, type: ToastTypes) => {
    const newToast: IToast = { id: Date.now().toString(), message, type };
    this.connector.setToasts((toasts) => [...toasts, newToast]);
    setTimeout(() => this.connector.setToasts((toasts) => toasts.slice(1)), TOAST_TTL);
  });

  get toasts(): IToast[] {
    return this.connector.toasts;
  }
}

export const useToastContextDelegate = () => new ToastContextDelegate(useToastContextConnector());
