import { ActualETAResponse } from '../@types/eta';
import { HTTPClient } from '../@types/handlers/httpClient';
import { Restaurant as RestaurantResponse } from '../@types/restaurants';
import { env } from '../constants/env';

export interface IRestaurantService {
  /**
   * Get actual eta
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @returns {ActualETAResponse} returns actual eta object for the restaurant
   */
  getActualEta(country: string, brand: string, restaurantId: string): Promise<ActualETAResponse>;

  /**
   * Get restaurant details
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @returns {RestaurantResponse} returns the restaurant details
   */
  getOne(country: string, brand: string, restaurantId: string): Promise<RestaurantResponse>;
}

class RestaurantService extends HTTPClient implements IRestaurantService {
  async getActualEta(
    regionCode: string,
    brand: string,
    restaurantId: string,
  ): Promise<ActualETAResponse> {
    const url = `/${regionCode}/${brand}/restaurants/${restaurantId}/eta`;
    const response = await this.get<ActualETAResponse>(url);

    return response.data;
  }

  async getOne(
    regionCode: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantResponse> {
    const url = `/${regionCode}/${brand}/restaurants/${restaurantId}`;
    const response = await this.get<RestaurantResponse>(url);

    return response.data;
  }
}

export default new RestaurantService(env.deliveryServiceAPI);
