import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Loading from '../../../../components/Loading';
import GenericRadioList from '../../../inputs/GenericRadioList';
import { useEtaListDelegate } from './controllers/delegate';

interface IEtaList {
  onChange: (eta: string) => void;
}

const Body = styled.div`
  width: 290px;
  height: 443px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebebe;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 24px 0;
`;

const LoaderBackground = styled.div`
  width: 290px;
  height: 290px;
`;

const InputList = styled.div`
  display: flex;
  justify-content: center;
`;

const EtaList: FC<IEtaList> = ({ onChange }) => {
  const { t } = useTranslation();

  const { etaList, getEtaValues } = useEtaListDelegate();

  useEffect(() => {
    getEtaValues();
  }, []);

  return etaList.length ? (
    <Body>
      <Title>{t('components.PageHeader.DeliveryTime.EtaList.etaListTitle')}</Title>
      <InputList>
        <GenericRadioList options={etaList} onChange={onChange} />
      </InputList>
    </Body>
  ) : (
    <LoaderBackground>
      <Loading />
    </LoaderBackground>
  );
};

export default EtaList;
