export enum StatusModalType {
  SUCCESS = 'success',
  ERROR = 'error',
  NEUTRAL = 'neutral',
}

export enum LastMileModalType {
  ASSIGN_DRIVER = 'ASSIGN_DRIVER',
  CANCEL_DRIVER = 'CANCEL_DRIVER',
  CANCELLED_BY_LAST_MILE_DRIVER = 'CANCELLED_BY_LAST_MILE_DRIVER',
}
