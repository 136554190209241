export const env = {
  assetsPath: `/assets/${process.env.REACT_APP_RBI_BRAND}`,
  brand: process.env.REACT_APP_RBI_BRAND?.toUpperCase(),
  deliveryServiceAPI:
    process.env.REACT_APP_PARTNERS_DELIVERY_API_URL ??
    `https://${process.env.REACT_APP_SHORT_REGION}-${process.env.REACT_APP_STAGE}-${process.env.REACT_APP_RBI_BRAND}-partners-delivery.rbictg.com/api/v1`,
  googleMapsApiKey: `${process.env.REACT_APP_RBI_GOOGLE_MAPS_API_KEY}`,
  launchDarklyClientId: `${process.env.REACT_APP_RBI_LAUNCH_DARKLY_CLIENT_ID}`,
  stage: `${process.env.REACT_APP_STAGE?.toUpperCase()}`,
  websocketURL: `${process.env.REACT_APP_WEBSOCKET_URL}`,
};
