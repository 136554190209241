import { ReactElement } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { IFloatingCardPosition } from '../components/GenericFloatingCard';
import { useFloatingCardContextConnector } from './connector';

export interface IFloatingCardContextDelegate {
  /**
   * Get content to be show in floating card
   */
  content: ReactElement<any, any> | undefined;

  /**
   * Get the floating card position on the screen
   */
  position: IFloatingCardPosition | undefined;

  /**
   * Get true if the floating card will show or false otherwise
   */
  show: boolean;

  /**
   * Handle when the floating card should be opened or closed
   * @param {ReactElement<any, any> | undefined} content content to be show in floating card
   * @param {IFloatingCardPosition} position the floating card position on the screen
   * @param {string[]} dependencies list of ids as dependency to not close the floating card
   */
  handleFloatingCard: (
    content?: ReactElement<any, any> | undefined,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ) => void;
}

type FloatingCardContextConnector = ReturnType<typeof useFloatingCardContextConnector>;

export class FloatingCardContextDelegate
  extends Delegate<FloatingCardContextConnector>
  implements IFloatingCardContextDelegate
{
  get content(): ReactElement<any, any> | undefined {
    return this.connector.content;
  }

  get position(): IFloatingCardPosition | undefined {
    return this.connector.position;
  }

  get show(): boolean {
    return !!this.connector.content;
  }

  get dependencies(): string[] | undefined {
    return this.connector.dependencies;
  }

  handleFloatingCard = (
    content?: ReactElement<any, any>,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ): void => {
    this.connector.setContent(content);
    this.connector.setPosition(position);
    this.connector.setDependencies(dependencies);
  };
}

export const useFloatingCardContextDelegate = () =>
  new FloatingCardContextDelegate(useFloatingCardContextConnector());
