interface IStringUtils {
  /**
   * Find string and replace in a sentence
   *
   * @param {string} sentence to search for string
   * @param {string} replaced string to be replaced
   * @param {string} replacement string to be put in place of the replaced
   * @returns {string} result string
   */
  findAndReplace(sentence: string, replaced: string, replacement: string): string;
}

export class StringUtils implements IStringUtils {
  findAndReplace(sentence: string, replaced: string, replacement: string): string {
    return sentence.replace(RegExp(replaced, 'gi'), replacement);
  }
}

export const stringUtils = () => new StringUtils();
