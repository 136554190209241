export enum DriverAvailabilityStatus {
  DRIVER_WORKING = 'DRIVER_WORKING',
  DRIVER_OFFSHIFT = 'DRIVER_OFFSHIFT',
}

export enum DriverStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DriverRealtimeStatus {
  DRIVER_IN_RESTAURANT = 'DRIVER_IN_RESTAURANT',
  DRIVER_DELIVERING = 'DRIVER_DELIVERING',
  DRIVER_HEADING_RESTAURANT = 'DRIVER_HEADING_RESTAURANT',
}

export enum DriverMarkerColor {
  RED = '#D62300',
  BLUE = '#1FA2BF',
  GREY = '#878787',
}
