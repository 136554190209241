import { Delegate } from '../../../../@types/handlers/delegate';
import useLanguageSelectorConnector from './connector';

interface ILanguageSelectorDelegate {
  /**
   * Method to set language in the language context
   *
   * @param {string} language new language to be used
   */
  setLanguage(language: string): void;
}

type LanguageSelectorConnector = ReturnType<typeof useLanguageSelectorConnector>;

export class LanguageSelectorDelegate
  extends Delegate<LanguageSelectorConnector>
  implements ILanguageSelectorDelegate
{
  setLanguage = (language: string): void => {
    this.connector.languageContext.setLanguage(language);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useLanguageSelectorDelegate = () =>
  new LanguageSelectorDelegate(useLanguageSelectorConnector());
