import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef, useState } from 'react';

import { SelectOption } from '../../../@types/input';
import { OrderDetailsFormatted } from '../../../@types/orders';
import { CancelOption, OrderIssue } from '../../../@types/settings';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useDeliveryContext } from '../../../contexts/DeliveryContext';
import { useDriversContext } from '../../../contexts/DriversContext/index';
import { useModalContext } from '../../../contexts/ModalContext';
import { useOrdersContext } from '../../../contexts/OrdersContext';
import { useSettingsContext } from '../../../contexts/SettingsContext';
import { useToastContext } from '../../../contexts/ToastContext';
import driversService from '../../../services/DriversService';
import ordersService from '../../../services/OrdersService';
import settingsService from '../../../services/SettingsService';
import { dateTimeUtils } from '../../../utils/DateTimeUtils';
import { moneyUtils } from '../../../utils/MoneyUtils';
import { ordersManagementUtils } from '../../../utils/OrdersManagementUtils';
import { ordersUtils } from '../../../utils/OrdersUtils';

const useOrderDetailsConnector = () => {
  /**
   * @todo Remove all info from the OrderDetailsFormatted interface after all
   * components get its own formatter methods
   *
   * Just leave the statusColor, timerAccepted and the timerColumn
   */
  const [order, setOrder] = useState<OrderDetailsFormatted | null>(null);
  const orderRef = useRef<OrderDetailsFormatted>(order);

  const [activeDrivers, setActiveDrivers] = useState<SelectOption[]>([]);
  const [issueOptions, setIssueOptions] = useState<OrderIssue[] | CancelOption[]>([]);

  const { enableLastMileProviders, enableOrderPrepared } = useFlags();

  return {
    activeDrivers,
    authContext: useAuthContext(),
    dateUtils: dateTimeUtils(),
    deliveryContext: useDeliveryContext(),
    driversContext: useDriversContext(),
    driversService,
    issueOptions,
    ldEnableOrderPrepared: enableOrderPrepared,
    ldFlagLastMileProviders: enableLastMileProviders,
    modalContext: useModalContext(),
    moneyUtils: moneyUtils(),
    order,
    orderManagementUtils: ordersManagementUtils(),
    orderRef,
    ordersContext: useOrdersContext(),
    ordersService,
    ordersUtils: ordersUtils(),
    setActiveDrivers,
    setIssueOptions,
    setOrder,
    settingsContext: useSettingsContext(),
    settingsService,
    toastContext: useToastContext(),
  };
};

export default useOrderDetailsConnector;
