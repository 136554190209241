import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IPreOrderSchedule {
  startTimeScheduled?: string;
  startTimeAccepted?: string;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 22px;
`;

const Subtitle = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #c2c2c2;
`;

const TimeStart = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-top: 6px;
`;

const Item = styled.div`
  width: calc(50% - 22px);
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 12px;
`;

const PreOrderSchedule: FC<IPreOrderSchedule> = ({ startTimeScheduled, startTimeAccepted }) => {
  const { t } = useTranslation();

  return (
    <Body>
      <Title>{t('pages.OrderManagement.OrderDetails.schedule')}</Title>
      <FlexRow>
        <Item>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.scheduledFor')}</Subtitle>
          <TimeStart>{startTimeScheduled}</TimeStart>
        </Item>
        <Item>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.orderWillPlaceAt')}</Subtitle>
          <TimeStart>{startTimeAccepted}</TimeStart>
        </Item>
      </FlexRow>
    </Body>
  );
};

export default PreOrderSchedule;
