import { Settings } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

import { IFloatingCardPosition } from '../../../../contexts/FloatingCardContext/components/GenericFloatingCard';
import LanguageSelector from '../../../language/LanguageSelector';
import SettingsCard from './components/SettingsCard';
import { useSettingsButtonDelegate } from './controllers/delegate';

const SettingsStyle = styled.button`
  height: fit-content;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 6px;
`;

const LanguageCard = styled.div`
  padding: 18px 32px;
`;

const SettingsButton: FC = () => {
  const { handleFloatingCard, logout } = useSettingsButtonDelegate();

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const position: IFloatingCardPosition = {
      left: event.pageX,
      top: event.pageY,
    };

    handleFloatingCard(
      <SettingsCard logout={logout} languageSelector={() => handleLanguageSelector(position)} />,
      position,
      [],
    );
  };

  const handleLanguageSelector = (position: IFloatingCardPosition) =>
    handleFloatingCard(
      <LanguageCard>
        <LanguageSelector />
      </LanguageCard>,
      position,
      [],
    );

  return (
    <SettingsStyle onClick={handleActionClick}>
      <Settings sx={{ color: '#507083', fontSize: '20px' }} />
    </SettingsStyle>
  );
};

export default SettingsButton;
