import { forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { ButtonType } from '../../constants/buttonType';
import SvgIcon from '../SvgIcon';

interface IGenericButton {
  disabled?: boolean;
  buttonColor?: string;
  borderColor?: string;
  size?: 'small' | 'default';
  icon?: ReactNode | undefined;
  title: string;
  type?: ButtonType;
  widthAuto?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const primaryButton = css<{ buttonColor?: string; borderColor?: string }>`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 53.12%),
    ${(props) => props.buttonColor};
  color: #ffffff;
  border-color: ${(props) => (props.borderColor ? props.borderColor : undefined)};
`;

const secondaryButton = css`
  border: 1px solid #97b0bd;
  background: #ffffff;
  color: #3b5a6b;
`;

const ButtonStyle = styled.button<{
  isDisabled: boolean;
  size?: 'small' | 'default';
  widthAuto: boolean | undefined;
  buttonColor?: string;
  borderColor?: string;
  variant?: ButtonType;
}>`
  width: ${(props) => (props.widthAuto ? 'auto' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (!props.size || props.size === 'default' ? '47px' : '35px')};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  white-space: nowrap;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  padding: ${(props) => (props.widthAuto ? '0 32px' : '0')};

  ${({ variant }) => (variant === ButtonType.SECONDARY ? secondaryButton : primaryButton)}
`;

const Title = styled.span`
  margin-inline: 6px;
`;

type Ref = HTMLButtonElement;

const GenericButton = forwardRef<Ref, IGenericButton>(
  (
    {
      disabled = false,
      buttonColor,
      borderColor,
      size,
      icon,
      title,
      type,
      widthAuto,
      onClick,
      className,
    },
    ref,
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick && onClick(e);
    };

    const color = disabled ? '#C2C2C2' : '#507083';

    return (
      <ButtonStyle
        ref={ref}
        onClick={handleClick}
        disabled={disabled}
        isDisabled={disabled}
        size={size}
        widthAuto={widthAuto}
        buttonColor={buttonColor ?? color}
        borderColor={borderColor}
        variant={type}
        className={className}
      >
        <Title>{title}</Title>
        {icon && (
          <SvgIcon
            children={icon}
            color={type === ButtonType.PRIMARY ? '#fff' : undefined}
            width={24}
            height={24}
          ></SvgIcon>
        )}
      </ButtonStyle>
    );
  },
);

export default GenericButton;
