import { MutableRefObject } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { LastMileDelivery } from '../../../@types/lastMile';
import { OrderResponse } from '../../../@types/orders';
import { NotifyOrderQueue, WebsocketTopic } from '../../../@types/websocket';
import { LastMileDeliveryStatus } from '../../../constants/lastMile';
import { useDeliveryContextConnector } from './connector';

export interface IDeliveryContextDelegate {
  /**
   * Delivery unique identifier
   */
  deliveryId: string | null;

  /**
   * Property that has a detail of a last-mile delivery
   */
  lastMileDetails: LastMileDelivery | null;

  /**
   * Property that indicates if the delivery context is loading a delivery
   */
  loading: boolean;

  /**
   * Order details array from delivery
   *
   * If delivery id is null, then this array will represent only orders
   * to be displayed in the sidebar and map that are still unassigned to a driver
   */
  ordersDetails: OrderResponse[];

  /**
   * Mutable reference from 'orderDetails' to be used outside main thread
   */
  ordersDetailsRef: MutableRefObject<OrderResponse[]>;

  /**
   * Get Last mile delivery object
   *
   * @param {OrderResponse} orderDetails details of the order
   */
  getLastMileDelivery(orderDetails: OrderResponse): Promise<void>;

  /**
   * Get orders details by delivery id
   *
   * @param {string} deliveryId delivery unique identifier
   */
  getOrdersByDeliveryId(deliveryId: string): Promise<void>;

  /**
   * Get order details by the order id
   *
   * @param {string} orderId order unique identifier
   */
  getOrderById(orderId: string): Promise<void>;

  /**
   * Subscribe to Websocket context pub/sub
   */
  subscribeToWebsocket(): void;

  /**
   * Unsubscribe to Websocket context pub/sub
   */
  unsubscribeToWebsocket(): void;

  /**
   * Set last mile details property from delivery context
   *
   * @param lastMileDetails lastMileDetails of an order
   */
  setLastMileDetails(lastMileDetails: LastMileDelivery): void;

  /**
   * Set orderDetails property on delivery context
   *
   * @param loading loading state
   */
  setOrderDetails(orderDetails: OrderResponse[]): void;

  /**
   * Set loading property from delivery context
   *
   * @param loading loading state
   */
  setLoading(loading: boolean): void;
}

type DeliveryContextConnector = ReturnType<typeof useDeliveryContextConnector>;

export class DeliveryContextDelegate
  extends Delegate<DeliveryContextConnector>
  implements IDeliveryContextDelegate
{
  get deliveryId(): string | null {
    return this.connector.deliveryId;
  }

  get lastMileDetails(): LastMileDelivery | null {
    return this.connector.lastMileDetails;
  }

  get loading(): boolean {
    return this.connector.loading;
  }

  get ordersDetails(): OrderResponse[] {
    return this.connector.ordersDetails;
  }

  get ordersDetailsRef(): MutableRefObject<OrderResponse[]> {
    return this.connector.ordersDetailsRef;
  }

  getLastMileDelivery = async (orderDetails: OrderResponse): Promise<void> => {
    if (
      orderDetails.lastMileDeliveryId &&
      orderDetails.lastMileDeliveryStatus !== LastMileDeliveryStatus.MATCHING &&
      orderDetails.lastMileDeliveryStatus !== LastMileDeliveryStatus.NO_DRIVERS_FOUND
    ) {
      const { regionCode, brand, restaurantId, lastMileDeliveryId } = orderDetails;

      const lastMileDetails = await this.connector.ordersService.getLastMileDeliveryById(
        regionCode,
        brand,
        restaurantId,
        lastMileDeliveryId,
      );

      this.connector.setLastMileDetails(lastMileDetails);
    }
  };

  getOrderById = async (orderId: string): Promise<void> => {
    this.setLoading(true);
    this.setDeliveryId(null);
    this.setOrdersDetails([]);

    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const orderDetails = await this.connector.ordersService.getOrderDetails(
        regionCode,
        brand,
        restaurantId,
        orderId,
      );

      this.setOrdersDetails([orderDetails]);
    } catch (_) {
      return;
    }

    this.setLoading(false);
  };

  getOrdersByDeliveryId = async (deliveryId: string): Promise<void> => {
    this.setLoading(true);
    this.setDeliveryId(null);
    this.setOrdersDetails([]);

    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const delivery = await this.connector.deliveryService.getOrdersByDeliveryId(
        regionCode,
        brand,
        restaurantId,
        deliveryId,
      );

      this.setDeliveryId(deliveryId);

      if (!this.compareLastState(this.ordersDetailsRef.current, delivery.orderDetails)) {
        this.setOrdersDetails(delivery.orderDetails);
      }
    } catch (_) {
      return;
    }

    this.setLoading(false);
  };

  handleWebsocketMessage = async (msg: string, { payload }: NotifyOrderQueue): Promise<void> => {
    const existOrder = this.ordersDetailsRef.current.find(
      (order) => order.rbiNumberId === payload.rbiNumberId,
    );

    if (existOrder) {
      if (payload.deliveryId) {
        if (payload.events.length > existOrder.events.length) {
          await this.getOrdersByDeliveryId(payload.deliveryId);
        }
      } else {
        this.setLoading(true);

        try {
          const existIndex = this.ordersDetailsRef.current.findIndex(
            (order) => order.rbiNumberId === payload.rbiNumberId,
          );

          const filteredOrders = this.ordersDetailsRef.current.filter(
            (order) => order.rbiNumberId !== payload.rbiNumberId,
          );

          const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

          const updatedOrder = await this.connector.ordersService.getOrderDetails(
            regionCode,
            brand,
            restaurantId,
            payload.rbiNumberId,
          );

          // add order details to the same index that it was before
          filteredOrders.splice(existIndex, 0, updatedOrder);

          this.connector.setOrdersDetails(() => [...filteredOrders]);
        } catch {
          return;
        }

        this.setLoading(false);
      }
    }
  };

  setLastMileDetails = (lasstMileDetails: LastMileDelivery): void => {
    this.connector.setLastMileDetails(lasstMileDetails);
  };

  setLoading = (loading: boolean): void => {
    this.connector.setLoading(loading);
  };

  setOrderDetails = (orderDetails: OrderResponse[]): void => {
    this.connector.setOrdersDetails(orderDetails);
  };

  subscribeToWebsocket = () => {
    this.connector.websocketContext.subscribeToWebsocket(
      WebsocketTopic.ORDER_EVENT,
      this.handleWebsocketMessage,
    );
  };

  unsubscribeToWebsocket = () => {
    this.connector.websocketContext.unsubscribeToWebsocket(() => ({}));
  };

  private compareLastState = (prevState: OrderResponse[], nextState: OrderResponse[]) => {
    return JSON.stringify(prevState) === JSON.stringify(nextState);
  };

  private setDeliveryId = (deliveryId: string | null): void => {
    this.connector.setDeliveryId(deliveryId);
  };

  private setOrdersDetails = (orders: OrderResponse[]): void => {
    const sortedOrders = this.connector.orderUtils.getOrdersSortedByCreationDate(orders);

    this.connector.setOrdersDetails(sortedOrders as OrderResponse[]);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useDeliveryContextDelegate = () =>
  new DeliveryContextDelegate(useDeliveryContextConnector());
