import { FC } from 'react';
import styled from 'styled-components';

import PosStatus from '../PosStatus';
import TotalActiveDrivers from '../TotalActiveDrivers';

const StatusStyle = styled.div`
  display: flex;
  min-width: max-content;
  flex-direction: column;
  align-items: end;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #878787;
  margin-right: 12px;
  justify-content: space-between;
  gap: 4px;
`;

const Status: FC = () => {
  return (
    <StatusStyle>
      <TotalActiveDrivers />
      <PosStatus />
    </StatusStyle>
  );
};

export default Status;
