import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
// eslint-disable-next-line node/no-missing-import
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { env } from './constants/env';
import { PrinterConfig } from './constants/print';
import reportWebVitals from './reportWebVitals';

import './i18n.config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.launchDarklyClientId,
    context: {
      anonymous: true,
      brand: env.brand,
      kind: 'user',
    },
    flags: {
      ['display-fees-from-webhook']: false,
      ['enableAutoFire']: false,
      ['enableCancelOrderSecondColumn']: true,
      ['enableLastMileProviders']: false,
      ['enableNewIssueCardDesign']: false,
      ['enableOrderPrepared']: false,
      ['enablePreScheduleOrders']: false,
      ['printer-configuration']: PrinterConfig.EPSON,
      ['timeToEnableButtonToMoveOrder']: 60,
    },
  });

  root.render(
    <LDProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </LDProvider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
