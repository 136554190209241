import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '../DatePicker';

interface IFilterText {
  filterDate: Date;
  onChange: (date: Date | null) => void;
}

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const FilterLabel = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const FilterDate: FC<IFilterText> = ({ filterDate, onChange }) => {
  const { t } = useTranslation();

  return (
    <FilterContainer>
      <FilterLabel>{t('pages.History.Header.filter')}:</FilterLabel>
      <DatePickerWrapper>
        <DatePicker inputLabel="Date" selectedDate={filterDate} onChange={onChange} />
      </DatePickerWrapper>
    </FilterContainer>
  );
};

export default FilterDate;
