import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LanguageList } from '../../@types/language';
import { ReactComponent as Basque } from '../../assets/icons/languages/basque.svg';
import { ReactComponent as Catalan } from '../../assets/icons/languages/catalan.svg';
import { ReactComponent as English } from '../../assets/icons/languages/english.svg';
import { ReactComponent as Espanol } from '../../assets/icons/languages/espanol.svg';
import { ReactComponent as French } from '../../assets/icons/languages/french.svg';
import { ReactComponent as Gallego } from '../../assets/icons/languages/gallego.svg';
import { ReactComponent as German } from '../../assets/icons/languages/german.svg';
import { ReactComponent as Portugues } from '../../assets/icons/languages/portugues.svg';
import { Languages } from '../../constants/languages';
import SvgIcon from '../SvgIcon';

interface ILanguageOption {
  language: Languages;
  onClick: (language: Languages) => void;
}

const LanguageRow = styled.button`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height 47px;
  align-items: center;
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const LanguageTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #191919;
`;

const LanguageOption: FC<ILanguageOption> = ({ language, onClick }) => {
  const { t } = useTranslation();

  const languageList: Partial<LanguageList> = {
    ca: {
      icon: <Catalan />,
      title: 'components.Language.options.ca',
    },
    de_DE: {
      icon: <German />,
      title: 'components.Language.options.deDE',
    },
    en_GB: {
      icon: <English />,
      title: 'components.Language.options.enGB',
    },
    es_ES: {
      icon: <Espanol />,
      title: 'components.Language.options.esES',
    },
    eu_ES: {
      icon: <Basque />,
      title: 'components.Language.options.euES',
    },
    fr_FR: {
      icon: <French />,
      title: 'components.Language.options.frFR',
    },
    gl_ES: {
      icon: <Gallego />,
      title: 'components.Language.options.glES',
    },
    pt_PT: {
      icon: <Portugues />,
      title: 'components.Language.options.ptPT',
    },
  };

  return (
    <LanguageRow key={language} onClick={() => onClick(language)}>
      <SvgIcon children={languageList[language]!.icon} width={16} height={16} color="#ffffff" />
      <LanguageTitle>{t(languageList[language]!.title)}</LanguageTitle>
    </LanguageRow>
  );
};

export default LanguageOption;
