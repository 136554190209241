import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GenericButton from '../../../../components/buttons/GenericButton';
import { ButtonType } from '../../../../constants/buttonType';
import { LastMileProviders } from '../../../../constants/lastMile';
import { LastMileModalType } from '../../../../constants/modalType';
import { useLastMileModalDelegate } from './controllers/delegate';

interface ILastMileModal {
  provider: LastMileProviders;
  type: LastMileModalType;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

const Title = styled.h3`
  margin-top: 0px;
  margin-bottom: 16px;

  color: #000000;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.03em;
`;

const SubTitle = styled.span`
  margin-bottom: 40px;

  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const PaddingStyle = styled.div`
  padding: 40px 32px;
`;

const ComponentStyle = styled.div`
  width: 600px;
  min-height: 274px;
  display: flex;
  flex-direction: column;
`;

const FooterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BodyStyle = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const LastMileModal: FC<ILastMileModal> = ({ provider, type, onConfirmClick, onBackClick }) => {
  const { t } = useTranslation();

  const { findAndReplace } = useLastMileModalDelegate();

  const assigningDriver = (): ReactElement<any, any> => (
    <>
      <Title>
        {findAndReplace(
          t('pages.OrderManagement.LastMileModal.assignDriver.title'),
          '{provider}',
          provider,
        )}
      </Title>
      <SubTitle>
        {findAndReplace(
          t('pages.OrderManagement.LastMileModal.assignDriver.subtitle'),
          '{provider}',
          provider,
        )}
      </SubTitle>
    </>
  );

  const cancelLastMileDriver = (): ReactElement<any, any> => (
    <>
      <Title>
        {findAndReplace(
          t('pages.OrderManagement.LastMileModal.cancelDriver.title'),
          '{provider}',
          provider,
        )}
      </Title>
      <SubTitle>
        {findAndReplace(
          t('pages.OrderManagement.LastMileModal.cancelDriver.subtitle'),
          '{provider}',
          provider,
        )}
      </SubTitle>
    </>
  );

  const cancelledByLastMileDriver = (): ReactElement<any, any> => (
    <>
      <Title>
        {findAndReplace(
          t('pages.OrderManagement.LastMileModal.cancelledByLastMileDriver.title'),
          '{provider}',
          provider,
        )}
      </Title>
      <SubTitle>
        {t('pages.OrderManagement.LastMileModal.cancelledByLastMileDriver.subtitle')}
      </SubTitle>
    </>
  );

  const pickTitleAndSubtitle = (type: LastMileModalType): ReactElement<any, any> => {
    switch (type) {
      case LastMileModalType.ASSIGN_DRIVER:
        return assigningDriver();
      case LastMileModalType.CANCEL_DRIVER:
        return cancelLastMileDriver();
      case LastMileModalType.CANCELLED_BY_LAST_MILE_DRIVER:
        return cancelledByLastMileDriver();
    }
  };

  const pickPrimaryButtonTitle = (type: LastMileModalType): string => {
    if (type === LastMileModalType.CANCELLED_BY_LAST_MILE_DRIVER) {
      return t('pages.OrderManagement.LastMileModal.cancelledByLastMileDriver.closeOrder');
    }

    return t('pages.OrderManagement.CloseWithIssue.IssueList.confirmButton');
  };

  return (
    <ComponentStyle>
      <PaddingStyle>
        <BodyStyle>{pickTitleAndSubtitle(type)}</BodyStyle>
        <FooterStyle>
          <GenericButton
            title={t('pages.OrderManagement.CancelOrder.backButton')}
            type={ButtonType.SECONDARY}
            onClick={() => onBackClick()}
            widthAuto
          />
          <GenericButton
            title={pickPrimaryButtonTitle(type)}
            onClick={() => onConfirmClick()}
            widthAuto
          />
        </FooterStyle>
      </PaddingStyle>
    </ComponentStyle>
  );
};

export default LastMileModal;
