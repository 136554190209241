import { DriverResponse, DriverShiftPayload } from '../@types/drivers';
import { HTTPClient } from '../@types/handlers/httpClient';
import { env } from '../constants/env';

export interface IDriversService {
  /**
   * List all drivers
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @return {DriverResponse[]} return a list of drivers as promise
   */
  getAll(country: string, brand: string, restaurantId: string): Promise<DriverResponse[]>;

  /**
   * List all available drivers on shift
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @return {DriverResponse[]} return a list of drivers as promise
   */
  getAllAvailable(country: string, brand: string, restaurantId: string): Promise<DriverResponse[]>;

  /**
   * Get one driver detail info
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} driverId Driver unique identifier
   * @return {DriverResponse} return a driver as promise
   */
  getOne(
    country: string,
    brand: string,
    restaurantId: string,
    driverId: string,
  ): Promise<DriverResponse>;

  /**
   * Update a drivers shift and motorbike number
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} driverId Driver unique identifier
   * @param {string} permissionToken Permission token generated to authorize the manager to update a driver availability and motorbike number
   * @param {DriverShiftPayload} body body of request to update a driver availability and motorbike number
   * @return {DriverResponse} return a driver information as promise
   */
  updateDriverShift(
    country: string,
    brand: string,
    restaurantId: string,
    driverId: string,
    permissionToken: string,
    body: DriverShiftPayload,
  ): Promise<DriverResponse>;
}

class DriversService extends HTTPClient implements IDriversService {
  async getAll(country: string, brand: string, restaurantId: string): Promise<DriverResponse[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/drivers?available=2`;
    const response = await this.get<DriverResponse[]>(url);

    return response.data;
  }

  async getAllAvailable(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<DriverResponse[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/drivers?available=1`;
    const response = await this.get<DriverResponse[]>(url);

    return response.data;
  }

  async getOne(
    country: string,
    brand: string,
    restaurantId: string,
    driverId: string,
  ): Promise<DriverResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/drivers/${driverId}`;
    const response = await this.get<DriverResponse>(url);

    return response.data;
  }

  async updateDriverShift(
    country: string,
    brand: string,
    restaurantId: string,
    driverId: string,
    permissionToken: string,
    body: DriverShiftPayload,
  ): Promise<DriverResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/drivers/${driverId}/availability`;
    const response = await this.put<DriverShiftPayload, DriverResponse>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }
}

export default new DriversService(env.deliveryServiceAPI);
