import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrderResponse } from '../../../@types/orders';
import EmptyPage from './EmptyPage';
import PreOrderCard from './PreOrderCard';

interface IPreScheduledDashboard {
  orders: Map<string, Array<OrderResponse>>;
  filterText: string;
}

const Container = styled.div`
  width: calc(100% - 32px);
  height: calc(775px - 48px);
  padding: 24px 16px;

  border-radius: 24px;
  border: 1px solid #c2c2c2;
  background: #fff;

  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 60px;
    background: #ececec;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background: #3b5a6b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
`;

const TimeStamp = styled.div`
  height: 44px;
  width: 45px;
  padding: 0px 10px;

  border-radius: 10px;
  background: #dae7ee;

  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.18px;
  color: #3b5a6b;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IntervalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TimeStampContainer = styled.div`
  padding-top: 30px;
  margin-right: 12px;

  display: flex;
  flex-direction: column;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const ScheduleDashboard: FC<IPreScheduledDashboard> = ({ orders, filterText }) => {
  const { t } = useTranslation();

  const renderCards = (order: OrderResponse) => {
    return <PreOrderCard key={order.displayOrderId} order={order} />;
  };

  if (orders.size === 0) {
    return filterText.length > 0 ? (
      <Container>
        <EmptyPage message={t('pages.PreOrders.noCustomerFound')} />
      </Container>
    ) : (
      <Container>
        <EmptyPage message={t('pages.PreOrders.emptyState')} />
      </Container>
    );
  }

  const renderContent = () => {
    const cards: JSX.Element[] = [];
    orders.forEach((value, key) => {
      if (value.length) {
        cards.push(
          <IntervalContainer key={key}>
            <TimeStampContainer>
              <TimeStamp>{key}</TimeStamp>
            </TimeStampContainer>
            <CardsContainer>{value.map((order) => renderCards(order))}</CardsContainer>
          </IntervalContainer>,
        );
      }
    });

    return cards;
  };

  return (
    <Container>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export default ScheduleDashboard;
