import { Delegate } from '../../../../../@types/handlers/delegate';
import { DateFormat } from '../../../../../utils/DateTimeUtils';
import useDateTimeConnector from './connector';

type DateTimeConnector = ReturnType<typeof useDateTimeConnector>;

interface IDateTimeDelegate {
  /**
   * Get current time formatted
   *
   * @returns {string} formatted time
   */
  currentTime: string;

  /**
   * Get current date formatted
   *
   * @returns {string} formatted date
   */
  currentDate: string;

  /**
   * Get timezone from date utils
   *
   * @returns {string | null} timezone or null (if not defined yet)
   */
  timezone: string | null;

  /**
   * Update current time, format and set in time state
   *
   * @returns {NodeJS.Timer} node timer reference from setInterval
   */
  updateCurrentTime(): NodeJS.Timer;
}

export class DateTimeDelegate extends Delegate<DateTimeConnector> implements IDateTimeDelegate {
  get currentTime(): string {
    return this.connector.time;
  }

  get currentDate(): string {
    return this.connector.dateUtils.formatDate(new Date(), DateFormat.DD_MM_YY);
  }

  get timezone(): string | null {
    return this.connector.dateUtils.getTimezone();
  }

  updateCurrentTime = (): NodeJS.Timer => {
    this.setActualTime();

    const interval = 10000;
    return setInterval(() => {
      this.setActualTime();
    }, interval);
  };

  private setActualTime = () => {
    const dateFormatted = this.connector.dateUtils.formatDate(new Date(), DateFormat.HH_MM);
    this.connector.setTime(dateFormatted);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useDateTimeDelegate = () => new DateTimeDelegate(useDateTimeConnector());
