import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ComplaintsResponse } from '../../../@types/complaints';
import complaintsService from '../../../services/ComplaintsService';
import { useAuthContext } from '../../AuthContext';
import { useWebsocketContext } from '../../WebsocketContext';

export const useComplaintsContextConnector = () => {
  const { pathname: currentPath } = useLocation();
  const [openedComplaints, setOpenedComplaints] = useState<ComplaintsResponse[]>([]);
  const [closedComplaints, setClosedComplaints] = useState<ComplaintsResponse[]>([]);
  const [unseenComplaintNotifications, setUnseenComplaintNotifications] = useState<string[]>([]);

  const notificationsRef = useRef<string[]>(unseenComplaintNotifications);
  const closedComplaintsRef = useRef<ComplaintsResponse[]>(closedComplaints);
  const openedComplaintsRef = useRef<ComplaintsResponse[]>(openedComplaints);
  const currentPathRef = useRef<string>(currentPath);

  return {
    authContext: useAuthContext(),
    closedComplaints,
    closedComplaintsRef,
    complaintsService,
    currentPath,
    currentPathRef,
    notificationsRef,
    openedComplaints,
    openedComplaintsRef,
    setClosedComplaints,
    setOpenedComplaints,
    setUnseenComplaintNotifications,
    unseenComplaintNotifications,
    websocketContext: useWebsocketContext(),
  };
};
