import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { useDateTimeDelegate } from './controllers/delegate';

const DateTimeStyle = styled.div`
  display: flex;
  flex-direction: column;
  color: #878787;
  align-items: center;
`;

const TimeStyle = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.03em;
`;

const DateStyle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

const DateTime: FC = () => {
  const { currentTime, currentDate, timezone, updateCurrentTime } = useDateTimeDelegate();

  useEffect(() => {
    let timeInterval: NodeJS.Timer;

    if (timezone) {
      timeInterval = updateCurrentTime();
    }

    return () => {
      clearInterval(timeInterval);
    };
  }, [timezone]);

  return (
    <DateTimeStyle>
      <TimeStyle>{currentTime}</TimeStyle>
      <DateStyle>{currentDate}</DateStyle>
    </DateTimeStyle>
  );
};

export default DateTime;
