import { useOrdersContext } from '../../../contexts/OrdersContext';

const useNavBarConnector = () => {
  const { scheduledOrders } = useOrdersContext();

  return {
    scheduledOrders,
  };
};

export default useNavBarConnector;
