import { createContext, FC, useContext } from 'react';

import GenericToast from './components/GenericToast';
import { IToastContextDelegate, useToastContextDelegate } from './controllers/delegate';

export const ToastContext = createContext<Pick<IToastContextDelegate, 'addToast'>>({
  addToast: () => ({}),
});

ToastContext.displayName = 'Toast';

interface IToastProvider {
  children: JSX.Element;
}

const ToastProvider: FC<IToastProvider> = ({ children }) => {
  const { toasts, addToast } = useToastContextDelegate();

  return (
    <ToastContext.Provider value={{ addToast }}>
      <GenericToast content={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);

export default ToastProvider;
