import { Close } from '@mui/icons-material';
import { FC, ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';

export interface IFloatingCardPosition {
  top: number;
  left: number;
}

interface IGenericFloatingCard {
  handleFloatingCard: () => void;
  show: boolean;
  position?: IFloatingCardPosition;
  content?: ReactElement<any, any>;
  dependencies?: string[];
}

const Body = styled.div<{ top: number; left: number }>`
  position: absolute;
  min-width: 50px;
  min-height: 50px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const GenericFloatingCard: FC<IGenericFloatingCard> = ({
  content,
  position = { left: 0, top: 0 },
  show,
  handleFloatingCard,
  dependencies,
}) => {
  const depsRef = useRef(dependencies);

  const clickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let closeFloatingCard = true;

    const checkParent = (id: string, child: React.ChangeEvent<HTMLInputElement>) => {
      let node = child.target.parentElement;

      while (node !== null) {
        if (node.id === id) return true;
        node = node.parentElement;
      }
      return false;
    };

    if (e.target.id === 'floating-card' || checkParent('floating-card', e)) {
      closeFloatingCard = false;
    }

    if (
      (e.target.lastChild as unknown as { id: string })?.id === 'menu-' ||
      checkParent('menu-', e)
    ) {
      closeFloatingCard = false;
    }

    if (closeFloatingCard) {
      depsRef.current?.map((id) => {
        if (e.target.id === id || checkParent(id, e)) {
          closeFloatingCard = false;
        }
      });
    }

    if (closeFloatingCard) handleFloatingCard();
  };

  useEffect(() => {
    window.addEventListener('click', clickHandler as unknown as EventListener, true);
    return window.removeEventListener('click', clickHandler as unknown as EventListener);
  }, []);

  useEffect(() => {
    depsRef.current = dependencies;
  }, [dependencies]);

  const modal = (
    <Body id="floating-card" top={position.top} left={position.left}>
      <CloseButton onClick={() => handleFloatingCard()} data-testid="close-floating-card">
        <Close sx={{ color: '#000000', fontSize: '20px' }} />
      </CloseButton>
      {content}
    </Body>
  );

  return show ? modal : null;
};

export default GenericFloatingCard;
