import { format } from 'date-fns';

import { HTTPClient } from '../@types/handlers/httpClient';
import { LastMileDelivery } from '../@types/lastMile';
import {
  BulkDeliveryPayload,
  BulkPreparationPayload,
  CancelOrderPayload,
  CloseWithIssuePayload,
  OrderResponse,
  UnformattedOrderResponse,
} from '../@types/orders';
import { env } from '../constants/env';
import { LastMileProviders } from '../constants/lastMile';
import { FilterStatus, OrderHistoryPeriod } from '../constants/order';
import { ordersUtils } from '../utils/OrdersUtils';

export interface IOrdersService {
  /**
   * Assign a given driver to an order
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @param {string} driverId Driver unique identifier
   * @return {string} returns message as response
   */
  assignDriverToOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    driverId: string,
  ): Promise<string>;

  /**
   * Move to in preparation all orders assigned to a given driver
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {BulkPreparationPayload} body Object containing the driver unique identifier
   * @return {string} returns message as response
   */
  bulkPreparation(
    country: string,
    brand: string,
    restaurantId: string,
    body: BulkPreparationPayload,
  ): Promise<string>;

  /**
   * Delivery all orders from a given driver
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {BulkDeliveryPayload} body Object containing the driver unique identifier
   * @return {string} returns message as response
   */
  bulkDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    body: BulkDeliveryPayload,
  ): Promise<string>;

  /**
   * Calculate the best route to an order
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId deliveryId that is going to group the orders
   * @param {string} orderId Order unique identifier
   */
  calculateRoute(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<google.maps.DirectionsResult>;

  /**
   * Cancel a last-mile delivery
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @return {OrderResponse} returns the updated orderResponse
   */
  cancelLastMileDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<OrderResponse>;

  /**
   * Cancel order
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @param {CancelOrderPayload} body Object containing the reason selected for canceling the order
   * @param {string} permissionToken Permission token generated to authorize the manager to cancel the order
   * @return {string} returns message as response
   */
  cancelOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    body: CancelOrderPayload,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Close order with issue
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @param {CloseWithIssuePayload} body Object containing the issue selected and optionally
   * a cart object containing the items to be compensated
   * @param {string} permissionToken Permission token generated to authorize the manager to cancel the order
   *
   * @return {string} returns message as response
   */
  closeWithIssue(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    body: CloseWithIssuePayload,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Close order on delivery
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @return {string} returns message as response
   */
  closeWithSuccess(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<void>;

  /**
   * Move external delivery orders to delivery
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @return {string} returns message as response
   */
  externalDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string>;

  /**
   * Create a last-mile delivery
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @param {LastMileProviders} provider provider's name
   * @return {LastMileDelivery} returns the created last-mile object
   */
  createLastMileDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    provider: LastMileProviders,
  ): Promise<LastMileDelivery>;

  /**
   *Get a last-mile delivery by id
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} lastMileId last-mile unique identifier
   * @return {LastMileDelivery} returns the created last-mile object
   */
  getLastMileDeliveryById(
    country: string,
    brand: string,
    restaurantId: string,
    lastMileId: string,
  ): Promise<LastMileDelivery>;

  /**
   * Get details of a given orderId
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @return {OrderResponse} returns the details of a given order if it exists
   */
  getOrderDetails(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<OrderResponse>;

  /**
   * Get orders by restaurant
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {FilterStatus} ordersState the status of the orders that will be returned
   * @return {OrderResponse[]} returns the list of active or previous orders by restaurant
   */
  getOrdersByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    ordersState: FilterStatus,
  ): Promise<OrderResponse[]>;

  /**
   **
   * Trigger event to inform that order is already prepared
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @return {string} returns message as response
   */
  preparedOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string>;

  /**
   * Move new order to in production
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} orderId Order unique identifier
   * @return {string} returns message as response
   */
  prepareOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string>;
}

class OrdersService extends HTTPClient implements IOrdersService {
  async assignDriverToOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    driverId: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/assign-driver/${driverId}`;
    const response = await this.post<string, string>(url, '');

    return response.data;
  }

  async bulkDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    body: BulkDeliveryPayload,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/bulk-delivery`;
    const response = await this.post<BulkDeliveryPayload, string>(url, body);

    return response.data;
  }

  async bulkPreparation(
    country: string,
    brand: string,
    restaurantId: string,
    body: BulkPreparationPayload,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/bulk-preparation`;
    const response = await this.post<BulkPreparationPayload, string>(url, body);

    return response.data;
  }

  async calculateRoute(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<google.maps.DirectionsResult> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/route`;
    const response = await this.get<google.maps.DirectionsResult>(url);

    return response.data;
  }

  async cancelLastMileDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<OrderResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/last-mile`;
    const response = await this.delete<OrderResponse>(url, undefined);

    return response.data;
  }

  async cancelOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    body: CancelOrderPayload,
    permissionToken: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/cancel`;
    const response = await this.post<CancelOrderPayload, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async closeWithIssue(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    body: CloseWithIssuePayload,
    permissionToken: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/close`;
    const response = await this.post<CloseWithIssuePayload, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async closeWithSuccess(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<void> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/close`;
    const response = await this.post<undefined, void>(url, undefined);

    return response.data;
  }

  async createLastMileDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
    provider: LastMileProviders,
  ): Promise<LastMileDelivery> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/last-mile/${provider}`;
    const response = await this.post<undefined, LastMileDelivery>(url, undefined);

    return response.data;
  }

  async externalDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/external-delivery`;
    const response = await this.post<undefined, string>(url, undefined);

    return response.data;
  }

  async getLastMileDeliveryById(
    country: string,
    brand: string,
    restaurantId: string,
    lastMileId: string,
  ): Promise<LastMileDelivery> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/last-mile/${lastMileId}`;
    const response = await this.get<LastMileDelivery>(url);

    return response.data;
  }

  async getOrderDetails(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<OrderResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}`;
    const response = await this.get<UnformattedOrderResponse>(url);

    return ordersUtils().formatToOrderResponse(response.data);
  }

  async getOrdersByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    ordersState = FilterStatus.ACTIVE_ORDERS,
    period = OrderHistoryPeriod.SINGLE_DAY,
    date?: Date,
  ): Promise<OrderResponse[]> {
    const params = new URLSearchParams();
    params.append('active', ordersState);
    params.append('period', period);
    if (date) {
      params.append('day', format(date, 'yyyy-MM-dd'));
    }

    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders?${params}`;
    const response = await this.get<UnformattedOrderResponse[]>(url);

    return response.data.map((order) => {
      return ordersUtils().formatToOrderResponse(order);
    });
  }

  async lastMileDelivery(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/last-mile-delivery`;
    const response = await this.post<undefined, string>(url);

    return response.data;
  }

  async prepareOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/prepare`;
    const response = await this.post<undefined, string>(url, undefined);

    return response.data;
  }

  async preparedOrder(
    country: string,
    brand: string,
    restaurantId: string,
    orderId: string,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/orders/${orderId}/prepared`;
    const response = await this.post<undefined, string>(url, undefined);

    return response.data;
  }
}

export default new OrdersService(env.deliveryServiceAPI);
