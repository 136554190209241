import { Currency } from '../constants/currency';

interface IMoneyUtils {
  /**
   * Format an integer number into string as currency
   *
   * @param {number} value integer number
   * @param {Currency} currency currency type ('EUR', 'USD', etc)
   * @param {'start' | 'end' | undefined} position position of the symbol in the formatted string
   * @returns {string} formatted value as a string with currency symbol
   */
  moneyFormatter(value: number, currency: Currency, position?: 'start' | 'end'): string;
}

const ONE_HUNDRED = 100;

export class MoneyUtils implements IMoneyUtils {
  moneyFormatter = (value: number, currency = Currency.EUR, position?: 'start' | 'end'): string => {
    const formatValue = value / ONE_HUNDRED;

    const money = new Intl.NumberFormat('en', {
      currency,
      style: 'currency',
    }).format(formatValue);

    // eslint-disable-next-line no-useless-escape
    const symbol = money.replaceAll(/([0-9\.\,\s])/g, '');
    // eslint-disable-next-line no-useless-escape
    const amount = money.replaceAll(/([^0-9\.\,])/g, '');

    if (position === 'end') {
      return `${amount}${symbol}`;
    }

    return `${symbol}${amount}`;
  };
}

export const moneyUtils = () => new MoneyUtils();
