import { WifiOffRounded, WifiRounded } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { usePosStatusDelegate } from './controllers/delegate';

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextStyle = styled.span`
  margin-right: 8px;
`;

const IconStyle = styled.div`
  margin-right: 3px;
`;

const PosStatus: FC = () => {
  const { t } = useTranslation();
  const { pos } = usePosStatusDelegate();

  return (
    <Div>
      <TextStyle>{t('components.PageHeader.PosStatus.title')}</TextStyle>
      <IconStyle>
        {pos ? (
          <WifiRounded sx={{ color: '#79BF1F', fontSize: '22px' }} />
        ) : (
          <WifiOffRounded sx={{ color: '#D62300', fontSize: '22px' }} />
        )}
      </IconStyle>
    </Div>
  );
};

export default PosStatus;
