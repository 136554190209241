import { Pause } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as MotorcycleIcon } from '../../../../assets/icons/motorcycle.svg';
import { PermissionTokenActions } from '../../../../constants/auth';
import PasswordValidation from '../../../PasswordValidation';
import SvgIcon from '../../../SvgIcon';
import CardButton from '../../../buttons/CardButton';
import AutoFireModal from './components/AutoFireModal';
import { useAutoFireDelegate } from './controllers/delegate';

const Div = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Title = styled.span<{
  isActive: boolean;
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => (props.isActive ? '#507083' : '#878787')};
  width: 81px;
  letter-spacing: 0.06em;
  white-space: pre-line;
`;

const AutoFire: FC = () => {
  const { t } = useTranslation();
  const { autoFire, autoPrepare, handleModal, updateAutoFireStatus, pos, isServiceAvailable } =
    useAutoFireDelegate();

  const handleChangeAutoFire = () => {
    autoFire ? handleDisableAutoFire() : handleEnableAutoFire();
  };

  const handleDisableAutoFire = async (): Promise<void> => {
    const response = await updateAutoFireStatus({ status: false });

    if (response) {
      handleModal(
        <AutoFireModal
          title={t('components.PageHeader.AutoFire.Modal.isDisabled')}
          message=""
          buttonConfirm={t('components.PageHeader.AutoFire.Modal.okButton')}
          onConfirm={() => handleModal()}
          showIconOnConfirmButton
        />,
      );
    }
  };

  const handleEnableAutoFire = async (): Promise<void> => {
    const afterPasswordValidation = async (token: string): Promise<void> => {
      handleUpdateAutoFire(token);
    };

    const handleUpdateAutoFire = async (token: string): Promise<void> => {
      const autoFireUpdated = await updateAutoFireStatus({ status: true }, token);

      if (autoFireUpdated) {
        handleModal(
          <AutoFireModal
            title={t('components.PageHeader.AutoFire.Modal.isRunning')}
            message={t('components.PageHeader.AutoFire.Modal.isRunningMessage')}
            buttonConfirm={t('components.PageHeader.AutoFire.Modal.okButton')}
            onConfirm={() => handleModal()}
            showIconOnConfirmButton
          />,
        );
      }
    };

    const passwordValidationModal = (): void =>
      handleModal(
        <PasswordValidation
          title={t('components.PageHeader.AutoFire.PasswordValidation.titleToEnable')}
          action={PermissionTokenActions.AUTO_FIRE}
          onSuccess={afterPasswordValidation}
        />,
      );

    if (autoPrepare) {
      handleModal(
        <AutoFireModal
          title={t('components.PageHeader.AutoFire.Modal.stopAutoPrepareTitle')}
          message={t('components.PageHeader.AutoFire.Modal.stopAutoPrepareMessage')}
          buttonConfirm={t('components.PageHeader.AutoFire.Modal.stopAutoPrepareButton')}
          buttonCancel={t('components.PageHeader.AutoFire.Modal.cancelButton')}
          onCancel={() => handleModal()}
          onConfirm={passwordValidationModal}
        />,
      );
    } else if (!isServiceAvailable) {
      handleModal(
        <AutoFireModal
          title={t('components.PageHeader.AutoFire.Modal.runServiceConfirmationTitle')}
          message={t('components.PageHeader.AutoFire.Modal.runServiceConfirmationMessage')}
          buttonConfirm={t('components.PageHeader.AutoFire.Modal.runServiceConfirmationButton')}
          buttonCancel={t('components.PageHeader.AutoFire.Modal.cancelButton')}
          onCancel={() => handleModal()}
          onConfirm={passwordValidationModal}
          showIconOnConfirmButton
        />,
      );
    } else {
      passwordValidationModal();
    }
  };

  const renderComponent = (autoFire: boolean) => {
    if (autoFire) {
      return (
        <>
          <Title isActive={autoFire}>{t('components.PageHeader.AutoFire.titleStopAutoFire')}</Title>
          <Pause sx={{ color: '#507083', height: '32px', width: '32px' }} />
        </>
      );
    } else {
      return (
        <>
          <Title isActive={autoFire}>{t('components.PageHeader.AutoFire.titleRunAutoFire')}</Title>
          <SvgIcon children={<MotorcycleIcon />} width={24} height={24} />
        </>
      );
    }
  };

  return (
    <CardButton
      onClick={handleChangeAutoFire}
      activeColor={'#DAE7EE'}
      isSwitchedOn={autoFire}
      borderColor={'#507083'}
      isDisabled={!pos}
    >
      <Div data-testid="auto-fire">{renderComponent(autoFire)}</Div>
    </CardButton>
  );
};

export default AutoFire;
