import { CalendarMonth } from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useDatePickerDelegate } from './controllers/delegate';

interface DatePickerProps {
  closeOnSelect?: boolean;
  disableFuture?: boolean;
  inputLabel: string;
  minDate?: Date;
  maxDate?: Date;
  selectedDate: Date;
  onChange: (date: Date | null) => void;
}

export const DatePicker = ({
  closeOnSelect = true,
  disableFuture = true,
  inputLabel,
  minDate,
  maxDate,
  selectedDate,
  onChange,
}: DatePickerProps) => {
  const { getLocalizedLanguage } = useDatePickerDelegate();
  const locale = getLocalizedLanguage();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <MUIDatePicker
        disableFuture={disableFuture}
        maxDate={maxDate}
        minDate={minDate}
        label={inputLabel}
        onChange={onChange}
        closeOnSelect={closeOnSelect}
        slots={{ openPickerIcon: CalendarMonth }}
        slotProps={{
          calendarHeader: {
            sx: {
              '& .MuiIconButton-edgeEnd': {
                bottom: 0,
                left: 0,
                position: 'absolute',
                top: 0,
              },
              '& .MuiIconButton-edgeStart': {
                bottom: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              },
              '& .MuiPickersArrowSwitcher-root': {
                width: 0,
              },
              '& .MuiPickersCalendarHeader-label ': {
                fontSize: '18px',
                fontWeight: '600',
                letterSpacing: '-0.18px',
                lineHeight: '26px',
              },
              '& .MuiPickersCalendarHeader-labelContainer': {
                fontFamily: 'Work Sans',
                margin: 'auto',
              },
              '& .MuiPickersCalendarHeader-root': {
                margin: '16px',
              },
              margin: '16px',
              padding: '0',
              position: 'relative',
              svg: { width: '24px' },
            },
          },
          day: {
            sx: {
              '&.MuiPickersDay-root.Mui-selected': {
                backgroundColor: '#507083',
              },
            },
          },
          field: {
            readOnly: true,
          },
          popper: {
            sx: {
              '& .MuiCalendarPicker-root': {
                fontFamily: 'Work Sans',
              },
              '& .MuiDayCalendar-weekDayLabel': {
                color: '#000',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontWeight: '600',
              },
              '& .MuiPaper-root': {
                fontFamily: 'Work Sans',
              },
              '& .MuiPickersDay-dayWithMargin': {
                fontFamily: 'Work Sans',
                fontSize: '16px',
                letterSpacing: '-0.16px',
              },
              '& .MuiYearPicker-root': {
                fontFamily: 'Work Sans',
              },
            },
          },
        }}
        sx={{
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: '#000',
            },
            color: '#000',
            fontFamily: 'Work Sans',
            fontWeight: 400,
            letterSpacing: '-0.12px',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#507083',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#507083',
            },
            borderRadius: '8px',
            fieldset: {
              borderColor: '#507083',
            },
          },
          'button.MuiButtonBase-root': {
            margin: 0,
            padding: 0,
          },
          'div.MuiInputAdornment-root': {
            margin: 0,
          },
          'div.MuiInputBase-root': {
            background: 'white',
            paddingRight: '12px',
          },
          'input.MuiInputBase-input': {
            fontFamily: 'Work Sans',
            letterSpacing: '-0.16px',
            lineHeight: '24px',
            padding: '6px 16px',
          },
          svg: { color: '#97B0BD', width: '16px' },
        }}
        value={selectedDate}
      />
    </LocalizationProvider>
  );
};
