import {
  AccessTime,
  AssignmentTurnedInRounded,
  PinDropRounded,
  ReportRounded,
  ViewWeekRounded,
} from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as CashflowIcon } from '../../assets/icons/cashflow.svg';
import { ReactComponent as CellphoneIcon } from '../../assets/icons/cellphone.svg';
import { ReactComponent as DriversIcon } from '../../assets/icons/driver.svg';
import { PagePath } from '../../constants/pages';
import NavBarItem, { INavBarItem } from './components/NavBarItem';
import { useNavBarDelegate } from './controllers/delegate';

const Base = styled.div`
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;
  left: 0px;
  top: 108px;
  background-color: #ffffff;
  border-top: 1px solid #97b0bd;
  position: fixed;
  z-index: 9;
`;

const NavBar: FC = () => {
  const { t } = useTranslation();
  const { scheduledOrdersQuantity } = useNavBarDelegate();
  const { enablePreScheduleOrders } = useFlags();

  const { pathname: currentPath } = useLocation();

  const Pages: INavBarItem[] = [
    {
      icon: <ViewWeekRounded />,
      path: PagePath.ORDERS_MANAGEMENT,
      title: t('components.Navbar.ordersManagement'),
    },
    {
      icon: <AccessTime />,
      path: PagePath.PRE_ORDERS,
      title: `${t('components.Navbar.preOrders')} (${scheduledOrdersQuantity})`,
    },
    {
      icon: <PinDropRounded />,
      path: PagePath.MAP,
      title: t('components.Navbar.map'),
    },
    {
      icon: <AssignmentTurnedInRounded />,
      path: PagePath.HISTORY,
      title: t('components.Navbar.history'),
    },
    {
      icon: <ReportRounded />,
      path: PagePath.COMPLAINTS,
      title: t('components.Navbar.complaints'),
    },
    {
      icon: <CashflowIcon />,
      isSvg: true,
      path: PagePath.CASHFLOW,
      title: t('components.Navbar.cashflow'),
    },
    {
      icon: <DriversIcon />,
      isSvg: true,
      path: PagePath.DRIVERS,
      title: t('components.Navbar.drivers'),
    },
    {
      icon: <CellphoneIcon />,
      isSvg: true,
      path: PagePath.DRIVER_APP,
      title: t('components.Navbar.driverApp'),
    },
  ];

  return (
    <Base>
      {Pages.map((page) =>
        !enablePreScheduleOrders && page.path === PagePath.PRE_ORDERS ? (
          <Fragment key={page.title}></Fragment>
        ) : (
          <NavBarItem key={page.title} selected={currentPath === `/${page.path}`} {...page} />
        ),
      )}
    </Base>
  );
};

export default NavBar;
