import { useRef, useState } from 'react';

import { ActualETAResponse } from '../../../@types/eta';
import { SettingsColorsResponse } from '../../../@types/settings';
import { RestaurantCapacity, RestaurantServiceStatus } from '../../../constants/restaurants';
import restaurantService from '../../../services/RestaurantService';
import settingsService from '../../../services/SettingsService';
import { useAuthContext } from '../../AuthContext';
import { useToastContext } from '../../ToastContext';
import { useWebsocketContext } from '../../WebsocketContext';

export const useSettingsContextConnector = () => {
  const [colors, setColors] = useState<SettingsColorsResponse | null>(null);
  const [actualEtaInfo, setActualEtaInfo] = useState<ActualETAResponse | null>(null);

  const [eta, setEta] = useState<number | null>(null);
  const [restaurantCapacity, setRestaurantCapacity] = useState<RestaurantCapacity | null>(null);
  const restaurantCapacityRef = useRef(restaurantCapacity);
  const [pos, setPos] = useState<boolean>(false);
  const [serviceStatus, setServiceStatus] = useState<RestaurantServiceStatus | null>(null);
  const [autoPrepare, setAutoPrepare] = useState<boolean>(false);
  const [autoFire, setAutoFire] = useState<boolean>(false);
  const [preScheduledIntervals, setPreScheduledIntervals] = useState<string[]>([]);

  const autoPrepareRef = useRef<boolean>(autoPrepare);

  return {
    actualEtaInfo,
    authContext: useAuthContext(),
    autoFire,
    autoPrepare,
    autoPrepareRef,
    colors,
    eta,
    pos,
    preScheduledIntervals,
    restaurantCapacity,
    restaurantCapacityRef,
    restaurantService,
    serviceStatus,
    setActualEtaInfo,
    setAutoFire,
    setAutoPrepare,
    setColors,
    setEta,
    setPos,
    setPreScheduledIntervals,
    setRestaurantCapacity,
    setServiceStatus,
    settingsService,
    toastContext: useToastContext(),
    websocketContext: useWebsocketContext(),
  };
};
