import { CheckCircle } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

interface IEmptyPage {
  message: string;
}

const Text = styled.p`
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const EmptyPage: FC<IEmptyPage> = ({ message }) => {
  return (
    <Container>
      <CheckCircle sx={{ color: '#507083', fontSize: '24px', marginRight: '12px' }} />
      <Text>{message}</Text>
    </Container>
  );
};

export default EmptyPage;
