import { ReactElement, useState } from 'react';

import { IFloatingCardPosition } from '../components/GenericFloatingCard';

export const useFloatingCardContextConnector = () => {
  const [content, setContent] = useState<ReactElement<any, any> | undefined>(undefined);
  const [position, setPosition] = useState<IFloatingCardPosition | undefined>(undefined);
  const [dependencies, setDependencies] = useState<string[] | undefined>(undefined);

  return {
    content,
    dependencies,
    position,
    setContent,
    setDependencies,
    setPosition,
  };
};
