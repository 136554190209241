import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ComplaintsProvider from '../../contexts/ComplaintsContext';
import DeliveryProvider from '../../contexts/DeliveryContext';
import DriversProvider from '../../contexts/DriversContext';
import FloatingCardProvider from '../../contexts/FloatingCardContext';
import ModalProvider from '../../contexts/ModalContext';
import OrdersProvider from '../../contexts/OrdersContext';
import SettingsProvider from '../../contexts/SettingsContext';
import SidebarProvider from '../../contexts/SidebarContext';
import ToastProvider from '../../contexts/ToastContext';
import WebsocketProvider from '../../contexts/WebsocketContext';

interface ILoggedProviders {
  children: JSX.Element;
}

const LoggedProviders: FC<ILoggedProviders> = ({ children }) => {
  return (
    <BrowserRouter>
      <WebsocketProvider>
        <ToastProvider>
          <SettingsProvider>
            <DriversProvider>
              <DeliveryProvider>
                <OrdersProvider>
                  <ComplaintsProvider>
                    <ModalProvider>
                      <SidebarProvider>
                        <FloatingCardProvider>{children}</FloatingCardProvider>
                      </SidebarProvider>
                    </ModalProvider>
                  </ComplaintsProvider>
                </OrdersProvider>
              </DeliveryProvider>
            </DriversProvider>
          </SettingsProvider>
        </ToastProvider>
      </WebsocketProvider>
    </BrowserRouter>
  );
};

export default LoggedProviders;
