import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormattedFees } from '../../@types/orders';

export interface IOrderFees {
  fees: FormattedFees;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
`;

const Fee = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #5a5a5a;
`;

const OrderFees: FC<IOrderFees> = ({ fees }) => {
  const { deliveryFee, serviceFee } = fees;
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Title>{t('pages.OrderManagement.OrderDetails.fees.serviceFee')}</Title>
        <Fee>{serviceFee}</Fee>
      </Row>
      <Row>
        <Title>{t('pages.OrderManagement.OrderDetails.fees.deliveryFee')}</Title>
        <Fee>{deliveryFee}</Fee>
      </Row>
    </Container>
  );
};

export default OrderFees;
