import { format } from 'date-fns';

import {
  CloseComplaintPayload,
  ComplaintsResponse,
  PredefinedComments,
  UnformattedComplaintsResponse,
} from '../@types/complaints';
import { HTTPClient } from '../@types/handlers/httpClient';
import { ComplaintStatus } from '../constants/complaints';
import { env } from '../constants/env';
import { complaintsUtils } from '../utils/ComplaintsUtils';

export interface IComplaintsService {
  /**
   * Close complaint
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} complaintId the complaintId
   * @param {CloseComplaintPayload} body Object containing the resolution type to close and optionally comments
   * and a cart object containing the items to be redelivered
   * @return {ComplaintsResponse} returns the updated complaint after being closed
   */
  closeComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
    body: CloseComplaintPayload,
  ): Promise<ComplaintsResponse>;

  /**
   * Get the possibles comments for a complaint
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} complaintId the complaintId
   * @return {PredefinedComments[]} possible comments for a complaint
   */
  getCommentsForComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<PredefinedComments[]>;

  /**
   * Get complaints by restaurant
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {ComplaintsStatus} status the status of the complaints that will be returned
   * @return {ComplaintsResponse[]} returns a list of all complaints for the day OR just open complaints OR closed complaints for the day
   */
  getComplaintsByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    status?: ComplaintStatus,
  ): Promise<ComplaintsResponse[]>;

  /**
   * Get details in a given complaint
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} complaintId the complaintId of the complaint that will be returned
   * @return {ComplaintsResponse} the details of the complaint and the order
   */
  getComplaintsDetails(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<ComplaintsResponse>;

  /**
   * Get a list of resolutions for a complaint
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId Restaurant unique identifier
   * @param {string} complaintId the complaintId
   * @return {string[]} possible resolutions for a complaint
   */
  getResolutionsForComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<string[]>;
}

const complaintsUtil = complaintsUtils();

class ComplaintsService extends HTTPClient implements IComplaintsService {
  async closeComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
    body: CloseComplaintPayload,
  ): Promise<ComplaintsResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/complaints/${complaintId}/close`;
    const response = await this.post<CloseComplaintPayload, UnformattedComplaintsResponse>(
      url,
      body,
    );

    return complaintsUtil.formatToComplaintsResponse(response.data);
  }

  async getCommentsForComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<PredefinedComments[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/complaints/${complaintId}/comments`;
    const response = await this.get<PredefinedComments[]>(url);

    return response.data;
  }

  async getComplaintsByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    status?: ComplaintStatus,
    date?: Date,
  ): Promise<ComplaintsResponse[]> {
    let url = `/${country}/${brand}/restaurants/${restaurantId}/complaints`;

    if (status) url = `${url}?status=${status}`;

    if (date) {
      const params = new URLSearchParams();
      params.append('endDate', format(date, 'yyyy-MM-dd'));
      params.append('startDate', format(date, 'yyyy-MM-dd'));

      url = `${url}?${params}`;
    }

    const response = await this.get<UnformattedComplaintsResponse[]>(url);

    return response.data.map((item) => complaintsUtil.formatToComplaintsResponse(item));
  }

  async getComplaintsDetails(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<ComplaintsResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/complaints/${complaintId}`;
    const response = await this.get<UnformattedComplaintsResponse>(url);

    return complaintsUtil.formatToComplaintsResponse(response.data);
  }

  async getResolutionsForComplaint(
    country: string,
    brand: string,
    restaurantId: string,
    complaintId: string,
  ): Promise<string[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/complaints/${complaintId}/resolutions`;
    const response = await this.get<string[]>(url);

    return response.data;
  }
}
export default new ComplaintsService(env.deliveryServiceAPI);
