import { t } from 'i18next';
import { MutableRefObject } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { OrderResponse } from '../../../@types/orders';
import { NotifyLastMileUpdate, NotifyOrderQueue, WebsocketTopic } from '../../../@types/websocket';
import { LastMileDeliveryStatus, LastMileEventType } from '../../../constants/lastMile';
import { OrderEventType } from '../../../constants/order';
import { ToastTypes } from '../../../constants/toasts';
import { useOrdersContextConnector } from './connector';

export interface IOrdersContextDelegate {
  /**
   * Current path being accessed in the application
   */
  currentPath: string;

  /**
   * React reference to Current path being accessed in the application
   */
  currentPathRef: MutableRefObject<string>;

  /**
   * Control variable to validate if is the
   * first time that we are loading the orders list
   */
  firstLoading: boolean;

  /**
   * React reference to the firstLoading attribute
   * to be used inside the setInterval
   */
  firstLoadingRef: MutableRefObject<boolean>;

  /**
   * Get orders list filtered by scheduled
   */
  scheduledOrders: OrderResponse[];

  /**
   * Get orders list filtered by new orders
   */
  newOrders: OrderResponse[];

  /**
   * Get orders list filtered by orders that were closed or finalized by driver
   */
  ordersClosedOrFinalizedByDriver: OrderResponse[];

  /**
   * Get orders list filtered by orders in production
   */
  ordersInProduction: OrderResponse[];

  /**
   * Get orders list filtered by orders on delivery
   */
  ordersOnDelivery: OrderResponse[];

  /**
   * Get boolean that indicates if map widget is being shown or not
   */
  showMap: boolean;

  /**
   * Add an order to the orders closed or finalized by driver array
   *
   * @param {OrderResponse} order order to be added
   */
  addOrderClosedOrFinalizedByDriver(order: OrderResponse): void;

  /**
   * Call method to immediately update the ordersList context
   */
  forceUpdate(): Promise<void>;

  /**
   * Get all active orders by restaurant
   */
  getOrdersByRestaurant: () => NodeJS.Timer | undefined;

  /**
   * Remove an order from the orders closed  or finalized by driver array
   *
   * @param {string} orderId orderId to be removed
   */
  removeOrderClosedOrFinalizedByDriver(orderId: string): void;

  /**
   * Retrieve Orders closed with issue from localStorage
   *
   */
  retrieveOrdersClosedWithIssue(): void;

  /**
   * Subscribe to Websocket context pub/sub
   */
  subscribeToWebsocket(): void;

  /**
   * Unsubscribe to Websocket context pub/sub
   */
  unsubscribeToWebsocket(): void;

  /**
   * Set boolean that indicates if map widget is being shown or not
   *
   * @param {boolean} value flag that indicates if map is to be shown or not
   */
  setShowMap(value: boolean): void;
}

type OrdersContextConnector = ReturnType<typeof useOrdersContextConnector>;

export class OrdersContextDelegate
  extends Delegate<OrdersContextConnector>
  implements IOrdersContextDelegate
{
  get currentPath(): string {
    return this.connector.currentPath;
  }

  get currentPathRef(): MutableRefObject<string> {
    return this.connector.currentPathRef;
  }

  get firstLoading(): boolean {
    return this.connector.firstLoading;
  }

  get firstLoadingRef(): MutableRefObject<boolean> {
    return this.connector.firstLoadingRef;
  }

  get scheduledOrders(): OrderResponse[] {
    return this.connector.scheduledOrders;
  }

  get newOrders(): OrderResponse[] {
    return this.connector.newOrders;
  }

  get ordersInProduction(): OrderResponse[] {
    return this.connector.ordersInProduction;
  }

  get ordersOnDelivery(): OrderResponse[] {
    return this.connector.ordersOnDelivery;
  }

  get ordersClosedOrFinalizedByDriver(): OrderResponse[] {
    return this.connector.ordersClosedOrFinalizedByDriver;
  }

  get showMap(): boolean {
    return this.connector.showMap;
  }

  addOrderClosedOrFinalizedByDriver = (closedOrder: OrderResponse) => {
    this.connector.setOrdersOnDelivery((orders) => {
      const filteredOrders = orders.filter((item) => item.rbiNumberId !== closedOrder.rbiNumberId);
      this.updateInLocalStorage(
        '@RBI_Expeditor_Tablet:Orders_On_Delivery',
        JSON.stringify([...filteredOrders]),
      );
      return filteredOrders;
    });

    this.connector.setOrdersClosedOrFinalizedByDriver((orders) => {
      const closeOrders = [...orders, closedOrder];
      this.updateInLocalStorage(
        '@RBI_Expeditor_Tablet:Orders_Closed_With_Issues',
        JSON.stringify([...closeOrders]),
      );
      return closeOrders;
    });
  };

  forceUpdate = async (): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    await this.connector.ordersService
      .getOrdersByRestaurant(regionCode, brand, restaurantId)
      .then((response) => {
        this.setOrdersInContext(response);
        this.soundPlayer(response);
      });
  };

  getOrdersByRestaurant = (): NodeJS.Timer | undefined => {
    this.forceUpdate();

    const interval = 5000;
    try {
      return setInterval(() => {
        this.forceUpdate();
      }, interval);
    } catch (err) {
      return undefined;
    }
  };

  handleWebsocketMessage = (
    _: string,
    { eventName, payload }: NotifyOrderQueue | NotifyLastMileUpdate,
  ): void => {
    if (eventName === OrderEventType.ORDER_CANCELLED) {
      const event = payload.events.find(({ name }) => name === eventName);

      event?.role?.toLocaleLowerCase() === 'driver' &&
        this.addOrderClosedOrFinalizedByDriver(payload);
    }

    if (eventName === OrderEventType.ORDER_FINALIZED) {
      payload.lastMileDeliveryId && this.addOrderClosedOrFinalizedByDriver(payload);
    }

    if (eventName === LastMileEventType.LAST_MILE_DELIVERY_UPDATED) {
      const lastEvent = payload.events[payload.events.length - 1].name;

      if (lastEvent === LastMileDeliveryStatus.NO_DRIVERS_FOUND) {
        this.connector.toastContext.addToast(
          t('pages.OrderManagement.OrderDetails.lastMile.errors.noDriversFound', {
            orderId: payload.orderId,
          }),
          ToastTypes.ERROR,
        );
      }

      if (lastEvent === LastMileDeliveryStatus.MATCHED) {
        this.connector.toastContext.addToast(
          t('pages.OrderManagement.OrderDetails.lastMile.driverAssigned.successMessage', {
            orderId: payload.orderId,
          }),
          ToastTypes.SUCCESS,
        );
      }

      this.connector.deliveryContext.getOrderById(payload.orderId);
    }
  };

  removeOrderClosedOrFinalizedByDriver = (orderId: string) => {
    const filteredOrders = this.ordersClosedOrFinalizedByDriver.filter(
      (order) => order.rbiNumberId !== orderId,
    );

    this.connector.setOrdersClosedOrFinalizedByDriver(() => [...filteredOrders]);

    this.updateInLocalStorage(
      '@RBI_Expeditor_Tablet:Orders_Closed_With_Issues',
      JSON.stringify(filteredOrders),
    );
  };

  retrieveOrdersClosedWithIssue = () => {
    const storedOrdersWithIssue = window.localStorage.getItem(
      '@RBI_Expeditor_Tablet:Orders_Closed_With_Issues',
    );

    storedOrdersWithIssue &&
      this.connector.setOrdersClosedOrFinalizedByDriver(JSON.parse(storedOrdersWithIssue));
  };

  setShowMap = (value: boolean): void => {
    this.connector.setShowMap(value);
  };

  subscribeToWebsocket = (): void => {
    this.connector.websocketContext.subscribeToWebsocket(
      WebsocketTopic.ORDER_EVENT,
      this.handleWebsocketMessage,
    );
  };

  unsubscribeToWebsocket = (): void => {
    this.connector.websocketContext.unsubscribeToWebsocket(this.handleWebsocketMessage);
  };

  private setOrdersInContext = (orders: OrderResponse[]): void => {
    const filteredScheduledOrders = this.connector.ordersUtils.getFilteredOrders(orders, [
      OrderEventType.ORDER_PRE_SCHEDULED,
    ]);
    const filteredNewOrders = this.connector.ordersUtils.getFilteredOrders(orders, [
      OrderEventType.ORDER_ACCEPTED,
    ]);
    const filteredOrdersInProduction = this.connector.ordersUtils.getFilteredOrders(orders, [
      OrderEventType.ORDER_IN_PREPARATION,
    ]);
    const filteredOrdersOnDelivery = this.connector.ordersUtils.getFilteredOrders(orders, [
      OrderEventType.ORDER_IN_DELIVERY,
    ]);

    this.connector.setScheduledOrders(filteredScheduledOrders);
    this.connector.setNewOrders(filteredNewOrders);
    this.connector.setOrdersInProduction(filteredOrdersInProduction);
    this.connector.setOrdersOnDelivery(filteredOrdersOnDelivery);

    this.updateInLocalStorage(
      '@RBI_Expeditor_Tablet:New_Orders',
      JSON.stringify(filteredNewOrders),
    );
    this.updateInLocalStorage(
      '@RBI_Expeditor_Tablet:Orders_In_Production',
      JSON.stringify(filteredOrdersInProduction),
    );
    this.updateInLocalStorage(
      '@RBI_Expeditor_Tablet:Orders_On_Delivery',
      JSON.stringify(filteredOrdersOnDelivery),
    );
  };

  private soundPlayer = (orders: OrderResponse[]): void => {
    if (!this.connector.firstLoadingRef.current && !this.currentPathRef.current.includes('print')) {
      const parseArrRbiNumberIds = (item: OrderResponse): string => item.rbiNumberId;

      const currentOrderIds = orders.map(parseArrRbiNumberIds);
      const refOrderIds = this.connector.ordersRef.current.map(parseArrRbiNumberIds);

      if (currentOrderIds.some((item) => !refOrderIds.includes(item))) {
        this.connector.audioPlayer.play();
      }
    } else {
      this.connector.setFirstLoading(false);
    }

    this.connector.ordersRef.current = orders;
  };

  private updateInLocalStorage = (key: string, value: string): void => {
    window.localStorage.setItem(key, value);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useOrdersContextDelegate = () =>
  new OrdersContextDelegate(useOrdersContextConnector());
