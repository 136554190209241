import { useRef, useState } from 'react';

import { AuthUser } from '../../../@types/auth';
import { Restaurant } from '../../../@types/restaurants';
import authService from '../../../services/AuthService';
import restaurantService from '../../../services/RestaurantService';
import { dateTimeUtils } from '../../../utils/DateTimeUtils';

export const useAuthContextConnector = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userRef = useRef(user);
  const restaurantRef = useRef(restaurant);

  return {
    authService,
    isLoading,
    restaurant,
    restaurantRef,
    restaurantService,
    setIsLoading,
    setRestaurant,
    setUser,
    user,
    userRef,
    utilsDate: dateTimeUtils(),
  };
};
