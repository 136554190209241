import { ReactElement } from 'react';

import { DriverResponse } from '../../../../../@types/drivers';
import { Delegate } from '../../../../../@types/handlers/delegate';
import { OrderResponse } from '../../../../../@types/orders';
import { ToastTypes } from '../../../../../constants/toasts';
import useOrderDetailsFooterConnector from './connector';

type OrderDetailsFooterConnector = ReturnType<typeof useOrderDetailsFooterConnector>;

interface IOrderDetailsFooterDelegate {
  /**
   * If auto prepare is active
   */
  autoPrepare: boolean;

  /**
   * Property that indicates if the pos is active or not
   */
  pos: boolean;

  /**
   * Property that indicates if the map is opened or not
   */
  showMap: boolean;

  /**
   * Close an order
   *
   * @param {string} orderId order unique identifier to be closed
   * @return true if the request succeeded or false otherwise
   */
  closeOrderWithSuccess(orderId: string): Promise<boolean>;

  /**
   * Get driver assigned to a given order
   *
   * @param {string} orderId order id to get the driver
   * @returns {DriverResponse | undefined} returns the driver assigned to the order or undefined
   */
  getDriverAssignedToOrder(orderId: string): DriverResponse | undefined;

  /**
   * Handle to open or close the modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;

  /**
   * Handle to open or close sidebar
   *
   * @param {ReactElement<any, any>} content child component to be rendered inside the sidebar
   * @param {string[]} dependencies list of ids as dependency to maintain the sidebar opened
   */
  handleSidebar(content?: ReactElement<any, any>, dependencies?: string[]): void;

  /**
   * Move a external delivery mode order to delivery
   *
   * @param {string} orderId order unique identifier to be closed
   */
  moveExternalDeliveryToOnDelivery(orderId: string): Promise<void>;

  /**
   * Move order to in production column
   *
   * @param {string} orderId order unique identifier
   * @returns {Promise<boolean>} returns boolean status to success or error
   */
  moveToInProduction(orderId: string): Promise<boolean>;

  /**
   * Move order to on delivery column
   *
   * @param {string} driverId driver unique identifier
   */
  moveToOnDelivery(driverId: string): Promise<void>;

  /**
   * Move last mile order by id from IN_PRODUCTION to ON_DELIVERY
   *
   * @param {string} order Id of the order that is going to be delivered
   */
  moveToDeliveryLastMile(order: string): Promise<void>;

  /**
   * Remove the order closed or finalized by driver when the user clicks on dismiss button
   *
   * @param {string} orderId order unique identifier
   */
  removeOrderClosedOrFinalizedByDriver(orderId: string): void;

  /**
   * Show map visibility
   *
   * @param {boolean} showMap new map visibility status
   */
  setShowMap(showMap: boolean): void;

  /**
   * It will trigger an event to inform aggregator that the order is already PREPARED
   *
   * @param {string} orderId order unique identifier
   * @param {boolean} hasPreparedEvent this attribute indicates if the PREPARED event was already triggered or not
   */
  triggerOrderPreparedEvent(orderId: string, hasPreparedEvent: boolean): Promise<void>;
}

export class OrderDetailsFooterDelegate
  extends Delegate<OrderDetailsFooterConnector>
  implements IOrderDetailsFooterDelegate
{
  get autoPrepare(): boolean {
    return this.connector.settingsContext.autoPrepare;
  }

  get pos(): boolean {
    return this.connector.settingsContext.pos;
  }

  get showMap(): boolean {
    return this.connector.ordersContext.showMap;
  }

  get newOrders(): OrderResponse[] {
    return this.connector.ordersContext.newOrders;
  }

  addToast = (message: string, type: ToastTypes): void => {
    this.connector.toastContext.addToast(message, type);
  };

  closeOrderWithSuccess = async (orderId: string): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.closeWithSuccess(regionCode, brand, restaurantId, orderId);

      this.connector.ordersContext.forceUpdate();

      return true;
    } catch (_) {
      this.addToast(
        'pages.OrderManagement.OrderDetails.footer.errors.closeOrderToastError',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  getDriverAssignedToOrder = (orderId: string): DriverResponse | undefined => {
    const order = this.newOrders.find(({ rbiNumberId }) => rbiNumberId === orderId);
    const driverId = order?.driverId;

    if (driverId)
      return this.connector.driversContext.driversOnShift.find(
        (driver) => driver.identifier === driverId,
      );
  };

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  handleMoveToProduction = async (orderId: string): Promise<boolean> => {
    if (!this.connector.settingsContext.pos) return false;

    const driver = this.getDriverAssignedToOrder(orderId);

    if (driver && driver.ordersAssigned.length > 1) {
      return this.moveToProductionBulk(driver.identifier);
    } else {
      return this.moveToInProduction(orderId);
    }
  };

  handleSidebar = (content?: ReactElement<any, any>, dependencies?: string[]): void => {
    this.connector.sidebarContext.handleSidebar(content, dependencies);
  };

  moveExternalDeliveryToOnDelivery = async (orderId: string): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.externalDelivery(regionCode, brand, restaurantId, orderId);

      await this.connector.ordersContext.forceUpdate();

      this.handleSidebar();
    } catch {
      this.addToast(
        'pages.OrderManagement.OrderDetails.footer.errors.moveToOnDeliveryToastError',
        ToastTypes.ERROR,
      );
    }
  };

  moveToDeliveryLastMile = async (orderId: string): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.lastMileDelivery(regionCode, brand, restaurantId, orderId);

      await this.connector.ordersContext.forceUpdate();

      this.handleSidebar();
    } catch (_) {
      this.addToast(
        'pages.OrderManagement.InProduction.moveToDeliveryToastError',
        ToastTypes.ERROR,
      );
    }
  };

  moveToInProduction = async (orderId: string): Promise<boolean> => {
    try {
      if (!this.connector.settingsContext.pos) throw new Error();

      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.prepareOrder(regionCode, brand, restaurantId, orderId);

      this.connector.ordersContext.forceUpdate();

      this.handleSidebar();
      return true;
    } catch (_) {
      this.addToast(
        'pages.OrderManagement.OrderDetails.footer.errors.moveToInProductionToastError',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  moveToOnDelivery = async (driverId: string): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.bulkDelivery(regionCode, brand, restaurantId, {
        driverId,
      });

      await this.connector.ordersContext.forceUpdate();

      this.handleSidebar();
    } catch (err) {
      const errorMessage = (err as any).response.data.message;
      if (errorMessage === 'Order status is different than IN_PREPARATION') {
        this.addToast(
          'pages.OrderManagement.OrderDetails.footer.errors.moveToDeliveryToastNotAllInProductionError',
          ToastTypes.ERROR,
        );
      } else {
        this.addToast(
          'pages.OrderManagement.OrderDetails.footer.errors.moveToOnDeliveryToastError',
          ToastTypes.ERROR,
        );
      }
    }
  };

  moveToProductionBulk = async (driverId: string): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.bulkPreparation(regionCode, brand, restaurantId, {
        driverId,
      });

      this.connector.ordersContext.forceUpdate();

      this.handleSidebar();

      return true;
    } catch {
      this.addToast(
        'pages.OrderManagement.OrderDetails.footer.errors.moveToInProductionToastError',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  removeOrderClosedOrFinalizedByDriver = (orderId: string): void => {
    this.connector.ordersContext.removeOrderClosedOrFinalizedByDriver(orderId);

    this.handleSidebar();
  };

  setShowMap = (showMap: boolean): void => {
    this.connector.ordersContext.setShowMap(showMap);
  };

  triggerOrderPreparedEvent = async (orderId: string, hasPreparedEvent: boolean): Promise<void> => {
    try {
      if (hasPreparedEvent) {
        this.addToast(
          'pages.OrderManagement.OrderDetails.prepared.toast.alreadyTriggered',
          ToastTypes.SUCCESS,
        );
        return;
      }

      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.preparedOrder(regionCode, brand, restaurantId, orderId);

      this.connector.ordersContext.forceUpdate();

      this.addToast(
        'pages.OrderManagement.OrderDetails.prepared.toast.success',
        ToastTypes.SUCCESS,
      );
    } catch {
      this.addToast('pages.OrderManagement.OrderDetails.prepared.toast.error', ToastTypes.ERROR);
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useOrderDetailsFooterDelegate = () =>
  new OrderDetailsFooterDelegate(useOrderDetailsFooterConnector());
