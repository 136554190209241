import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ComplaintsNotification } from '../ComplaintsNotification';
import NavBar from '../Navbar';
import PageHeader from '../PageHeader';

interface IPrivateRoute {
  element: ReactNode;
}

const Base = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Page = styled.div`
  display: flex;
  flex: 1;
  background-color: #dae7ee;
  margin-top: 204px;
`;

const PrivateRoute: FC<IPrivateRoute> = ({ element }) => {
  return (
    <>
      <PageHeader />
      <NavBar />
      <ComplaintsNotification />
      <Base>
        <Page>{element}</Page>
      </Base>
    </>
  );
};

export default PrivateRoute;
