import { CheckCircle } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

import { ButtonType } from '../../../../../constants/buttonType';
import GenericButton from '../../../../buttons/GenericButton';

interface IAutoFireModal {
  title: string;
  message: string;
  buttonConfirm: string;
  buttonCancel?: string;
  showIconOnConfirmButton?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const Container = styled.div`
  padding: 0 32px 32px 32px;
  width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.96px;
`;

const TextContainer = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.18px;
  margin-bottom: 32px;
`;

const FlexRow = styled.div<{ hasMoreThanOneButton: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.hasMoreThanOneButton ? 'space-between' : 'end')};
  width: 100%;
`;

const AutoFireModal: FC<IAutoFireModal> = ({
  message,
  title,
  buttonConfirm,
  buttonCancel,
  onConfirm,
  onCancel,
  showIconOnConfirmButton = false,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <TextContainer>{message}</TextContainer>
      <FlexRow hasMoreThanOneButton={!!buttonCancel}>
        {buttonCancel && (
          <GenericButton
            title={buttonCancel}
            type={ButtonType.SECONDARY}
            size="default"
            widthAuto={true}
            onClick={onCancel}
          />
        )}

        <GenericButton
          title={buttonConfirm}
          icon={showIconOnConfirmButton ? <CheckCircle /> : undefined}
          widthAuto={true}
          onClick={onConfirm}
        />
      </FlexRow>
    </Container>
  );
};

export default AutoFireModal;
