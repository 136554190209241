import { Delegate } from '../../../../../@types/handlers/delegate';
import useLastMileModalConnector from './connector';

interface ILastMileModalDelegate {
  /**
   * Find string and replace in a sentence
   *
   * @param {string} sentence to search for string
   * @param {string} replaced string to be replaced
   * @param {string} replacement string to be put in place of the replaced
   * @returns {string} result string
   */
  findAndReplace(sentence: string, replaced: string, replacement: string): string;
}

type useLastMileModalConnector = ReturnType<typeof useLastMileModalConnector>;

export class LastMileModalDelegate
  extends Delegate<useLastMileModalConnector>
  implements ILastMileModalDelegate
{
  findAndReplace = (sentence: string, replaced: string, replacement: string): string => {
    return this.connector.stringUtils.findAndReplace(sentence, replaced, replacement);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useLastMileModalDelegate = () =>
  new LastMileModalDelegate(useLastMileModalConnector());
