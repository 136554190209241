import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Loading from '../../../../components/Loading';
import PasswordValidation from '../../../../components/PasswordValidation';
import GenericButton from '../../../../components/buttons/GenericButton';
import GenericRadioList from '../../../../components/inputs/GenericRadioList';
import ResponseStatusModal from '../../../../components/modals/ResponseStatusModal';
import OrderDetailsHeader from '../../../../components/orders/OrderDetailsHeader';
import OrderHeaderInfo from '../../../../components/orders/OrderHeaderInfo';
import { PermissionTokenActions } from '../../../../constants/auth';
import { ButtonType } from '../../../../constants/buttonType';
import { StatusModalType } from '../../../../constants/modalType';
import { useCancelOrderDelegate } from './controllers/delegate';

interface ICancelOrder {
  orderId: string;
  displayOrderId: string;
  onBackClick: () => void;
}

const VerticalBorder = styled.div`
  background: #c2c2c2;
  width: 2px;
  height: 329px;
  margin: 0 40px;
`;

const Title = styled.h3`
  margin-top: 0px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
`;

const SubTitle = styled.span`
  margin-bottom: 40px;
  line-height: 24px;
  color: #000000;
`;

const PaddingStyle = styled.div`
  padding: 32px 24px;
`;

const ComponentStyle = styled.div`
  width: 908px;
  min-height: 544px;
  display: flex;
  flex-direction: column;
`;

const ContentStyle = styled.div`
  display: flex;
`;

const RightContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FooterStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonStyle = styled.div`
  width: 180px;
`;

const BodyStyle = styled.div`
  padding: 0px 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const JustificationTitle = styled.h3`
  margin-top: 0px;
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 405px;
`;

const CancelOrder: FC<ICancelOrder> = ({ orderId, onBackClick }) => {
  const { t } = useTranslation();

  const {
    cancelIssues,
    issueSelected,
    orderDetails,
    cancelOrder,
    handleModal,
    getIssuesList,
    getOrderDetails,
    setIssueSelected,
    getFormattedHeaderInfo,
    getFormattedOrderDetailsHeader,
  } = useCancelOrderDelegate();

  useEffect(() => {
    getOrderDetails(orderId);
    getIssuesList();
  }, []);

  const handleInputChange = async (issueId: string) => {
    setIssueSelected(issueId);
  };

  const handleCancelClick = async (orderId: string, displayOrderId: string, issueId: string) => {
    const payload = { displayOrderId, orderId };

    const showSuccessWarning = () =>
      handleModal(
        <ResponseStatusModal
          message={`#${displayOrderId} ${t(
            'pages.OrderManagement.CancelOrder.successfullyCancelledOrderMessage',
          )}`}
          onClick={() => handleModal()}
          type={StatusModalType.ERROR}
        />,
        '#FFFFFF',
      );

    handleModal(
      <PasswordValidation
        title={`${t(
          'pages.OrderManagement.CancelOrder.authenticationModalMessage',
        )} #${displayOrderId}`}
        payload={payload}
        action={PermissionTokenActions.CANCEL_ORDER}
        onSuccess={async (permissionToken) => {
          const result = await cancelOrder(orderId, issueId, permissionToken);
          if (result) showSuccessWarning();
        }}
      />,
    );
  };

  const headerDetails = useMemo(() => getFormattedOrderDetailsHeader(orderDetails), [orderDetails]);
  const orderHeaderInfo = useMemo(() => getFormattedHeaderInfo(orderDetails), [orderDetails]);

  return orderDetails && cancelIssues ? (
    <ComponentStyle>
      <PaddingStyle>
        <BodyStyle>
          <Title>{t('pages.OrderManagement.CancelOrder.title')}</Title>
          <SubTitle>{t('pages.OrderManagement.CancelOrder.subTitle')}</SubTitle>
          <ContentStyle>
            <Header>
              <OrderDetailsHeader
                quadrant={headerDetails.quadrant}
                createdHour={headerDetails.createdHour}
                statusColor={headerDetails.statusColor}
                customerName={headerDetails.customerName}
                customerPhone={headerDetails.customerPhone}
                channel={headerDetails.channel}
                displayOrderId={headerDetails.displayOrderId}
                paymentMethod={headerDetails.paymentMethod}
                formattedAmountToBePaid={headerDetails.formattedAmountToBePaid}
                deliveryMode={orderDetails.deliveryMode}
              />
              <OrderHeaderInfo
                address={orderHeaderInfo.address}
                channel={orderHeaderInfo.channel}
                paymentMethod={orderHeaderInfo.paymentMethod}
                timerAccepted={orderHeaderInfo.timerAccepted}
                timerColumn={orderHeaderInfo.timerColumn}
                lastEvent={orderHeaderInfo.lastEvent}
                driverName={orderHeaderInfo.driverName}
                createdDate={orderHeaderInfo.createdDate}
                createdHour={orderHeaderInfo.createdHour}
                deliveryMode={orderDetails.deliveryMode}
              />
            </Header>
            <VerticalBorder />
            <RightContentStyle>
              <JustificationTitle>
                {t('pages.OrderManagement.CancelOrder.cancelationTitle')}
              </JustificationTitle>
              <GenericRadioList
                options={cancelIssues}
                onChange={handleInputChange}
                dataTestId="cancel-option"
              />
            </RightContentStyle>
          </ContentStyle>
        </BodyStyle>
        <FooterStyle>
          <ButtonStyle>
            <GenericButton
              title={t('pages.OrderManagement.CancelOrder.backButton')}
              type={ButtonType.SECONDARY}
              onClick={() => onBackClick()}
            />
          </ButtonStyle>
          <ButtonStyle>
            <GenericButton
              title={t('pages.OrderManagement.CancelOrder.cancelButton')}
              disabled={!issueSelected}
              onClick={() =>
                handleCancelClick(
                  orderDetails.rbiNumberId,
                  orderDetails.displayOrderId,
                  issueSelected,
                )
              }
            />
          </ButtonStyle>
        </FooterStyle>
      </PaddingStyle>
    </ComponentStyle>
  ) : (
    <Loading />
  );
};

export default CancelOrder;
