import { PrintRounded } from '@mui/icons-material';
import { FC, useEffect, useState } from 'react';

import { PagePath } from '../../../constants/pages';
import { ToastTypes } from '../../../constants/toasts';
import PrintPage from '../../PrintPage';
import GenericIconButton from '../GenericIconButton';
import { useGenericPrintButtonDelegate } from './controllers/delegate';

interface IGenericPrintButton {
  id: string;
  params?: string;
  page: PagePath;
}

const GenericPrintButton: FC<IGenericPrintButton> = ({ id, page, params }) => {
  const { addToast, printPage } = useGenericPrintButtonDelegate();

  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [printClick, setPrintClick] = useState<boolean>(false);

  const iframeId = `print-button-${id}`;

  useEffect(() => {
    printClick && iframeLoaded && handlePrint();
  }, [printClick, iframeLoaded]);

  const handlePrint = (): void => {
    if (iframeLoaded) {
      printPage(iframeId);
    } else {
      setPrintClick(true);
      addToast('constants.print.pageNotLoaded', ToastTypes.WARNING);
    }
  };

  const handleReload = (): void => {
    setIframeLoaded(false);
    setPrintClick(false);
  };

  return (
    <>
      <GenericIconButton icon={<PrintRounded />} onClick={handlePrint} />
      <PrintPage
        iframeId={iframeId}
        src={`${page}/${params}`}
        onLoad={() => setIframeLoaded(true)}
        onReload={handleReload}
      />
    </>
  );
};

export default GenericPrintButton;
