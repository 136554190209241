import { useRef, useState } from 'react';

import { LastMileDelivery } from '../../../@types/lastMile';
import { OrderResponse } from '../../../@types/orders';
import deliveryService from '../../../services/DeliveryService';
import ordersService from '../../../services/OrdersService';
import { ordersUtils } from '../../../utils/OrdersUtils';
import { useAuthContext } from '../../AuthContext';
import { useWebsocketContext } from '../../WebsocketContext';

export const useDeliveryContextConnector = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deliveryId, setDeliveryId] = useState<string | null>(null);
  const [ordersDetails, setOrdersDetails] = useState<OrderResponse[]>([]);
  const [lastMileDetails, setLastMileDetails] = useState<LastMileDelivery | null>(null);

  const ordersDetailsRef = useRef<OrderResponse[]>(ordersDetails);

  return {
    authContext: useAuthContext(),
    deliveryId,
    deliveryService,
    lastMileDetails,
    loading,
    orderUtils: ordersUtils(),
    ordersDetails,
    ordersDetailsRef,
    ordersService,
    setDeliveryId,
    setLastMileDetails,
    setLoading,
    setOrdersDetails,
    websocketContext: useWebsocketContext(),
  };
};
