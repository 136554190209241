import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as BookmarkCheck } from '../../../assets/icons/bookmark-check.svg';
import SvgIcon from '../../../components/SvgIcon';

const Base = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-left: 30px;
`;

export const EmptyTable: FC = () => {
  const { t } = useTranslation();

  return (
    <Base>
      <SvgIcon children={<BookmarkCheck />} width={24} height={24} color="#507083" />
      <Description>{t('pages.History.Table.emptyTable')}</Description>
    </Base>
  );
};
