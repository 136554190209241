import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../@types/input';
import { OrderResponse } from '../../../../../@types/orders';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDriversContext } from '../../../../../contexts/DriversContext';
import { useModalContext } from '../../../../../contexts/ModalContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import ordersService from '../../../../../services/OrdersService';
import settingsService from '../../../../../services/SettingsService';
import { dateTimeUtils } from '../../../../../utils/DateTimeUtils';
import { moneyUtils } from '../../../../../utils/MoneyUtils';
import { ordersUtils } from '../../../../../utils/OrdersUtils';

const useCancelOrderConnector = () => {
  const { t: translate } = useTranslation();

  const [orderDetails, setOrderDetails] = useState<OrderResponse | null>(null);

  const [cancelIssuesOptions, setCancelIssuesOptions] = useState<SelectOption[] | null>(null);
  const [issueSelected, setIssueSelected] = useState<string>('');

  return {
    authContext: useAuthContext(),
    cancelIssuesOptions,
    dateUtils: dateTimeUtils(),
    driversContext: useDriversContext(),
    issueSelected,
    modalContext: useModalContext(),
    moneyUtils: moneyUtils(),
    orderDetails,
    ordersService,
    ordersUtils: ordersUtils(),
    setCancelIssuesOptions,
    setIssueSelected,
    setOrderDetails,
    settingsService,
    toastContext: useToastContext(),
    translate,
  };
};

export default useCancelOrderConnector;
