import { PermissionTokenPayload } from '../../../@types/auth';
import { Delegate } from '../../../@types/handlers/delegate';
import { PermissionTokenActions } from '../../../constants/auth';
import usePasswordValidationConnector from './connector';

interface IPasswordValidationDelegate {
  /**
   * password to be validated
   */
  password: string;

  /**
   * Message indicating a given error in the password input
   */
  errorMessage: string;

  /**
   * Function to set the password in the state and
   * check for error message in case of empty password
   *
   * @param {string} password new password
   */
  setPassword(password: string): void;

  /**
   * Validate the password with the API and save permission token on state
   * set error message in case of invalid credentials
   *
   * @param {PermissionTokenActions} action new API call that requires permission
   * @param {any} payload any payload to be added to the body of the permission token
   * @return {string | null} return the permission token or null
   */
  validatePassword(action: PermissionTokenActions, payload?: any): Promise<string | null>;
}

type PasswordValidationConnector = ReturnType<typeof usePasswordValidationConnector>;

export class PasswordValidationDelegate
  extends Delegate<PasswordValidationConnector>
  implements IPasswordValidationDelegate
{
  get password(): string {
    return this.connector.password;
  }

  get errorMessage(): string {
    return this.connector.errorMessage;
  }

  setPassword = (password: string): void => {
    this.connector.setPassword(password);
    this.connector.setErrorMessage(!password ? 'components.PasswordValidation.emptyField' : '');
  };

  validatePassword = async (
    action: PermissionTokenActions,
    payload?: any,
  ): Promise<string | null> => {
    const { regionCode, brand, identifier } = this.connector.authContext.user!;

    const permissionPayload: PermissionTokenPayload = {
      action,
      password: this.password,
      payload,
    };

    try {
      const { token } = await this.connector.authService.permissionToken(
        regionCode,
        brand,
        identifier,
        permissionPayload,
      );
      return token;
    } catch (_) {
      this.connector.setErrorMessage('components.PasswordValidation.invalidPassword');
      return null;
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePasswordValidationDelegate = () =>
  new PasswordValidationDelegate(usePasswordValidationConnector());
