import { useState } from 'react';

import { OrderResponse } from '../../../@types/orders';
import { HistoryTableType } from '../../../constants/order';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useDeliveryContext } from '../../../contexts/DeliveryContext';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import ordersService from '../../../services/OrdersService';
import { ordersUtils } from '../../../utils/OrdersUtils';

const useOrdersHistoryConnector = () => {
  const [cancelled, setCancelled] = useState<OrderResponse[] | null>(null);
  const [closedWithIssues, setClosedWithIssues] = useState<OrderResponse[] | null>(null);
  const [delivered, setDelivered] = useState<OrderResponse[] | null>(null);
  const [activeTable, setActiveTable] = useState<HistoryTableType>(HistoryTableType.DELIVERED);
  const [filterDate, setFilterDate] = useState<Date>(new Date());

  return {
    activeTable,
    authContext: useAuthContext(),
    cancelled,
    closedWithIssues,
    delivered,
    deliveryContext: useDeliveryContext(),
    filterDate,
    ordersService,
    ordersUtils: ordersUtils(),
    setActiveTable,
    setCancelled,
    setClosedWithIssues,
    setDelivered,
    setFilterDate,
    sidebarContext: useSidebarContext(),
  };
};

export default useOrdersHistoryConnector;
