import { Delegate } from '../../../../../@types/handlers/delegate';
import usePosStatusConnector from './connector';

interface IPosStatusDelegate {
  /**
   * POS status
   */
  pos: boolean;
}

type PosStatusConnector = ReturnType<typeof usePosStatusConnector>;

export class PosStatusDelegate extends Delegate<PosStatusConnector> implements IPosStatusDelegate {
  get pos(): boolean {
    return this.connector.pos;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePosStatusDelegate = () => new PosStatusDelegate(usePosStatusConnector());
