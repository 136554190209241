import { CheckCircleOutlineOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IToast } from '../../../@types/toast';
import { ToastTypes } from '../../../constants/toasts';

interface IGenericToast {
  content: IToast[];
}

interface IIcon {
  type: ToastTypes;
}

const ToastWrapper = styled.div`
  z-index: 9999999;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

const Toast = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 14px;
  gap: 16px;
  position: relative;
  width: 406px;
  min-height: 48px;
  background: #263c47;
  border: 2px solid ${(props) => props.color};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 10px;
`;

const ToastMessage = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const GenericToast: FC<IGenericToast> = ({ content }) => {
  const { t } = useTranslation();

  const getColor = (type: ToastTypes) => {
    switch (type) {
      case ToastTypes.SUCCESS:
        return '#79BF1F';
      case ToastTypes.ERROR:
        return '#DD3712';
      case ToastTypes.WARNING:
        return '#FFC224';
    }
  };

  const Icon: FC<IIcon> = ({ type }) => {
    const color = getColor(type);
    switch (type) {
      case ToastTypes.SUCCESS:
        return <CheckCircleOutlineOutlined sx={{ color, fontSize: '20px' }} />;
      case ToastTypes.ERROR:
        return <ErrorOutlineOutlined sx={{ color, fontSize: '20px' }} />;
      case ToastTypes.WARNING:
        return <ErrorOutlineOutlined sx={{ color, fontSize: '20px' }} />;
    }
  };

  return (
    <ToastWrapper>
      {content.map((toast) => (
        <Toast data-testid={`toast-${toast.type}`} key={toast.id} color={getColor(toast.type)}>
          <>
            <Icon type={toast.type} />
            <ToastMessage>{t(toast.message)}</ToastMessage>
          </>
        </Toast>
      ))}
    </ToastWrapper>
  );
};

export default GenericToast;
