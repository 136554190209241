export const NO_DRIVER = 'NO_DRIVER';

export enum OrderStatus {
  ORDER_PRE_SCHEDULED = 'ORDER_PRE_SCHEDULED',
  ORDER_ACCEPTED = 'ORDER_ACCEPTED',
  ORDER_IN_PREPARATION = 'ORDER_IN_PREPARATION',
  ORDER_ON_DELIVERY = 'ORDER_IN_DELIVERY',
  ORDER_DELIVERED = 'ORDER_DELIVERED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_FINALIZED = 'ORDER_FINALIZED',
}

export enum OrderEventType {
  ORDER_PRE_SCHEDULED = 'ORDER_PRE_SCHEDULED',
  ORDER_ACCEPTED = 'ORDER_ACCEPTED',
  ORDER_IN_PREPARATION = 'ORDER_IN_PREPARATION',
  ORDER_PREPARED = 'ORDER_PREPARED',
  ORDER_IN_DELIVERY = 'ORDER_IN_DELIVERY',
  ORDER_DELIVERED = 'ORDER_DELIVERED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_FINALIZED = 'ORDER_FINALIZED',
  DRIVER_ASSIGNED_TO_ORDER = 'DRIVER_ASSIGNED_TO_ORDER',
}

export enum FilterStatus {
  ALL_ORDERS = '2',
  ACTIVE_ORDERS = '1',
  PREVIOUS_ORDERS = '0',
}

export enum HistoryTableType {
  DELIVERED = 'DELIVERED',
  CLOSED_ISSUES = 'CLOSED_ISSUES',
  CANCELLED = 'CANCELLED',
}

export enum CashFlowTableType {
  TOTAL = 'TOTAL',
  MARKETPLACE = 'MARKETPLACE',
  CHANNEL = 'CHANNEL',
}

export enum OrderHistoryPeriod {
  SINGLE_DAY = '0',
  DOUBLE_DAY = '1',
  SPECIFIC_DAY = '2',
}

const pathIssueTranslation = 'pages.OrderManagement.CloseWithIssue.IssueList.IssueOptions';

export const issueOptionsTranslation: Record<string, string> = {
  '0001': `${pathIssueTranslation}.wrongAddress`,
  '0002': `${pathIssueTranslation}.customerAbsent`,
  '0003': `${pathIssueTranslation}.lateDelivery`,
  '0004': `${pathIssueTranslation}.productQuality`,
  '0005': `${pathIssueTranslation}.fakeOrder`,
  '0006': `${pathIssueTranslation}.wrongProduct`,
  '0007': `${pathIssueTranslation}.paymentIssues`,
};

const pathCancelOptionTranslation = 'pages.OrderManagement.CancelOrder.CancelOptions';

export const cancelOptionsTranslation: Record<string, string> = {
  '0001': `${pathCancelOptionTranslation}.customerRequest`,
  '0002': `${pathCancelOptionTranslation}.fakeOrder`,
  '0003': `${pathCancelOptionTranslation}.technicalIssues`,
  '0004': `${pathCancelOptionTranslation}.lastMileReason`,
};
