import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { CloseWithIssuePayload } from '../../../../../@types/orders';
import { ToastTypes } from '../../../../../constants/toasts';
import useCloseWithIssueConnector from './connector';

interface ICloseWithIssueDelegate {
  /**
   * Handle to open or close sidebar
   *
   * @param {ReactElement<any, any>} content child component to be rendered inside the sidebar
   * @param {string} closeBtnColor close modal button color
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;

  /**
   * Close order with issue and returns a boolean
   *
   * @param {string} orderId order unique identifier
   * @param {CloseWithIssuePayload} body Object containing the issue selected and optionally
   * a cart object containing the items to be compensated
   * @param {string} permissionToken Permission token generated to authorize the manager to cancel the order
   * @returns {boolean} boolean response with true on success and false on error
   */
  closeOrderWithIssue(
    orderId: string,
    body: CloseWithIssuePayload,
    permissionToken: string,
  ): Promise<boolean>;
}

type CloseWithIssueConnector = ReturnType<typeof useCloseWithIssueConnector>;

export class CloseWithIssueDelegate
  extends Delegate<CloseWithIssueConnector>
  implements ICloseWithIssueDelegate
{
  closeOrderWithIssue = async (
    orderId: string,
    body: CloseWithIssuePayload,
    permissionToken: string,
  ): Promise<boolean> => {
    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      await this.connector.ordersService.closeWithIssue(
        regionCode,
        brand,
        restaurantId,
        orderId,
        body,
        permissionToken,
      );

      this.connector.ordersContext.forceUpdate();

      return true;
    } catch (_) {
      this.connector.toastContext.addToast(
        'pages.OrderManagement.CloseWithIssue.errorOnClose',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useCloseWithIssueDelegate = () =>
  new CloseWithIssueDelegate(useCloseWithIssueConnector());
