import { HomeRounded, PaidRounded } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as DriverIcon } from '../../assets/icons/driver.svg';
import { channelNamesObject, ChannelResponses } from '../../constants/delivery';
import { DeliveryMode } from '../../constants/deliveryMode';
import { OrderEventType } from '../../constants/order';
import { PaymentMethod, paymentMethodTranslation } from '../../constants/paymentMethod';
import SvgIcon from '../SvgIcon';
import OrderInstructions from './OrderInstructions';

export interface IOrderHeaderInfo {
  address: string;
  channel: string;
  paymentMethod: string;
  createdHour?: string | null;
  createdDate?: string | null;
  deliveryInstructions?: string;
  deliveryMode?: DeliveryMode;
  lastEvent?: OrderEventType;
  driverName?: string;
  timerColumn?: string;
  timerAccepted?: string;
  totalOrderTime?: string;
  lastMileDeliveryId?: string;
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Icon = styled.div`
  margin: 0 6px 0 3px;
`;

const DriverIconMargin = styled.div`
  margin: 3px 6px 0 6px;
`;

const HomeIconMargin = styled.div`
  margin: 0px 6px 0 2px;
`;

const TextWeight400 = styled.span`
  font-weight: 400;
`;

const FontWeight600 = styled.span`
  font-weight: 600;
`;

const TimerTextRight = styled.div`
  width: 64px;
  margin-right: 10px;
`;

const TimerTextLeft = styled.div`
  width: 64px;
  margin-left: 6px;
`;

const Body = styled(FlexColumn)`
  gap: 22px;
`;

const SpanAggregator = styled.span`
  margin-right: 5px;
`;

const SpanPayment = styled.span`
  margin-left: 5px;
`;

const EmptyLine = styled.div`
  margin-bottom: 12px;
`;

const OrderHeaderInfo: FC<IOrderHeaderInfo> = ({
  address,
  channel,
  paymentMethod,
  timerAccepted,
  timerColumn,
  createdHour,
  createdDate,
  lastEvent,
  driverName,
  totalOrderTime,
  deliveryInstructions,
  deliveryMode = DeliveryMode.MANAGED_BY_STORE,
  lastMileDeliveryId,
}) => {
  const { t } = useTranslation();

  const showDriverField = (lastEvent?: OrderEventType, lastMileDeliveryId?: string) => {
    if (lastMileDeliveryId && lastEvent === OrderEventType.ORDER_IN_DELIVERY) return false;
    if (
      lastEvent === OrderEventType.ORDER_FINALIZED ||
      lastEvent === OrderEventType.ORDER_CANCELLED ||
      lastEvent === OrderEventType.ORDER_DELIVERED ||
      lastEvent === OrderEventType.ORDER_IN_DELIVERY
    ) {
      return true;
    }
    return false;
  };

  return (
    <Body>
      {address && (
        <FlexRow>
          <HomeIconMargin>
            <SvgIcon children={<HomeRounded />} width={20} height={20} color="#97B0BD" />
          </HomeIconMargin>
          <TextWeight400 data-testid="address">{address}</TextWeight400>
        </FlexRow>
      )}
      {deliveryInstructions && (
        <OrderInstructions
          title={t('pages.OrderManagement.OrderDetails.deliveryInstructions')}
          instructions={deliveryInstructions}
          dataTestId="delivery-instructions"
        />
      )}
      {lastEvent !== OrderEventType.ORDER_PRE_SCHEDULED && (
        <FlexRow>
          <Icon>
            <SvgIcon children={<ClockIcon />} width={20} height={20} color="#97B0BD" />
          </Icon>
          <TextWeight400>
            <FlexRow>
              {(lastEvent === OrderEventType.ORDER_FINALIZED ||
                lastEvent === OrderEventType.ORDER_CANCELLED) && (
                <>
                  {t('pages.OrderManagement.OrderDetails.orderedAt')}: {createdHour} - {createdDate}
                </>
              )}
              {lastEvent === OrderEventType.ORDER_DELIVERED && (
                <div>
                  {t('pages.OrderManagement.OrderDetails.deliveredIn')}{' '}
                  <FontWeight600>{totalOrderTime}</FontWeight600>
                </div>
              )}
              {(lastEvent === OrderEventType.DRIVER_ASSIGNED_TO_ORDER ||
                lastEvent === OrderEventType.ORDER_ACCEPTED ||
                lastEvent === OrderEventType.ORDER_PREPARED ||
                lastEvent === OrderEventType.ORDER_IN_PREPARATION ||
                lastEvent === OrderEventType.ORDER_IN_DELIVERY) &&
                (totalOrderTime ? (
                  <>
                    <TimerTextRight>{timerColumn}</TimerTextRight> /
                    <TimerTextLeft>
                      <FontWeight600>{timerAccepted}</FontWeight600>
                    </TimerTextLeft>
                  </>
                ) : (
                  <>
                    {t('pages.OrderManagement.OrderDetails.orderedAt')}: {createdHour} -{' '}
                    {createdDate}
                  </>
                ))}
            </FlexRow>
          </TextWeight400>
        </FlexRow>
      )}
      <FlexRow>
        <Icon>
          <SvgIcon children={<PaidRounded />} width={20} height={20} color="#97B0BD" />
        </Icon>
        <TextWeight400>
          <FlexRow>
            <SpanAggregator>
              {channel ? channelNamesObject[channel as ChannelResponses] || channel : '-'}
            </SpanAggregator>
            {' / '}
            <SpanPayment>
              {t(paymentMethodTranslation[paymentMethod as PaymentMethod] || paymentMethod)}
            </SpanPayment>
          </FlexRow>
        </TextWeight400>
      </FlexRow>
      {deliveryMode === DeliveryMode.MANAGED_EXTERNALLY ? (
        <FlexRow>
          <DriverIconMargin>
            <SvgIcon children={<DriverIcon />} width={19} height={19} color="#97B0BD" />
          </DriverIconMargin>
          <TextWeight400>
            <FlexRow>
              {channel ? channelNamesObject[channel as ChannelResponses] || channel : '-'}
            </FlexRow>
          </TextWeight400>
        </FlexRow>
      ) : (
        showDriverField(lastEvent, lastMileDeliveryId) && (
          <FlexRow>
            <DriverIconMargin>
              <SvgIcon children={<DriverIcon />} width={19} height={19} color="#97B0BD" />
            </DriverIconMargin>
            <TextWeight400>
              <FlexRow>{driverName ?? '-'}</FlexRow>
            </TextWeight400>
          </FlexRow>
        )
      )}
      <EmptyLine />
    </Body>
  );
};

export default OrderHeaderInfo;
