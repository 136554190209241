import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IDeliveryFee {
  deliveryFee: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
`;

const Fee = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #5a5a5a;
`;

const DeliveryFee: FC<IDeliveryFee> = ({ deliveryFee }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('pages.OrderManagement.OrderDetails.deliveryCosts')}</Title>
      <Fee>{deliveryFee}</Fee>
    </Container>
  );
};

export default DeliveryFee;
