import { MutableRefObject } from 'react';

import { ActualETAResponse } from '../../../@types/eta';
import { Delegate } from '../../../@types/handlers/delegate';
import {
  RestaurantCapacitySettings,
  RestaurantLastMileProvidersSettings,
  RestaurantSettings,
} from '../../../@types/restaurants';
import { PreScheduleIntervals, SettingsColorsResponse } from '../../../@types/settings';
import { RestaurantEvent, WebsocketTopic } from '../../../@types/websocket';
import { RestaurantCapacity, RestaurantServiceStatus } from '../../../constants/restaurants';
import { ToastTypes } from '../../../constants/toasts';
import { useSettingsContextConnector } from './connector';

export interface ISettingsContextDelegate {
  /**
   * Actual ETA object for the restaurant
   */
  actualEtaInfo: ActualETAResponse | null;

  /**
   * If restaurant is configured to allow managers use the auto fire feature
   */
  allowAutoFire: boolean;

  /**
   * If restaurant is configured to allow managers request for a last mile providers delivery
   */
  allowLastMile: boolean;

  /**
   * If restaurant is configured to allow managers to pause the service
   */
  allowPauseService: boolean;

  /**
   * If restaurant is configured to allow managers to use reduced area
   */
  allowReducedArea: boolean;

  /**
   * If auto fire is active
   */
  autoFire: boolean;

  /**
   * If auto prepare is active
   */
  autoPrepare: boolean;

  /**
   * Object reference for auto prepare
   */
  autoPrepareRef: MutableRefObject<boolean>;

  /**
   * Color settings
   */
  colors: SettingsColorsResponse | null;

  /**
   * ETA for the restaurant
   */
  eta: number | null;

  /**
   * POS status
   */
  pos: boolean;

  /**
   * Intervals for scheduled orders
   */
  preScheduledInterval: string[];

  /**
   * Restaurant capacity enum
   */
  restaurantCapacity: RestaurantCapacity | null;

  /**
   * Object reference from restaurant capacity
   */
  restaurantCapacityRef: MutableRefObject<RestaurantCapacity | null>;

  /**
   * Restaurant service availability status
   */
  serviceStatus: RestaurantServiceStatus | null;

  /**
   * Last mile settings of the restaurant
   */
  lastMileSettings: RestaurantLastMileProvidersSettings | null;

  /**
   * Get actual eta from restaurant
   *
   * @returns {Promise<NodeJS.Timer | undefined>} returns the setInterval reference
   */
  retrieveActualEta: () => Promise<NodeJS.Timer | undefined>;

  /**
   * Get color settings according to restaurant configuration
   *
   * @returns {Promise<void>} set the colors when done
   */
  retrieveColors: () => Promise<void>;

  /**
   * Get interval settings for pre scheduled order
   * @returns an array of intervals
   */
  retrievePreScheduledIntervals: () => Promise<void>;

  /**
   * Get settings from restaurant
   *
   * @returns {Promise<NodeJS.Timer | undefined>} returns the setInterval reference
   */
  retrieveRestaurantSettings: () => Promise<NodeJS.Timer | undefined>;

  /**
   * Set the auto fire status
   *
   * @param {boolean} autoFire new status
   */
  setAutoFire: (autoFire: boolean) => void;

  /**
   * Set the auto prepare status
   *
   * @param {boolean} autoPrepare new status
   */
  setAutoPrepare: (autoPrepare: boolean) => void;

  /**
   * Set the ETA to execute some actions, like cancel order
   *
   * @param {number | null} eta new ETA
   */
  setEta: (eta: number | null) => void;

  /**
   * Set the restaurant capacity in the frontend through state variable and in the backend through PUT
   *
   * @param {RestaurantCapacity} restaurantCapacity enum that determines restaurant capacity
   * @param {string | undefined} token optional parameter, used in case restaurant capacity is reduced
   */
  setRestaurantCapacity: (
    restaurantCapacity: RestaurantCapacity,
    token: string,
  ) => Promise<boolean>;

  /**
   * Subscribe to Websocket context pub/sub
   */
  subscribeToWebsocket(): void;

  /**
   * Unsubscribe to Websocket context pub/sub
   */
  unsubscribeToWebsocket(): void;
}

type SettingsContextConnector = ReturnType<typeof useSettingsContextConnector>;

let flagSetCapacityRequest = false;

export class SettingsContextDelegate
  extends Delegate<SettingsContextConnector>
  implements ISettingsContextDelegate
{
  get actualEtaInfo(): ActualETAResponse | null {
    return this.connector.actualEtaInfo;
  }

  get allowAutoFire(): boolean {
    return this.connector.authContext.restaurant?.settings.allowAutoFire || false;
  }

  get allowLastMile(): boolean {
    return this.connector.authContext.restaurant?.settings.allowLastMile || false;
  }

  get allowPauseService(): boolean {
    return this.connector.authContext.restaurant?.settings.allowPauseService || false;
  }

  get allowReducedArea(): boolean {
    return this.connector.authContext.restaurant?.settings.allowReducedArea || false;
  }

  get autoFire(): boolean {
    return this.connector.autoFire;
  }

  get autoPrepare(): boolean {
    return this.connector.autoPrepare;
  }

  get autoPrepareRef(): MutableRefObject<boolean> {
    return this.connector.autoPrepareRef;
  }

  get colors(): SettingsColorsResponse | null {
    return this.connector.colors;
  }

  get eta(): number | null {
    return this.connector.eta;
  }

  get lastMileSettings(): RestaurantLastMileProvidersSettings | null {
    return this.connector.authContext.restaurant?.settings.lastMile || null;
  }

  get pos(): boolean {
    return this.connector.pos;
  }

  get preScheduledInterval(): string[] {
    return this.connector.preScheduledIntervals;
  }

  get restaurantCapacity(): RestaurantCapacity | null {
    return this.connector.restaurantCapacity;
  }

  get restaurantCapacityRef(): MutableRefObject<RestaurantCapacity | null> {
    return this.connector.restaurantCapacityRef;
  }

  get serviceStatus(): RestaurantServiceStatus | null {
    return this.connector.serviceStatus;
  }

  handleWebsocketMessage = (_: string, { payload }: RestaurantEvent): void => {
    if (
      flagSetCapacityRequest &&
      this.connector.restaurantCapacityRef.current !== payload.settings.capacitySettings.capacity
    ) {
      if (payload.settings.capacitySettings.capacity === RestaurantCapacity.NORMAL) {
        this.connector.toastContext.addToast(
          'contexts.Settings.updateCapacityNormal',
          ToastTypes.SUCCESS,
        );

        flagSetCapacityRequest = false;
      } else {
        this.connector.toastContext.addToast(
          'contexts.Settings.updateCapacityReduced',
          ToastTypes.SUCCESS,
        );
      }
    }

    this.setRestaurantSettings(payload.settings);
  };

  retrieveActualEta = async (): Promise<NodeJS.Timer | undefined> => {
    const interval = 60000;
    this.getActualEta();

    return setInterval(() => {
      this.getActualEta();
    }, interval);
  };

  retrieveColors = async (): Promise<void> => {
    const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

    const colors: SettingsColorsResponse = await this.connector.settingsService.getColors(
      regionCode,
      brand,
      restaurantId,
    );

    this.setColors(colors);
  };

  retrievePreScheduledIntervals = async (): Promise<void> => {
    const { regionCode, brand } = this.connector.authContext.user!;
    const { intervals }: PreScheduleIntervals =
      await this.connector.settingsService.getPreScheduleSettings(regionCode, brand);

    this.setPreScheduledIntervals(intervals);
  };

  retrieveRestaurantSettings = async (): Promise<NodeJS.Timer | undefined> => {
    const interval = 60000;
    this.getRestaurantSettings();

    return setInterval(() => {
      this.getRestaurantSettings();
    }, interval);
  };

  setAutoFire = (autoFire: boolean) => {
    this.connector.setAutoFire(autoFire);
  };

  setAutoPrepare = (autoPrepare: boolean) => {
    this.connector.setAutoPrepare(autoPrepare);
  };

  setEta = (eta: number | null) => {
    this.connector.setEta(eta);
  };

  setPreScheduledIntervals = (intervals: string[]): void => {
    this.connector.setPreScheduledIntervals(intervals);
  };

  setRestaurantCapacity = async (
    restaurantCapacity: RestaurantCapacity,
    token?: string,
  ): Promise<boolean> => {
    try {
      flagSetCapacityRequest = true;

      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const restaurantCapacityPayload: RestaurantCapacitySettings = {
        capacity: restaurantCapacity,
        updatedAt: new Date().toISOString(),
      };

      switch (restaurantCapacity) {
        case RestaurantCapacity.NORMAL:
          await this.connector.settingsService.updateRestaurantCapacity(
            regionCode,
            brand,
            restaurantId,
            restaurantCapacityPayload,
          );
          break;
        case RestaurantCapacity.REDUCED:
          if (!token) throw new Error('set restaurant capacity missing token');

          await this.connector.settingsService.updateRestaurantCapacity(
            regionCode,
            brand,
            restaurantId,
            restaurantCapacityPayload,
            token,
          );
          break;
        default:
          throw new Error('invalid restaurant capacity');
      }

      return true;
    } catch (err) {
      this.connector.toastContext.addToast(
        'contexts.Settings.updateCapacityError',
        ToastTypes.ERROR,
      );

      return false;
    }
  };

  subscribeToWebsocket = () => {
    this.connector.websocketContext.subscribeToWebsocket(
      WebsocketTopic.STORE_EVENT,
      this.handleWebsocketMessage,
    );
  };

  unsubscribeToWebsocket = () => {
    this.connector.websocketContext.unsubscribeToWebsocket(this.handleWebsocketMessage);
  };

  private getActualEta = async (): Promise<void> => {
    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const response = await this.connector.restaurantService.getActualEta(
        regionCode,
        brand,
        restaurantId,
      );

      this.connector.setActualEtaInfo(response);
    } catch (_) {
      return;
    }
  };

  private getRestaurantSettings = async (): Promise<void> => {
    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const restaurant = await this.connector.restaurantService.getOne(
        regionCode,
        brand,
        restaurantId,
      );

      this.setRestaurantSettings(restaurant.settings);
    } catch (_) {
      this.connector.setPos(false);
    }
  };

  private setColors = (colors: SettingsColorsResponse | null): void => {
    this.connector.setColors(colors);
  };

  private setRestaurantSettings = (settings: RestaurantSettings): void => {
    this.connector.setPos(settings.posStatus.available);
    this.connector.setRestaurantCapacity(settings.capacitySettings.capacity);
    this.connector.setServiceStatus(settings.serviceStatus.status);
    this.connector.setEta(settings.deliverySettings.eta);
    this.connector.setAutoPrepare(settings.autoPrepareStatus);
    this.connector.setAutoFire(settings.autoFireStatus);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useSettingsContextDelegate = () =>
  new SettingsContextDelegate(useSettingsContextConnector());
