import { FC } from 'react';
import styled from 'styled-components';

import { SelectOption } from '../../@types/input';

interface IGenericRadioList {
  options: SelectOption[];
  onChange: (id: string) => void;
  dataTestId?: string;
}

const InputList = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  gap: 12px;
`;

const InputLabel = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`;

const InputStyle = styled.input`
  width: 20px;
  height: 20px;
  accent-color: #263c47;
  border: 2px solid #263c47;
`;

const GenericRadioList: FC<IGenericRadioList> = ({ options, onChange, dataTestId }) => {
  return (
    <InputList>
      {options.map(({ id, title }, index) => (
        <InputRow key={id} data-testid={dataTestId ? `${dataTestId}-${index}` : id}>
          <InputStyle
            type="radio"
            name="radio-list"
            value={id}
            key={`input-${id}`}
            onChange={() => onChange(id)}
          />
          <InputLabel key={`label-${id}`}>{title}</InputLabel>
        </InputRow>
      ))}
    </InputList>
  );
};

export default GenericRadioList;
