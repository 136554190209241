import styled from 'styled-components';

import { NotificationCard } from './components/NotificationCard';
import { useComplaintsNotificationDelegate } from './controllers/delegate';

const Container = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 140px;
  right: 16px;
  z-index: 10000;
`;

export const ComplaintsNotification = () => {
  const { unseenComplaintNotifications, removeComplaintNotificationFromStorage } =
    useComplaintsNotificationDelegate();

  // @see: https://rbictg.atlassian.net/browse/RDP-4299
  // Temporary not showing popups
  const dontShowPopUp = true;

  return dontShowPopUp ? (
    <></>
  ) : (
    <Container isVisible={unseenComplaintNotifications.length > 0}>
      {unseenComplaintNotifications.map((complaintId) => (
        <NotificationCard
          key={complaintId}
          onCloseNotification={() => removeComplaintNotificationFromStorage(complaintId)}
        />
      ))}
    </Container>
  );
};
