import { DriverResponse } from '../../../@types/drivers';
import { Delegate } from '../../../@types/handlers/delegate';
import { DriverAvailabilityStatus } from '../../../constants/drivers';
import { useDriversContextConnector } from './connector';

export interface IDriversContextDelegate {
  /**
   * Get list of drivers on shift
   */
  driversOnShift: DriverResponse[];

  /**
   * Get list of drivers off shift
   */
  driversOffShift: DriverResponse[];

  /**
   * Call method to immediately update the drivers context
   */
  forceUpdate(): Promise<void>;

  /**
   * Get all drivers and filter only the ones on shift.
   * Pool every 5s and save on localStorage.
   */
  getDriversShift(): NodeJS.Timer | undefined;
}

type DriversContextConnector = ReturnType<typeof useDriversContextConnector>;

export class DriversContextDelegate
  extends Delegate<DriversContextConnector>
  implements IDriversContextDelegate
{
  get driversOnShift(): DriverResponse[] {
    return this.connector.driversOnShift;
  }

  get driversOffShift(): DriverResponse[] {
    return this.connector.driversOffShift;
  }

  forceUpdate = async (): Promise<void> => {
    await this.updateDriversShift();
  };

  getDriversShift = (): NodeJS.Timer | undefined => {
    const interval = 5000;
    try {
      this.updateDriversShift();
      return setInterval(() => this.updateDriversShift(), interval);
    } catch (err) {
      return;
    }
  };

  private setDriversOffShift(drivers: DriverResponse[]) {
    this.connector.setDriversOffShift(drivers);
  }

  private setDriversOnShift(drivers: DriverResponse[]) {
    this.connector.setDriversOnShift(drivers);
  }

  private updateDriversShift = async (): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    const drivers = await this.connector.driversService.getAll(regionCode, brand, restaurantId);

    const driversByAvailability = (availability: DriverAvailabilityStatus): DriverResponse[] =>
      drivers.filter((driver) => driver.availabilityStatus === availability);

    const driversOnShift = driversByAvailability(DriverAvailabilityStatus.DRIVER_WORKING);
    const driversOffShift = driversByAvailability(DriverAvailabilityStatus.DRIVER_OFFSHIFT);

    window.localStorage.setItem(
      '@RBI_Expeditor_Tablet:Drivers_On_Shift',
      JSON.stringify(driversOnShift),
    );

    window.localStorage.setItem(
      '@RBI_Expeditor_Tablet:Drivers_Off_Shift',
      JSON.stringify(driversOffShift),
    );

    this.setDriversOnShift(driversOnShift);
    this.setDriversOffShift(driversOffShift);
  };
}

export const useDriversContextDelegate = () =>
  new DriversContextDelegate(useDriversContextConnector());
