import { FC } from 'react';
import styled from 'styled-components';

import { GenericEmptyTable, GenericTableHeader, GenericTableRow } from '../@types/genericTable';
import Loading from './Loading';

interface IGenericTable {
  headers: GenericTableHeader[];
  rows: GenericTableRow[];
  emptyTable: GenericEmptyTable;
  loading?: boolean;
}

const Div = styled.div`
  width: -webkit-fill-available;
  overflow-x: auto;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
`;

const LoadingContainer = styled(Div)`
  padding: 50px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  tr th:first-child {
    padding: 0 0 0 46px;
  }

  tr th:last-child {
    padding: 0 34px 0 0;
  }
`;

const TableHeaderData = styled.th<{ width?: string; position?: 'left' | 'center' | 'right' }>`
  height: 62px;

  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => (props.position ? `text-align: ${props.position};` : 'text-align: left;')}
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #507083;
`;

const TableBody = styled.tbody`
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const TableBodyRow = styled.tr<{ backgroundColor?: string; fontWeight?: number }>`
  border-top: 1px solid #c2c2c2;

  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : 'fff')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : '400')};

  td:first-child {
    padding: 0 0 0 46px;
  }

  td:last-child {
    padding: 0 34px 0 0;
  }
`;

const TableBodyData = styled.td<{ position?: 'left' | 'center' | 'right' }>`
  height: 80px;
  position: relative;
  ${(props) => (props.position ? `text-align: ${props.position};` : 'text-align: left;')}
`;

const NoData = styled.div`
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const GenericTable: FC<IGenericTable> = ({ headers, rows, emptyTable, loading = false }) => {
  if (loading) {
    return (
      <LoadingContainer>
        <Loading strokeColor="#DAE7EE" />
      </LoadingContainer>
    );
  }

  return (
    <Div>
      {rows.length ? (
        <Table>
          <TableHeader>
            <tr>
              {headers.map((header, headerKey) => (
                <TableHeaderData key={headerKey} width={header.width} position={header.position}>
                  <Title>{header.title}</Title>
                </TableHeaderData>
              ))}
            </tr>
          </TableHeader>
          <TableBody>
            {rows.map((row, rowKey) => (
              <TableBodyRow
                key={rowKey}
                backgroundColor={row.options?.backgroundColor}
                fontWeight={row.options?.fontWeight}
              >
                {row.data.map((item, itemKey) => (
                  <TableBodyData key={itemKey} position={item.options?.position}>
                    {item.data}
                  </TableBodyData>
                ))}
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <NoData>{emptyTable}</NoData>
      )}
    </Div>
  );
};

export default GenericTable;
