import { addSeconds } from 'date-fns';
import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { OrderResponse } from '../../../../../@types/orders';
import { PreOrderCardInformation } from '../../../../../@types/preOrderCard';
import { channelNamesObject, ChannelResponses } from '../../../../../constants/delivery';
import { DateFormat } from '../../../../../utils/DateTimeUtils';
import { usePreOrderCardConnector } from './connector';

interface IPreOrderCardDelegate {
  /**
   * Format one order to the Pre-Orders card
   *
   * @param {OrderResponse} order order to be formatted
   * @return {PreOrderCardInformation} formatted object
   */
  formatOrderToCard(order: OrderResponse): PreOrderCardInformation;

  /**
   * Get order details to show in the sidebar
   *
   * @param {string} orderId unique order identifier
   * @returns {Promise<OrderResponse>} order details response
   */
  getOrderDetails(orderId: string): Promise<OrderResponse>;

  /**
   * Handle to open or close sidebar
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the sidebar
   * @param {string[]} dependencies list of components dependencies on the sidebar
   */
  handleSidebar(content?: ReactElement<any, any> | undefined, dependencies?: string[]): void;
}

type PreOrderCardConnector = ReturnType<typeof usePreOrderCardConnector>;

export class PreOrderCardDelegate
  extends Delegate<PreOrderCardConnector>
  implements IPreOrderCardDelegate
{
  formatOrderToCard = (order: OrderResponse): PreOrderCardInformation => {
    const {
      channel,
      payment,
      customer,
      quadrant,
      displayOrderId,
      deliveryAddress,
      instructions,
      fireOrderInSeconds,
    } = order;

    const orderDate = new Date(order.createdAt);
    const timeSchedule = addSeconds(orderDate, fireOrderInSeconds);

    const getInstructionQuantity = (): number =>
      [instructions, deliveryAddress.instructions].reduce((acc, curr) => (curr ? acc + 1 : acc), 0);

    const orderFormatted: PreOrderCardInformation = {
      amountToBePaid: this.connector.moneyUtils.moneyFormatter(
        payment.total.amount,
        payment.total.currency,
      ),
      channel: channelNamesObject[channel as ChannelResponses] || channel,
      customerAddress: `${deliveryAddress.formattedAddress}${
        deliveryAddress.subpremise ? `, ${deliveryAddress.subpremise}` : ''
      }`,
      customerName: customer.name,
      displayOrderId,
      instructionQuantity: getInstructionQuantity(),
      paymentMethod: payment.paymentMethod,
      quadrant: quadrant?.name ?? '',
      timeSchedule: this.connector.dateUtils.formatDate(timeSchedule, DateFormat.HH_MM),
    };

    return orderFormatted;
  };

  getOrderDetails = async (orderId: string): Promise<OrderResponse> => {
    const { regionCode, brand, restaurantId } = this.connector.user!;

    const orderDetails = await this.connector.ordersService.getOrderDetails(
      regionCode,
      brand,
      restaurantId,
      orderId,
    );

    return orderDetails;
  };

  handleSidebar = (
    content?: ReactElement<any, any> | undefined,
    dependencies?: string[] | undefined,
  ): void => {
    this.connector.handleSidebar(content, dependencies);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePreOrderCardDelegate = () => new PreOrderCardDelegate(usePreOrderCardConnector());
