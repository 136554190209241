import PubSub from 'pubsub-js';
import { MutableRefObject } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { PubSubListener } from '../../../@types/pubsub';
import { WebsocketEvent, WebsocketTopic } from '../../../@types/websocket';
import { RestaurantEventType } from '../../../constants/restaurants';
import { useWebsocketContextConnector } from './connector';

export interface IWebsocketContextDelegate {
  /**
   * Value that indicates if the websocket reconnection has already been tried
   */
  retryConn: boolean;

  /**
   * React reference that indicates if the websocket reconnection has already been tried
   */
  retryConnRef: MutableRefObject<boolean>;

  /**
   * Initialize the Websocket client passing the private method handler
   */
  initWebSocketClient(): void;

  /**
   * Subscribe to pubsub topic
   *
   * @param topic which topic the listener will be subscribed to
   * @param listener callback to be executed whe a message is received
   */
  subscribeToWebsocket(topic: WebsocketTopic, listener: PubSubListener): void;

  /**
   * Unsubscribe to pubsub topic
   *
   * @param listener callback to be executed whe a message is received
   */
  unsubscribeToWebsocket(listener: PubSubListener): void;
}

type WebsocketContextConnector = ReturnType<typeof useWebsocketContextConnector>;

export class WebsocketContextDelegate
  extends Delegate<WebsocketContextConnector>
  implements IWebsocketContextDelegate
{
  get retryConn(): boolean {
    return this.connector.retryConn;
  }

  get retryConnRef(): MutableRefObject<boolean> {
    return this.connector.retryConnRef;
  }

  initWebSocketClient = (): void => {
    this.connector.getWSClient({
      onclose: this.onCloseWSClient,
      onmessage: this.onMessageHandler,
    });
  };

  subscribeToWebsocket = (topic: WebsocketTopic, listener: PubSubListener): void => {
    PubSub.subscribe(topic, listener);
  };

  unsubscribeToWebsocket = (listener: PubSubListener): void => {
    PubSub.unsubscribe(listener);
  };

  private onCloseWSClient = async (): Promise<void> => {
    if (this.retryConnRef.current) {
      this.connector.setRetryConn(false);
    } else {
      const { user, userRef } = this.connector.authContext;

      if (user?.expiresAt !== userRef.current?.expiresAt) return;

      await this.connector.authContext.refreshToken(user);

      this.initWebSocketClient();
      this.connector.setRetryConn(true);
    }
  };

  private onMessageHandler = (message: WebsocketEvent): void => {
    const topic =
      message.eventName in RestaurantEventType
        ? WebsocketTopic.STORE_EVENT
        : WebsocketTopic.ORDER_EVENT;
    PubSub.publish(topic, message);
  };
}

export const useWebsocketContextDelegate = () =>
  new WebsocketContextDelegate(useWebsocketContextConnector());
