import { IOrderSchedule } from '../components/orders/OrderSchedule';
import { IOrderSummary } from '../components/orders/OrderSummary';
import { IComplaintDetailsHeader } from '../pages/Complaints/components/ComplaintDetails/components/ComplaintDetailsHeader';
import { IComplaintOrderInfo } from '../pages/Complaints/components/ComplaintDetails/components/ComplaintOrderInfo';
import { Brand } from './brand';
import { OrderEventType } from './order';

export enum ComplaintsTableHeader {
  ID = 'ID',
  VALUE = 'VALUE',
  PAID_WITH = 'PAID_WITH',
  CHANNEL = 'CHANNEL',
  ORDER_ID = 'ORDER_ID',
  CLIENT = 'CLIENT',
  CONTACT = 'CONTACT',
  TYPE = 'TYPE',
  STATUS = 'STATUS',
}

const pathTranslation = 'pages.Complaints.table.titles';

export const complaintsTableHeaderTranslation: Record<ComplaintsTableHeader, string> = {
  CHANNEL: `${pathTranslation}.CHANNEL`,
  CLIENT: `${pathTranslation}.CLIENT`,
  CONTACT: `${pathTranslation}.CONTACT`,
  ID: `${pathTranslation}.ID`,
  ORDER_ID: `${pathTranslation}.ORDER_ID`,
  PAID_WITH: `${pathTranslation}.PAID_WITH`,
  STATUS: `${pathTranslation}.STATUS`,
  TYPE: `${pathTranslation}.TYPE`,
  VALUE: `${pathTranslation}.VALUE`,
};

export enum ComplaintStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export const complaintStatusTranslation: Record<ComplaintStatus, string> = {
  CLOSED: 'constants.complaint.status.closed',
  OPEN: 'constants.complaint.status.open',
};

export enum ComplaintDMPCategory {
  TIME = 'TIME',
  QUALITY = 'QUALITY',
  FULL_REFUND = 'FULL_REFUND',
  COMMENTS = 'COMMENTS',
  ACCURACY = 'ACCURACY',
}

export const complaintCategoryTranslation: Record<ComplaintDMPCategory, string> = {
  ACCURACY: 'constants.complaint.category.accuracy',
  COMMENTS: 'constants.complaint.category.comments',
  FULL_REFUND: 'constants.complaint.category.fullRefund',
  QUALITY: 'constants.complaint.category.quality',
  TIME: 'constants.complaint.category.time',
};

export enum ComplaintResolutionTypes {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  REDELIVERY = 'REDELIVERY',
  COMMENT = 'COMMENT',
  REFUND = 'REFUND',
  VOUCHER = 'VOUCHER',
}

export enum CommentsId {
  IMPROVE_QUALITY = 'IMPROVE_QUALITY',
}

export enum ComplaintEventType {
  COMPLAINT_OPENED = 'COMPLAINT_OPENED',
  COMPLAINT_CLOSED = 'COMPLAINT_CLOSED',
}

export const resolutionTranslation: Record<ComplaintResolutionTypes, string> = {
  [ComplaintResolutionTypes.COMMENT]: 'pages.Complaints.details.resolutions.types.comment',
  [ComplaintResolutionTypes.NOT_APPLICABLE]:
    'pages.Complaints.details.resolutions.types.notApplicable',
  [ComplaintResolutionTypes.REDELIVERY]: 'pages.Complaints.details.resolutions.types.redelivery',
  [ComplaintResolutionTypes.REFUND]: 'pages.Complaints.details.resolutions.types.refund',
  [ComplaintResolutionTypes.VOUCHER]: 'pages.Complaints.details.resolutions.types.voucher',
};

export const commentsBrand: Record<Brand, string> = {
  [Brand.PLK]: 'pages.Complaints.details.comments.types.improveQuality.popeyes',
  [Brand.BK]: 'pages.Complaints.details.comments.types.improveQuality.burguerKing',
  [Brand.FHS]: 'pages.Complaints.details.comments.types.improveQuality.fireHouse',
  [Brand.TH]: 'pages.Complaints.details.comments.types.improveQuality.timHortons',
};

export const commentsTranslation: Record<CommentsId, string> = {
  [CommentsId.IMPROVE_QUALITY]: 'pages.Complaints.details.comments.types.improveQuality.message',
};

export const complaintHeaderDetails: IComplaintDetailsHeader = {
  complaintId: '',
  createdDate: '',
  email: '',
  hourMinute: '',
  name: '',
  phone: '',
  status: ComplaintStatus.OPEN,
};

export const complaintOrderInfo: IComplaintOrderInfo = {
  address: '',
  channel: '',
  createdDate: '',
  createdHour: '',
  deliveryInstructions: '',
  displayOrderId: '',
  driverName: '',
  lastEvent: OrderEventType.ORDER_DELIVERED,
  paymentMethod: '',
};

export const complaintScheduleDetails: IOrderSchedule = {
  events: [],
  startTimeAccepted: '',
  startTimeCancelled: '',
  startTimeDelivered: '',
  startTimeInProduction: '',
  startTimeOnDelivery: '',
  totalOrderTime: '',
  totalTimeAccepted: '',
  totalTimeInProduction: '',
  totalTimeOnDelivery: '',
};

export const complaintOrderSummary: IOrderSummary = {
  cartSummary: [],
};

export const ACCURACY_REDELIVERY_VALUE = 300;

export const GET_LAST_CHARS = -7;
