import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import SvgIcon from '../SvgIcon';

interface IGenericIconButton {
  size?: number;
  disabled?: boolean;
  iconSize?: number;
  icon: ReactNode;
  onClick?: () => void;
}

const IconButton = styled.button<{ size: number; isDisabled: boolean }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? '0.8' : '1')};
  background: #507083;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 0px rgba(0, 0, 0, 0.25);
`;

const defaultSize = 47;
const defaultIconSize = 32;

const GenericIconButton: FC<IGenericIconButton> = ({
  icon,
  size = defaultSize,
  disabled = false,
  iconSize = defaultIconSize,
  onClick,
}) => {
  return (
    <IconButton onClick={onClick} size={size} disabled={disabled} isDisabled={disabled}>
      <SvgIcon children={icon} color="#fff" width={iconSize} height={iconSize} />
    </IconButton>
  );
};

export default GenericIconButton;
