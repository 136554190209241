import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import styled from 'styled-components';

import { env } from '../../constants/env';
import AutoFire from './components/AutoFire';
import AutoPrepare from './components/AutoPrepare';
import Complaints from './components/Complaints';
import DateTime from './components/DateTime';
import DeliveryTime from './components/DeliveryTime';
import ServiceActive from './components/ServiceActive';
import SettingsButton from './components/SettingsButton';
import Status from './components/Status';
import StoreIdentification from './components/StoreIdentification';
import { usePageHeaderDelegate } from './controllers/delegate';

const Base = styled.div`
  display: flex;
  align-content: center;
  padding: 0 22px 0 28px;
  height: 108px;
  background-color: #ffffff;
  align-items: center;
  width: -webkit-fill-available;
  position: fixed;
  z-index: 9;
`;

const VerticalBorder = styled.div`
  background: #878787;
  width: 1px;
  height: 74px;
  margin: 0 16px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 108px;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const PageHeader: FC = () => {
  const { allowPauseService, allowAutoFire } = usePageHeaderDelegate();

  const { enableAutoFire } = useFlags();

  return (
    <Base>
      <img src={`${env.assetsPath}/logo.svg`} alt="brand-logo" height="75" />
      <StoreIdentification />
      <SettingsButton />
      <RightContainer>
        <DateTime />
        <VerticalBorder />
        <Complaints />
        <VerticalBorder />
        <Status />
        <CardsContainer>
          <DeliveryTime />
          {allowAutoFire && enableAutoFire && <AutoFire />}
          <AutoPrepare />
          {allowPauseService && <ServiceActive />}
        </CardsContainer>
      </RightContainer>
    </Base>
  );
};

export default PageHeader;
