export enum PaymentMethod {
  APPLE_PAY = 'APPLE_PAY',
  BIZUM = 'BIZUM',
  CASH = 'CASH',
  CHEQUE_GOURMET = 'CHEQUE_GOURMET',
  CHEQUE_GOURMET_VOUCHER = 'CHEQUE_GOURMET_VOUCHER',
  CREDIT_CARD = 'CREDIT_CARD',
  GIFTCARD = 'GIFTCARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL',
  PAYMENT_ON_DELIVERY_CARD = 'PAYMENT_ON_DELIVERY_CARD',
  SODEXO = 'SODEXO',
  SODEXO_VOUCHER = 'SODEXO_VOUCHER',
}

export const paymentMethodTranslation: Record<PaymentMethod, string> = {
  APPLE_PAY: 'constants.paymentMethod.applePay',
  BIZUM: 'constants.paymentMethod.bizum',
  CASH: 'constants.paymentMethod.cash',
  CHEQUE_GOURMET: 'constants.paymentMethod.chequeGourmet',
  CHEQUE_GOURMET_VOUCHER: 'constants.paymentMethod.chequeGourmetVoucher',
  CREDIT_CARD: 'constants.paymentMethod.creditCard',
  GIFTCARD: 'constants.paymentMethod.giftcard',
  GOOGLE_PAY: 'constants.paymentMethod.googlePay',
  PAYMENT_ON_DELIVERY_CARD: 'constants.paymentMethod.paymentOnDeliveryCard',
  PAYPAL: 'constants.paymentMethod.paypal',
  SODEXO: 'constants.paymentMethod.sodexo',
  SODEXO_VOUCHER: 'constants.paymentMethod.sodexoVoucher',
};
