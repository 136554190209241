import { useDriversContext } from '../../../../../contexts/DriversContext';

const useTotalActiveDriversConnector = () => {
  const { driversOnShift } = useDriversContext();

  return {
    driversOnShift,
  };
};

export default useTotalActiveDriversConnector;
