import { HTTPClient } from '../@types/handlers/httpClient';
import {
  AutoFireUpdate,
  AutoPrepareUpdate,
  RestaurantCapacitySettings,
  RestaurantServiceStatusUpdate,
} from '../@types/restaurants';
import {
  CancelOption,
  EtaPayload,
  EtaValues,
  OrderIssue,
  PreScheduleIntervals,
  RestaurantPolygonsList,
  SettingsColorsResponse,
} from '../@types/settings';
import { ShiftHoursResponse } from '../@types/shiftHours';
import { env } from '../constants/env';
import { RestaurantServiceStatusReasons } from '../constants/restaurants';

export interface ISettingsService {
  /**
   * Retrieve list of valid cancel options
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<CancelOption[]>} an array containing the valid cancel option values
   */
  getCancelOptions(country: string, brand: string, restaurantId: string): Promise<CancelOption[]>;

  /**
   * Retrieve channels (aggregators) for a country and brand
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @returns {string[]} channels as an array of strings
   */
  getChannels(country: string, brand: string): Promise<string[]>;

  /**
   * Retrieve a list of valid ETA's for a given restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<EtaValues>} the list of possible eta values for the restaurant
   */
  getEtaValues(country: string, brand: string, restaurantId: string): Promise<EtaValues>;

  /**
   * Retrieve ETA value setted for a given restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<number>} the eta value setted for the restaurant
   */
  getEtaByRestaurant(country: string, brand: string, restaurantId: string): Promise<number>;

  /**
   * Retrieve settings colors
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<SettingsColorsResponse>} an object the colors values
   */
  getColors(country: string, brand: string, restaurantId: string): Promise<SettingsColorsResponse>;

  /**
   * Retrieve list of order issues
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<OrderIssue[]>} an array of valid order issues
   */
  getCloseOrderIssues(country: string, brand: string, restaurantId: string): Promise<OrderIssue[]>;

  /**
   * Retrieve pause reasons for a restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<RestaurantServiceStatusReasons[]>} pause reasons as an array of strings
   */
  getPauseReasons(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantServiceStatusReasons[]>;

  /**
   * Retrieve Polygons for restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<RestaurantPolygonsList>} a list with all polygons for restaurant
   */
  getPolygons(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantPolygonsList>;

  /**
   * Retrieve POS status
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<boolean>} a boolean with the POS status
   */
  getPos(country: string, brand: string, restaurantId: string): Promise<boolean>;

  /**
   * Retrieve Pre schedule intervals
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @returns {Promise<PreScheduleIntervals>} a list of configured intervals to orders pre scheduled
   */
  getPreScheduleSettings(country: string, brand: string): Promise<PreScheduleIntervals>;

  /**
   * Retrieve capacity (area of delivery) of a given restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @returns {Promise<RestaurantCapacitySettings>} capacity of the restaurant input given
   */
  getRestaurantCapacity(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantCapacitySettings>;

  /**
   * Retrieve shift hours for a country and brand
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @returns {ShiftHoursResponse} shift hours response as an object with 'day', 'evening' and 'night' info
   */
  getShiftHours(country: string, brand: string): Promise<ShiftHoursResponse>;

  /**
   * Pause the restaurant service status
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {RestaurantServiceStatusUpdate} body body of request to pause the restaurant service status
   * @param {string} permissionToken permission token generated to authorize the manager to change the service status
   * @returns {Promise<string>} the success or error message
   */
  pauseRestaurantService(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantServiceStatusUpdate,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Resume the restaurant service status
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {RestaurantServiceStatusUpdate} body body of request to resume the restaurant service status
   * @param {string} permissionToken permission token generated to authorize the manager to change the service status
   * @returns {Promise<string>} the success or error message
   */
  resumeRestaurantService(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantServiceStatusUpdate,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Update Auto-fire status to turn-on or turn-off the functionality
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {RestaurantServiceStatusUpdate} body body of request to resume the restaurant auto-fire status
   * @param {string} permissionToken permission token generated to authorize the manager to change the auto-fire status
   * @returns {Promise<string>} the success or error message
   */
  updateAutoFireStatus(
    country: string,
    brand: string,
    restaurantId: string,
    body: AutoFireUpdate,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Update the restaurant auto prepare status
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {AutoPrepareUpdate} body body of request to update the auto prepare status
   * @param {string} permissionToken permission token generated to authorize the manager to change the auto prepare status
   * @returns {Promise<string>} the success or error message
   */
  updateAutoPrepareStatus(
    country: string,
    brand: string,
    restaurantId: string,
    body: AutoPrepareUpdate,
    permissionToken: string,
  ): Promise<string>;

  /**
   * Update ETA value for a given restaurant
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {string} permissionToken Permission token generated to authorize the manager to change the ETA
   * @param {EtaPayload} body body of request to update the ETA value
   * @returns {Promise<string>} the success message or error message
   */
  updateEtaByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    permissionToken: string,
    body: EtaPayload,
  ): Promise<string>;

  /**
   * Update the restaurant capacity (area of delivery)
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} restaurantId restaurant unique identifier
   * @param {RestaurantCapacitySettings} body body of request to update the restaurant capacity
   * @param {string} permissionToken permission token generated to authorize the manager to change the restaurant capacity to reduced
   * @returns {Promise<string>} the success message or error message
   */
  updateRestaurantCapacity(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantCapacitySettings,
    permissionToken: string,
  ): Promise<string>;
}

class SettingsService extends HTTPClient implements ISettingsService {
  async getCancelOptions(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<CancelOption[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/cancel-options`;
    const response = await this.get<CancelOption[]>(url);

    return response.data;
  }

  async getChannels(country: string, brand: string): Promise<string[]> {
    const url = `/${country}/${brand}/settings/channels`;
    const response = await this.get<string[]>(url);

    return response.data;
  }

  async getCloseOrderIssues(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<OrderIssue[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/order-issues`;
    const response = await this.get<OrderIssue[]>(url);

    return response.data;
  }

  async getColors(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<SettingsColorsResponse> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/colors`;
    const response = await this.get<SettingsColorsResponse>(url);

    return response.data;
  }

  async getEtaByRestaurant(country: string, brand: string, restaurantId: string): Promise<number> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/eta`;
    const response = await this.get<number>(url);

    return response.data;
  }

  async getEtaValues(country: string, brand: string, restaurantId: string): Promise<EtaValues> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/eta-values`;
    const response = await this.get<EtaValues>(url);

    return response.data;
  }

  async getPauseReasons(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantServiceStatusReasons[]> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/pause-reasons`;
    const response = await this.get<RestaurantServiceStatusReasons[]>(url);

    return response.data;
  }

  async getPolygons(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantPolygonsList> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/polygons`;
    const response = await this.get<RestaurantPolygonsList>(url);

    return response.data;
  }

  async getPos(country: string, brand: string, restaurantId: string): Promise<boolean> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/pos`;
    const response = await this.get<boolean>(url);

    return response.data;
  }

  async getPreScheduleSettings(country: string, brand: string): Promise<PreScheduleIntervals> {
    const url = `/${country}/${brand}/settings/pre-scheduled-interval`;
    const response = await this.get<PreScheduleIntervals>(url);

    return response.data;
  }

  async getRestaurantCapacity(
    country: string,
    brand: string,
    restaurantId: string,
  ): Promise<RestaurantCapacitySettings> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/capacity`;
    const response = await this.get<RestaurantCapacitySettings>(url);

    return response.data;
  }

  async getShiftHours(country: string, brand: string): Promise<ShiftHoursResponse> {
    const url = `/${country}/${brand}/settings/shift-hours`;
    const response = await this.get<ShiftHoursResponse>(url);

    return response.data;
  }

  async pauseRestaurantService(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantServiceStatusUpdate,
    permissionToken = '',
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/pause`;
    const response = await this.put<RestaurantServiceStatusUpdate, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async resumeRestaurantService(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantServiceStatusUpdate,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/resume`;
    const response = await this.put<RestaurantServiceStatusUpdate, string>(url, body);

    return response.data;
  }

  async updateAutoFireStatus(
    country: string,
    brand: string,
    restaurantId: string,
    body: AutoFireUpdate,
    permissionToken = '',
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/auto-fire`;
    const response = await this.put<AutoFireUpdate, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async updateAutoPrepareStatus(
    country: string,
    brand: string,
    restaurantId: string,
    body: AutoPrepareUpdate,
    permissionToken = '',
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/auto-prepare`;
    const response = await this.put<AutoPrepareUpdate, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async updateEtaByRestaurant(
    country: string,
    brand: string,
    restaurantId: string,
    permissionToken: string,
    body: EtaPayload,
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/eta`;
    const response = await this.put<EtaPayload, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }

  async updateRestaurantCapacity(
    country: string,
    brand: string,
    restaurantId: string,
    body: RestaurantCapacitySettings,
    permissionToken = '',
  ): Promise<string> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/settings/capacity`;
    const response = await this.put<RestaurantCapacitySettings, string>(url, body, {
      authorization_action: permissionToken,
    });

    return response.data;
  }
}

export default new SettingsService(env.deliveryServiceAPI);
