import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import newOrderAlarm from '../../../../src/assets/sounds/notification_simple-02.wav';
import { OrderResponse } from '../../../@types/orders';
import ordersService from '../../../services/OrdersService';
import { ordersUtils } from '../../../utils/OrdersUtils';
import { useAuthContext } from '../../AuthContext';
import { useDeliveryContext } from '../../DeliveryContext';
import { useToastContext } from '../../ToastContext';
import { useWebsocketContext } from '../../WebsocketContext';

export const useOrdersContextConnector = () => {
  const audioPlayer = new Audio(newOrderAlarm);
  const { pathname: currentPath } = useLocation();

  const [newOrders, setNewOrders] = useState<OrderResponse[]>([]);
  const [ordersInProduction, setOrdersInProduction] = useState<OrderResponse[]>([]);
  const [ordersOnDelivery, setOrdersOnDelivery] = useState<OrderResponse[]>([]);
  const [ordersClosedOrFinalizedByDriver, setOrdersClosedOrFinalizedByDriver] = useState<
    OrderResponse[]
  >([]);
  const [scheduledOrders, setScheduledOrders] = useState<OrderResponse[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [showMap, setShowMap] = useState<boolean>(false);

  const ordersRef = useRef<OrderResponse[]>([]);
  const firstLoadingRef = useRef<boolean>(firstLoading);
  const currentPathRef = useRef<string>(currentPath);

  return {
    audioPlayer,
    authContext: useAuthContext(),
    currentPath,
    currentPathRef,
    deliveryContext: useDeliveryContext(),
    firstLoading,
    firstLoadingRef,
    newOrders,
    ordersClosedOrFinalizedByDriver,
    ordersInProduction,
    ordersOnDelivery,
    ordersRef,
    ordersService,
    ordersUtils: ordersUtils(),
    scheduledOrders,
    setFirstLoading,
    setNewOrders,
    setOrdersClosedOrFinalizedByDriver,
    setOrdersInProduction,
    setOrdersOnDelivery,
    setScheduledOrders,
    setShowMap,
    showMap,
    toastContext: useToastContext(),
    websocketContext: useWebsocketContext(),
  };
};
