import { MutableRefObject, ReactElement } from 'react';

import { Cart } from '../../../@types/cart';
import { DriverResponse } from '../../../@types/drivers';
import { Delegate } from '../../../@types/handlers/delegate';
import { SelectOption } from '../../../@types/input';
import {
  FormattedCartItems,
  FormattedFees,
  OrderDetailsFormatted,
  OrderEvent,
  OrderResponse,
} from '../../../@types/orders';
import { CancelOption, OrderIssue, SettingsColorsResponse } from '../../../@types/settings';
import { ColorConfigKeys } from '../../../constants/colorConfig';
import { channelNamesObject, ChannelResponses } from '../../../constants/delivery';
import { DeliveryMode } from '../../../constants/deliveryMode';
import { LastMileDeliveryStatus, LastMileProviders } from '../../../constants/lastMile';
import { OrderEventType, OrderStatus } from '../../../constants/order';
import { emptyQuadrant, orderIssueSection } from '../../../constants/orderDetails';
import { OrderTypeColor } from '../../../constants/orderTypeColor';
import { ToastTypes } from '../../../constants/toasts';
import { DateFormat } from '../../../utils/DateTimeUtils';
import { IOrderDetailsHeader } from '../../orders/OrderDetailsHeader';
import { IOrderHeaderInfo } from '../../orders/OrderHeaderInfo';
import { IOrderSchedule } from '../../orders/OrderSchedule';
import { IOrderSummary } from '../../orders/OrderSummary';
import { IOrderIssueSection } from '../components/OrderIssueSection';
import useOrderDetailsConnector from './connector';

type OrderDetailsConnector = ReturnType<typeof useOrderDetailsConnector>;

interface IOrderDetailsDelegate {
  /**
   * return active drivers list from state variable to show as select options
   */
  activeDrivers: SelectOption[];

  /**
   * Color configuration from Settings Context
   *
   * @returns {SettingsColorsResponse | null}  color settings response or null if not set
   */
  colorSettings: SettingsColorsResponse | null;

  /**
   * Cancel or issue options for an order that has been cancelled or finalized
   */
  issueOptions: OrderIssue[] | CancelOption[];

  /**
   * Get current order if set or null otherwise
   *
   * @returns {OrderDetailsFormatted | null} formatted order or null if not set
   */
  order: OrderDetailsFormatted | null;

  /**
   * Orders list reference to mutate inside setInterval
   *
   * @returns {MutableRefObject<OrderDetailsFormatted  | null>} reference to order or null if order not set
   */
  orderRef: MutableRefObject<OrderDetailsFormatted | null>;

  /**
   * return last mile providers list for the restaurant
   */
  lastMileProviders: LastMileProviders[] | [];

  /**
   * Assign an available driver to order
   *
   * @param {string} driverId driver unique identifier
   */
  assignDriverToOrder(driverId: string): Promise<void>;

  /**
   * Format order details that comes from props
   *
   * @param {OrderResponse} orderDetails order details to be formatted
   *
   * @returns {Promise<OrderDetailsFormatted>} formatted order details
   */
  formatOrderDetails(orderDetails: OrderResponse): Promise<OrderDetailsFormatted>;

  /**
   * Retrieves cancel or issue options based on the provided order details.
   *
   * @param {OrderResponse} orderDetails order details used to retrieve options.
   */
  getIssueSectionOptions(orderDetails: OrderResponse): Promise<void>;

  /**
   * Format a giver order to filter the information necessary for the details sidebar
   *
   * @param {OrderResponse} order target order
   * @param {OrderEventType} event order last event (current status)
   *
   * @returns {OrderDetailsFormatted} object containing the order's relevant information for the sidebar
   */
  genericFormatter(order: OrderResponse, event: OrderEventType): OrderDetailsFormatted;

  /**
   * Get active drivers list
   *
   * @param {OrderResponse} order order details response
   */
  getActiveDrivers(order: OrderResponse): Promise<void>;

  /**
   * Format the delivery fee
   *
   * @param {OrderResponse} order order details
   *
   * @returns {string} the delivery fee formatted
   */
  getFormattedDeliveryFee(order: OrderResponse): string;

  /**
   * Format the order fees
   *
   * @param {OrderResponse} order order details
   *
   * @returns {FormattedFees} the delivery fee formatted
   */
  getFormattedFees(order: OrderResponse): FormattedFees;

  /**
   * Format Order Details Header
   *
   * @param {OrderResponse} order order details to be formatted
   *
   * @returns {IOrderDetailsHeader} the order details header formatted
   */
  getFormattedOrderDetailsHeader(order: OrderResponse): IOrderDetailsHeader;

  /**
   * Formats the provided order and issue options into an order issue section.
   *
   * @param {OrderResponse} order order to include in information of the order issue section.
   * @param {OrderIssue[] | CancelOption[]} issueOptions issue options to include in the information of the order issue section.
   *
   * @returns {IOrderIssueSection} formatted order issue section.
   */
  getFormattedOrderIssueSection(
    order: OrderResponse,
    issueOptions: OrderIssue[] | CancelOption[],
  ): IOrderIssueSection;

  /**
   * Get formatted schedule for the orders
   *
   * @param {OrderResponse} order order to get schedule info.
   * @returns {IOrderSchedule} formatted order schedule
   */
  getFormattedSchedule(order: OrderResponse): IOrderSchedule;

  /**
   * Returns an object containing a formatted summary of the given cart.
   *
   * @param {Cart} cart cart object information to be formatted.
   *
   * @returns {IOrderSummary} formatted order summary.
   */
  getFormattedSummary(cart: Cart): IOrderSummary;

  /**
   * Get all OrderHeaderInfo component info formatted
   *
   * @param {OrderResponse} order order response unformatted to be formatted
   *
   * @returns {IOrderHeaderInfo} formatted object props
   */
  getFormattedHeaderInfo(order: OrderResponse): IOrderHeaderInfo;

  /**
   * Format a given order to filter the information necessary for the details sidebar
   *
   * @param {OrderEvent[]} events events list of an order
   * @param {number} timeToEnableButtonToMoveOrder LD flag that stores the time to enable action button on cards to be manually moved in case of some error to receive the webhook
   *
   * @returns {OrderEventType} event order before assign driver to order
   */
  getLastEventBeforeAssignDriver(
    events: OrderEvent[],
    timeToEnableButtonToMoveOrder?: number,
  ): OrderEventType;

  /**
   * Get select drivers options
   *
   * @param orderPaymentMethod payment method from the order
   * @returns {SelectOption[]} options
   */
  getSelectOptions(orderPaymentMethod: string): SelectOption[];

  /*
   * Get total time that order was in production
   *
   *  @param {OrderEventType} lastEvent the lastEvent registered by the order
   *  @param {OrderEvent[]} events the list of events registered by the order
   *  @param {boolean} isManagedExternally if the delivery of the order is managed outside the restaurant
   *  @param {boolean} containsOnDeliveryEvent orders closed with issue by the aggregators could not have IN_DELIVERY event
   *  @returns {string} total time of order in production column
   */
  getTotalTimeInProduction(
    lastEvent: OrderEventType,
    events: OrderEvent[],
    isManagedExternally: boolean,
    containsOnDeliveryEvent: boolean,
  ): string;

  /**
   * Get total time that order was on delivery
   *
   *  @param {OrderEventType} lastEvent the lastEvent registered by the order
   *  @param {OrderEvent[]} events the list of events registered by the order
   *  @param {boolean} containsOnDeliveryEvent orders closed with issue by the aggregators could not have IN_DELIVERY event
   *  @returns {string} total time of order in delivery column
   */
  getTotalTimeOnDelivery(
    lastEvent: OrderEventType,
    events: OrderEvent[],
    hasOnDeliveryEvent: boolean,
  ): string;

  /**
   * Start timers and colors pooling
   *
   * @returns {NodeJS.Timer} created timer reference
   */
  startPooling(): NodeJS.Timer;
}

export class OrderDetailsDelegate
  extends Delegate<OrderDetailsConnector>
  implements IOrderDetailsDelegate
{
  get activeDrivers(): SelectOption[] {
    return this.connector.activeDrivers;
  }

  get colorSettings(): SettingsColorsResponse | null {
    return this.connector.settingsContext.colors;
  }

  get ldEnableOrderPrepared(): boolean {
    return this.connector.ldEnableOrderPrepared;
  }

  get issueOptions(): OrderIssue[] | CancelOption[] {
    return this.connector.issueOptions;
  }

  get lastMileProviders(): LastMileProviders[] {
    return this.connector.settingsContext.lastMileSettings?.providers || [];
  }

  get order(): OrderDetailsFormatted | null {
    return this.connector.order;
  }

  get orderRef(): MutableRefObject<OrderDetailsFormatted | null> {
    return this.connector.orderRef;
  }

  assignDriverToOrder = async (driverId: string): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    this.connector.setOrder({ ...this.connector.order!, driverId });

    await this.connector.ordersService.assignDriverToOrder(
      regionCode,
      brand,
      restaurantId,
      this.order!.rbiNumberId,
      driverId,
    );

    this.connector.deliveryContext.setOrderDetails([{ ...this.connector.order!, driverId }]);
    await this.connector.ordersContext.forceUpdate();
  };

  calculatePreOrderPlacementTime = (
    startTimeScheduled: Date | number,
    fireOrderInSeconds: number,
    eta: number,
  ): string => {
    const secondsInAMinute = 60;
    const secondsToPreparation = fireOrderInSeconds - eta * secondsInAMinute;

    const secondsToPlacement = secondsToPreparation >= 0 ? secondsToPreparation : 0;

    return this.connector.dateUtils.formatDate(
      this.connector.dateUtils.addSeconds(startTimeScheduled, secondsToPlacement),
      DateFormat.DD_MM_YY_HH_MM,
    );
  };

  createLastMileDelivery = async (provider: LastMileProviders): Promise<void> => {
    this.handleModal();

    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    try {
      const {
        id,
        events,
        provider: lastMileProvider,
      } = await this.connector.ordersService.createLastMileDelivery(
        regionCode,
        brand,
        restaurantId,
        this.order!.rbiNumberId,
        provider,
      );

      this.connector.setOrder({
        ...this.connector.order!,
        lastMileDeliveryId: id,
        lastMileDeliveryStatus: events[events.length - 1].name,
        lastMileProvider,
      });

      await this.connector.deliveryContext.getOrderById(this.order!.rbiNumberId);
    } catch (err) {
      const errorMessage = (err as any).response?.data?.message;

      switch (errorMessage) {
        case 'Last Mile Delivery cannot be created for order with payment method CASH':
          this.connector.toastContext.addToast(
            'pages.OrderManagement.OrderDetails.lastMile.errors.errorOnCashOrder',
            ToastTypes.ERROR,
          );
          break;
        case 'The LocationId of the restaurant is invalid for Catcher':
          this.connector.toastContext.addToast(
            'pages.OrderManagement.OrderDetails.lastMile.errors.errorOnLocationId',
            ToastTypes.ERROR,
          );
          break;
        default:
          this.connector.toastContext.addToast(
            'pages.OrderManagement.OrderDetails.lastMile.errors.errorOnCreate',
            ToastTypes.ERROR,
          );
      }
    }
  };

  formatOrderDetails = async (orderDetails: OrderResponse): Promise<OrderDetailsFormatted> => {
    this.connector.setOrder(null);

    const orderFormatted = this.genericFormatter(orderDetails);

    this.connector.setOrder(orderFormatted);

    return orderFormatted;
  };

  genericFormatter = (order: OrderResponse): OrderDetailsFormatted => {
    const lastEvent =
      order.events[order.events.length - 1].name === OrderEventType.DRIVER_ASSIGNED_TO_ORDER
        ? this.getLastEventBeforeAssignDriver(order.events)
        : order.events[order.events.length - 1].name;

    const colorSettings = this.colorSettings!;

    let colorKey: ColorConfigKeys;

    switch (lastEvent) {
      case OrderEventType.ORDER_ACCEPTED:
        colorKey = ColorConfigKeys.NEW_ORDERS;
        break;
      case OrderEventType.ORDER_PREPARED:
      case OrderEventType.ORDER_IN_PREPARATION:
        colorKey = ColorConfigKeys.IN_PRODUCTION;
        break;
      case OrderEventType.ORDER_IN_DELIVERY:
      case OrderEventType.ORDER_CANCELLED:
      case OrderEventType.ORDER_DELIVERED:
      case OrderEventType.ORDER_FINALIZED:
      case OrderEventType.ORDER_PRE_SCHEDULED:
        colorKey = ColorConfigKeys.ON_DELIVERY;
        break;
      default:
        throw new Error('Invalid Event Type');
    }

    return this.connector.orderManagementUtils.formatToOrderDetailsFormatted(
      order,
      lastEvent,
      colorSettings,
      colorKey,
    );
  };

  getActiveDrivers = async (order: OrderResponse): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    let drivers = [];

    if (
      order.status === OrderStatus.ORDER_IN_PREPARATION ||
      order.status === OrderStatus.ORDER_ACCEPTED
    ) {
      drivers = await this.connector.driversService.getAllAvailable(
        regionCode,
        brand,
        restaurantId,
      );
    } else {
      drivers = await this.connector.driversService.getAll(regionCode, brand, restaurantId);
    }

    const availableDrivers = drivers.map((item) => ({
      id: item.identifier,
      title: `${item.firstName} ${item.lastName}`,
    }));

    const driverExist = availableDrivers.some((driver) => driver.id === order.driverId);

    if (!driverExist && order.driverId) {
      const driverAssigned = this.connector.driversContext.driversOnShift.find(
        (driver) => driver.identifier === order.driverId,
      )!;

      this.connector.setActiveDrivers(() => [
        ...availableDrivers,
        {
          id: driverAssigned.identifier,
          title: `${driverAssigned.firstName} ${driverAssigned.lastName}`,
        },
      ]);
    } else {
      this.connector.setActiveDrivers(availableDrivers);
    }
  };

  getFormattedDeliveryFee = (order: OrderResponse): string => {
    const feeValue = this.connector.ordersUtils.calculateDeliveryFee(order.cart, order.payment);

    return this.connector.moneyUtils.moneyFormatter(feeValue, order.payment.total.currency);
  };

  getFormattedFees = (order: OrderResponse): FormattedFees => {
    const deliveryFee = order.fees?.deliveryFee.amount ?? 0;
    const serviceFee = order.fees?.serviceFee.amount ?? 0;
    const currency = order.payment.total.currency;

    return {
      deliveryFee: this.connector.moneyUtils.moneyFormatter(deliveryFee, currency),
      serviceFee: this.connector.moneyUtils.moneyFormatter(serviceFee, currency),
    };
  };

  getFormattedHeaderInfo = (order: OrderResponse): IOrderHeaderInfo => {
    const { findEventTime, findLastEvent } = this.connector.ordersUtils;
    const { formatDate, timerCalculator } = this.connector.dateUtils;

    const driver = this.getAllDrivers().find((d) => d.identifier === order.driverId);

    const lastEvent = findLastEvent(order.events)!.name;
    const baseEvent =
      order.events[0].name === OrderEventType.ORDER_PRE_SCHEDULED
        ? OrderEventType.ORDER_PRE_SCHEDULED
        : OrderEventType.ORDER_ACCEPTED;

    const acceptedEventTime = findEventTime(order.events, baseEvent);
    const lastEventTime = findEventTime(order.events, lastEvent);

    return {
      address: `${order.deliveryAddress.formattedAddress}${
        order.deliveryAddress.subpremise ? `, ${order.deliveryAddress.subpremise}` : ''
      }`,
      channel: channelNamesObject[order.channel as ChannelResponses] || order.channel,
      createdDate: formatDate(new Date(order.createdAt), DateFormat.DD_MM_YY),
      createdHour: formatDate(new Date(order.createdAt), DateFormat.HH_MM),
      deliveryInstructions: order.deliveryAddress?.instructions ?? '',
      deliveryMode: order.deliveryMode,
      driverName: this.getDriverField(order, driver),
      lastEvent,
      paymentMethod: order.payment.paymentMethod,
      timerAccepted: '',
      timerColumn: '',
      totalOrderTime:
        lastEvent === OrderEventType.ORDER_PRE_SCHEDULED
          ? ''
          : timerCalculator(new Date(acceptedEventTime), new Date(lastEventTime)),
    };
  };

  getFormattedOrderDetailsHeader = (order: OrderResponse): IOrderDetailsHeader => {
    return {
      channel: channelNamesObject[order.channel as ChannelResponses] || order.channel,
      createdHour: this.connector.dateUtils.formatDate(new Date(order.createdAt), DateFormat.HH_MM),
      customerName: order.customer.name,
      customerPhone: order.deliveryAddress.phoneNumber,
      customerPhoneNumberCode: order.deliveryAddress.phoneNumberCode,
      displayOrderId: order.displayOrderId,
      formattedAmountToBePaid: this.connector.moneyUtils.moneyFormatter(
        order.payment.total.amount,
        order.payment.total.currency,
      ),
      paymentMethod: order.payment.paymentMethod,
      quadrant: order.quadrant ?? emptyQuadrant,
      statusColor: OrderTypeColor.DEFAULT,
    };
  };

  getFormattedOrderIssueSection = (
    order: OrderResponse,
    issueOptions: OrderIssue[] | CancelOption[],
  ): IOrderIssueSection => {
    const { issue, name: lastEvent } = order.events[order.events.length - 1];

    if (
      (lastEvent === OrderEventType.ORDER_CANCELLED ||
        lastEvent === OrderEventType.ORDER_FINALIZED) &&
      issue
    ) {
      const issueObject = issueOptions.find((item) => item.id === issue[0])?.en ?? issue[0];

      return {
        event: lastEvent,
        issueDescription: issueObject ?? '-',
      };
    }

    return orderIssueSection;
  };

  getFormattedSchedule = (order: OrderResponse): IOrderSchedule => {
    const defaultEtaInMinutes = 30;
    const lastEvent = order.events[order.events.length - 1].name;
    const isManagedExternally = order.deliveryMode === DeliveryMode.MANAGED_EXTERNALLY;

    const containsAcceptedEvent = order.events.some(
      (event) => event.name === OrderEventType.ORDER_ACCEPTED,
    );
    const isOrderCanceled = order.events.some(
      (event) => event.name === OrderEventType.ORDER_FINALIZED,
    );

    const scheduledTime = this.connector.ordersUtils.formatScheduledEvent(
      this.connector.ordersUtils.findEventTime(order.events, OrderEventType.ORDER_PRE_SCHEDULED),
      order.fireOrderInSeconds,
    );

    let startTimeAccepted = '';
    if (lastEvent !== OrderEventType.ORDER_PRE_SCHEDULED && containsAcceptedEvent) {
      startTimeAccepted = this.connector.ordersUtils.formatEventTime(
        OrderEventType.ORDER_ACCEPTED,
        order.events,
      );
    } else if (!isOrderCanceled) {
      startTimeAccepted = this.calculatePreOrderPlacementTime(
        new Date(order.createdAt),
        order.fireOrderInSeconds,
        this.connector.settingsContext.eta ?? defaultEtaInMinutes,
      );
    }

    const startTimeCancelled = this.connector.ordersUtils.formatEventTime(
      OrderEventType.ORDER_CANCELLED,
      order.events,
    );
    const startTimeDelivered = this.connector.ordersUtils.formatEventTime(
      OrderEventType.ORDER_DELIVERED,
      order.events,
    );
    const startTimeInProduction = this.connector.ordersUtils.formatEventTime(
      OrderEventType.ORDER_IN_PREPARATION,
      order.events,
    );
    const startTimeOnDelivery = this.connector.ordersUtils.formatEventTime(
      OrderEventType.ORDER_IN_DELIVERY,
      order.events,
    );
    const startTimeFinalized = this.connector.ordersUtils.formatEventTime(
      OrderEventType.ORDER_FINALIZED,
      order.events,
    );

    const acceptedEventReference =
      lastEvent === OrderEventType.ORDER_FINALIZED
        ? OrderEventType.ORDER_FINALIZED
        : OrderEventType.ORDER_IN_PREPARATION;

    const containsOnDeliveryEvent = order.events.some(
      (event) => event.name === OrderEventType.ORDER_IN_DELIVERY,
    );

    return {
      events: order.events,
      scheduledTime,
      startTimeAccepted,
      startTimeCancelled,
      startTimeDelivered,
      startTimeFinalized,
      startTimeInProduction,
      startTimeOnDelivery,
      totalOrderTime:
        lastEvent === OrderEventType.ORDER_DELIVERED || lastEvent === OrderEventType.ORDER_CANCELLED
          ? this.getTotalTime(OrderEventType.ORDER_ACCEPTED, lastEvent, order.events)
          : '',
      totalTimeAccepted:
        lastEvent !== OrderEventType.ORDER_ACCEPTED &&
        lastEvent !== OrderEventType.DRIVER_ASSIGNED_TO_ORDER &&
        lastEvent !== OrderEventType.ORDER_PRE_SCHEDULED &&
        containsAcceptedEvent
          ? this.getTotalTime(OrderEventType.ORDER_ACCEPTED, acceptedEventReference, order.events)
          : '',
      totalTimeInProduction:
        lastEvent === OrderEventType.ORDER_DELIVERED ||
        lastEvent === OrderEventType.ORDER_CANCELLED ||
        lastEvent === OrderEventType.ORDER_IN_DELIVERY
          ? this.getTotalTimeInProduction(
              lastEvent,
              order.events,
              isManagedExternally,
              containsOnDeliveryEvent,
            )
          : '',
      totalTimeOnDelivery:
        lastEvent === OrderEventType.ORDER_DELIVERED || lastEvent === OrderEventType.ORDER_CANCELLED
          ? this.getTotalTimeOnDelivery(lastEvent, order.events, containsOnDeliveryEvent)
          : '',
    };
  };

  getFormattedSummary = (cart: Cart): IOrderSummary => {
    const cartSummary: FormattedCartItems[] = cart.menuSelections.map((item) => {
      const formattedSubitems: FormattedCartItems[] = item.menuSelections.map((subitem) => ({
        dmpId: subitem.dmpId,
        externalReferenceId: subitem.externalReferenceId,
        formattedPrice: this.connector.moneyUtils.moneyFormatter(
          subitem.price.amount,
          subitem.price.currency,
        ),
        id: subitem.id,
        menuSelections: [],
        name: subitem.name,
        quantity: subitem.quantity,
      }));

      return {
        dmpId: item.dmpId,
        externalReferenceId: item.externalReferenceId,
        formattedPrice: this.connector.moneyUtils.moneyFormatter(
          item.price.amount,
          item.price.currency,
        ),
        id: item.id,
        menuSelections: formattedSubitems,
        name: item.name,
        quantity: item.quantity,
      };
    });

    return { cartSummary };
  };

  getIssueSectionOptions = async (orderDetails: OrderResponse): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;
    const { issue, name: lastEvent } = orderDetails.events[orderDetails.events.length - 1];

    let issueOptions: OrderIssue[] | CancelOption[] = [];

    if (lastEvent === OrderEventType.ORDER_CANCELLED && issue) {
      issueOptions = await this.connector.settingsService.getCloseOrderIssues(
        regionCode,
        brand,
        restaurantId,
      );
    }

    if (lastEvent === OrderEventType.ORDER_FINALIZED && issue) {
      issueOptions = await this.connector.settingsService.getCancelOptions(
        regionCode,
        brand,
        restaurantId,
      );
    }

    this.connector.setIssueOptions(issueOptions);
  };

  getLastEventBeforeAssignDriver = (
    events: OrderEvent[],
    timeToEnableButtonToMoveOrder?: number,
  ): OrderEventType => {
    const lastEvent = events[events.length - 1].name;
    let lastEventBeforeAssignDriver = events[events.length - 1];

    const acceptedTime = events.find((event) => event.name === OrderEventType.ORDER_ACCEPTED)?.time;
    if (acceptedTime && timeToEnableButtonToMoveOrder && this.ldEnableOrderPrepared) {
      const isTimeToEnableButton = this.connector.ordersUtils.isTimeToEnableActionButton(
        acceptedTime,
        timeToEnableButtonToMoveOrder,
      );

      if (lastEvent === OrderEventType.ORDER_PREPARED && isTimeToEnableButton)
        return OrderEventType.ORDER_IN_PREPARATION;
    }

    if (lastEvent === OrderEventType.DRIVER_ASSIGNED_TO_ORDER) {
      const reverseEvents = [...events];
      reverseEvents.reverse();
      lastEventBeforeAssignDriver = reverseEvents.find(
        (event) => event.name !== OrderEventType.DRIVER_ASSIGNED_TO_ORDER,
      ) as OrderEvent;
    }
    return lastEventBeforeAssignDriver.name;
  };

  getSelectOptions = (orderPaymentMethod: string): SelectOption[] => {
    if (!this.connector.settingsContext.allowLastMile || !this.connector.ldFlagLastMileProviders) {
      return this.activeDrivers;
    } else {
      const options: SelectOption[] = this.activeDrivers;
      let providersOptions = new Array<{ id: string; title: string }>();

      const lastMileSettings = this.connector.settingsContext.lastMileSettings;

      if (lastMileSettings) {
        const providers = this.connector.ordersUtils.pickAvailableLastMileProviders(
          lastMileSettings,
          orderPaymentMethod,
        );

        providersOptions = providers.map((item) => ({
          id: item,
          title: item,
        }));
      }

      return options.concat(providersOptions);
    }
  };

  getTotalTimeInProduction = (
    lastEvent: OrderEventType,
    events: OrderEvent[],
    isManagedExternally: boolean,
    containsOnDeliveryEvent: boolean,
  ): string => {
    if (isManagedExternally) {
      return containsOnDeliveryEvent
        ? this.getTotalTime(
            OrderEventType.ORDER_IN_PREPARATION,
            OrderEventType.ORDER_IN_DELIVERY,
            events,
          )
        : this.getTotalTime(
            OrderEventType.ORDER_IN_PREPARATION,
            OrderEventType.ORDER_CANCELLED,
            events,
          );
    } else {
      return lastEvent === OrderEventType.ORDER_DELIVERED ||
        lastEvent === OrderEventType.ORDER_CANCELLED ||
        lastEvent === OrderEventType.ORDER_IN_DELIVERY
        ? this.getTotalTime(
            OrderEventType.ORDER_IN_PREPARATION,
            OrderEventType.ORDER_IN_DELIVERY,
            events,
          )
        : '';
    }
  };

  getTotalTimeOnDelivery = (
    lastEvent: OrderEventType,
    events: OrderEvent[],
    hasOnDeliveryEvent: boolean,
  ): string => {
    return hasOnDeliveryEvent
      ? this.getTotalTime(OrderEventType.ORDER_IN_DELIVERY, lastEvent, events)
      : '-';
  };

  handleModal = (content?: ReactElement<any, any>): void => {
    return this.connector.modalContext.handleModal(content);
  };

  isValidProvider = (provider: string): boolean => {
    return this.lastMileProviders.includes(provider as LastMileProviders);
  };

  startPooling = (): NodeJS.Timer => {
    const interval = 1000;

    return setInterval(() => {
      const order = this.connector.orderRef.current;
      const lastEvent = order?.events[order.events.length - 1].name;

      if (
        order &&
        lastEvent &&
        lastEvent !== OrderEventType.ORDER_DELIVERED &&
        lastEvent !== OrderEventType.ORDER_CANCELLED &&
        lastEvent !== OrderEventType.ORDER_FINALIZED
      ) {
        const orderFormatted: OrderDetailsFormatted = this.genericFormatter(order);

        this.connector.setOrder(orderFormatted);
      }
    }, interval);
  };

  private getAllDrivers = (): DriverResponse[] => {
    const { driversOnShift, driversOffShift } = this.connector.driversContext;

    return [...driversOnShift, ...driversOffShift];
  };

  private getDriverField = (order: OrderResponse, driver?: DriverResponse) => {
    if (
      !driver &&
      !!order.lastMileDeliveryId &&
      order.lastMileDeliveryStatus !== LastMileDeliveryStatus.MATCHING &&
      order.lastMileDeliveryStatus !== LastMileDeliveryStatus.NO_DRIVERS_FOUND
    ) {
      const lastMileDetails = this.connector.deliveryContext.lastMileDetails;
      const fullName = lastMileDetails?.courier.fullName;
      const provider = lastMileDetails?.provider;

      if (fullName && provider) {
        return `${fullName} (${provider})`;
      }
      return '-';
    }

    return (driver && `${driver.firstName} ${driver.lastName}`) ?? '-';
  };

  private getTotalTime = (
    event1: OrderEventType,
    event2: OrderEventType,
    orderEvents: OrderEvent[],
  ): string => {
    const eventTime1 = this.connector.ordersUtils.findEventTime(orderEvents, event1);
    const eventTime2 = this.connector.ordersUtils.findEventTime(orderEvents, event2);

    return this.connector.dateUtils.timerCalculator(new Date(eventTime1), new Date(eventTime2));
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useOrderDetailsDelegate = () => new OrderDetailsDelegate(useOrderDetailsConnector());
