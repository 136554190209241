import { addSeconds, isWithinInterval } from 'date-fns';

import { Delegate } from '../../../@types/handlers/delegate';
import { OrderResponse } from '../../../@types/orders';
import { DateFormat } from '../../../utils/DateTimeUtils';
import { usePreOrdersConnector } from './connector';
interface IPreOrdersDelegate {
  /**
   * List of active orders that is in pre-schedule status
   */
  scheduledOrdersQuantity: number;

  /**
   * It creates a map between the available intervals and orders inside the interval
   */
  getPreOrdersPerInterval: () => Map<string, OrderResponse[]>;

  /**
   * Set the input value to filter orders
   */
  setTextField: (value: string) => void;

  /**
   * Clear the filter input
   */
  clearTextField: () => void;
}

type PreOrdersConnector = ReturnType<typeof usePreOrdersConnector>;

export class PreOrdersDelegate extends Delegate<PreOrdersConnector> implements IPreOrdersDelegate {
  get filterText(): string {
    return this.connector.filterText;
  }

  get scheduledOrdersQuantity(): number {
    return this.connector.scheduledOrders.length;
  }

  clearTextField = () => {
    this.connector.setFilterText('');
  };

  getPreOrdersPerInterval = (): Map<string, OrderResponse[]> => {
    const preScheduledInterval = this.connector.preScheduledInterval;
    const mapOrdersPerInterval = new Map<string, OrderResponse[]>();
    preScheduledInterval.forEach((interval, idx) => {
      let nextInterval: string;
      const [startHour, startMinute] = interval.split(':');

      // For the last interval we need to compare it with something
      // Comparing with the last hour minute
      if (idx + 1 >= preScheduledInterval.length) {
        nextInterval = `${startHour}:59`;
      } else {
        nextInterval = preScheduledInterval[idx + 1];
      }

      const [nextIntervalHour, nextIntervalMinute] = nextInterval.split(':');

      const intervalToDate = new Date();
      const nextIntervalToDate = new Date();

      intervalToDate.setHours(+startHour);
      intervalToDate.setMinutes(+startMinute);
      nextIntervalToDate.setHours(+nextIntervalHour);
      nextIntervalToDate.setMinutes(+nextIntervalMinute);

      const orderForInterval = this.filterOrdersFor(intervalToDate, nextIntervalToDate);

      if (orderForInterval.length > 0) {
        mapOrdersPerInterval.set(interval, orderForInterval);
      }
    });

    return mapOrdersPerInterval;
  };

  setTextField = (value: string): void => {
    this.connector.setFilterText(value);
  };

  /**
   * It creates a map with the orders that matches the text to filter
   *
   * @return {OrderResponse[]} the orders filtered
   */
  private filterByCustomerName = (): OrderResponse[] => {
    const scheduledOrders = this.connector.scheduledOrders;

    const regex = new RegExp(this.filterText, 'gi');

    return scheduledOrders.filter((order) => regex.test(order.customer.name));
  };

  /**
   * @param interval - The starting date and time of the interval to compare.
   * @param nextInterval - The date and time representing the next interval.
   * @returns An array of OrderResponse objects containing orders scheduled for the given interval.
   */
  private filterOrdersFor(interval: Date, nextInterval: Date): OrderResponse[] {
    const filteredOrders = this.filterByCustomerName();

    return filteredOrders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const scheduled = addSeconds(orderDate, order.fireOrderInSeconds);
      const dateFormattedInTimezone = this.connector.dateUtils.formatDate(
        scheduled,
        DateFormat.MM_DD_YY_HH_MM,
      );

      return isWithinInterval(new Date(dateFormattedInTimezone), {
        end: nextInterval,
        start: interval,
      });
    });
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePreOrdersDelegate = () => new PreOrdersDelegate(usePreOrdersConnector());
