import { Visibility } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

interface IShowOrderDetails {
  orderId: string;
  onClick: (orderId: string) => void;
}

const Base = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
`;

export const ShowOrderDetails: FC<IShowOrderDetails> = ({ orderId, onClick }) => {
  return (
    <Base onClick={() => onClick(orderId)}>
      <Visibility sx={{ color: '#507083', fontSize: '33px' }} />
    </Base>
  );
};
