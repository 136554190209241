import { Close, Error } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  padding: 24px 16px;
  margin-bottom: 16px;
  min-width: 290px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
`;

const NotificationText = styled.p`
  color: #000;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

interface NotificationCardProps {
  onCloseNotification: () => void;
}

export const NotificationCard = ({ onCloseNotification }: NotificationCardProps) => {
  const { t } = useTranslation();

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    onCloseNotification();
  };

  return (
    <NotificationContainer>
      <CloseButton onClick={handleCloseClick} data-testid="close-notification">
        <Close sx={{ color: '#000000', fontSize: '12px', padding: '8px' }} />
      </CloseButton>
      <Error sx={{ color: '#DD3712', fontSize: '20px' }} />
      <NotificationText>
        {t('components.ComplaintsNotification.newComplaintReceived')}
      </NotificationText>
    </NotificationContainer>
  );
};
