import { ComplaintsResponse, UnformattedComplaintsResponse } from '../@types/complaints';
import { ordersUtils } from './OrdersUtils';

interface IComplaintsUtils {
  /**
   * Receives a complaint object from API and format the paymentMethod to handle paymentMethodBrand
   *
   * @param {UnformattedComplaintsResponse} complaint the complaint incoming from API
   * @returns {ComplaintsResponse} complaint formatted
   */
  formatToComplaintsResponse(complaint: UnformattedComplaintsResponse): ComplaintsResponse;
}

const ordersUtil = ordersUtils();

export class ComplaintsUtils implements IComplaintsUtils {
  formatToComplaintsResponse = (complaint: UnformattedComplaintsResponse): ComplaintsResponse => {
    return {
      ...complaint,
      order: complaint.order && ordersUtil.formatToOrderResponse(complaint.order),
      payment: complaint.payment && {
        amountLeftToBePaid: complaint.payment.amountLeftToBePaid,
        paymentMethod: complaint.payment.paymentMethodBrand || complaint.payment.paymentMethod,
        tip: complaint.payment.tip,
        total: complaint.payment.total,
      },
    };
  };
}

export const complaintsUtils = () => new ComplaintsUtils();
