import { createContext, FC, useContext, useEffect, useMemo } from 'react';

import { IAuthContextDelegate, useAuthContextDelegate } from './controllers/delegate';

export const AuthContext = createContext<
  Pick<
    IAuthContextDelegate,
    | 'isLoading'
    | 'login'
    | 'logout'
    | 'restaurant'
    | 'restaurantRef'
    | 'user'
    | 'refreshToken'
    | 'userRef'
    | 'setRestaurant'
  >
>({
  isLoading: false,
  login: () => Promise.resolve(false),
  logout: () => Promise.resolve(false),
  refreshToken: () => Promise.resolve(),
  restaurant: null,
  restaurantRef: { current: null },
  setRestaurant: () => ({}),
  user: null,
  userRef: { current: null },
});

AuthContext.displayName = 'Auth';

interface IAuthProvider {
  children: JSX.Element;
}

const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const {
    isLoading,
    restaurant,
    restaurantRef,
    user,
    userRef,
    login,
    logout,
    refreshToken,
    renewToken,
    retrieveUser,
    retrieveRestaurant,
    setRestaurant,
  } = useAuthContextDelegate();

  useEffect(() => {
    retrieveUser();
    retrieveRestaurant();

    const interval = renewToken();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    restaurantRef.current = restaurant;
  }, [restaurant]);

  const contextProps = useMemo(() => {
    return {
      isLoading,
      login,
      logout,
      refreshToken,
      restaurant,
      restaurantRef,
      setRestaurant,
      user,
      userRef,
    };
  }, [
    isLoading,
    login,
    logout,
    refreshToken,
    restaurant,
    restaurantRef,
    setRestaurant,
    user,
    userRef,
  ]);

  return <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
