import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { AutoFireUpdate } from '../../../../../@types/restaurants';
import { RestaurantServiceStatus } from '../../../../../constants/restaurants';
import { ToastTypes } from '../../../../../constants/toasts';
import useAutoFireConnector from './connector';

interface IAutoFireDelegate {
  /**
   * Indicates if auto Fire is active
   */
  autoFire: boolean;

  /**
   * Call stettings service to update autoFireStatus
   *
   * @param {AutoFireUpdate} autoFireStatus status to update
   * @param {string | undefined} token optional parameter used for activate autoFire
   *
   * @returns {boolean} returns true if success and false if error
   */
  updateAutoFireStatus(autoFireStatus: AutoFireUpdate, token?: string): Promise<boolean>;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;
}

type AutoFireConnector = ReturnType<typeof useAutoFireConnector>;

export class AutoFireDelegate extends Delegate<AutoFireConnector> implements IAutoFireDelegate {
  get autoFire(): boolean {
    return this.connector.settingsContext.autoFire;
  }

  get autoPrepare(): boolean {
    return this.connector.settingsContext.autoPrepare;
  }

  get pos(): boolean {
    return this.connector.settingsContext.pos;
  }

  get isServiceAvailable(): boolean {
    return (
      this.connector.settingsContext.serviceStatus === RestaurantServiceStatus.RESTAURANT_AVAILABLE
    );
  }

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  updateAutoFireStatus = async (autoFireStatus: AutoFireUpdate, token = ''): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      if (autoFireStatus.status && !token) {
        throw new Error('error on enable auto fire, missing token');
      }

      await this.connector.settingsService.updateAutoFireStatus(
        regionCode,
        brand,
        restaurantId,
        autoFireStatus,
        token,
      );

      this.connector.settingsContext.setAutoFire(autoFireStatus.status);

      if (autoFireStatus.status)
        this.connector.settingsContext.setAutoPrepare(!autoFireStatus.status);

      return true;
    } catch {
      this.connector.toastContext.addToast(
        'components.PageHeader.AutoFire.errorOnUpdate',
        ToastTypes.ERROR,
      );

      return false;
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useAutoFireDelegate = () => new AutoFireDelegate(useAutoFireConnector());
