import { createContext, FC, useContext, useEffect } from 'react';

import { IDriversContextDelegate, useDriversContextDelegate } from './controllers/delegate';

export const DriversContext = createContext<
  Pick<IDriversContextDelegate, 'driversOnShift' | 'driversOffShift' | 'forceUpdate'>
>({
  driversOffShift: [],
  driversOnShift: [],
  forceUpdate: () => Promise.resolve(),
});

DriversContext.displayName = 'Drivers';

interface IDriversProvider {
  children: JSX.Element;
}

const DriversProvider: FC<IDriversProvider> = ({ children }) => {
  const { driversOffShift, driversOnShift, forceUpdate, getDriversShift } =
    useDriversContextDelegate();

  useEffect(() => {
    const interval = getDriversShift();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <DriversContext.Provider value={{ driversOffShift, driversOnShift, forceUpdate }}>
      {children}
    </DriversContext.Provider>
  );
};

export const useDriversContext = () => useContext(DriversContext);

export default DriversProvider;
