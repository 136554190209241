import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as DriverIcon } from '../../../../assets/icons/driver.svg';
import SvgIcon from '../../../SvgIcon';
import { useTotalActiveDriversDelegate } from './controllers/delegate';

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextStyle = styled.span`
  margin-right: 10px;
  text-align: end;
`;

const TotalActiveDrivers: FC = () => {
  const { t } = useTranslation();
  const delegate = useTotalActiveDriversDelegate();

  return (
    <Div>
      <TextStyle data-testid="active-drivers">
        {delegate.totalActiveDrivers}{' '}
        {t('components.PageHeader.TotalActiveDrivers.activeDrivers', {
          count: delegate.totalActiveDrivers,
        })}
      </TextStyle>
      <SvgIcon children={<DriverIcon />} width={23} height={23} color="#878787" />
    </Div>
  );
};

export default TotalActiveDrivers;
