import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { StatusModalType } from '../../../../../../constants/modalType';
import { RestaurantServiceStatusReasons } from '../../../../../../constants/restaurants';
import Loading from '../../../../../Loading';
import GenericButton from '../../../../../buttons/GenericButton';
import GenericRadioList from '../../../../../inputs/GenericRadioList';
import ResponseStatusModal from '../../../../../modals/ResponseStatusModal';
import { usePauseServiceModalDelegate } from './controllers/delegate';

interface IPauseServiceModal {
  token: string;
}

const Container = styled.div`
  padding: 68px 86px 36px 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0;
`;

const InputList = styled.div`
  width: 100%;
  padding: 52px 0;
  margin-left: 42px;
`;

const ButtonDiv = styled.div`
  width: 370px;
`;

const PauseServiceModal: FC<IPauseServiceModal> = ({ token }) => {
  const { t } = useTranslation();

  const {
    pauseReasons,
    selectedReason,
    handleModal,
    pauseService,
    getPauseReasons,
    setSelectedReason,
    getPauseReasonsOptions,
  } = usePauseServiceModalDelegate();

  useEffect(() => {
    getPauseReasons();
  }, []);

  const pauseReasonsOptions = useMemo(getPauseReasonsOptions, [pauseReasons]);

  const showPausedModal = async (): Promise<void> => {
    if (selectedReason) {
      const result = await pauseService(token, selectedReason);

      if (result) {
        if (selectedReason === RestaurantServiceStatusReasons.NUMBER_OF_ORDERS) {
          handleModal(
            <ResponseStatusModal
              message={`${t(
                'components.PageHeader.ServiceActive.PausedNumberOrdersModal.message',
              )}`}
              observation={`${t('components.PageHeader.ServiceActive.modalObservation')}`}
              highlightedObservation={`${t(
                'components.PageHeader.ServiceActive.PausedNumberOrdersModal.highlightedObservation',
              )}`}
              onClick={() => handleModal()}
              type={StatusModalType.NEUTRAL}
            />,
            '#FFFFFF',
          );
        } else {
          handleModal(
            <ResponseStatusModal
              message={`${t('components.PageHeader.ServiceActive.PausedModal.message')}`}
              observation={`${t('components.PageHeader.ServiceActive.modalObservation')}`}
              highlightedObservation={`${t(
                'components.PageHeader.ServiceActive.PausedModal.highlightedObservation',
              )}`}
              onClick={() => handleModal()}
              type={StatusModalType.NEUTRAL}
            />,
            '#FFFFFF',
          );
        }
      } else {
        handleModal();
      }
    }
  };

  return (
    <Container>
      {pauseReasonsOptions.length ? (
        <>
          <div>
            <Title>{t('components.PageHeader.ServiceActive.PauseServiceModal.title')}</Title>
          </div>
          <InputList>
            <GenericRadioList
              options={pauseReasonsOptions}
              onChange={(id) => setSelectedReason(id as RestaurantServiceStatusReasons)}
            />
          </InputList>
          <ButtonDiv>
            <GenericButton
              title={t('components.PageHeader.ServiceActive.PauseServiceModal.button')}
              onClick={() => showPausedModal()}
              disabled={!selectedReason}
            />
          </ButtonDiv>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default PauseServiceModal;
