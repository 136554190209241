import { CheckRounded, ErrorOutline } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ICloseOrder {
  onCloseIssue?: () => void;
  onCloseSuccess?: () => void;
}

const Body = styled.div`
  width: 716px;
  height: 643px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0;
`;

const Button = styled.button`
  width: 623px;
  height: 219px;
  left: 52px;
  top: 130px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-width: 0.2px;
  cursor: pointer;
`;

const ButtonSuccess = styled(Button)`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 53.12%),
    #79bf1f;
`;

const ButtonError = styled(Button)`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 53.12%),
    #dd3712;
`;

const ButtonText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const CloseOrder: FC<ICloseOrder> = ({ onCloseIssue, onCloseSuccess }) => {
  const { t } = useTranslation();

  const handleSuccess = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseSuccess && onCloseSuccess();
  };

  const handleIssue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseIssue && onCloseIssue();
  };

  return (
    <Body>
      <Title>{t('pages.OrderManagement.CloseOrder.title')}</Title>
      <ButtonSuccess onClick={handleSuccess}>
        <CheckRounded sx={{ color: '#FFFFFF', fontSize: '60px' }} />
        <ButtonText>{t('pages.OrderManagement.CloseOrder.buttonSuccess')}</ButtonText>
      </ButtonSuccess>
      <ButtonError onClick={handleIssue}>
        <ErrorOutline sx={{ color: '#FFFFFF', fontSize: '60px' }} />
        <ButtonText>{t('pages.OrderManagement.CloseOrder.buttonIssue')}</ButtonText>
      </ButtonError>
    </Body>
  );
};

export default CloseOrder;
