import { Input } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SelectOption } from '../../@types/input';
import SvgIcon from '../SvgIcon';

interface IGenericSelect {
  value: string;
  options: SelectOption[];
  optionWidth?: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  emptyOption?: string;
  handleOnChange: (value: string) => void;
}

const Body = styled.div`
  display: flex;
  height: 50px;
  background: #ffffff;
  border: 1px solid #97b0bd;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  align-items: center;
  padding-right: 8px;
`;

const SelectIcon = styled.div`
  margin-left: 12px;
  margin-right: 8px;
`;

const inputStyles: React.CSSProperties = {
  color: '#3B5A6B',
  fontFamily: 'Work Sans',
  fontSize: '18px',
  fontWeight: 400,
  letterSpacing: '-0.01em',
  lineHeight: '26px',
  minHeight: '50px',
};

const GenericSelect: FC<IGenericSelect> = ({
  options,
  icon,
  emptyOption,
  handleOnChange,
  optionWidth,
  value,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (e: SelectChangeEvent<string>): void => {
    e.stopPropagation();
    e.preventDefault();

    handleOnChange(e.target.value);
  };

  const parseValue = (value: string): string => {
    if (value === '') {
      return '';
    }

    const currentOption = options.find((option) => option.id === value);

    return currentOption ? currentOption.id : '';
  };

  return (
    <Body>
      <Select
        sx={{ marginRight: 0 }}
        fullWidth
        value={parseValue(value)}
        onChange={handleChange}
        disabled={isDisabled}
        variant="standard"
        disableUnderline
        displayEmpty
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              borderRadius: '0px 0px 8px 8px',
            },
          },
        }}
        input={
          <Input
            style={{
              ...inputStyles,
              fontWeight: 600,
            }}
            startAdornment={
              <SelectIcon>
                <SvgIcon children={icon} color="#97B0BD" width={24} height={24} />
              </SelectIcon>
            }
          />
        }
      >
        {!value && (
          <MenuItem
            value=""
            style={{
              ...inputStyles,
              border: '1px solid #97B0BD',
            }}
            disabled
          >
            <em>{emptyOption || t('components.GenericSelect.emptyOption')}</em>
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={{
              ...inputStyles,
              border: '1px solid #97B0BD',
              fontSize: '14px',
              whiteSpace: 'normal',
              width: optionWidth ? `${optionWidth}px` : 'auto',
            }}
            disabled={value === option.id}
          >
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </Body>
  );
};

export default GenericSelect;
