import { Fastfood, Pause } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AutoPrepareUpdate } from '../../../../@types/restaurants';
import { PermissionTokenActions } from '../../../../constants/auth';
import { StatusModalType } from '../../../../constants/modalType';
import PasswordValidation from '../../../PasswordValidation';
import CardButton from '../../../buttons/CardButton';
import ResponseStatusModal from '../../../modals/ResponseStatusModal';
import AutoFireModal from '../AutoFire/components/AutoFireModal';
import { useAutoPrepareDelegate } from './controllers/delegate';

const Div = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Title = styled.p<{ $isActive?: boolean }>`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ $isActive }) => ($isActive ? '#507083' : '#878787')};
  letter-spacing: 0.06em;
  white-space: pre-line;
`;

const AutoPrepare: FC = () => {
  const { t } = useTranslation();
  const { pos, autoFire, autoPrepare, handleModal, updateAutoPrepareStatus } =
    useAutoPrepareDelegate();

  const handleChangeAutoPrepare = () => {
    autoPrepare ? handleDisableAutoPrepare() : handleEnableAutoPrepare();
  };

  const handleDisableAutoPrepare = async (): Promise<void> => {
    const response = await updateAutoPrepareStatus({ status: false });

    if (response) {
      handleModal(
        <ResponseStatusModal
          message={t('components.PageHeader.AutoPrepare.successOnDisable')}
          onClick={() => handleModal()}
          type={StatusModalType.NEUTRAL}
        />,
        '#FFFFFF',
      );
    }
  };

  const enableAutoPrepare = async (
    autoPrepareStatus: AutoPrepareUpdate,
    token: string,
  ): Promise<void> => {
    const response = await updateAutoPrepareStatus(autoPrepareStatus, token);

    if (response) {
      handleModal(
        <ResponseStatusModal
          message={t('components.PageHeader.AutoPrepare.successOnEnable')}
          onClick={() => handleModal()}
          type={StatusModalType.SUCCESS}
        />,
        '#FFFFFF',
      );
    } else {
      handleModal();
    }
  };

  const handleEnableAutoPrepare = (): void => {
    const passwordValidationModal = (): void =>
      handleModal(
        <PasswordValidation
          title={t('components.PageHeader.AutoPrepare.PasswordValidation.titleToEnable')}
          action={PermissionTokenActions.AUTO_PREPARE}
          onSuccess={(token) => enableAutoPrepare({ status: true }, token)}
        />,
      );

    if (autoFire) {
      handleModal(
        <AutoFireModal
          title={t('components.PageHeader.AutoFire.Modal.stopAutoFireTitle')}
          message={t('components.PageHeader.AutoFire.Modal.stopAutoFireMessage')}
          buttonConfirm={t('components.PageHeader.AutoFire.Modal.stopAutoFireButton')}
          buttonCancel={t('components.PageHeader.AutoFire.Modal.cancelButton')}
          onCancel={() => handleModal()}
          onConfirm={passwordValidationModal}
        />,
      );
    } else {
      passwordValidationModal();
    }
  };

  return (
    <CardButton
      onClick={handleChangeAutoPrepare}
      isDisabled={!pos}
      isSwitchedOn={autoPrepare}
      activeColor="#DAE7EE"
      borderColor="#507083"
    >
      <Div data-testid="auto-prepare">
        <Title $isActive={autoPrepare}>
          {autoPrepare
            ? t('components.PageHeader.AutoPrepare.titleStopAutoPrepare')
            : t('components.PageHeader.AutoPrepare.titleRunAutoPrepare')}
        </Title>
        {autoPrepare ? (
          <Pause sx={{ color: '#507083', height: '32px', width: '32px' }} />
        ) : (
          <Fastfood sx={{ color: '#878787', height: '20px', width: '20px' }} />
        )}
      </Div>
    </CardButton>
  );
};

export default AutoPrepare;
