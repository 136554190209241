import { ReactElement, useState } from 'react';

export const useSidebarContextConnector = () => {
  const [content, setContent] = useState<ReactElement<any, any> | undefined>(undefined);
  const [dependencies, setDependencies] = useState<string[] | undefined>(undefined);

  return {
    content,
    dependencies,
    setContent,
    setDependencies,
  };
};
