import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RestaurantServiceStatusReasons } from '../../../../../../../constants/restaurants';
import { useAuthContext } from '../../../../../../../contexts/AuthContext';
import { useModalContext } from '../../../../../../../contexts/ModalContext';
import { useToastContext } from '../../../../../../../contexts/ToastContext';
import settingsService from '../../../../../../../services/SettingsService';

const usePauseServiceModalConnector = () => {
  const { t: translate } = useTranslation();

  const [pauseReasons, setPauseReasons] = useState<RestaurantServiceStatusReasons[]>([]);
  const [selectedReason, setSelectedReason] = useState<
    RestaurantServiceStatusReasons | undefined
  >();

  return {
    authContext: useAuthContext(),
    modalContext: useModalContext(),
    pauseReasons,
    selectedReason,
    setPauseReasons,
    setSelectedReason,
    settingsService,
    toastContext: useToastContext(),
    translate,
  };
};

export default usePauseServiceModalConnector;
