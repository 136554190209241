import { MutableRefObject } from 'react';

import { AuthUser } from '../../../@types/auth';
import { Delegate } from '../../../@types/handlers/delegate';
import { HTTPClient } from '../../../@types/handlers/httpClient';
import { WebSocketClient } from '../../../@types/handlers/websocketClient';
import { Restaurant } from '../../../@types/restaurants';
import { REFRESH_TOKEN_POOLING, TOKEN_MINIMUM_EXP_TIME } from '../../../constants/auth';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import { useAuthContextConnector } from './connector';

export interface IAuthContextDelegate {
  /**
   * Authenticated user info if logged or null
   */
  user: AuthUser | null;

  /**
   * Object reference from user
   */
  userRef: MutableRefObject<AuthUser | null>;

  /**
   * Restaurant info if logged or null
   */
  restaurant: Restaurant | null;

  /**
   * Object reference from restaurant
   */
  restaurantRef: MutableRefObject<Restaurant | null>;

  /**
   * Attribute to identify if retrieveUser is loading
   */
  isLoading: boolean;

  /**
   * Login user in the application
   *
   * @param {string} identifier user identifier
   * @param {string} password user password
   * @returns {Promise<boolean>} returns true to success or false on error
   */
  login: (identifier: string, password: string) => Promise<boolean>;

  /**
   * Logout user from application
   * @returns {Promise<boolean>} returns true to success or false on error
   */
  logout: () => Promise<boolean>;

  refreshToken: (user: AuthUser | null) => Promise<void>;

  /**
   * Renew user token pooling
   */
  renewToken(): NodeJS.Timer;

  /**
   * Update restaurant context if info is saved on local storage
   */
  retrieveRestaurant: () => void;

  /**
   * Update user context if info is saved on local storage
   */
  retrieveUser: () => Promise<void>;

  /**
   * Set restaurant info on state and local storage
   *
   * @param {Restaurant | null} restaurant new restaurant info
   */
  setRestaurant(restaurant: Restaurant | null): void;
}

type AuthContextConnector = ReturnType<typeof useAuthContextConnector>;

export class AuthContextDelegate
  extends Delegate<AuthContextConnector>
  implements IAuthContextDelegate
{
  get isLoading(): boolean {
    return this.connector.isLoading;
  }

  get user(): AuthUser | null {
    return this.connector.user;
  }

  get userRef(): MutableRefObject<AuthUser | null> {
    return this.connector.userRef;
  }

  get restaurant(): Restaurant | null {
    return this.connector.restaurant;
  }

  get restaurantRef(): MutableRefObject<Restaurant | null> {
    return this.connector.restaurantRef;
  }

  login = async (identifier: string, password: string): Promise<boolean> => {
    if (!identifier || !password) return false;

    try {
      const { token, expiresAt, refreshToken } = await this.connector.authService.login({
        identifier,
        password,
      });

      const payload = JSON.parse(window.atob(token.split('.')[1]));
      const brand = payload['custom:brand'];
      const regionCode = payload['custom:regionCode'];
      const restaurantId = payload['custom:restaurantId'];
      const role = payload['custom:role'];

      const user: AuthUser = {
        brand,
        expiresAt,
        identifier,
        refreshToken,
        regionCode,
        restaurantId,
        role,
        token,
      };

      this.setUserInfo(user);

      const restaurant = await this.connector.restaurantService.getOne(
        regionCode,
        brand,
        restaurantId,
      );

      this.setRestaurant(restaurant);

      return true;
    } catch (_) {
      return false;
    }
  };

  logout = async (): Promise<boolean> => {
    try {
      if (!this.restaurant || !this.user) {
        throw Error('Restaurant or user information is null');
      }

      const brand = this.restaurant.brand;
      const country = this.restaurant.address.regionCode;
      const refreshToken = this.user.refreshToken;

      await this.connector.authService.logout(country, brand, refreshToken);

      this.setUserInfo(null);
      this.setRestaurant(null);
      this.clearStorage();
      return true;
    } catch (_) {
      return false;
    }
  };

  refreshToken = async (user: AuthUser | null): Promise<void> => {
    if (user) {
      try {
        const { regionCode, brand, refreshToken } = user;

        const { token, expiresAt } = await this.connector.authService.refreshToken(
          regionCode,
          brand,
          {
            refreshToken,
          },
        );

        const userUpdated: AuthUser = {
          ...user,
          expiresAt,
          token,
        };

        this.setUserInfo(userUpdated);
      } catch (_) {
        this.logout();
      }
    }
  };

  renewToken = (): NodeJS.Timer => {
    return setInterval(() => {
      this.refreshToken(this.userRef.current);
    }, REFRESH_TOKEN_POOLING);
  };

  retrieveRestaurant = (): void => {
    const storedRestaurant = window.localStorage.getItem('@RBI_Expeditor_Tablet:Restaurant');

    if (storedRestaurant) this.setRestaurant(JSON.parse(storedRestaurant));
  };

  retrieveUser = async (): Promise<void> => {
    const storedUser = window.localStorage.getItem('@RBI_Expeditor_Tablet:User');

    if (storedUser) {
      const user: AuthUser = JSON.parse(storedUser);

      this.setUserInfo(user);
      await this.validateToken(user);
    }

    this.connector.setIsLoading(false);
  };

  setRestaurant = (restaurant: Restaurant | null): void => {
    this.connector.setRestaurant(restaurant);
    DateTimeUtils.setTimezone(restaurant?.settings.timezone ?? null);

    if (restaurant) {
      window.localStorage.setItem('@RBI_Expeditor_Tablet:Restaurant', JSON.stringify(restaurant));
    } else {
      window.localStorage.removeItem('@RBI_Expeditor_Tablet:Restaurant');
    }
  };

  private clearStorage = () => {
    Object.keys(window.localStorage).map((key) => window.localStorage.removeItem(key));
  };

  private setUserInfo = (user: AuthUser | null) => {
    this.connector.setUser(user);
    HTTPClient.setAuthToken(user ? user.token : null);
    WebSocketClient.setAuthToken(user ? user.token : null);

    if (user) {
      window.localStorage.setItem('@RBI_Expeditor_Tablet:User', JSON.stringify(user));
    } else {
      window.localStorage.removeItem('@RBI_Expeditor_Tablet:User');
    }
  };

  private validateToken = async (user: AuthUser): Promise<void> => {
    const { expiresAt } = user;

    const isAfter = this.connector.utilsDate.isDateAfter(new Date(expiresAt), new Date());

    if (isAfter) {
      const response = this.connector.utilsDate.timeDifference(
        new Date(expiresAt),
        new Date(),
        'minutes',
      );

      if (response < TOKEN_MINIMUM_EXP_TIME) {
        await this.refreshToken(user);
      }
    } else {
      await this.refreshToken(user);
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useAuthContextDelegate = () => new AuthContextDelegate(useAuthContextConnector());
