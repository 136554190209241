export enum RestaurantCapacity {
  NORMAL = 'NORMAL',
  REDUCED = 'REDUCED',
}

export enum RestaurantEventType {
  SETTINGS_UPDATE = 'SETTINGS_UPDATE',
}

export enum RestaurantServiceStatus {
  RESTAURANT_AVAILABLE = 'RESTAURANT_AVAILABLE',
  RESTAURANT_PAUSED = 'RESTAURANT_PAUSED',
  RESTAURANT_PAUSED_BY_CORP = 'RESTAURANT_PAUSED_BY_CORP',
}

export enum RestaurantSettingsWeekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum RestaurantServiceStatusReasons {
  NUMBER_OF_ORDERS = 'NUMBER_OF_ORDERS',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  RESTAURANT_SCHEDULE = 'RESTAURANT_SCHEDULE',
  WEATHER = 'WEATHER',
  STREET_CLOSED = 'STREET_CLOSED',
  NO_DRIVERS_AVAILABLE = 'NO_DRIVERS_AVAILABLE',
}

export const restaurantServiceStatusReasonsTranslation: Record<
  RestaurantServiceStatusReasons,
  string
> = {
  NO_DRIVERS_AVAILABLE: 'constants.pauseServiceReasons.noDriversAvailable',
  NUMBER_OF_ORDERS: 'constants.pauseServiceReasons.numberOfOrders',
  RESTAURANT_SCHEDULE: 'constants.pauseServiceReasons.restaurantSchedule',
  STREET_CLOSED: 'constants.pauseServiceReasons.streetClosed',
  TECHNICAL_ISSUES: 'constants.pauseServiceReasons.techIssues',
  WEATHER: 'constants.pauseServiceReasons.weather',
};
