import { Close } from '@mui/icons-material';
import { FC, ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface IGenericSidebar {
  handleSidebar: () => void;
  showSidebar: boolean;
  content: ReactElement<any, any> | undefined;
  dependencies?: string[];
}

const Background = styled.div`
  position: fixed;
  width: 409px;
  height: calc(100% - 207px);
  bottom: 0px;
  right: 0;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  z-index: 9;
`;

const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 3;
`;

const GenericSidebar: FC<IGenericSidebar> = ({
  content,
  showSidebar,
  dependencies,
  handleSidebar,
}) => {
  const depsRef = useRef(dependencies);

  const clickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let closeSidebar = true;

    const checkParent = (id: string, child: React.ChangeEvent<HTMLInputElement>) => {
      const sidebar = document.getElementById(id);
      let node = child.target.parentNode;

      while (node !== null) {
        if (node === sidebar) return true;
        node = node.parentNode;
      }
      return false;
    };

    if (e.target.id === 'sidebar-context' || checkParent('sidebar-context', e)) {
      closeSidebar = false;
    }

    if (
      (e.target.lastChild as unknown as { id: string })?.id === 'menu-' ||
      checkParent('menu-', e)
    ) {
      closeSidebar = false;
    }

    depsRef.current?.map((id) => {
      if (e.target.id === id || checkParent(id, e)) {
        closeSidebar = false;
      }
    });

    if (closeSidebar) handleSidebar();
  };

  useEffect(() => {
    window.addEventListener('click', clickHandler as unknown as EventListener);
    return window.removeEventListener('click', clickHandler as unknown as EventListener, true);
  }, []);

  useEffect(() => {
    depsRef.current = dependencies;
  }, [dependencies]);

  const sidebar = (
    <Background id="sidebar-context">
      <CloseButton onClick={() => handleSidebar()} data-testid="close-sidebar-context">
        <Close sx={{ color: '#507083', fontSize: '30px' }} />
      </CloseButton>
      {content}
    </Background>
  );

  return showSidebar ? sidebar : null;
};

export default GenericSidebar;
