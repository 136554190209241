import { ReactElement } from 'react';

import { Delegate } from '../../../../../../../@types/handlers/delegate';
import { SelectOption } from '../../../../../../../@types/input';
import { RestaurantServiceStatusUpdate } from '../../../../../../../@types/restaurants';
import {
  RestaurantServiceStatus,
  RestaurantServiceStatusReasons,
  restaurantServiceStatusReasonsTranslation,
} from '../../../../../../../constants/restaurants';
import { ToastTypes } from '../../../../../../../constants/toasts';
import usePauseServiceModalConnector from './connector';

interface IPauseServiceModalDelegate {
  /**
   * Pause service reasons
   */
  pauseReasons: RestaurantServiceStatusReasons[];

  /**
   * Selected pause service reasons
   */
  selectedReason: RestaurantServiceStatusReasons | undefined;

  /**
   * Get pause service reasons from API
   */
  getPauseReasons(): Promise<void>;

  /**
   * Get formatted pause service reasons options to be selected
   *
   * @returns {SelectOption[]} pause service reasons
   */
  getPauseReasonsOptions(): SelectOption[];

  /**
   * Handle when the modal is opened or closed
   */
  handleModal: (content?: ReactElement<any, any>, closeBtnColor?: string) => void;

  /**
   * Pause the Restaurant Service
   *
   * @param {string} token permission token to update the restaurant service status
   * @param {RestaurantServiceStatusReasons} reason reason why the service status is being paused
   * @returns {Promise<boolean>} returns true on success or false on failure
   */
  pauseService(token: string, reason: RestaurantServiceStatusReasons): Promise<boolean>;
}

type PauseServiceModalConnector = ReturnType<typeof usePauseServiceModalConnector>;

export class PauseServiceModalDelegate
  extends Delegate<PauseServiceModalConnector>
  implements IPauseServiceModalDelegate
{
  get pauseReasons(): RestaurantServiceStatusReasons[] {
    return this.connector.pauseReasons;
  }

  get selectedReason(): RestaurantServiceStatusReasons | undefined {
    return this.connector.selectedReason;
  }

  getPauseReasons = async (): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const pauseReasons = await this.connector.settingsService.getPauseReasons(
        regionCode,
        brand,
        restaurantId,
      );

      return this.connector.setPauseReasons(pauseReasons);
    } catch (_) {
      return;
    }
  };

  getPauseReasonsOptions = (): SelectOption[] => {
    return this.connector.pauseReasons.map((pauseReason) => ({
      id: pauseReason,
      title: this.connector.translate(restaurantServiceStatusReasonsTranslation[pauseReason]),
    }));
  };

  handleModal = (content?: ReactElement<any, any>, color?: string): void => {
    return this.connector.modalContext.handleModal(content, color);
  };

  pauseService = async (
    token: string,
    reason: RestaurantServiceStatusReasons,
  ): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const body: RestaurantServiceStatusUpdate = {
        reason,
        status: RestaurantServiceStatus.RESTAURANT_PAUSED,
      };

      await this.connector.settingsService.pauseRestaurantService(
        regionCode,
        brand,
        restaurantId,
        body,
        token,
      );

      return true;
    } catch (_) {
      this.connector.toastContext.addToast(
        'components.PageHeader.ServiceActive.errorOnPause',
        ToastTypes.ERROR,
      );

      return false;
    }
  };

  setSelectedReason = (pauseReason: RestaurantServiceStatusReasons): void => {
    this.connector.setSelectedReason(pauseReason);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePauseServiceModalDelegate = () =>
  new PauseServiceModalDelegate(usePauseServiceModalConnector());
