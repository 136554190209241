import {
  addSeconds,
  differenceInMinutes,
  differenceInSeconds,
  intervalToDuration,
  isAfter,
  subDays,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
// eslint-disable-next-line node/no-missing-import
import { ca, de, enGB, enUS, es, eu, fr, gl, pt, ptBR } from 'date-fns/locale';

import { Languages } from '../constants/languages';

export enum DateFormat {
  HH_MM = 'HH:mm',
  HH_MM_SS = 'HH:mm:ss',
  DD_MM_YY = 'dd/MM/yy',
  DD_MM_YY_HH_MM = 'dd/MM/yyyy HH:mm',
  DD_MM_HH_MM_SS = 'dd/MM HH:mm:ss',
  MM_DD_YY_HH_MM = 'MM/dd/yyyy HH:mm',
}

export type DateUnit = 'minutes' | 'seconds';

export interface Interval {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export const DateLocale = {
  [Languages.CA]: ca,
  [Languages.DE_DE]: de,
  [Languages.EN]: enUS,
  [Languages.EN_CH]: enGB,
  [Languages.EN_GB]: enGB,
  [Languages.ES_ES]: es,
  [Languages.EU_ES]: eu,
  [Languages.FR_FR]: fr,
  [Languages.GL_ES]: gl,
  [Languages.PT_BR]: ptBR,
  [Languages.PT_PT]: pt,
};

interface IDateTimeUtils {
  /**
   * Format date into a specific string
   *
   * @param {Date | number} date unformatted date
   * @param {DateFormat} dateFormat date expected format
   * @param {{ locale: Locale }} options optional local info
   * @returns {string} formatted date
   */
  formatDate(date: Date | number, dateFormat: DateFormat, options?: { locale?: Locale }): string;

  /**
   * Get interval from date to now
   *
   * @param {Date | number} date date to validate interval
   * @returns {Interval} interval object
   */
  getInterval(date: Date | number): Interval;

  /**
   * Given a selected language returns the localized language
   *
   * @param {Languages} language
   *
   * @returns {keyof DateLocale}
   */
  getLocalizedLanguage(language: Languages): Locale;

  /**
   * Get timezone variable defined outside the class
   *
   * @returns {string | null} timezone as string or null if not defined
   */
  getTimezone(): string | null;

  /**
   * Validates if first date is after second date
   *
   * @param {Date | number} firstDate first date to be compared
   * @param {Date | number} secondDate second date to be compared
   * @returns {boolean} boolean response
   */
  isDateAfter(firstDate: Date | number, secondDate: Date | number): boolean;

  /**
   * Subtract days for a given date
   * @param startDate Date to be subtract
   * @param days days amount to subtract from the date
   */
  subtractDays(startDate: Date, days: number): Date;

  /**
   * Difference between two dates
   *
   * @param {Date} firstDate first date to be compared
   * @param {Date} secondDate second date to be compared
   * @param {DateUnit} unit unit of difference
   * @returns {number} time difference between dates
   */
  timeDifference(firstDate: Date, secondDate: Date, unit: DateUnit): number;

  /**
   * Receives two dates, converts them to seconds and then returns difference between dates as hh:mm:ss string
   *
   * @param {Date} startTime start date to calculate time difference
   * @param {Date} endTime end date to calculate time difference
   * @returns {string} calculated total time
   */
  timerCalculator(startTime: Date, endTime: Date): string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let timezone: string | null;

export class DateTimeUtils implements IDateTimeUtils {
  public static setTimezone = (zone: string | null): void => {
    timezone = zone;
  };

  addSeconds = (date: Date | number, seconds: number): Date => {
    return addSeconds(date, seconds);
  };

  formatDate = (
    date: Date | number,
    dateFormat: DateFormat,
    options?: { locale?: Locale },
  ): string => {
    return formatInTimeZone(date, timezone!, dateFormat, options);
  };

  getInterval = (date: Date | number): Interval => {
    return intervalToDuration({ end: date, start: 0 });
  };

  getLocalizedLanguage(language: Languages): Locale {
    return DateLocale[language] as Locale;
  }

  getTimezone = (): string | null => {
    return timezone;
  };

  isDateAfter = (firstDate: Date | number, secondDate: Date | number): boolean => {
    return isAfter(firstDate, secondDate);
  };

  subtractDays(startDate: Date, days: number): Date {
    return subDays(startDate, days);
  }

  timeDifference = (firstDate: Date, secondDate: Date, unit: DateUnit): number => {
    switch (unit) {
      case 'minutes':
        return differenceInMinutes(firstDate, secondDate);
      case 'seconds':
        return differenceInSeconds(firstDate, secondDate);
      default:
        throw new Error('invalid time unit');
    }
  };

  timerCalculator = (startTime: Date, endTime: Date): string => {
    const differenceSeconds = this.timeDifference(startTime, endTime, 'seconds');

    const secondsMultiplier = 1000;
    const secondsDate = differenceSeconds * secondsMultiplier;

    const { hours, minutes, seconds } = this.getInterval(secondsDate);

    const zeroPad = (num: number | undefined) => {
      const padLength = 2;
      return (num || 0).toString().padStart(padLength, '0');
    };

    return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const dateTimeUtils = () => new DateTimeUtils();
