import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Languages } from '../../../constants/languages';
import LanguageOption from '../LanguageOption';
import { useLanguageSelectorDelegate } from './controllers/delegate';

const Body = styled.div`
  width: 100%;
`;

const PaddingDiv = styled.div`
  padding: 16px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #3b5a6b;
  margin-top: 16px;
  margin-bottom: 30px;
`;

const Row = styled.div`
  border-bottom: 1px solid #e9e9e9;
`;

const LanguageSelector: FC = () => {
  const { t } = useTranslation();

  const { setLanguage } = useLanguageSelectorDelegate();

  const languageList: Languages[] = [
    Languages.EN_GB,
    Languages.ES_ES,
    Languages.CA,
    Languages.GL_ES,
    Languages.PT_PT,
    Languages.EU_ES,
    Languages.DE_DE,
    Languages.FR_FR,
  ];

  return (
    <Body>
      <PaddingDiv>
        <Title>{t('components.Language.title')}</Title>
        {languageList.map((language) => (
          <Row key={language}>
            <LanguageOption language={language} onClick={setLanguage} />
          </Row>
        ))}
      </PaddingDiv>
    </Body>
  );
};

export default LanguageSelector;
