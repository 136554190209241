export enum Languages {
  CA = 'ca',
  EN = 'en',
  EN_GB = 'en_GB',
  EN_CH = 'en_CH',
  ES_ES = 'es_ES',
  EU_ES = 'eu_ES',
  FR_FR = 'fr_FR',
  DE_DE = 'de_DE',
  GL_ES = 'gl_ES',
  PT_PT = 'pt_PT',
  PT_BR = 'pt_BR',
}
