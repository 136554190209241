import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrderEvent } from '../../@types/orders';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { OrderEventType } from '../../constants/order';
import SvgIcon from '../SvgIcon';

export interface IOrderSchedule {
  events: OrderEvent[];
  scheduledTime?: string;
  startTimeAccepted?: string;
  startTimeInProduction?: string;
  startTimeOnDelivery?: string;
  startTimeCancelled?: string;
  startTimeDelivered?: string;
  startTimeFinalized?: string;
  totalTimeAccepted?: string;
  totalTimeInProduction?: string;
  totalTimeOnDelivery?: string;
  totalOrderTime?: string;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 22px;
`;

const Subtitle = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #c2c2c2;
`;

const SubtitleGreen = styled(Subtitle)`
  color: #79bf1f;
`;

const SubtitleRed = styled(Subtitle)`
  color: #dd3712;
`;

const TimeStart = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-top: 6px;
`;

const Item = styled.div`
  width: calc(50% - 22px);
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 12px;
`;

const TimerColumnDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
  align-items: center;
`;

const TimerColumn = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #507083;
`;

const OrderSchedule: FC<IOrderSchedule> = ({
  events,
  scheduledTime,
  startTimeAccepted,
  startTimeInProduction,
  startTimeOnDelivery,
  startTimeCancelled,
  startTimeDelivered,
  startTimeFinalized,
  totalTimeAccepted,
  totalTimeInProduction,
  totalTimeOnDelivery,
  totalOrderTime,
}) => {
  const { t } = useTranslation();

  const lastEvent = events[events.length - 1].name;
  const wasPreScheduled = events.some((event) => event.name === OrderEventType.ORDER_PRE_SCHEDULED);

  const getLastSchedule = (): JSX.Element => {
    {
      if (lastEvent === OrderEventType.ORDER_CANCELLED && startTimeCancelled) {
        return (
          <>
            <SubtitleRed>
              {t('pages.OrderManagement.OrderDetails.orderClosedWithIssues')}
            </SubtitleRed>
            <TimeStart>{startTimeCancelled}</TimeStart>
          </>
        );
      }

      if (lastEvent === OrderEventType.ORDER_FINALIZED && startTimeFinalized) {
        return (
          <>
            <SubtitleRed>{t('pages.OrderManagement.OrderDetails.orderFinalized')}</SubtitleRed>
            <TimeStart>{startTimeFinalized}</TimeStart>
          </>
        );
      }

      if (lastEvent === OrderEventType.ORDER_DELIVERED && startTimeDelivered && totalOrderTime) {
        return (
          <>
            <SubtitleGreen>{t('pages.OrderManagement.OrderDetails.orderDelivered')}</SubtitleGreen>
            <TimeStart>{startTimeDelivered}</TimeStart>
            <TimerColumnDiv>
              <SvgIcon children={<ClockIcon />} width={20} height={20} color="#97B0BD" />
              <TimerColumn>{totalOrderTime}</TimerColumn>
            </TimerColumnDiv>
          </>
        );
      }

      return (
        <>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.orderDelivered')}</Subtitle>
          <TimeStart> - </TimeStart>
        </>
      );
    }
  };

  return (
    <Body>
      <Title>{t('pages.OrderManagement.OrderDetails.schedule')}</Title>
      <FlexRow>
        {wasPreScheduled && (
          <Item>
            <Subtitle>{t('pages.OrderManagement.OrderDetails.scheduledFor')}</Subtitle>
            <TimeStart>{scheduledTime}</TimeStart>
          </Item>
        )}
        <Item>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.orderPlaced')}</Subtitle>
          <TimeStart>{startTimeAccepted}</TimeStart>
          <TimerColumnDiv>
            <SvgIcon children={<ClockIcon />} width={20} height={20} color="#97B0BD" />
            <TimerColumn>{totalTimeAccepted || '-'}</TimerColumn>
          </TimerColumnDiv>
        </Item>
        <Item>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.orderInProduction')}</Subtitle>
          <TimeStart>{startTimeInProduction}</TimeStart>
          <TimerColumnDiv>
            <SvgIcon children={<ClockIcon />} width={20} height={20} color="#97B0BD" />
            <TimerColumn>{totalTimeInProduction || '-'}</TimerColumn>
          </TimerColumnDiv>
        </Item>
        <Item>
          <Subtitle>{t('pages.OrderManagement.OrderDetails.orderInDelivery')}</Subtitle>
          <TimeStart>{startTimeOnDelivery}</TimeStart>
          <TimerColumnDiv>
            <SvgIcon children={<ClockIcon />} width={20} height={20} color="#97B0BD" />
            <TimerColumn>{totalTimeOnDelivery || '-'}</TimerColumn>
          </TimerColumnDiv>
        </Item>
        <Item>{getLastSchedule()}</Item>
      </FlexRow>
    </Body>
  );
};

export default OrderSchedule;
