import * as i18next from 'i18next';

import { Delegate } from '../../../@types/handlers/delegate';
import { Languages } from '../../../constants/languages';
import { useLanguageContextConnector } from './connector';

export interface ILanguageContextDelegate {
  /**
   * App selected language
   */
  language: Languages;

  /**
   * Method to get language from local storage, set it on i18n and save it on state
   */
  initializeLanguage(): void;

  /**
   * Method to set language from i18n and persist in local storage and state
   *
   * @param {string} language new language to be used
   */
  setLanguage(language: string): void;
}

const LOCAL_STORAGE_KEY = '@RBI_Expeditor_Tablet:App_Language';

type LanguageContextConnector = ReturnType<typeof useLanguageContextConnector>;

export class LanguageContextDelegate
  extends Delegate<LanguageContextConnector>
  implements ILanguageContextDelegate
{
  get language(): Languages {
    return this.connector.language;
  }

  initializeLanguage = (): void => {
    const language = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (language) {
      this.setAppLanguage(language as Languages);
    } else {
      this.setAppLanguage(Languages.EN_GB);
      window.localStorage.setItem(LOCAL_STORAGE_KEY, Languages.EN_GB);
    }
  };

  setLanguage = (language: Languages): void => {
    this.setAppLanguage(language);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, language);
  };

  private setAppLanguage = (language: Languages) => {
    i18next.changeLanguage(language);
    this.connector.setLanguage(language);
  };
}

export const useLanguageContextDelegate = () =>
  new LanguageContextDelegate(useLanguageContextConnector());
