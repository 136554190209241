import { Close } from '@mui/icons-material';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

interface IGenericModal {
  handleModal: () => void;
  showModal: boolean;
  content?: ReactElement<any, any>;
  closeBtnColor?: string;
}

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  min-height: 275px;
  min-width: 600px;
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  overflow: hidden;
`;

const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const GenericModal: FC<IGenericModal> = ({ content, handleModal, showModal, closeBtnColor }) => {
  const modal = (
    <Background id="modal-context">
      <Body>
        <CloseButton onClick={() => handleModal()} data-testid="close-modal-context">
          <Close sx={{ color: closeBtnColor, fontSize: '20px' }} />
        </CloseButton>
        {content}
      </Body>
    </Background>
  );

  return showModal ? modal : null;
};

export default GenericModal;
