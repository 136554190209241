import { ReactElement } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { OrderResponse } from '../../../@types/orders';
import {
  FilterStatus,
  HistoryTableType,
  OrderEventType,
  OrderHistoryPeriod,
} from '../../../constants/order';
import useOrdersHistoryConnector from './connector';

interface IOrdersHistoryDelegate {
  /**
   * Page header table type chosen to be shown
   */
  activeTable: HistoryTableType;

  /**
   * Array with cancelled orders
   */
  cancelled: OrderResponse[] | null;

  /**
   * Array with orders closed with issue
   */
  closedWithIssues: OrderResponse[] | null;

  /**
   * Array with delivered orders
   */
  delivered: OrderResponse[] | null;

  /**
   * Get order details to show in the sidebar
   *
   * @param {string} orderId unique order identifier
   * @returns {Promise<OrderResponse>} order details response
   */
  getOrderDetails(orderId: string): Promise<OrderResponse>;

  /**
   * Get previous orders by restaurant using orders service
   */
  getPreviousOrdersByRestaurant(): Promise<void>;

  /**
   * Handle to open or close sidebar
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the sidebar
   * @param {string[]} dependencies list of components dependencies on the sidebar
   */
  handleSidebar(content?: ReactElement<any, any> | undefined, dependencies?: string[]): void;

  /**
   * Set page header table type chosen
   *
   * @param {HistoryTableType} activeTable page header table type
   */
  setActiveTable(activeTable: HistoryTableType): void;

  /**
   * Set the date to filter by
   *
   * @param {Date} date to filter the orders
   */
  setFilterDate(date: Date): void;
}

type OrdersHistoryConnector = ReturnType<typeof useOrdersHistoryConnector>;

export class OrdersHistoryDelegate
  extends Delegate<OrdersHistoryConnector>
  implements IOrdersHistoryDelegate
{
  get activeTable(): HistoryTableType {
    return this.connector.activeTable;
  }

  get cancelled(): OrderResponse[] | null {
    return this.connector.cancelled;
  }

  get closedWithIssues(): OrderResponse[] | null {
    return this.connector.closedWithIssues;
  }

  get delivered(): OrderResponse[] | null {
    return this.connector.delivered;
  }

  get filterDate(): Date {
    return this.connector.filterDate;
  }

  getOrderDetails = async (orderId: string): Promise<OrderResponse> => {
    const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

    const orderDetails = await this.connector.ordersService.getOrderDetails(
      regionCode,
      brand,
      restaurantId,
      orderId,
    );

    await this.connector.deliveryContext.getLastMileDelivery(orderDetails);

    return orderDetails;
  };

  getPreviousOrdersByRestaurant = async (): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;
    try {
      const orders = await this.connector.ordersService.getOrdersByRestaurant(
        regionCode,
        brand,
        restaurantId,
        FilterStatus.PREVIOUS_ORDERS,
        OrderHistoryPeriod.SPECIFIC_DAY,
        this.connector.filterDate,
      );

      const delivered = this.connector.ordersUtils.getFilteredOrders(orders, [
        OrderEventType.ORDER_DELIVERED,
      ]);

      const closedWithIssues = this.connector.ordersUtils.getFilteredOrders(orders, [
        OrderEventType.ORDER_CANCELLED,
      ]);

      const cancelled = this.connector.ordersUtils.getFilteredOrders(orders, [
        OrderEventType.ORDER_FINALIZED,
      ]);

      this.setDelivered(delivered);
      this.setClosedWithIssues(closedWithIssues);
      this.setCancelled(cancelled);
    } catch (_) {
      return;
    }
  };

  handleSidebar = (content?: ReactElement<any, any> | undefined, dependencies?: string[]): void => {
    return this.connector.sidebarContext.handleSidebar(content, dependencies);
  };

  setActiveTable = (activeTable: HistoryTableType): void => {
    this.connector.setActiveTable(activeTable);
  };

  setFilterDate = (date: Date): void => {
    this.connector.setFilterDate(date);
  };

  private setCancelled = (cancelled: OrderResponse[]): void => {
    this.connector.setCancelled(cancelled);
  };

  private setClosedWithIssues = (closedWithIssues: OrderResponse[]): void => {
    this.connector.setClosedWithIssues(closedWithIssues);
  };

  private setDelivered = (delivered: OrderResponse[]): void => {
    this.connector.setDelivered(delivered);
  };
}

export const useOrdersHistoryDelegate = () =>
  new OrdersHistoryDelegate(useOrdersHistoryConnector());
