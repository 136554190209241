import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrderDetailsFormatted, OrderResponse } from '../../@types/orders';
import { ReactComponent as DriverIcon } from '../../assets/icons/driver.svg';
import { DeliveryMode } from '../../constants/deliveryMode';
import { env } from '../../constants/env';
import { LastMileProviders } from '../../constants/lastMile';
import { LastMileModalType } from '../../constants/modalType';
import { OrderEventType } from '../../constants/order';
import { OrderTypeColor } from '../../constants/orderTypeColor';
import LastMileModal from '../../pages/OrdersManagement/components/LastMileModal';
import Loading from '../Loading';
import GenericSelect from '../inputs/GenericSelect';
import DeliveryFee from '../orders/DeliveryFee';
import OrderDetailsHeader from '../orders/OrderDetailsHeader';
import OrderFees from '../orders/OrderFees';
import OrderHeaderInfo from '../orders/OrderHeaderInfo';
import OrderInstructions from '../orders/OrderInstructions';
import OrderSchedule from '../orders/OrderSchedule';
import OrderSummary from '../orders/OrderSummary';
import PreOrderSchedule from '../orders/PreOrderSchedule';
import LastMileTracker from './components/LastMileTracker';
import OrderDetailsFooter from './components/OrderDetailsFooter';
import OrderIssueSection from './components/OrderIssueSection';
import { useOrderDetailsDelegate } from './controllers/delegate';

interface IOrderDetails {
  orderDetails: OrderResponse;
  onlyDismiss?: boolean;
}

const Body = styled.div`
  padding: 62px 30px 30px 30px;
  height: calc(100% - 200px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebebe;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
`;

const OrderSummaryDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

const OrderDetails: FC<IOrderDetails> = ({ orderDetails, onlyDismiss }) => {
  const { t } = useTranslation();
  const { displayFeesFromWebhook, timeToEnableButtonToMoveOrder } = useFlags();

  const {
    order,
    orderRef,
    issueOptions,
    assignDriverToOrder,
    createLastMileDelivery,
    formatOrderDetails,
    getFormattedOrderDetailsHeader,
    getLastEventBeforeAssignDriver,
    getFormattedOrderIssueSection,
    getActiveDrivers,
    getFormattedSummary,
    getFormattedSchedule,
    getFormattedHeaderInfo,
    getIssueSectionOptions,
    getFormattedDeliveryFee,
    getFormattedFees,
    getSelectOptions,
    handleModal,
    isValidProvider,
    startPooling,
  } = useOrderDetailsDelegate();

  useEffect(() => {
    getIssueSectionOptions(orderDetails);
  }, [orderDetails]);

  useEffect(() => {
    let pooling: NodeJS.Timer;

    getActiveDrivers(orderDetails);

    formatOrderDetails(orderDetails).then(() => {
      pooling = startPooling();
    });

    return () => clearInterval(pooling);
  }, [orderDetails]);

  useEffect(() => {
    orderRef.current = order;
  }, [order]);

  const onChangeSelect = async (driverId: string): Promise<void> => {
    if (isValidProvider(driverId)) {
      handleModal(
        <LastMileModal
          provider={driverId as LastMileProviders}
          type={LastMileModalType.ASSIGN_DRIVER}
          onBackClick={() => handleModal()}
          onConfirmClick={() => createLastMileDelivery(driverId as LastMileProviders)}
        />,
      );
    } else {
      await assignDriverToOrder(driverId);
    }
  };

  const showLastMileTracker = (order: OrderDetailsFormatted): boolean => {
    return (
      !!order.lastMileDeliveryId &&
      !!order.lastMileDeliveryStatus &&
      !!order.lastMileProvider &&
      lastEvent !== OrderEventType.ORDER_FINALIZED &&
      lastEvent !== OrderEventType.ORDER_CANCELLED &&
      lastEvent !== OrderEventType.ORDER_DELIVERED
    );
  };

  const summary = useMemo(() => getFormattedSummary(orderDetails.cart), [orderDetails]);
  const deliveryFee = useMemo(() => getFormattedDeliveryFee(orderDetails), [orderDetails]);
  const fees = useMemo(() => getFormattedFees(orderDetails), [orderDetails]);
  const headerDetails = useMemo(() => getFormattedOrderDetailsHeader(orderDetails), [orderDetails]);
  const orderHeaderInfo = useMemo(() => getFormattedHeaderInfo(orderDetails), [orderDetails]);
  const schedule = useMemo(() => getFormattedSchedule(orderDetails), [orderDetails]);
  const issueSection = useMemo(
    () => getFormattedOrderIssueSection(orderDetails, issueOptions),
    [orderDetails, issueOptions],
  );

  const hasPreparedEvent = !!orderDetails.events.some(
    (event) => event.name === OrderEventType.ORDER_PREPARED,
  );
  const lastEvent = orderDetails?.events[orderDetails.events.length - 1].name;
  const hasDriverAssigned = orderDetails.events.find(
    (event) => event.name === OrderEventType.DRIVER_ASSIGNED_TO_ORDER,
  );
  const orderAcceptedTime = order?.events.find(
    (event) => event.name === OrderEventType.ORDER_ACCEPTED,
  )?.time;

  return order ? (
    <>
      <Body>
        <OrderDetailsHeader
          quadrant={headerDetails.quadrant}
          createdHour={headerDetails.createdHour}
          statusColor={order.statusColor ?? OrderTypeColor.SUCCESS}
          customerName={headerDetails.customerName}
          customerPhone={headerDetails.customerPhone}
          channel={headerDetails.channel}
          displayOrderId={headerDetails.displayOrderId}
          paymentMethod={headerDetails.paymentMethod}
          formattedAmountToBePaid={headerDetails.formattedAmountToBePaid}
          deliveryMode={order.deliveryMode}
          customerPhoneNumberCode={headerDetails.customerPhoneNumberCode}
        />
        <OrderHeaderInfo
          address={orderHeaderInfo.address}
          deliveryInstructions={orderHeaderInfo.deliveryInstructions}
          channel={orderHeaderInfo.channel}
          paymentMethod={orderHeaderInfo.paymentMethod}
          timerAccepted={order.timerAccepted}
          timerColumn={order.timerColumn}
          lastEvent={orderHeaderInfo.lastEvent}
          driverName={orderHeaderInfo.driverName}
          totalOrderTime={orderHeaderInfo.totalOrderTime}
          createdHour={orderHeaderInfo.createdHour}
          createdDate={orderHeaderInfo.createdDate}
          deliveryMode={orderHeaderInfo.deliveryMode}
          lastMileDeliveryId={order.lastMileDeliveryId}
        />
        {orderDetails.deliveryMode === DeliveryMode.MANAGED_BY_STORE &&
          !orderDetails.lastMileDeliveryId &&
          (lastEvent === OrderEventType.ORDER_IN_PREPARATION ||
            lastEvent === OrderEventType.DRIVER_ASSIGNED_TO_ORDER ||
            (lastEvent === OrderEventType.ORDER_ACCEPTED &&
              ['QA', 'PROD'].indexOf(env.stage) == -1)) && (
            <GenericSelect
              icon={<DriverIcon />}
              options={getSelectOptions(orderDetails.payment.paymentMethod)}
              value={orderDetails.driverId ? orderDetails.driverId : ''}
              handleOnChange={onChangeSelect}
            />
          )}
        {(lastEvent === OrderEventType.ORDER_CANCELLED ||
          lastEvent === OrderEventType.ORDER_FINALIZED) && (
          <OrderIssueSection
            event={issueSection.event}
            issueDescription={issueSection.issueDescription}
          />
        )}
        {showLastMileTracker(order) && (
          <LastMileTracker
            orderId={orderDetails.rbiNumberId}
            provider={order.lastMileProvider!}
            lastMileDeliveryId={order.lastMileDeliveryId!}
            lastMileDeliveryStatus={order.lastMileDeliveryStatus!}
          />
        )}
        {orderDetails.instructions && (
          <OrderInstructions
            title={t('pages.OrderManagement.OrderDetails.specialInstructions')}
            instructions={orderDetails.instructions}
            dataTestId="special-instructions"
          />
        )}
        <OrderSummaryDiv>
          <Title>{t('pages.OrderManagement.OrderDetails.summary')}</Title>
          <OrderSummary cartSummary={summary.cartSummary} />
        </OrderSummaryDiv>
        {displayFeesFromWebhook ? (
          <OrderFees fees={fees} />
        ) : (
          <DeliveryFee deliveryFee={deliveryFee}></DeliveryFee>
        )}
        {lastEvent === OrderEventType.ORDER_PRE_SCHEDULED ? (
          <PreOrderSchedule
            startTimeScheduled={schedule.scheduledTime}
            startTimeAccepted={schedule.startTimeAccepted}
          />
        ) : (
          <OrderSchedule
            events={schedule.events}
            scheduledTime={schedule.scheduledTime}
            startTimeAccepted={schedule.startTimeAccepted}
            startTimeInProduction={schedule.startTimeInProduction}
            startTimeOnDelivery={schedule.startTimeOnDelivery}
            startTimeCancelled={schedule.startTimeCancelled}
            startTimeDelivered={schedule.startTimeDelivered}
            startTimeFinalized={schedule.startTimeFinalized}
            totalTimeAccepted={schedule.totalTimeAccepted}
            totalTimeInProduction={schedule.totalTimeInProduction}
            totalTimeOnDelivery={schedule.totalTimeOnDelivery}
            totalOrderTime={schedule.totalOrderTime}
          />
        )}
      </Body>
      <OrderDetailsFooter
        orderId={order.rbiNumberId}
        displayOrderId={order.displayOrderId}
        driverId={orderDetails.driverId}
        lastEvent={getLastEventBeforeAssignDriver(
          orderDetails.events,
          timeToEnableButtonToMoveOrder,
        )}
        onlyDismiss={onlyDismiss}
        deliveryMode={order.deliveryMode}
        hasDriverAssigned={!!hasDriverAssigned}
        hasLastMile={!!order.lastMileDeliveryId}
        lastMileStatus={order.lastMileDeliveryStatus}
        hasPreparedEvent={hasPreparedEvent}
        acceptedTime={orderAcceptedTime}
      />
    </>
  ) : (
    <Loading />
  );
};

export default OrderDetails;
