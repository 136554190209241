import { FC } from 'react';
import styled from 'styled-components';

interface ISmallLoading {
  isLoadingWhite?: boolean;
}

const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderGradient = styled.div<{ isLoadingWhite?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: spin 1s infinite;
  -webkit-animation: spin 1s infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Create a gradient for the loading */
  background: linear-gradient(
        ${(props) => (props.isLoadingWhite ? '#C2C2C2' : '#ffffff')},
        ${(props) => (props.isLoadingWhite ? '#C2C2C2' : '#ffffff')}
      )
      padding-box,
    linear-gradient(
        to right,
        ${(props) => (props.isLoadingWhite ? '#ffffff' : '#3b5a6b')},
        ${(props) => (props.isLoadingWhite ? '#C2C2C2' : '#dae7ee')}
      )
      border-box;
`;

const SmallLoading: FC<ISmallLoading> = ({ isLoadingWhite }) => {
  return (
    <LoaderBackground>
      <LoaderGradient isLoadingWhite={isLoadingWhite}></LoaderGradient>
    </LoaderBackground>
  );
};

export default SmallLoading;
