import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PermissionTokenActions } from '../../../../constants/auth';
import { Tendency } from '../../../../constants/eta';
import { StatusModalType } from '../../../../constants/modalType';
import { IFloatingCardPosition } from '../../../../contexts/FloatingCardContext/components/GenericFloatingCard';
import PasswordValidation from '../../../PasswordValidation';
import SvgIcon from '../../../SvgIcon';
import CardButton from '../../../buttons/CardButton';
import ResponseStatusModal from '../../../modals/ResponseStatusModal';
import EtaList from '../EtaList';
import { useDeliveryTimeDelegate } from './controllers/delegate';

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #5a5a5a;
  letter-spacing: 0.06em;
`;

const Font = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0px;
`;

const InlineFlex = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3px;
`;

const ColoredSpan = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 600;
`;

const DeliveryTime: FC = () => {
  const { t } = useTranslation();
  const {
    actualEtaInfo,
    eta,
    getActualEtaColor,
    getActualEtaValue,
    handleFloatingCard,
    handleModal,
    updateEta,
    retrieveEtaSettings,
  } = useDeliveryTimeDelegate();

  const showSuccessWarning = (): void =>
    handleModal(
      <ResponseStatusModal
        message={`${t('components.PageHeader.DeliveryTime.EtaList.successfullyEtaUpdatedMessage')}`}
        onClick={() => handleModal()}
        type={StatusModalType.SUCCESS}
      />,
      '#FFFFFF',
    );

  const callUpdateEta = async (eta: number, token: string): Promise<void> => {
    const result = await updateEta(eta, token);
    if (result) showSuccessWarning();
  };

  const handleInputChange = (eta: number): void => {
    handleModal(
      <PasswordValidation
        title={t('components.PasswordValidation.titleChangeEta')}
        action={PermissionTokenActions.UPDATE_ETA}
        onSuccess={(token) => callUpdateEta(eta, token)}
      />,
    );
    handleFloatingCard();
  };

  const handleActionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const position: IFloatingCardPosition = {
      left: event.pageX,
      top: event.pageY,
    };

    handleFloatingCard(<EtaList onChange={(eta) => handleInputChange(parseInt(eta))} />, position, [
      'delivery-time',
    ]);
  };

  useEffect(() => {
    let etaSettingInterval: NodeJS.Timer;
    retrieveEtaSettings().then((response) => {
      etaSettingInterval = response!;
    });

    return () => {
      clearInterval(etaSettingInterval);
    };
  }, []);

  return (
    <div id="delivery-time">
      <CardButton onClick={handleActionClick}>
        <>
          <Title data-testid="eta-button">{t('components.PageHeader.DeliveryTime.title')}</Title>
          <div>
            <InlineFlex>
              <Font>
                <ColoredSpan color={getActualEtaColor(actualEtaInfo?.actualEta)}>
                  {getActualEtaValue(actualEtaInfo?.actualEta)}
                </ColoredSpan>{' '}
                {t('components.PageHeader.DeliveryTime.actual')}
              </Font>
            </InlineFlex>
            <InlineFlex>
              <Font>
                {eta}' {t('components.PageHeader.DeliveryTime.eta')}{' '}
              </Font>
              <SvgIcon
                children={
                  actualEtaInfo?.tendency === Tendency.DOWN ? <ArrowDownward /> : <ArrowUpward />
                }
                width={17}
                height={17}
                color="#507083"
              />
            </InlineFlex>
          </div>
        </>
      </CardButton>
    </div>
  );
};

export default DeliveryTime;
