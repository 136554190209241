import { Delegate } from '../../../../../@types/handlers/delegate';
import useTotalActiveDriversConnector from './connector';

interface ITotalActiveDriversDelegate {
  /**
   * Return total of active drivers
   */
  totalActiveDrivers: number;
}

type TotalActiveDriversConnector = ReturnType<typeof useTotalActiveDriversConnector>;

export class TotalActiveDriversDelegate
  extends Delegate<TotalActiveDriversConnector>
  implements ITotalActiveDriversDelegate
{
  get totalActiveDrivers(): number {
    return this.connector.driversOnShift.length;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useTotalActiveDriversDelegate = () =>
  new TotalActiveDriversDelegate(useTotalActiveDriversConnector());
