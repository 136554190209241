interface IPrintUtils {
  /**
   * Method to call browser print to an iframe
   *
   * This method will only work when there is already an iframe in the document
   *
   * Example:
   * <iframe id="order-iframe-example" src="/print/order/6536308" style={{ display: 'none' }} />
   *
   * @param {string} iframeId iframe unique identifier (as the example 'order-iframe-example')
   * @return {boolean} a boolean indicating success or error
   */
  printIframe(iframeId: string): boolean;
}

export class PrintUtils implements IPrintUtils {
  printIframe = (iframeId: string): boolean => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

    if (!iframe) return false;

    const iframeWindow = iframe.contentWindow;

    iframe.focus();

    iframeWindow?.print();

    return true;
  };
}

export const printUtils = () => new PrintUtils();
