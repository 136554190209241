import { createContext, FC, useContext, useEffect } from 'react';

import { IOrdersContextDelegate, useOrdersContextDelegate } from './controllers/delegate';

export const OrdersContext = createContext<
  Pick<
    IOrdersContextDelegate,
    | 'addOrderClosedOrFinalizedByDriver'
    | 'removeOrderClosedOrFinalizedByDriver'
    | 'forceUpdate'
    | 'newOrders'
    | 'ordersClosedOrFinalizedByDriver'
    | 'ordersInProduction'
    | 'ordersOnDelivery'
    | 'setShowMap'
    | 'showMap'
    | 'scheduledOrders'
  >
>({
  addOrderClosedOrFinalizedByDriver: () => ({}),
  forceUpdate: () => Promise.resolve(),
  newOrders: [],
  ordersClosedOrFinalizedByDriver: [],
  ordersInProduction: [],
  ordersOnDelivery: [],
  removeOrderClosedOrFinalizedByDriver: () => ({}),
  scheduledOrders: [],
  setShowMap: () => ({}),
  showMap: false,
});

OrdersContext.displayName = 'Orders';

interface IOrdersProvider {
  children: JSX.Element;
}

const OrdersProvider: FC<IOrdersProvider> = ({ children }) => {
  const {
    currentPath,
    currentPathRef,
    scheduledOrders,
    newOrders,
    firstLoading,
    firstLoadingRef,
    ordersOnDelivery,
    ordersInProduction,
    ordersClosedOrFinalizedByDriver,
    forceUpdate,
    getOrdersByRestaurant,
    addOrderClosedOrFinalizedByDriver,
    removeOrderClosedOrFinalizedByDriver,
    retrieveOrdersClosedWithIssue,
    setShowMap,
    showMap,
    subscribeToWebsocket,
    unsubscribeToWebsocket,
  } = useOrdersContextDelegate();

  useEffect(() => {
    const interval = getOrdersByRestaurant();
    retrieveOrdersClosedWithIssue();
    subscribeToWebsocket();

    return () => {
      clearInterval(interval);
      unsubscribeToWebsocket();
    };
  }, []);

  useEffect(() => {
    currentPathRef.current = currentPath;
  }, [currentPath]);

  useEffect(() => {
    firstLoadingRef.current = firstLoading;
  }, [firstLoading]);

  return (
    <OrdersContext.Provider
      value={{
        addOrderClosedOrFinalizedByDriver,
        forceUpdate,
        newOrders,
        ordersClosedOrFinalizedByDriver,
        ordersInProduction,
        ordersOnDelivery,
        removeOrderClosedOrFinalizedByDriver,
        scheduledOrders,
        setShowMap,
        showMap,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrdersContext = () => useContext(OrdersContext);

export default OrdersProvider;
