import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ClickAwayListener, Grow, MenuList, Paper } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ButtonType } from '../../../constants/buttonType';
import GenericButton from '../../buttons/GenericButton';

const buttonStyle = css`
  border-radius: 0 0 8px 8px !important;
  background-color: #3b5a6b !important;
`;
const StyledButton = styled(GenericButton)<{ isActive?: boolean }>`
  border: unset;
  ${({ isActive }) => isActive && buttonStyle}
`;

interface ButtonMenuProps {
  children: React.ReactNode;
}

export const ButtonMenu = ({ children }: ButtonMenuProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const toggleOpen = () => setOpen(!open);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButton
        data-testId="options-button"
        ref={anchorRef}
        title={t('pages.OrderManagement.OrderDetails.options')}
        onClick={toggleOpen}
        icon={open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        isActive={open}
        type={open ? ButtonType.PRIMARY : ButtonType.SECONDARY}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-start"
        disablePortal
        style={{ width: anchorRef?.current?.clientWidth }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList style={{ padding: 0 }}>{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
