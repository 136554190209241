import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

import { CompensationItem } from '../../../@types/compensation';
import GenericIconButton from '../../buttons/GenericIconButton';

interface ICompensationRow {
  item: CompensationItem;
  setCompensationItemQuantity: (action: 'add' | 'sub') => void;
}

const InputRow = styled.div`
  display: flex;
  gap: 12px;
`;

const Font = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const InputLabel = styled(Font)`
  display: flex;
  align-items: flex-end;
  color: #000000;
`;

const ValueLabel = styled(Font)`
  padding: 0 9px;
  color: #313144;
  width: 14px;
  text-align: center;
`;

const CompensationRow: FC<ICompensationRow> = ({ item, setCompensationItemQuantity }) => {
  return (
    <InputRow key={item.externalReferenceId}>
      <GenericIconButton
        icon={<RemoveRounded />}
        size={30}
        onClick={() => setCompensationItemQuantity('sub')}
        disabled={item.quantity <= 0}
      />
      <ValueLabel>{item.quantity}</ValueLabel>
      <GenericIconButton
        icon={<AddRounded />}
        size={30}
        onClick={() => setCompensationItemQuantity('add')}
        disabled={item.quantity >= item.max}
      />
      <InputLabel>{item.name}</InputLabel>
    </InputRow>
  );
};

export default CompensationRow;
