import { ReactElement } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { useSidebarContextConnector } from './connector';

export interface ISidebarContextDelegate {
  /**
   * Get child component to show in sidebar
   */
  content: ReactElement<any, any> | undefined;

  /**
   * Get true if the sidebar will show or false otherwise
   */
  showSidebar: boolean;

  /**
   * Get list of ids as dependency to not close the side bar
   */
  dependencies: string[] | undefined;

  /**
   * Handle when the sidebar is opened or closed
   */
  handleSidebar: (content?: ReactElement<any, any> | undefined, dependencies?: string[]) => void;
}

type SidebarContextConnector = ReturnType<typeof useSidebarContextConnector>;

export class SidebarContextDelegate
  extends Delegate<SidebarContextConnector>
  implements ISidebarContextDelegate
{
  handleSidebar = (content?: ReactElement<any, any> | undefined, dependencies?: string[]): void => {
    this.connector.setContent(content);
    this.connector.setDependencies(dependencies);
  };

  get content(): ReactElement<any, any> | undefined {
    return this.connector.content;
  }

  get dependencies(): string[] | undefined {
    return this.connector.dependencies;
  }

  get showSidebar(): boolean {
    return !!this.connector.content;
  }
}

export const useSidebarContextDelegate = () =>
  new SidebarContextDelegate(useSidebarContextConnector());
