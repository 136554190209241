import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericTableRow } from '../../../../../@types/genericTable';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useSettingsContext } from '../../../../../contexts/SettingsContext';
import driversService from '../../../../../services/DriversService';
import settingsService from '../../../../../services/SettingsService';
import { dateTimeUtils } from '../../../../../utils/DateTimeUtils';
import { moneyUtils } from '../../../../../utils/MoneyUtils';
import { orderHistoryUtils } from '../../../../../utils/OrdersHistoryUtils';

const useGenericHistoryTableConnector = () => {
  const { t: translate } = useTranslation();

  const [formattedList, setFormattedList] = useState<GenericTableRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { colors: colorSettings } = useSettingsContext();

  return {
    authContext: useAuthContext(),
    colorSettings,
    dateUtils: dateTimeUtils(),
    driversService,
    formattedList,
    loading,
    moneyUtils: moneyUtils(),
    orderHistoryUtils: orderHistoryUtils(),
    setFormattedList,
    setLoading,
    settingsService,
    translate,
  };
};

export default useGenericHistoryTableConnector;
