import { CartItem } from '../../../../../../../@types/cart';
import { CompensationItem } from '../../../../../../../@types/compensation';
import { Delegate } from '../../../../../../../@types/handlers/delegate';
import { SelectOption } from '../../../../../../../@types/input';
import { OrderResponse } from '../../../../../../../@types/orders';
import { issueOptionsTranslation } from '../../../../../../../constants/order';
import useIssueListConnector from './connector';

interface IIssueListDelegate {
  /**
   * Return the state variable to know which issue from the list was selected
   *
   */
  issueSelected: string;

  /**
   * Get issue list on the state
   *
   */
  issueList: SelectOption[];

  /**
   * Get order details
   *
   */
  orderDetails: OrderResponse | null;

  /**
   * Compensation items list
   */
  compensations: CompensationItem[];

  /**
   * Creates a compensation items list based on the menu selections
   *
   * @param {CartItem[]} menuSelections menu selection from order
   */
  createCompensationList(menuSelections: CartItem[]): void;

  /**
   * Check if there is some compensation item with quantity greater than 0
   *
   * @returns {boolean} validation boolean response
   */
  disableCompensationButton(): boolean;

  /**
   * Set compensation item quantity in the compensation list
   *
   * @param {number} itemDmpId item dmpId
   * @param {'add' | 'sub'} action action to add or subtract item quantity
   * @param {number} subitemDmpId optional subitem dmpId
   */
  setCompensationItemQuantity(
    itemDmpId: string,
    action: 'add' | 'sub',
    subitemDmpId?: string,
  ): void;

  /**
   * Get list of available issues to close the order
   *
   */
  getIssueList(): Promise<void>;

  /**
   * Get order details to get cart menu selections
   *
   * @param {string} orderId unique order identifier
   */
  getOrderDetails(orderId: string): Promise<OrderResponse | undefined>;

  /**
   * Set selected option from radioButton
   *
   */
  setIssueSelected(issue: string): void;
}

type IssueListConnector = ReturnType<typeof useIssueListConnector>;

export class IssueListDelegate extends Delegate<IssueListConnector> implements IIssueListDelegate {
  get issueSelected(): string {
    return this.connector.issueSelected;
  }

  get issueList(): SelectOption[] {
    return this.connector.issueList;
  }

  get orderDetails(): OrderResponse | null {
    return this.connector.orderDetails;
  }

  get compensations(): CompensationItem[] {
    return this.connector.compensations;
  }

  createCompensationList = (menuSelections: CartItem[]): void => {
    const compensations: CompensationItem[] = menuSelections.map((item) => {
      const menuSelections: CompensationItem[] = item.menuSelections.map((subitem) => ({
        ...subitem,
        max: item.quantity * subitem.quantity,
        menuSelections: [],
        quantity: 0,
      }));

      return {
        ...item,
        max: item.quantity,
        menuSelections,
        quantity: 0,
      };
    });

    this.connector.setCompensations(compensations);
  };

  disableCompensationButton = (): boolean => {
    return !this.compensations.some(
      (item) => item.quantity > 0 || item.menuSelections.some((subitem) => subitem.quantity),
    );
  };

  getIssueList = async (): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const responseList = await this.connector.settingsService.getCloseOrderIssues(
        regionCode,
        brand,
        restaurantId,
      );

      const issueList: SelectOption[] = responseList?.map((item) => ({
        id: item.id,
        title:
          this.connector.translate(issueOptionsTranslation[this.formatOptionId(item.id)]) ??
          item.en,
      }));

      this.connector.setIssueList(issueList);
    } catch (_) {
      return;
    }
  };

  getOrderDetails = async (orderId: string): Promise<OrderResponse | undefined> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const orderDetails = await this.connector.ordersService.getOrderDetails(
        regionCode,
        brand,
        restaurantId,
        orderId,
      );
      this.connector.setOrderDetails(orderDetails);

      return orderDetails;
    } catch (_) {
      return;
    }
  };

  isProductMissing = (issueId: string): boolean => {
    const issue = this.issueList.find(({ id }) => id === issueId);

    return !!issue?.title.includes(
      this.connector.translate(
        'pages.OrderManagement.CloseWithIssue.IssueList.IssueOptions.wrongProduct',
      ),
    );
  };

  setCompensationItemQuantity = (
    itemDmpId: string,
    action: 'add' | 'sub',
    subitemDmpId?: string,
  ): void => {
    const isSubitemChange = !!subitemDmpId;

    const compensations: CompensationItem[] = isSubitemChange
      ? this.balanceFromSubitems([...this.connector.compensations], itemDmpId, subitemDmpId, action)
      : this.balanceFromItem([...this.connector.compensations], itemDmpId, action);

    this.connector.setCompensations(compensations);
  };

  setIssueSelected = (issue: string): void => {
    this.connector.setIssueSelected(issue);
  };

  private balanceFromItem = (
    current: CompensationItem[],
    itemDmpId: string,
    action: 'add' | 'sub',
  ): CompensationItem[] => {
    const balanced: CompensationItem[] = [...current];

    const newQuantity = (subItem: CompensationItem) =>
      action === 'add'
        ? subItem.quantity < subItem.max
          ? subItem.quantity + 1
          : subItem.quantity
        : subItem.quantity > 0
        ? subItem.quantity - 1
        : subItem.quantity;

    const itemIndex = balanced.findIndex(({ dmpId }) => dmpId === itemDmpId);

    const item = balanced[itemIndex];

    balanced[itemIndex] = {
      ...item,
      menuSelections: item.menuSelections.map((subItem) => ({
        ...subItem,
        quantity: newQuantity(subItem),
      })),
      quantity: action === 'add' ? item.quantity + 1 : item.quantity - 1,
    };

    return balanced;
  };

  private balanceFromSubitems = (
    current: CompensationItem[],
    itemDmpId: string,
    subitemDmpId: string,
    action: 'add' | 'sub',
  ): CompensationItem[] => {
    const balanced: CompensationItem[] = [...current];

    const itemIndex = balanced.findIndex(({ dmpId }) => dmpId === itemDmpId);
    const item = balanced[itemIndex];

    const subitemIndex = item.menuSelections.findIndex(({ dmpId }) => dmpId === subitemDmpId);
    const subItem = item.menuSelections[subitemIndex];

    balanced[itemIndex].menuSelections[subitemIndex].quantity =
      action === 'add' ? subItem.quantity + 1 : subItem.quantity - 1;

    /*
     * Returns an array of numbers with the relationship between the selected
     * quantity of a sub-item and how many combos this quantity refers to
     */
    const itemQuantity: number = Math.min(
      ...balanced[itemIndex].menuSelections.map((sub) => {
        /*
         * Quantity of a given subitem that comes from just one combo
         */
        const subItemComboUnity = sub.max / item.max;

        return sub.quantity / subItemComboUnity;
      }),
    );

    balanced[itemIndex].quantity = itemQuantity;

    return balanced;
  };

  private formatOptionId = (id: string): string => this.connector.ordersUtils.formatOptionId(id);
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useIssueListDelegate = () => new IssueListDelegate(useIssueListConnector());
