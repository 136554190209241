import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { AutoPrepareUpdate } from '../../../../../@types/restaurants';
import { ToastTypes } from '../../../../../constants/toasts';
import useAutoPrepareConnector from './connector';

interface IAutoPrepareDelegate {
  /**
   * POS status
   */
  pos: boolean;

  /**
   * Indicates if auto prepare is active
   */
  autoPrepare: boolean;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;

  /**
   * Call stettings service to update autoPrepareStatus
   *
   * @param {AutoPrepareUpdate} autoPrepareStatus status to update
   * @param {string | undefined} token optional parameter used for activate autoPrepare
   *
   * @returns {boolean} returns true if success and false if error
   */
  updateAutoPrepareStatus(autoPrepareStatus: AutoPrepareUpdate, token: string): Promise<boolean>;
}

type AutoPrepareConnector = ReturnType<typeof useAutoPrepareConnector>;

export class AutoPrepareDelegate
  extends Delegate<AutoPrepareConnector>
  implements IAutoPrepareDelegate
{
  get pos(): boolean {
    return this.connector.settingsContext.pos;
  }

  get autoFire(): boolean {
    return this.connector.settingsContext.autoFire;
  }

  get autoPrepare(): boolean {
    return this.connector.settingsContext.autoPrepare;
  }

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  updateAutoPrepareStatus = async (
    autoPrepareStatus: AutoPrepareUpdate,
    token = '',
  ): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      if (autoPrepareStatus.status && !token) {
        throw new Error('error on enable auto prepare, missing token');
      }

      await this.connector.settingsService.updateAutoPrepareStatus(
        regionCode,
        brand,
        restaurantId,
        autoPrepareStatus,
        token,
      );

      this.connector.settingsContext.setAutoPrepare(autoPrepareStatus.status);

      if (autoPrepareStatus.status)
        this.connector.settingsContext.setAutoFire(!autoPrepareStatus.status);

      return true;
    } catch (_) {
      this.connector.toastContext.addToast(
        'components.PageHeader.AutoPrepare.errorOnUpdate',
        ToastTypes.ERROR,
      );

      return false;
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useAutoPrepareDelegate = () => new AutoPrepareDelegate(useAutoPrepareConnector());
