import { CreditCardOutlined, ModeComment, Person } from '@mui/icons-material';
import { FC } from 'react';
import styled from 'styled-components';

import { OrderResponse } from '../../../../@types/orders';
import { PreOrderCardInformation } from '../../../../@types/preOrderCard';
import { ReactComponent as MoneyPayment } from '../../../../assets/icons/money-payment.svg';
import OrderDetails from '../../../../components/OrderDetails';
import SvgIcon from '../../../../components/SvgIcon';
import { PaymentMethod } from '../../../../constants/paymentMethod';
import { usePreOrderCardDelegate } from './controllers/delegate';

export interface IPreOrderCard {
  order: OrderResponse;
}

const CardStyle = styled.div`
  background-color: #ffffff;
  width: 357px;
  height: 74px;
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  border-left: 8px solid #507083;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 10px 14px 10px;
  cursor: pointer;
`;

const VerticalBorder = styled.div`
  background: #ececec;
  width: 2px;
  height: 83px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 96px;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 24px;
  align-items: center;
`;

const Text = styled.span<{ weight: '400' | '500' | '600'; fontSize: number }>`
  font-size: ${(props) => props.fontSize}px;
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: ${(props) => props.weight};
`;

const TextEllipsisDiv = styled.div`
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  word-break: break-all;

  display: -webkit-inline-box;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const InstructionsDiv = styled.div`
  position: relative;
`;

const InstructionsCounter = styled.span`
  top: 2.5px;
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  left: 7px;
`;

const TimeScheduled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  padding: 3px 0px;
  border-radius: 4px;
  background-color: #dae7ee;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.28px;
  color: #3b5a6b;
`;

const PreOrderCard: FC<IPreOrderCard> = ({ order }) => {
  const { formatOrderToCard, getOrderDetails, handleSidebar } = usePreOrderCardDelegate();

  const handleClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const orderDetails = await getOrderDetails(order.rbiNumberId);

    handleSidebar(<OrderDetails orderDetails={orderDetails} />);
  };

  const orderFormatted: PreOrderCardInformation = formatOrderToCard(order);

  const getPaymentIcon = (paymentMethod: string) => {
    return paymentMethod === PaymentMethod.CASH ? (
      <SvgIcon children={<MoneyPayment />} width={18} height={18} color="#97B0BD" />
    ) : (
      <CreditCardOutlined sx={{ color: '#97B0BD', fontSize: '20px' }} />
    );
  };

  return (
    <CardStyle data-testid="pre-schedule-card" onClick={handleClick}>
      <FirstColumn>
        <FlexRow>
          <TextEllipsisDiv>
            <Text data-testid="order_id_pre_scheduled" fontSize={18} weight="600">
              #{orderFormatted.displayOrderId}
            </Text>
          </TextEllipsisDiv>
        </FlexRow>
        <FlexRow>
          <TextEllipsisDiv>
            <Text fontSize={14} weight="400">
              {orderFormatted.channel}
            </Text>
          </TextEllipsisDiv>
        </FlexRow>
        <FlexRow>
          <TimeScheduled>{orderFormatted.timeSchedule}</TimeScheduled>
        </FlexRow>
      </FirstColumn>
      <VerticalBorder />
      <SecondColumn>
        <FlexRow>
          <Icon>
            <Person sx={{ color: '#97B0BD', fontSize: '20px' }} />
            <TextEllipsisDiv>
              <Text fontSize={16} weight="600">
                {orderFormatted.customerName}
              </Text>
            </TextEllipsisDiv>
          </Icon>
          <Icon>
            {!!orderFormatted.instructionQuantity && (
              <InstructionsDiv>
                <InstructionsCounter>{orderFormatted.instructionQuantity}</InstructionsCounter>
                <ModeComment sx={{ color: '#FFC224', fontSize: '20px' }} />
              </InstructionsDiv>
            )}
          </Icon>
          <Icon>
            {getPaymentIcon(orderFormatted.paymentMethod)}
            <Text fontSize={14} weight="400">
              {orderFormatted.amountToBePaid}
            </Text>
          </Icon>
        </FlexRow>
        <FlexRow>
          <Icon>
            {orderFormatted.quadrant && (
              <Text fontSize={16} weight="600">
                {orderFormatted.quadrant}
              </Text>
            )}
            {orderFormatted.customerAddress && (
              <TextEllipsisDiv>
                <Text fontSize={14} weight="400">
                  {orderFormatted.customerAddress}
                </Text>
              </TextEllipsisDiv>
            )}
          </Icon>
        </FlexRow>
      </SecondColumn>
    </CardStyle>
  );
};

export default PreOrderCard;
