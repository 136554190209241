import { addMinutes, isBefore } from 'date-fns';

import { Cart } from '../@types/cart';
import {
  OrderEvent,
  OrderPayment,
  OrderResponse,
  UnformattedOrderResponse,
} from '../@types/orders';
import { RestaurantLastMileProvidersSettings } from '../@types/restaurants';
import { LastMileProviders } from '../constants/lastMile';
import { OrderEventType } from '../constants/order';
import { DateFormat, dateTimeUtils } from './DateTimeUtils';

interface IOrdersUtils {
  /**
   * Calculate the Delivery Fee for the order
   *
   * @param {Cart} cart order's cart
   * @param {OrderPayment} payment order's payment
   *
   * @returns {number} the delivery fee
   */
  calculateDeliveryFee(cart: Cart, payment: OrderPayment): number;

  /**
   * Get pre order delivery time and format into DD/MM/YY HH:MM string format
   *
   * @param {string} startTimeScheduled date string for the scheduled event
   * @param {number} fireOrderInSeconds time in seconds to delivery time
   * @returns {string} formatted date string
   */
  formatScheduledEvent(startTimeScheduled: string, fireOrderInSeconds: number): string;

  /**
   * Get time from specific OrderEventType in an array of OrderEvent
   *
   * @param {OrderEvent[]} events array of order events
   * @param {OrderEventType} targetEvent even to get time from
   *
   * @returns {string} time from the event
   */
  findEventTime(events: OrderEvent[], targetEvent: OrderEventType): string;

  /**
   * Get last event from an array of events
   *
   * @param {OrderEvent[]} events array of order events
   * @returns {OrderEvent} last event
   */
  findLastEvent(events: OrderEvent[]): OrderEvent | undefined;

  /**
   * Get event time and format into DD/MM/YY HH:MM string format
   *
   * @param {OrderEventType} eventType type of event to get time
   * @param {OrderEvent} orderEvents events of the order
   * @returns {string} formatted date string
   */
  formatEventTime(eventType: OrderEventType, orderEvents: OrderEvent[]): string;

  /**
   * Receives an order object from API and format the paymentMethod to handle paymentMethodBrand
   *
   * @param {UnformattedOrderResponse} order the order incoming from API
   * @returns {OrderResponse} order formatted
   */
  formatToOrderResponse(order: UnformattedOrderResponse): OrderResponse;

  /**
   * Format id from menu options to return only the option identifier
   *
   * @param {string} item complete id
   * @returns {string} formatted id
   */
  formatOptionId(id: string): string;

  /**
   * Filter array of orders according to a OrderEventType array and returns the filtered orders in an array
   *
   * @param {OrderResponse[]} ordersList list of orders to be filtered
   * @param {OrderEventType[]} filters filter criteria to be applied
   * @returns {OrderResponse[]} filtered order array
   */
  getFilteredOrders(ordersList: OrderResponse[], filters: OrderEventType[]): OrderResponse[];

  /**
   * Sort orders from arrays by creation date
   *
   * @param {OrderResponse[]} orders list of orders from context
   * @returns {OrderResponse[]} arrays of orders merged and sorted by creation date
   */
  getOrdersSortedByCreationDate(orders: OrderResponse[]): OrderResponse[];

  /**
   * Based on the flag created on LD, validates if it is already the time to enable the action button
   *
   * @param {string} acceptedTime time that the order was accepted
   * @returns {number} timeToEnableButtonToMoveOrder flag that stores time to release the action button to move the order
   */
  isTimeToEnableActionButton(acceptedTime: string, timeToEnableButtonToMoveOrder: number): boolean;

  /**
   * Pick available last mile providers for an order
   *
   * @param {RestaurantLastMileProvidersSettings} lastMileSettings restaurant last mile settings
   * @param {string} orderPaymentMethod order payment method
   * @returns {LastMileProviders[]} available last mile providers
   */
  pickAvailableLastMileProviders(
    lastMileSettings: RestaurantLastMileProvidersSettings,
    orderPaymentMethod: string,
  ): LastMileProviders[];
}

export class OrdersUtils implements IOrdersUtils {
  calculateDeliveryFee = (cart: Cart, payment: OrderPayment): number => {
    const totalCart = cart.menuSelections.reduce((initial, payment) => {
      return initial + payment.price.amount;
    }, 0);

    const deliveryFee = payment.total.amount - totalCart;

    return deliveryFee;
  };

  findEventTime = (events: OrderEvent[], targetEvent: OrderEventType): string => {
    return events.find((e) => e.name === targetEvent)?.time || '';
  };

  findLastEvent = (events: OrderEvent[]): OrderEvent | undefined => {
    return events[events.length - 1];
  };

  formatEventTime = (eventType: OrderEventType, orderEvents: OrderEvent[]): string => {
    const event = this.findEventTime(orderEvents, eventType);

    return event ? dateTimeUtils().formatDate(new Date(event), DateFormat.DD_MM_YY_HH_MM) : '-';
  };

  formatOptionId = (id: string): string => id.split('-')[id.split('-').length - 1];

  formatScheduledEvent = (startTimeScheduled: string, fireOrderInSeconds: number): string => {
    if (startTimeScheduled === '-' || !startTimeScheduled) return '-';

    const deliveryTime = dateTimeUtils().addSeconds(
      new Date(startTimeScheduled),
      fireOrderInSeconds,
    );

    return dateTimeUtils().formatDate(deliveryTime, DateFormat.DD_MM_YY_HH_MM);
  };

  formatToOrderResponse = (order: UnformattedOrderResponse): OrderResponse => {
    return {
      ...order,
      payment: {
        amountLeftToBePaid: order.payment.amountLeftToBePaid,
        paymentMethod: order.payment.paymentMethodBrand || order.payment.paymentMethod,
        tip: order.payment.tip,
        total: order.payment.total,
      },
    };
  };

  getFilteredOrders = (ordersList: OrderResponse[], filters: OrderEventType[]): OrderResponse[] => {
    const penult = 2;
    return ordersList.filter((order) =>
      filters.some((filterBy) => {
        if (
          order.events[order.events.length - 1].name === OrderEventType.DRIVER_ASSIGNED_TO_ORDER
        ) {
          const reverseEvents = [...order.events];
          reverseEvents.reverse();
          const index = reverseEvents.findIndex(
            (event) => event.name !== OrderEventType.DRIVER_ASSIGNED_TO_ORDER,
          );
          if (reverseEvents[index].name === filterBy) return order;
        } else if (
          order.events[order.events.length - 1].name === OrderEventType.ORDER_PREPARED &&
          order.events[order.events.length - penult].name === filterBy
        ) {
          return order;
        } else if (
          order.events[order.events.length - 1].name !== OrderEventType.DRIVER_ASSIGNED_TO_ORDER &&
          order.events[order.events.length - 1].name === filterBy
        )
          return order;
      }),
    );
  };

  getOrdersSortedByCreationDate = (orders: OrderResponse[]): OrderResponse[] => {
    return orders.sort(
      (order1: { events: OrderEvent[] }, order2: { events: OrderEvent[] }): number => {
        const event1 = order1.events.find((event) => event.name === OrderEventType.ORDER_ACCEPTED);
        const event2 = order2.events.find((event) => event.name === OrderEventType.ORDER_ACCEPTED);
        return new Date(event1!.time).getTime() - new Date(event2!.time).getTime();
      },
    );
  };

  isTimeToEnableActionButton = (
    acceptedTime: string,
    timeToEnableButtonToMoveOrder: number,
  ): boolean => {
    const acceptedDate = new Date(acceptedTime);
    const now = new Date();
    const timeToEnable = addMinutes(acceptedDate, timeToEnableButtonToMoveOrder);

    return isBefore(timeToEnable, now);
  };

  pickAvailableLastMileProviders = (
    lastMileSettings: RestaurantLastMileProvidersSettings,
    orderPaymentMethod: string,
  ): LastMileProviders[] => {
    const { providers: allowedProviders, settings: providersConfigurations } = lastMileSettings;
    if (providersConfigurations) {
      const availableProviders = allowedProviders.filter((lastMileProvider) => {
        const providerConfig = providersConfigurations.find(
          (configuration) => configuration.provider === lastMileProvider,
        );

        if (providerConfig) {
          return !providerConfig.disabledPaymentMethods.includes(orderPaymentMethod);
        } else {
          return true;
        }
      });

      return availableProviders;
    }

    return [...allowedProviders];
  };
}

export const ordersUtils = () => new OrdersUtils();
