import { FC, useEffect } from 'react';
import styled from 'styled-components';

import OrderDetails from '../../components/OrderDetails';
import { HistoryTableType } from '../../constants/order';
import { GenericHistoryTable } from './components/GenericHistoryTable';
import { HistoryHeader } from './components/HistoryHeader';
import { useOrdersHistoryDelegate } from './controllers/delegate';

const Container = styled.div`
  width: 100%;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 20px;
  gap: 25px;
`;

const TableVisibility = styled.div<{ isVisible: boolean }>`
  ${(props) => (props.isVisible ? 'width: 100%;' : 'display: none;')};
`;

export const OrdersHistory: FC = () => {
  const {
    activeTable,
    cancelled,
    closedWithIssues,
    delivered,
    filterDate,
    getOrderDetails,
    getPreviousOrdersByRestaurant,
    handleSidebar,
    setActiveTable,
    setFilterDate,
  } = useOrdersHistoryDelegate();

  useEffect(() => {
    getPreviousOrdersByRestaurant();
  }, [filterDate]);

  const handleOrderClick = async (orderId: string): Promise<void> => {
    const orderDetails = await getOrderDetails(orderId);
    handleSidebar(<OrderDetails orderDetails={orderDetails} onlyDismiss />);
  };

  return (
    <Container>
      <HistoryHeader
        activeButton={activeTable}
        filterDate={filterDate}
        totalDelivered={delivered?.length ?? 0}
        totalCancelled={cancelled?.length ?? 0}
        totalCloseWithIssues={closedWithIssues?.length ?? 0}
        setViewTable={setActiveTable}
        setFilterDate={setFilterDate}
      />
      <Table>
        <TableVisibility isVisible={activeTable === HistoryTableType.DELIVERED}>
          <GenericHistoryTable
            orders={delivered}
            onClick={handleOrderClick}
            tableType={HistoryTableType.DELIVERED}
          />
        </TableVisibility>
        <TableVisibility isVisible={activeTable === HistoryTableType.CLOSED_ISSUES}>
          <GenericHistoryTable
            orders={closedWithIssues}
            onClick={handleOrderClick}
            tableType={HistoryTableType.CLOSED_ISSUES}
          />
        </TableVisibility>
        <TableVisibility isVisible={activeTable === HistoryTableType.CANCELLED}>
          <GenericHistoryTable
            orders={cancelled}
            onClick={handleOrderClick}
            tableType={HistoryTableType.CANCELLED}
          />
        </TableVisibility>
      </Table>
    </Container>
  );
};
