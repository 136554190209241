import { CheckCircleRounded, FastForwardRounded } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FooterValue } from '../../../../@types/orderDetails';
import { ButtonType } from '../../../../constants/buttonType';
import { DeliveryMode } from '../../../../constants/deliveryMode';
import { LastMileDeliveryStatus } from '../../../../constants/lastMile';
import { StatusModalType } from '../../../../constants/modalType';
import { OrderEventType } from '../../../../constants/order';
import { ButtonFunctionClick } from '../../../../constants/orderDetails';
import { PagePath } from '../../../../constants/pages';
import { ToastTypes } from '../../../../constants/toasts';
import CancelOrder from '../../../../pages/OrdersManagement/components/CancelOrder';
import CloseOrder from '../../../../pages/OrdersManagement/components/CloseOrder';
import CloseWithIssue from '../../../../pages/OrdersManagement/components/CloseWithIssue';
import { ordersUtils } from '../../../../utils/OrdersUtils';
import GenericButton from '../../../buttons/GenericButton';
import GenericPrintButton from '../../../buttons/GenericPrintButton';
import ResponseStatusModal from '../../../modals/ResponseStatusModal';
import { ButtonMenu } from '../ButtonMenu';
import { ButtonMenuItem } from '../ButtonMenuItem';
import { useOrderDetailsFooterDelegate } from './controllers/delegate';
interface IOrderDetailsFooter {
  orderId: string;
  displayOrderId: string;
  lastEvent: OrderEventType;
  driverId?: string;
  onlyDismiss?: boolean;
  deliveryMode?: DeliveryMode;
  hasDriverAssigned?: boolean;
  hasLastMile?: boolean;
  lastMileStatus?: LastMileDeliveryStatus;
  hasPreparedEvent?: boolean;
  acceptedTime?: string;
}

const FooterStyle = styled.div`
  box-shadow: 0px 4px 13px 4px rgba(0, 0, 0, 0.25);
  height: 104px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
`;

const translationPath = 'pages.OrderManagement.OrderDetails.footer.buttons';

const OrderDetailsFooter: FC<IOrderDetailsFooter> = ({
  orderId,
  displayOrderId,
  lastEvent,
  driverId,
  onlyDismiss,
  hasLastMile,
  lastMileStatus,
  deliveryMode = DeliveryMode.MANAGED_BY_STORE,
  hasDriverAssigned = false,
  hasPreparedEvent = false,
  acceptedTime,
}) => {
  const { t } = useTranslation();
  const { timeToEnableButtonToMoveOrder, enableOrderPrepared, enableCancelOrderSecondColumn } =
    useFlags();

  const [loading, setLoading] = useState<boolean>(false);
  const isManagedExternally = deliveryMode === DeliveryMode.MANAGED_EXTERNALLY;
  const orderReadyIcon = hasPreparedEvent ? <CheckCircleRounded /> : '';

  const isTimeToEnableActionButton = acceptedTime
    ? ordersUtils().isTimeToEnableActionButton(acceptedTime, timeToEnableButtonToMoveOrder)
    : false;

  const {
    pos,
    showMap,
    moveToDeliveryLastMile,
    handleModal,
    handleSidebar,
    handleMoveToProduction,
    moveToOnDelivery,
    moveExternalDeliveryToOnDelivery,
    closeOrderWithSuccess,
    removeOrderClosedOrFinalizedByDriver,
    triggerOrderPreparedEvent,
    setShowMap,
    addToast,
    autoPrepare,
  } = useOrderDetailsFooterDelegate();

  const handleCancelOrder = (orderId: string): void => {
    handleModal(
      <CancelOrder
        orderId={orderId}
        displayOrderId={displayOrderId}
        onBackClick={() => handleModal()}
      />,
    );
  };

  const handleCloseOrder = (orderId: string): void => {
    const isTimeToEnableAction = acceptedTime
      ? ordersUtils().isTimeToEnableActionButton(acceptedTime, timeToEnableButtonToMoveOrder)
      : false;

    if (hasLastMile && !isTimeToEnableAction) {
      addToast(
        t('pages.OrderManagement.OnDelivery.lastMileNotClosedByTheDriverYet'),
        ToastTypes.WARNING,
      );
      return;
    }

    if (deliveryMode === DeliveryMode.MANAGED_EXTERNALLY) {
      if (isTimeToEnableAction) {
        handleCloseWithSuccess(orderId);
      }
    } else {
      handleModal(
        <CloseOrder
          onCloseSuccess={() => handleCloseWithSuccess(orderId)}
          onCloseIssue={() => handleCloseWithIssue(orderId)}
        />,
      );
    }
  };

  const handleDismissClosedOrder = (orderId: string): void => {
    removeOrderClosedOrFinalizedByDriver(orderId);
  };

  const handleCloseWithSuccess = async (orderId: string): Promise<void> => {
    const orderWasClosed = await closeOrderWithSuccess(orderId);

    if (orderWasClosed) {
      handleModal(
        <ResponseStatusModal
          message={`${t(
            'pages.OrderManagement.OnDelivery.responseStatusModal.order',
          )} #${displayOrderId} ${t(
            'pages.OrderManagement.OnDelivery.responseStatusModal.closed',
          )}`}
          onClick={() => handleModal()}
          type={StatusModalType.SUCCESS}
        />,
        '#ffffff',
      );
      handleSidebar();
    }
  };

  const handleCloseWithIssue = (orderId: string): void => {
    handleModal(
      <CloseWithIssue
        orderId={orderId}
        displayOrderId={displayOrderId}
        onBack={() => handleCloseOrder(orderId)}
      />,
    );
  };

  const handleMoveToOnDelivery = async (): Promise<void> => {
    if (deliveryMode !== DeliveryMode.MANAGED_EXTERNALLY && !hasLastMile && !driverId) return;

    if (hasLastMile) {
      await moveToDeliveryLastMile(orderId);
    } else {
      await moveToOnDelivery(driverId!);
    }
  };

  const handleTriggerOrderPrepared = async (): Promise<void> => {
    if (deliveryMode !== DeliveryMode.MANAGED_EXTERNALLY) return;

    await triggerOrderPreparedEvent(orderId, hasPreparedEvent);
  };

  const handleMoveToInProduction = async (orderId: string): Promise<void> => {
    await handleMoveToProduction(orderId);
  };

  const handleMap = (): void => {
    handleSidebar();
    setShowMap(!showMap);
  };

  const onClick = async (clickFunction: ButtonFunctionClick): Promise<void> => {
    setLoading(true);

    const eventFunction = footerValues[lastEvent][clickFunction];

    if (
      lastEvent === OrderEventType.DRIVER_ASSIGNED_TO_ORDER &&
      clickFunction === 'onClickSecondButton'
    ) {
      await eventFunction(driverId!);
    } else {
      await eventFunction(orderId);
    }

    setLoading(false);
  };

  const shouldDisableOutForDeliveryButton: boolean = useMemo(() => {
    if (hasLastMile && lastMileStatus) {
      return lastEvent === OrderEventType.ORDER_IN_PREPARATION || loading;
    }

    if (deliveryMode !== DeliveryMode.MANAGED_EXTERNALLY && !hasDriverAssigned) {
      return true;
    }

    return false;
  }, [hasLastMile, lastMileStatus, deliveryMode]);

  const getOrderInPreparationClickAction = () => {
    if (isManagedExternally && !isTimeToEnableActionButton && enableOrderPrepared)
      return handleTriggerOrderPrepared;

    if (isManagedExternally) return moveExternalDeliveryToOnDelivery;

    return handleMoveToOnDelivery;
  };

  const footerValues: Record<OrderEventType, FooterValue> = {
    [OrderEventType.ORDER_ACCEPTED]: {
      icon: <FastForwardRounded />,
      isDisabledSecondButton: !pos,
      onClickFirstButton: handleCancelOrder,
      onClickSecondButton: handleMoveToInProduction,
      textFirstButton: `${translationPath}.cancelOrder`,
      textSecondButton: `${translationPath}.moveToKitchen`,
    },
    [OrderEventType.ORDER_IN_PREPARATION]: {
      icon:
        isManagedExternally && !isTimeToEnableActionButton && enableOrderPrepared ? (
          orderReadyIcon
        ) : (
          <FastForwardRounded />
        ),
      isDisabledSecondButton: shouldDisableOutForDeliveryButton,
      onClickFirstButton: handleMap,
      onClickSecondButton: getOrderInPreparationClickAction(),
      textFirstButton: showMap ? `${translationPath}.closeMap` : `${translationPath}.openMap`,
      textSecondButton:
        isManagedExternally && !isTimeToEnableActionButton && enableOrderPrepared
          ? `${translationPath}.orderReady`
          : `${translationPath}.outForDelivery`,
    },
    [OrderEventType.ORDER_PREPARED]: {
      buttonColor: isManagedExternally && hasPreparedEvent ? '#263C47' : '',
      icon: orderReadyIcon,
      isDisabledSecondButton: false,
      onClickFirstButton: handleMap,
      onClickSecondButton: handleTriggerOrderPrepared,
      textFirstButton: showMap ? `${translationPath}.closeMap` : `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.orderReady`,
    },
    [OrderEventType.ORDER_IN_DELIVERY]: {
      borderColor: hasLastMile ? 'rgba(118, 118, 118, 0.3)' : undefined,
      buttonColor:
        (hasLastMile && isTimeToEnableActionButton) ||
        deliveryMode === DeliveryMode.MANAGED_BY_STORE
          ? undefined
          : '#C0C0C0',
      onClickFirstButton: handleMap,
      onClickSecondButton: handleCloseOrder,
      textFirstButton: showMap ? `${translationPath}.closeMap` : `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.closeOrder`,
    },
    [OrderEventType.ORDER_DELIVERED]: {
      isDisabledSecondButton: true,
      onClickFirstButton: handleMap,
      onClickSecondButton: () => Promise.resolve(),
      textFirstButton: showMap ? `${translationPath}.closeMap` : `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.closeOrder`,
    },
    [OrderEventType.ORDER_CANCELLED]: {
      isDisabledSecondButton: showMap,
      onClickFirstButton: handleMap,
      onClickSecondButton: handleDismissClosedOrder,
      textFirstButton: showMap ? `${translationPath}.closeMap` : `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.dismiss`,
    },
    [OrderEventType.ORDER_FINALIZED]: {
      isDisabledFirstButton: true,
      isDisabledSecondButton: true,
      onClickFirstButton: () => Promise.resolve(),
      onClickSecondButton: () => Promise.resolve(),
      textFirstButton: `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.dismiss`,
    },
    [OrderEventType.DRIVER_ASSIGNED_TO_ORDER]: {
      icon: <FastForwardRounded />,
      onClickFirstButton: handleMap,
      onClickSecondButton: handleMoveToOnDelivery,
      textFirstButton: `${translationPath}.openMap`,
      textSecondButton: `${translationPath}.outForDelivery`,
    },
    [OrderEventType.ORDER_PRE_SCHEDULED]: {
      isDisabledSecondButton: true,
      onClickFirstButton: handleCancelOrder,
      onClickSecondButton: () => Promise.resolve(),
      textFirstButton: `${translationPath}.cancelOrder`,
      textSecondButton: '',
    },
  };

  const renderCancelButton = () => {
    if (lastEvent === OrderEventType.ORDER_IN_PREPARATION && autoPrepare && !hasLastMile) {
      return (
        <ButtonContainer>
          <ButtonMenu>
            <ButtonMenuItem
              data-testId="view_map"
              onClick={() => onClick(ButtonFunctionClick.CLICK_FIRST)}
            >
              {t(footerValues[lastEvent].textFirstButton)}
            </ButtonMenuItem>
            {enableCancelOrderSecondColumn && (
              <ButtonMenuItem data-testId="cancel_order" onClick={() => handleCancelOrder(orderId)}>
                {t(`${translationPath}.cancelOrder`)}
              </ButtonMenuItem>
            )}
          </ButtonMenu>
        </ButtonContainer>
      );
    } else {
      return (
        <ButtonContainer>
          <GenericButton
            title={t(footerValues[lastEvent].textFirstButton)}
            onClick={() => onClick(ButtonFunctionClick.CLICK_FIRST)}
            disabled={!!footerValues[lastEvent].isDisabledFirstButton || loading}
            type={ButtonType.SECONDARY}
          />
        </ButtonContainer>
      );
    }
  };

  return (
    <FooterStyle>
      <FlexRow>
        {onlyDismiss ? (
          <GenericButton title={t(`${translationPath}.dismiss`)} onClick={() => handleSidebar()} />
        ) : (
          <>
            {renderCancelButton()}
            {lastEvent !== OrderEventType.ORDER_PRE_SCHEDULED && (
              <ButtonContainer>
                <GenericButton
                  title={t(footerValues[lastEvent].textSecondButton)}
                  onClick={() => onClick(ButtonFunctionClick.CLICK_SECOND)}
                  disabled={footerValues[lastEvent].isDisabledSecondButton}
                  icon={footerValues[lastEvent].icon}
                  buttonColor={footerValues[lastEvent].buttonColor}
                  borderColor={footerValues[lastEvent].borderColor}
                />
              </ButtonContainer>
            )}
          </>
        )}
        <GenericPrintButton id={orderId} page={PagePath.PRINT_ORDER_DETAILS} params={orderId} />
      </FlexRow>
    </FooterStyle>
  );
};

export default OrderDetailsFooter;
