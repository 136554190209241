import { useState } from 'react';

import { useAuthContext } from '../../../contexts/AuthContext';
import authService from '../../../services/AuthService';

const usePasswordValidationConnector = () => {
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  return {
    authContext: useAuthContext(),
    authService,
    errorMessage,
    password,
    setErrorMessage,
    setPassword,
  };
};

export default usePasswordValidationConnector;
