export enum PagePath {
  CASHFLOW = 'cashflow',
  DRIVERS = 'drivers',
  DRIVER_APP = 'driver-app',
  HISTORY = 'history',
  COMPLAINTS = 'complaints',
  MAP = 'map',
  ORDERS_MANAGEMENT = 'orders-management',
  PRODUCT_MANAGEMENT = 'product-management',
  LOGIN = 'login',
  NOT_FOUND = 'not-found',
  PRE_ORDERS = 'pre-orders',
  PRINT_ORDER_DETAILS = 'print/order',
  PRINT_DRIVER_CASHFLOW = 'print/cash-flow/by-driver',
  PRINT_COMPLAINT_DETAILS = 'print/complaints',
}
