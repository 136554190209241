import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Cart } from '../../../../../../@types/cart';
import { CloseWithIssuePayload } from '../../../../../../@types/orders';
import Compensation from '../../../../../../components/Compensation';
import Loading from '../../../../../../components/Loading';
import GenericButton from '../../../../../../components/buttons/GenericButton';
import GenericRadioList from '../../../../../../components/inputs/GenericRadioList';
import { ButtonType } from '../../../../../../constants/buttonType';
import { useIssueListDelegate } from './controllers/delegate';

interface IIssueList {
  orderId: string;
  onConfirm: (payload: CloseWithIssuePayload) => void;
  onBack: () => void;
}

const Body = styled.div`
  overflow-y: auto;
  width: 716px;
  height: 687px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebebe;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
`;

const PaddingStyle = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 0px;
`;

const ButtonStyle = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0px;
  width: 420px;
`;

const InputList = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 27px 10px;
  overflow-y: auto;
  max-height: 380px;
`;

const FooterStyle = styled.div`
  display: flex;
  gap: 20px;
`;

const IssueList: FC<IIssueList> = ({ orderId, onConfirm, onBack }) => {
  const { t } = useTranslation();

  const [showCompensation, setShowCompensation] = useState<boolean>(false);

  const {
    issueList,
    orderDetails,
    compensations,
    issueSelected,
    getIssueList,
    getOrderDetails,
    isProductMissing,
    setIssueSelected,
    createCompensationList,
    disableCompensationButton,
    setCompensationItemQuantity,
  } = useIssueListDelegate();

  useEffect(() => {
    getIssueList();
    getOrderDetails(orderId).then((orderDetails) => {
      createCompensationList(orderDetails?.cart.menuSelections ?? []);
    });
  }, []);

  const onConfirmIssue = (): void => {
    if (isProductMissing(issueSelected)) {
      setShowCompensation(true);
    } else {
      const payload = createConfirmPayload();
      onConfirm(payload);
    }
  };

  const onConfirmCompensation = (): void => {
    const payload = createConfirmPayload({
      menuSelections: compensations.filter(
        (item) => item.quantity || item.menuSelections.some((subitem) => subitem.quantity),
      ),
    });
    onConfirm(payload);
  };

  const createConfirmPayload = (compensation?: Cart): CloseWithIssuePayload => {
    return {
      compensation,
      issue: [issueSelected],
    };
  };

  return (
    <Body>
      {orderDetails && issueList.length ? (
        <PaddingStyle>
          {showCompensation ? (
            <>
              <Title>{t('pages.OrderManagement.CloseWithIssue.Compensation.title')}</Title>
              <InputList>
                <Compensation
                  menuSelections={compensations}
                  onChange={setCompensationItemQuantity}
                />
              </InputList>
              <FooterStyle>
                <ButtonStyle>
                  <GenericButton
                    type={ButtonType.SECONDARY}
                    title={t('pages.OrderManagement.CloseWithIssue.backButton')}
                    onClick={() => setShowCompensation(false)}
                  />
                </ButtonStyle>
                <ButtonStyle>
                  <GenericButton
                    title={t('pages.OrderManagement.CloseWithIssue.Compensation.confirmButton')}
                    onClick={onConfirmCompensation}
                    disabled={disableCompensationButton()}
                  />
                </ButtonStyle>
              </FooterStyle>
            </>
          ) : (
            <>
              <Title>{t('pages.OrderManagement.CloseWithIssue.IssueList.title')}</Title>
              <GenericRadioList
                options={issueList}
                onChange={setIssueSelected}
                dataTestId="issue-option"
              />
              <FooterStyle>
                <ButtonStyle>
                  <GenericButton
                    type={ButtonType.SECONDARY}
                    title={t('pages.OrderManagement.CloseWithIssue.backButton')}
                    onClick={onBack}
                  />
                </ButtonStyle>
                <ButtonStyle>
                  <GenericButton
                    title={t('pages.OrderManagement.CloseWithIssue.IssueList.confirmButton')}
                    onClick={onConfirmIssue}
                    disabled={!issueSelected}
                  />
                </ButtonStyle>
              </FooterStyle>
            </>
          )}
        </PaddingStyle>
      ) : (
        <Loading />
      )}
    </Body>
  );
};

export default IssueList;
