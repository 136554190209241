import { FC } from 'react';
import styled from 'styled-components';

import { useStoreIdentificationDelegate } from './controllers/delegate';

const StoreIdentificationStyle = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3b5a6b;
  margin: 0px 10px 0px 24px;
  min-width: 56px;
  word-break: break-word;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StoreIdentification: FC = () => {
  const { restaurant } = useStoreIdentificationDelegate();

  return (
    <StoreIdentificationStyle data-testid="store-identification">
      {restaurant}
    </StoreIdentificationStyle>
  );
};

export default StoreIdentification;
