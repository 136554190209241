import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseWithIssuePayload } from '../../../../@types/orders';
import PasswordValidation from '../../../../components/PasswordValidation';
import ResponseStatusModal from '../../../../components/modals/ResponseStatusModal';
import { PermissionTokenActions } from '../../../../constants/auth';
import { StatusModalType } from '../../../../constants/modalType';
import IssueList from './components/IssueList';
import { useCloseWithIssueDelegate } from './controllers/delegate';

interface ICloseWithIssue {
  orderId: string;
  displayOrderId: string;
  onBack: () => void;
}

const CloseWithIssue: FC<ICloseWithIssue> = ({ orderId, displayOrderId, onBack }) => {
  const { t } = useTranslation();

  const { closeOrderWithIssue, handleModal } = useCloseWithIssueDelegate();

  const handleTokenSuccess = async (payload: CloseWithIssuePayload, token: string) => {
    const closed = await closeOrderWithIssue(orderId, payload, token);

    if (closed) {
      handleModal(
        <ResponseStatusModal
          message={`${t(
            'pages.OrderManagement.CloseWithIssue.responseStatusModal.order',
          )} #${displayOrderId} ${t(
            'pages.OrderManagement.CloseWithIssue.responseStatusModal.closed',
          )}`}
          onClick={() => handleModal()}
          type={StatusModalType.ERROR}
        />,
        '#ffffff',
      );
    }
  };

  const onConfirm = (payload: CloseWithIssuePayload): void => {
    handleModal(
      <PasswordValidation
        title={`${t(
          'pages.OrderManagement.CloseWithIssue.authenticationModalMessage',
        )} #${displayOrderId}`}
        action={PermissionTokenActions.CLOSE_ORDER}
        payload={{ orderId }}
        onSuccess={(token) => handleTokenSuccess(payload, token)}
      />,
    );
  };

  return <IssueList orderId={orderId} onConfirm={onConfirm} onBack={onBack} />;
};

export default CloseWithIssue;
