import { MutableRefObject } from 'react';

import { ComplaintsResponse } from '../../../@types/complaints';
import { Delegate } from '../../../@types/handlers/delegate';
import { NotifyComplaintEvent, WebsocketTopic } from '../../../@types/websocket';
import { ComplaintEventType, ComplaintStatus } from '../../../constants/complaints';
import { PagePath } from '../../../constants/pages';
import { useComplaintsContextConnector } from './connector';

export interface IComplaintsContextDelegate {
  /**
   * Current path being accessed in the application
   */
  currentPath: string;

  /**
   * React reference to Current path being accessed in the application
   */
  currentPathRef: MutableRefObject<string>;

  /**
   * Get all opened complaints
   */
  openedComplaints: ComplaintsResponse[];

  /**
   * Get all closed complaints on the restaurant shift
   */
  closedComplaints: ComplaintsResponse[];

  /**
   * Get all opened complaints notifications
   */
  unseenComplaintNotifications: string[];

  /**
   * React reference to the closedComplaints state
   */
  closedComplaintsRef: MutableRefObject<ComplaintsResponse[]>;

  /**
   * React reference to the openedComplaints state
   */
  openedComplaintsRef: MutableRefObject<ComplaintsResponse[]>;

  /**
   * React reference to the unseenComplaintNotifications state
   */
  notificationsRef: MutableRefObject<string[]>;

  /**
   * Call method to immediately update the closed complaints
   */
  forceUpdate(): Promise<void>;

  /**
   * Update complaint notifications on storage and state
   */
  setComplaintNotifications(complaintIds: string[]): void;

  /**
   * Subscribe to Websocket context pub/sub
   */
  subscribeToWebsocket(): void;

  /**
   * Sync updates between the complaints state and the notification state and localStorage
   */
  syncComplaintsNotification(): void;

  /**
   * Unsubscribe to Websocket context pub/sub
   */
  unsubscribeToWebsocket(): void;

  /**
   * Remove a notification from storage and state
   *
   * @param {string} complaintId to remove the notification
   */
  removeComplaintNotificationFromStorage(complaintId: string): void;

  /**
   * Remove the closed complaint received from state and the notification from storage
   *
   * @param {ComplaintsResponse} closedComplaint to remove from state
   */
  handleClosedComplaintsMessage(closedComplaint: ComplaintsResponse): void;

  /**
   * Retrieve opened and closed complaints from api
   */
  retrieveComplaintsFromApi(): Promise<void>;
}

type ComplaintsContextConnector = ReturnType<typeof useComplaintsContextConnector>;

export class ComplaintsContextDelegate
  extends Delegate<ComplaintsContextConnector>
  implements IComplaintsContextDelegate
{
  get openedComplaints(): ComplaintsResponse[] {
    return this.connector.openedComplaints;
  }

  get closedComplaints(): ComplaintsResponse[] {
    return this.connector.closedComplaints;
  }

  get currentPath(): string {
    return this.connector.currentPath;
  }

  get currentPathRef(): MutableRefObject<string> {
    return this.connector.currentPathRef;
  }

  get unseenComplaintNotifications(): string[] {
    return this.connector.unseenComplaintNotifications;
  }

  get notificationsRef(): MutableRefObject<string[]> {
    return this.connector.notificationsRef;
  }

  get closedComplaintsRef(): MutableRefObject<ComplaintsResponse[]> {
    return this.connector.closedComplaintsRef;
  }

  get openedComplaintsRef(): MutableRefObject<ComplaintsResponse[]> {
    return this.connector.openedComplaintsRef;
  }

  forceUpdate = async (): Promise<void> => {
    await this.retrieveComplaintsFromApi();
  };

  handleClosedComplaintsMessage = (closedComplaint: ComplaintsResponse): void => {
    const closedComplaints = [...this.closedComplaintsRef.current, closedComplaint];

    this.connector.setClosedComplaints(closedComplaints);

    const openedComplaints = this.connector.openedComplaintsRef.current.filter(
      (complaint) => complaint.id !== closedComplaint.id,
    );

    this.connector.setOpenedComplaints(openedComplaints);

    if (this.notificationsRef.current.includes(closedComplaint.id)) {
      this.removeComplaintNotificationFromStorage(closedComplaint.id);
    }
  };

  removeComplaintNotificationFromStorage = (complaintId: string): void => {
    const existentComplaintNotifications = this.retrieveNotificationsFromStorage();
    const unseenComplaintNotifications = existentComplaintNotifications.filter(
      (id) => id !== complaintId,
    );
    this.setComplaintNotifications(unseenComplaintNotifications);
  };

  retrieveComplaintsFromApi = async (): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    const [openedComplaints, closedComplaints] = await Promise.all([
      this.connector.complaintsService.getComplaintsByRestaurant(
        regionCode,
        brand,
        restaurantId,
        ComplaintStatus.OPEN,
      ),
      this.connector.complaintsService.getComplaintsByRestaurant(
        regionCode,
        brand,
        restaurantId,
        ComplaintStatus.CLOSED,
      ),
    ]);

    this.connector.setOpenedComplaints(openedComplaints);
    this.connector.setClosedComplaints(closedComplaints);
  };

  setComplaintNotifications = (complaintIds: string[]): void => {
    window.localStorage.setItem(
      '@RBI_Expeditor_Tablet:Complaint_Ids_Notification',
      JSON.stringify(complaintIds),
    );

    this.connector.setUnseenComplaintNotifications(complaintIds);
  };

  subscribeToWebsocket = (): void => {
    this.connector.websocketContext.subscribeToWebsocket(
      WebsocketTopic.ORDER_EVENT,
      this.handleWebsocketMessage,
    );
  };

  syncComplaintsNotification = (): void => {
    // the localStorage is our source of truth for the complaints notification
    const existentComplaintNotifications = this.retrieveNotificationsFromStorage();

    // to guarantee that only open complaints will be on the notifications
    const filteredComplaintsNotifications = existentComplaintNotifications.filter((complaintId) =>
      this.closedComplaints.every((complaint) => complaint.id !== complaintId),
    );
    this.setComplaintNotifications(filteredComplaintsNotifications);
  };

  unsubscribeToWebsocket = (): void => {
    this.connector.websocketContext.unsubscribeToWebsocket(this.handleWebsocketMessage);
  };

  /**
   * Add a new notification on storage and state
   *
   * @param {string} complaintId the complaint id
   */
  private addComplaintNotificationOnStorage = (complaintId: string): void => {
    const existentComplaintNotifications = this.retrieveNotificationsFromStorage();

    if (!existentComplaintNotifications.includes(complaintId)) {
      const maxNotifications = 8;

      if (existentComplaintNotifications.length >= maxNotifications) {
        const remainingOldNotifications = existentComplaintNotifications.slice(1, maxNotifications);

        this.setComplaintNotifications([...remainingOldNotifications, complaintId]);
      } else {
        this.setComplaintNotifications([...existentComplaintNotifications, complaintId]);
      }
    }
  };

  /**
   * Add a new complaint on the context state
   *
   * @param {ComplaintsResponse} complaint the complaint object
   */
  private addComplaintOnContext = (complaint: ComplaintsResponse) => {
    const existentOpenedComplaints = this.openedComplaintsRef.current;

    const complaintIsAlreadyOnTheList = existentOpenedComplaints.find(
      (existentComplaint) => existentComplaint.id === complaint.id,
    );

    if (!complaintIsAlreadyOnTheList) {
      this.connector.setOpenedComplaints([...existentOpenedComplaints, complaint]);
    }
  };

  private handleWebsocketMessage = (
    _: string,
    { eventName, payload }: NotifyComplaintEvent,
  ): void => {
    if (eventName === ComplaintEventType.COMPLAINT_OPENED) {
      if (this.connector.currentPathRef.current !== `/${PagePath.COMPLAINTS}`) {
        this.addComplaintNotificationOnStorage(payload.id);
      }
      this.addComplaintOnContext(payload);
    }

    if (eventName === ComplaintEventType.COMPLAINT_CLOSED) {
      this.handleClosedComplaintsMessage(payload);
    }
  };

  /**
   * Retrieve from localStorage the list of unseen complaints notifications
   * @returns a list of unseen notifications
   */
  private retrieveNotificationsFromStorage = (): string[] => {
    const unseenComplaintsNotifications = window.localStorage.getItem(
      '@RBI_Expeditor_Tablet:Complaint_Ids_Notification',
    );

    if (unseenComplaintsNotifications) {
      const parsedUnseenComplaintsNotifications: string[] = JSON.parse(
        unseenComplaintsNotifications,
      );
      return parsedUnseenComplaintsNotifications;
    }

    return [];
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useComplaintsContextDelegate = () =>
  new ComplaintsContextDelegate(useComplaintsContextConnector());
