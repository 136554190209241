import { useSettingsContext } from '../../../contexts/SettingsContext';

const usePageHeaderConnector = () => {
  const { allowPauseService, allowAutoFire } = useSettingsContext();

  return {
    allowAutoFire,
    allowPauseService,
  };
};

export default usePageHeaderConnector;
