import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../@types/input';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import settingsService from '../../../../../services/SettingsService';

const useEtaListConnector = () => {
  const { t: translate } = useTranslation();

  const [etaList, setEtaList] = useState<SelectOption[]>([]);

  return {
    authContext: useAuthContext(),
    etaList,
    setEtaList,
    settingsService,
    toastContext: useToastContext(),
    translate,
  };
};

export default useEtaListConnector;
