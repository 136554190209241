import { createContext, FC, useContext, useEffect } from 'react';

import { WebsocketTopic } from '../../@types/websocket';
import { useWebsocketContextDelegate } from './controllers/delegate';

interface IWebsocketContext {
  subscribeToWebsocket(topic: WebsocketTopic, listener: Function): void;
  unsubscribeToWebsocket(listener: Function): void;
}

export const WebsocketContext = createContext<IWebsocketContext>({} as IWebsocketContext);

WebsocketContext.displayName = 'Websocket';

interface IWebsocketProvider {
  children: JSX.Element;
}

const WebsocketProvider: FC<IWebsocketProvider> = ({ children }) => {
  const {
    retryConn,
    retryConnRef,
    initWebSocketClient,
    subscribeToWebsocket,
    unsubscribeToWebsocket,
  } = useWebsocketContextDelegate();

  useEffect(() => {
    initWebSocketClient();
  }, []);

  useEffect(() => {
    retryConnRef.current = retryConn;
  }, [retryConn]);

  return (
    <WebsocketContext.Provider value={{ subscribeToWebsocket, unsubscribeToWebsocket }}>
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocketContext = () => useContext(WebsocketContext);

export default WebsocketProvider;
