import { env } from '../../constants/env';

export interface WebSocketClientHandlers {
  onmessage: Function;
  onopen?: Function;
  onerror?: Function;
  onclose?: Function;
}

let wsClient: WebSocketClient | null;
let authToken: string | null;

enum WebsocketStatusCodes {
  CLOSE_NORMAL = 1000,
  CLOSE_GOING_AWAY = 1001, // Inactivity or close browser tab
  CLOSE_ABNORMAL = 1006, // Close by token expiration, or intermittent failure
}

export class WebSocketClient {
  public static initWSClient(handlers: WebSocketClientHandlers): WebSocketClient {
    if (!wsClient) {
      wsClient = new WebSocketClient(handlers);
      wsClient.initSocket();
      return wsClient;
    }

    return wsClient;
  }

  public static setAuthToken = (token: string | null): void => {
    authToken = token;
  };

  isOpen: boolean;
  webSocket: WebSocket | null;

  constructor(private handlers: WebSocketClientHandlers) {
    this.isOpen = false;
    this.webSocket = null;
  }

  public close = (): void => {
    this.webSocket?.close();
  };

  private initSocket = (): void => {
    this.webSocket = new WebSocket(`${env.websocketURL}?idToken=${authToken}`);
    this.webSocket.onopen = this.onOpen;
    this.webSocket.onclose = this.onClose;
    this.webSocket.onerror = this.onError;
    this.webSocket.onmessage = this.onMessage;
  };

  private onClose = (event: CloseEvent): void => {
    wsClient = null;

    switch (event.code) {
      case WebsocketStatusCodes.CLOSE_ABNORMAL:
        this.handlers.onclose && this.handlers.onclose(event);
        break;
      case WebsocketStatusCodes.CLOSE_NORMAL:
      case WebsocketStatusCodes.CLOSE_GOING_AWAY:
        // Initialize again ( reconnect )
        this.initSocket();
        break;
    }
  };

  private onError = (event: Event): void => {
    this.handlers.onerror && this.handlers.onerror(event);
  };

  private onMessage = (event: MessageEvent): void => {
    if (event) {
      const message = JSON.parse(event.data);
      this.handlers.onmessage(message);
    }
  };

  private onOpen = (event: Event): void => {
    this.isOpen = true;
    this.handlers.onopen && this.handlers.onopen(event);
  };
}

export const getWSClient = (handlers: WebSocketClientHandlers): WebSocketClient =>
  WebSocketClient.initWSClient(handlers);
