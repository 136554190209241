import { FC } from 'react';
import styled from 'styled-components';

interface ILoading {
  strokeColor?: string;
}

const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = styled.div`
  @keyframes dash {
    0% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 125;
    }
    100% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 250;
    }
  }

  @-webkit-keyframes dashWebkit {
    0% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 125;
    }
    100% {
      stroke-dasharray: 186 62;
      stroke-dashoffset: 250;
    }
  }
`;

const SvgIndicator = styled.svg`
  width: 100px;
  height: 100px;
`;

const CircleTrack = styled.circle`
  cx: 50px;
  cy: 50px;
  r: 40px;
  fill: transparent;

  stroke: #3b5a6b;
  stroke-linecap: round;
  stroke-width: 10px;

  -webkit-animation-name: dashWebkit;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;

  animation-name: dash;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const CircleIndication = styled.circle<{ strokeColor?: string }>`
  cx: 50px;
  cy: 50px;
  r: 38px;
  fill: transparent;
  stroke-width: 12px;
  stroke: ${(props) => (props.strokeColor ? props.strokeColor : '#dae7ee')};
`;

const Loading: FC<ILoading> = ({ strokeColor }) => {
  return (
    <LoaderBackground>
      <Loader>
        <SvgIndicator>
          <CircleIndication strokeColor={strokeColor} />
          <CircleTrack />
        </SvgIndicator>
      </Loader>
    </LoaderBackground>
  );
};

export default Loading;
