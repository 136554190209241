import { DriverResponse } from '../../../../../@types/drivers';
import {
  GenericTableData,
  GenericTableDataOptions,
  GenericTableRow,
} from '../../../../../@types/genericTable';
import { Delegate } from '../../../../../@types/handlers/delegate';
import { OrderResponse } from '../../../../../@types/orders';
import { CancelOption, OrderIssue } from '../../../../../@types/settings';
import { HistoryTableType } from '../../../../../constants/order';
import { OrderTypeColor } from '../../../../../constants/orderTypeColor';
import { PaymentMethod, paymentMethodTranslation } from '../../../../../constants/paymentMethod';
import useGenericHistoryTableConnector from './connector';

interface IGenericHistoryTableDelegate {
  /**
   * State variable for formatted table data
   */
  formattedList: GenericTableRow[];

  /**
   * State variable for loading indicator
   */
  loading: boolean;

  /**
   * Format array of orders information according to a HistoryTableType
   *
   * @param {OrderResponse[]} orders information array
   * @param {HistoryTableType} tableType type of table that is going to receive the information
   */
  formatOrders(orders: OrderResponse[], tableType: HistoryTableType): Promise<void>;

  /**
   * Returns order color to be used in the interface according to the order information
   *
   * @param orderId orderId of the order to be decorated with a specific color
   * @returns {OrderTypeColor} color which should decorate the order
   */
  getOrderColor(orderId: string): OrderTypeColor;
}

type GenericHistoryTableConnector = ReturnType<typeof useGenericHistoryTableConnector>;

export class GenericHistoryTableDelegate
  extends Delegate<GenericHistoryTableConnector>
  implements IGenericHistoryTableDelegate
{
  get formattedList(): GenericTableRow[] {
    return this.connector.formattedList;
  }

  get loading(): boolean {
    return this.connector.loading;
  }

  formatOrders = async (orders: OrderResponse[], tableType: HistoryTableType): Promise<void> => {
    switch (tableType) {
      case HistoryTableType.DELIVERED:
        return this.formatDeliveredOrders(orders);
      case HistoryTableType.CLOSED_ISSUES:
        return this.formatClosedIssuesOrders(orders);
      case HistoryTableType.CANCELLED:
        return this.formatCancelledOrders(orders);
      default:
        throw new Error('Invalid table type');
    }
  };

  getOrderColor = (orderId: string): OrderTypeColor => {
    const orderInfo = this.connector.formattedList.find(
      (orderInfo) => orderInfo.data[0].data === orderId,
    );

    if (!orderInfo) return OrderTypeColor.SUCCESS;

    const arrTotal = orderInfo.data[orderInfo.data.length - 1].data.toString().split(':');

    const secondsInMinute = 60;
    const secondsInHour = 3600;

    const totalSeconds = parseInt(arrTotal[arrTotal.length - 1]);
    const totalMinutes = parseInt(arrTotal[1]) * secondsInMinute;
    const totalHours = parseInt(arrTotal[0]) * secondsInHour;

    const totalTimeSeconds = totalSeconds + totalMinutes + totalHours;

    return this.connector.orderHistoryUtils.calculateColor(
      this.connector.colorSettings!,
      totalTimeSeconds,
    );
  };

  private formatCancelledOrders = async (orders: OrderResponse[]): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    this.connector.setLoading(true);

    const cancelledOptions: CancelOption[] = await this.connector.settingsService.getCancelOptions(
      regionCode,
      brand,
      restaurantId,
    );

    const drivers: DriverResponse[] = await this.connector.driversService.getAll(
      regionCode,
      brand,
      restaurantId,
    );

    const { formatReason } = this.connector.orderHistoryUtils;

    const formattedList: GenericTableRow[] = orders.map((order) => {
      return {
        data: [
          ...this.genericFormatter(order, drivers),
          { data: '-' },
          formatReason(cancelledOptions, order.events[order.events.length - 1].issue),
        ],
      };
    });

    this.connector.setFormattedList([...formattedList].reverse());
    this.connector.setLoading(false);
  };

  private formatClosedIssuesOrders = async (orders: OrderResponse[]): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    this.connector.setLoading(true);

    const cancelledOptions: OrderIssue[] = await this.connector.settingsService.getCloseOrderIssues(
      regionCode,
      brand,
      restaurantId,
    );

    const drivers: DriverResponse[] = await this.connector.driversService.getAll(
      regionCode,
      brand,
      restaurantId,
    );

    const { totalOrderTime, formatIssue } = this.connector.orderHistoryUtils;

    const formattedList: GenericTableRow[] = orders.map((order) => {
      return {
        data: [
          ...this.genericFormatter(order, drivers),
          totalOrderTime(order.events),
          formatIssue(cancelledOptions, order.events[order.events.length - 1].issue),
        ],
      };
    });

    this.connector.setFormattedList([...formattedList].reverse());
    this.connector.setLoading(false);
  };

  private formatDeliveredOrders = async (orders: OrderResponse[]): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    this.connector.setLoading(true);

    const drivers: DriverResponse[] = await this.connector.driversService.getAll(
      regionCode,
      brand,
      restaurantId,
    );

    const { totalOrderTime } = this.connector.orderHistoryUtils;

    const formattedList: GenericTableRow[] = orders.map((order) => {
      return {
        data: [...this.genericFormatter(order, drivers), totalOrderTime(order.events)],
      };
    });

    this.connector.setFormattedList([...formattedList].reverse());
    this.connector.setLoading(false);
  };

  private genericFormatter = (
    order: OrderResponse,
    drivers: DriverResponse[],
  ): GenericTableData[] => {
    const { formatChannel, formatCreatedAt, formatDriverName } = this.connector.orderHistoryUtils;

    const createGenericData = (
      data: string | number | JSX.Element,
      options?: GenericTableDataOptions,
    ): GenericTableData => ({
      data,
      options,
    });

    return [
      createGenericData(order.rbiNumberId),
      createGenericData(`#${order.displayOrderId}`),
      createGenericData(order.customer.name),
      createGenericData(
        this.connector.moneyUtils.moneyFormatter(
          order.payment.total.amount,
          order.payment.total.currency,
        ),
      ),
      createGenericData(
        this.connector.translate(
          paymentMethodTranslation[order.payment.paymentMethod as PaymentMethod] ||
            order.payment.paymentMethod,
        ),
      ),
      formatChannel(order.channel),
      createGenericData(order.quadrant?.name ?? '', { position: 'center' }),
      createGenericData(formatCreatedAt(order.createdAt).data, { position: 'center' }),
      formatDriverName(drivers, order),
    ];
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useGenericHistoryTableDelegate = () =>
  new GenericHistoryTableDelegate(useGenericHistoryTableConnector());
