import {
  LoginBody,
  LoginResponse,
  PermissionTokenPayload,
  PermissionTokenResponse,
  RefreshTokenPayload,
  RefreshTokenResponse,
} from '../@types/auth';
import { HTTPClient } from '../@types/handlers/httpClient';
import { env } from '../constants/env';

export interface IAuthService {
  /**
   * Authenticate the user
   *
   * @param {LoginBody} body object with user identifier and password
   * @return {LoginResponse} returns the token and the expiration date
   */
  login(body: LoginBody): Promise<LoginResponse>;

  /**
   * Logout the user
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} refreshToken refresh token
   * @return {string} success or error message
   */
  logout(country: string, brand: string, refreshToken: string): Promise<string>;

  /**
   * Validate password
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {string} userId user unique identifier
   * @param {PermissionTokenPayload} payload next request action, password and payload
   * @returns {Promise<PermissionTokenResponse>} an object with the validate token
   */
  permissionToken(
    country: string,
    brand: string,
    userId: string,
    payload: PermissionTokenPayload,
  ): Promise<PermissionTokenResponse>;

  /**
   * Refresh id token from user
   *
   * @param {string} country country for the restaurant
   * @param {string} brand  brand for the restaurant
   * @param {RefreshTokenPayload} payload object with valid refresh token
   * @returns {Promise<RefreshTokenResponse>} object with the new token and expired date value
   */
  refreshToken(
    country: string,
    brand: string,
    payload: RefreshTokenPayload,
  ): Promise<RefreshTokenResponse>;
}

class AuthService extends HTTPClient implements IAuthService {
  async login(body: LoginBody): Promise<LoginResponse> {
    const url = `/ES/${env.brand}/auth/users/login`;
    const response = await this.post<LoginBody, LoginResponse>(url, body);

    return response.data;
  }

  async logout(country: string, brand: string, refreshToken: string): Promise<string> {
    const url = `/${country}/${brand}/auth/users/logout`;

    const response = await this.post<undefined, string>(url, undefined, {
      authorization: `Bearer ${refreshToken}`,
    });

    return response.data;
  }

  async permissionToken(
    country: string,
    brand: string,
    userId: string,
    payload: PermissionTokenPayload,
  ): Promise<PermissionTokenResponse> {
    const url = `/${country}/${brand}/auth/users/${userId}/tokens`;
    const response = await this.post<PermissionTokenPayload, PermissionTokenResponse>(url, payload);

    return response.data;
  }

  async refreshToken(
    country: string,
    brand: string,
    payload: RefreshTokenPayload,
  ): Promise<RefreshTokenResponse> {
    const url = `/${country}/${brand}/auth/users/refresh-token`;

    const response = await this.post<RefreshTokenPayload, RefreshTokenResponse>(url, payload);

    return response.data;
  }
}

export default new AuthService(env.deliveryServiceAPI);
