import React, { createContext, FC, useContext } from 'react';

import GenericSidebar from './components/GenericSidebar';
import { ISidebarContextDelegate, useSidebarContextDelegate } from './controllers/delegate';

export const SidebarContext = createContext<Pick<ISidebarContextDelegate, 'handleSidebar'>>({
  handleSidebar: () => ({}),
});

SidebarContext.displayName = 'Sidebar';

interface ISidebarProvider {
  children: JSX.Element;
}

const SidebarProvider: FC<ISidebarProvider> = ({ children }) => {
  const { showSidebar, content, dependencies, handleSidebar } = useSidebarContextDelegate();

  return (
    <SidebarContext.Provider value={{ handleSidebar }}>
      <GenericSidebar
        handleSidebar={() => handleSidebar()}
        showSidebar={showSidebar}
        content={content}
        dependencies={dependencies}
      />
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);

export default SidebarProvider;
