import React, { createContext, FC, useContext } from 'react';

import GenericFloatingCard from './components/GenericFloatingCard';
import {
  IFloatingCardContextDelegate,
  useFloatingCardContextDelegate,
} from './controllers/delegate';

const FloatingCardContext = createContext<Pick<IFloatingCardContextDelegate, 'handleFloatingCard'>>(
  {
    handleFloatingCard: () => ({}),
  },
);

FloatingCardContext.displayName = 'FloatingCard';

interface IFloatingCardProvider {
  children: JSX.Element;
}

const FloatingCardProvider: FC<IFloatingCardProvider> = ({ children }) => {
  const { position, show, content, dependencies, handleFloatingCard } =
    useFloatingCardContextDelegate();

  return (
    <FloatingCardContext.Provider value={{ handleFloatingCard }}>
      <GenericFloatingCard
        position={position}
        show={show}
        content={content}
        handleFloatingCard={handleFloatingCard}
        dependencies={dependencies}
      />
      {children}
    </FloatingCardContext.Provider>
  );
};

export const useFloatingCardContext = () => useContext(FloatingCardContext);

export default FloatingCardProvider;
