import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import 'react-simple-keyboard/build/css/index.css';

interface IGenericInput {
  value: string;
  label: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: string;
  inputHeight?: number;
  name?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyBoardEnter?: () => void;
}

const Body = styled.div`
  background: transparent;
  width: 100%;
`;

const InputOutline = styled.div`
  border-radius: 4px;
  border: 4px solid #dae7ee;
  display: flex;
  position: relative;
`;

const LabelHolderStyle = styled.label<{ error?: string }>`
  top: -10px;
  background-color: #fff;
  position: absolute;
  margin: 0 16px;
  padding: 0 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${(props) => (props.error ? '#DD3712' : '#000000')};
`;

const Input = styled.div<{ error?: string; height?: number }>`
  height: ${(props) => (props.height ? `${props.height}px` : '46px')};
  width: 100%;
  background: #ffffff;
  border: 1.8px solid ${(props) => (props.error ? '#DD3712' : '#263C47')};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
`;

const InputStyle = styled.input`
  width: -webkit-fill-available;
  height: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 0 16px;
  border-radius: 3px;
  outline: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
  &[type='search']::-webkit-search-cancel-button {
    /*
    *  to avoid appear an "x" on the search bar when
    *  input type is set to search type
    */
    -webkit-appearance: none;
  }
`;

const OnErrorMessage = styled.div`
  margin-top: 4px;
  margin-left: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #dd3712;

  .react-simple-keyboard .hg-button.hg-functionBtn.hg-button-space {
    min-width: 500px !important;
    max-width: none;
  }
`;

const InputButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
`;

const GenericInput: FC<IGenericInput> = ({
  autoFocus = false,
  value,
  label,
  name,
  inputHeight,
  placeholder,
  type = 'text',
  error,
  onBlur,
  onChange,
  onKeyBoardEnter,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      (inputRef.current as { focus: any }).focus();
    }
  }, [autoFocus, inputRef.current]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const input = e.target.value;
    onChange(input);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onBlur && onBlur(e);
  };

  const handleShowPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (inputRef.current!.type === 'password') {
      inputRef.current!.type = 'text';
      setShowPassword(true);
    } else {
      inputRef.current!.type = 'password';
      setShowPassword(false);
    }
  };

  const switchVisibilityIcon = (): JSX.Element => {
    return showPassword === false ? (
      <Visibility sx={{ color: '#3b5a6b', fontSize: '22px' }} />
    ) : (
      <VisibilityOff sx={{ color: '#3b5a6b', fontSize: '22px' }} />
    );
  };

  return (
    <Body>
      <InputOutline>
        <Input error={error} height={inputHeight}>
          <LabelHolderStyle error={error} htmlFor={name || label}>
            {label}
          </LabelHolderStyle>
          <InputStyle
            ref={inputRef}
            name={name || label}
            id={name || label}
            type={type}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onSubmit={() => onKeyBoardEnter && onKeyBoardEnter()}
            placeholder={placeholder}
          />
          {type === 'password' && (
            <InputButton data-testid="show-password" onMouseDown={handleShowPassword}>
              {switchVisibilityIcon()}
            </InputButton>
          )}
          {type === 'search' && (
            <Search
              sx={{ color: '#97B0BD', fontSize: '24px', height: '100%', marginRight: '16px' }}
            />
          )}
        </Input>
      </InputOutline>
      {error && <OnErrorMessage>{t(error)}</OnErrorMessage>}
    </Body>
  );
};

export default GenericInput;
