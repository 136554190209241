import { useState } from 'react';

import { dateTimeUtils } from '../../../../../utils/DateTimeUtils';

const useDateTimeConnector = () => {
  const dateUtils = dateTimeUtils();

  const [time, setTime] = useState<string>('');

  return {
    dateUtils,
    setTime,
    time,
  };
};

export default useDateTimeConnector;
