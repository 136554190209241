import { useState } from 'react';

import { DriverResponse } from '../../../@types/drivers';
import driversService from '../../../services/DriversService';
import { useAuthContext } from '../../AuthContext';

export const useDriversContextConnector = () => {
  const [driversOnShift, setDriversOnShift] = useState<DriverResponse[]>([]);
  const [driversOffShift, setDriversOffShift] = useState<DriverResponse[]>([]);

  return {
    authContext: useAuthContext(),
    driversOffShift,
    driversOnShift,
    driversService,
    setDriversOffShift,
    setDriversOnShift,
  };
};
