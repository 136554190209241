import { Delegate } from '../../../@types/handlers/delegate';
import useNavBarConnector from './connector';

interface INavBarDelegate {
  /**
   * List of active orders that is in pre-schedule status
   */
  scheduledOrdersQuantity: number;
}

type NavBarConnector = ReturnType<typeof useNavBarConnector>;

export class NavBarDelegate extends Delegate<NavBarConnector> implements INavBarDelegate {
  get scheduledOrdersQuantity(): number {
    return this.connector.scheduledOrders.length;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useNavBarDelegate = () => new NavBarDelegate(useNavBarConnector());
