import { FC } from 'react';
import styled from 'styled-components';

import { KanbanCounterColor } from '../../../constants/orderKanban';

interface ICounter {
  type: KanbanCounterColor;
  quantity: number;
  supportTestId: string;
}

const Circle = styled.div<{ type: KanbanCounterColor }>`
  width: 40px;
  height: 28px;
  background: ${(props) => props.type};
  border-radius: 50%;
  justify-content: center;
  display: flex;
`;

const Number = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-left: -1px;
`;

const testId: Record<KanbanCounterColor, string> = {
  [KanbanCounterColor.DANGER]: 'red',
  [KanbanCounterColor.WARNING]: 'yellow',
  [KanbanCounterColor.SUCCESS]: 'green',
  [KanbanCounterColor.REGULAR]: 'gray',
};

const Counter: FC<ICounter> = ({ type, quantity, supportTestId }) => {
  return (
    <Circle type={type} data-testid={`kanban-counter-${testId[type]}-${supportTestId}`}>
      <Number>{quantity}</Number>
    </Circle>
  );
};

export default Counter;
