// 5400000ms is equal to 1.5h
export const REFRESH_TOKEN_POOLING = 5400000;

// 90 is equivalent to 1h and 30min
export const TOKEN_MINIMUM_EXP_TIME = 90;

export enum PermissionTokenActions {
  AUTO_FIRE = 'AUTO_FIRE',
  AUTO_PREPARE = 'AUTO_PREPARE',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CLOSE_ORDER = 'CLOSE_ORDER',
  UPDATE_CAPACITY = 'UPDATE_CAPACITY',
  UPDATE_DRIVER_SHIFT = 'UPDATE_DRIVER_SHIFT',
  UPDATE_ETA = 'UPDATE_ETA',
  PAUSE_RESTAURANT = 'PAUSE_RESTAURANT',
}
