import { Delegate } from '../../../@types/handlers/delegate';
import useComplaintsNotificationConnector from './connector';

type ComplaintsNotificationConnector = ReturnType<typeof useComplaintsNotificationConnector>;

interface IComplaintsNotificationDelegate {
  /**
   * Get all opened complaints notifications
   */
  unseenComplaintNotifications: string[];

  /**
   * Remove a notification from storage and state
   *
   * @param {string} complaintId id of the complaint
   */
  removeComplaintNotificationFromStorage(complaintId: string): void;
}

export class ComplaintsNotificationDelegate
  extends Delegate<ComplaintsNotificationConnector>
  implements IComplaintsNotificationDelegate
{
  get unseenComplaintNotifications(): string[] {
    return this.connector.complaintsContext.unseenComplaintNotifications;
  }

  removeComplaintNotificationFromStorage = (complaintId: string): void => {
    this.connector.complaintsContext.removeComplaintNotificationFromStorage(complaintId);
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useComplaintsNotificationDelegate = () =>
  new ComplaintsNotificationDelegate(useComplaintsNotificationConnector());
