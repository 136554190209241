import React, { createContext, FC, useContext } from 'react';

import GenericModal from './components/GenericModal';
import { IModalContextDelegate, useModalContextDelegate } from './controllers/delegate';

export const ModalContext = createContext<Pick<IModalContextDelegate, 'handleModal'>>({
  handleModal: () => ({}),
});

ModalContext.displayName = 'Modal';

interface IModalProvider {
  children: JSX.Element;
}

const ModalProvider: FC<IModalProvider> = ({ children }) => {
  const { showModal, content, closeBtnColor, handleModal } = useModalContextDelegate();

  return (
    <ModalContext.Provider value={{ handleModal }}>
      <GenericModal
        handleModal={handleModal}
        showModal={showModal}
        content={content}
        closeBtnColor={closeBtnColor}
      />
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export default ModalProvider;
