import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useOrdersContext } from '../../../../../contexts/OrdersContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import ordersService from '../../../../../services/OrdersService';
import { useModalContext } from './../../../../../contexts/ModalContext/index';

const useCloseWithIssueConnector = () => {
  return {
    authContext: useAuthContext(),
    modalContext: useModalContext(),
    ordersContext: useOrdersContext(),
    ordersService,
    toastContext: useToastContext(),
  };
};

export default useCloseWithIssueConnector;
