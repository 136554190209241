import { FC } from 'react';
import styled from 'styled-components';

export interface IOrderInstructions {
  title: string;
  instructions: string;
  dataTestId?: string;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextWeight400 = styled.span`
  font-weight: 400;
`;

const InstructionHeader = styled.span`
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.02em;
`;

const InstructionDescription = styled.div`
  margin-top: 12px;
`;

const EmptyLine = styled.div`
  margin-bottom: 12px;
`;

const OrderInstructions: FC<IOrderInstructions> = ({ title, instructions, dataTestId }) => {
  return (
    <Body>
      <InstructionHeader>{title}</InstructionHeader>
      <InstructionDescription data-testid={dataTestId}>
        <TextWeight400>{instructions}</TextWeight400>
      </InstructionDescription>
      <EmptyLine />
    </Body>
  );
};

export default OrderInstructions;
