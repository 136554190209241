import { ErrorOutline } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrderIssueSectionEvents } from '../../../@types/orders';
import { OrderEventType } from '../../../constants/order';

export interface IOrderIssueSection {
  event: OrderIssueSectionEvents;
  issueDescription: string;
}

const Title = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

const Span = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #dd3712;
`;

const OrderIssueSection: FC<IOrderIssueSection> = ({ event, issueDescription }) => {
  const { t } = useTranslation();

  const eventTitle: Record<OrderIssueSectionEvents, string> = {
    [OrderEventType.ORDER_CANCELLED]: t('pages.OrderManagement.OrderDetails.issues'),
    [OrderEventType.ORDER_FINALIZED]: t('pages.OrderManagement.OrderDetails.cancelled'),
  };

  return (
    <>
      <Div>
        <Title>{eventTitle[event]}</Title>
        <ErrorOutline sx={{ color: '#dd3712', fontSize: '24px' }} />
      </Div>
      <Span>{issueDescription}</Span>
    </>
  );
};

export default OrderIssueSection;
