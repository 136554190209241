import { ReactElement } from 'react';

import { DriverResponse } from '../../../../../@types/drivers';
import { Delegate } from '../../../../../@types/handlers/delegate';
import { SelectOption } from '../../../../../@types/input';
import { CancelOrderPayload, OrderResponse } from '../../../../../@types/orders';
import { IOrderDetailsHeader } from '../../../../../components/orders/OrderDetailsHeader';
import { IOrderHeaderInfo } from '../../../../../components/orders/OrderHeaderInfo';
import { channelNamesObject, ChannelResponses } from '../../../../../constants/delivery';
import { cancelOptionsTranslation, OrderEventType } from '../../../../../constants/order';
import {
  emptyQuadrant,
  orderDetailsHeader,
  orderHeaderInfo,
} from '../../../../../constants/orderDetails';
import { OrderTypeColor } from '../../../../../constants/orderTypeColor';
import { ToastTypes } from '../../../../../constants/toasts';
import { DateFormat } from '../../../../../utils/DateTimeUtils';
import useCancelOrderConnector from './connector';

type CancelOrderConnector = ReturnType<typeof useCancelOrderConnector>;

interface ICancelOrderDelegate {
  /**
   * Cancellation items options
   */
  cancelIssues: SelectOption[] | null;

  /**
   * Issue selected in the radio button
   */
  issueSelected: string;

  /**
   * Order to be cancelled
   */
  orderDetails: OrderResponse | null;

  /**
   * Cancel an order with a given issue
   *
   *  @param {string} orderId id of the order to be cancelled
   *  @param {string} issueId id of the issue for the cancellation
   *  @param {string} permissionToken permission token generated for this specific action
   * @returns {boolean} whether the request was successful or not
   */
  cancelOrder(orderId: string, issueId: string, permissionToken: string): Promise<boolean>;

  /**
   * Format Order Details Header
   *
   * @param {OrderResponse | null} order order response unformatted to be formatted
   * @returns {IOrderDetailsHeader} the order details header formatted
   */
  getFormattedOrderDetailsHeader(order: OrderResponse | null): IOrderDetailsHeader;

  /**
   * Get all OrderHeaderInfo component info formatted
   *
   * @param {OrderResponse | null} order order response unformatted to be formatted
   * @return {IOrderHeaderInfo} formatted object props
   */
  getFormattedHeaderInfo(order: OrderResponse | null): IOrderHeaderInfo;

  /**
   * Fetch options list for order cancellation
   */
  getIssuesList(): Promise<void>;

  /**
   * Fetch order details and format it for displaying
   *
   * @param {string} orderId id of the order to be cancelled
   */
  getOrderDetails(orderId: string): Promise<void>;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal: (content?: ReactElement<any, any>, closeBtnColor?: string) => void;

  /**
   * Set radio button state
   *
   * @param {string} issue id of the issue to be set
   */
  setIssueSelected(issue: string): void;
}

export class CancelOrderDelegate
  extends Delegate<CancelOrderConnector>
  implements ICancelOrderDelegate
{
  get cancelIssues(): SelectOption[] | null {
    return this.connector.cancelIssuesOptions;
  }

  get issueSelected(): string {
    return this.connector.issueSelected;
  }

  get orderDetails(): OrderResponse | null {
    return this.connector.orderDetails;
  }

  cancelOrder = async (
    orderId: string,
    issueId: string,
    permissionToken: string,
  ): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const body: CancelOrderPayload = { cancelReason: issueId };

      await this.connector.ordersService.cancelOrder(
        regionCode,
        brand,
        restaurantId,
        orderId,
        body,
        permissionToken,
      );

      this.handleModal();

      return true;
    } catch (_) {
      this.handleModal();
      this.connector.toastContext.addToast(
        'pages.OrderManagement.CancelOrder.cancelOrderToastError',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  getFormattedHeaderInfo = (order: OrderResponse | null): IOrderHeaderInfo => {
    if (order) {
      const { findEventTime, findLastEvent } = this.connector.ordersUtils;
      const { formatDate, timerCalculator } = this.connector.dateUtils;

      const driver = this.getAllDrivers().find((d) => d.identifier === order.driverId);

      const lastEvent = findLastEvent(order.events)!.name;

      const acceptedEventTime = findEventTime(order.events, order.events[0].name);
      const lastEventTime = findEventTime(order.events, lastEvent);

      return {
        address: `${order.deliveryAddress.formattedAddress}${
          order.deliveryAddress.subpremise ? `, ${order.deliveryAddress.subpremise}` : ''
        }`,
        channel: channelNamesObject[order.channel as ChannelResponses] || order.channel,
        createdDate: formatDate(new Date(order.createdAt), DateFormat.DD_MM_YY),
        createdHour: formatDate(new Date(order.createdAt), DateFormat.HH_MM),
        deliveryInstructions: order.deliveryAddress?.instructions ?? '',
        driverName: (driver && `${driver.firstName} ${driver.lastName}`) ?? '-',
        lastEvent: OrderEventType.ORDER_FINALIZED,
        paymentMethod: order.payment.paymentMethod,
        timerAccepted: '',
        timerColumn: '',
        totalOrderTime: timerCalculator(new Date(acceptedEventTime), new Date(lastEventTime)),
      };
    }

    return orderHeaderInfo;
  };

  getFormattedOrderDetailsHeader = (order: OrderResponse | null): IOrderDetailsHeader => {
    if (order) {
      return {
        channel: channelNamesObject[order.channel as ChannelResponses] || order.channel,
        createdHour: this.connector.dateUtils.formatDate(
          new Date(order.createdAt),
          DateFormat.HH_MM,
        ),
        customerName: order.customer.name,
        customerPhone: order.deliveryAddress.phoneNumber,
        displayOrderId: order.displayOrderId,
        formattedAmountToBePaid: this.connector.moneyUtils.moneyFormatter(
          order.payment.total.amount,
          order.payment.total.currency,
        ),
        paymentMethod: order.payment.paymentMethod,
        quadrant: order.quadrant ?? emptyQuadrant,
        statusColor: OrderTypeColor.CANCELLED,
      };
    }

    return orderDetailsHeader;
  };

  getIssuesList = async (): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const issues = await this.connector.settingsService.getCancelOptions(
        regionCode,
        brand,
        restaurantId,
      );

      const reasonNotAllowed = `${regionCode}-${brand}-${restaurantId}-0004`;

      const cancelIssuesOptions: SelectOption[] = issues
        ?.filter((item) => item.id !== reasonNotAllowed)
        .map((item) => ({
          id: item.id,
          title:
            this.connector.translate(cancelOptionsTranslation[this.formatOptionId(item.id)]) ??
            item.en,
        }));

      this.connector.setCancelIssuesOptions(cancelIssuesOptions);
    } catch (_) {
      this.connector.toastContext.addToast(
        'pages.OrderManagement.CancelOrder.listIssuesOptionsToastError',
        ToastTypes.ERROR,
      );
    }
  };

  getOrderDetails = async (orderId: string): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const orderResponse = await this.connector.ordersService.getOrderDetails(
        regionCode,
        brand,
        restaurantId,
        orderId,
      );

      this.connector.setOrderDetails(orderResponse);
    } catch (_) {
      this.connector.toastContext.addToast(
        'pages.OrderManagement.CancelOrder.getOrderToastError',
        ToastTypes.ERROR,
      );
    }
  };

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  setIssueSelected = (issue: string): void => {
    this.connector.setIssueSelected(issue);
  };

  private formatOptionId = (id: string): string => this.connector.ordersUtils.formatOptionId(id);

  private getAllDrivers = (): DriverResponse[] => {
    const { driversOnShift, driversOffShift } = this.connector.driversContext;

    return [...driversOnShift, ...driversOffShift];
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useCancelOrderDelegate = () => new CancelOrderDelegate(useCancelOrderConnector());
