import { useState } from 'react';

import { useOrdersContext } from '../../../contexts/OrdersContext';
import { useSettingsContext } from '../../../contexts/SettingsContext';
import { dateTimeUtils } from '../../../utils/DateTimeUtils';

export const usePreOrdersConnector = () => {
  const [filterText, setFilterText] = useState<string>('');

  const { scheduledOrders } = useOrdersContext();
  const { preScheduledInterval } = useSettingsContext();

  return {
    dateUtils: dateTimeUtils(),
    filterText,
    preScheduledInterval,
    scheduledOrders,
    setFilterText,
  };
};
