import { FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PermissionTokenActions } from '../../constants/auth';
import GenericButton from '../buttons/GenericButton';
import GenericInput from '../inputs/GenericInput';
import { usePasswordValidationDelegate } from './controllers/delegate';

interface IPasswordValidation {
  title: string;
  action: PermissionTokenActions;
  payload?: any;
  onSuccess: (token: string) => void;
}

const Body = styled.div`
  padding: 32px;
  max-width: 536px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;

const Title = styled.span`
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: left;
`;

const InputSize = styled.div`
  width: 536px;
`;

const ButtonSize = styled.div`
  width: 167px;
  display: flex;
  align-self: flex-end;
`;

const PasswordValidation: FC<IPasswordValidation> = ({ title, action, payload, onSuccess }) => {
  const { t } = useTranslation();

  const { password, errorMessage, setPassword, validatePassword } = usePasswordValidationDelegate();

  const onSubmitForm = async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
    e && e.preventDefault();
    const token = await validatePassword(action, payload);
    if (token) {
      onSuccess(token);
    }
  };

  return (
    <Body>
      <Title>{title}</Title>
      <form onSubmit={onSubmitForm}>
        <InputSize>
          <GenericInput
            type="password"
            name="password"
            label={t('pages.Login.LoginForm.Password')}
            value={password}
            onChange={(e) => setPassword(e)}
            onKeyBoardEnter={() => onSubmitForm()}
            autoFocus
            error={errorMessage}
            inputHeight={48}
          />
        </InputSize>
      </form>
      <ButtonSize>
        <GenericButton title={t('utils.buttons.confirmButton')} onClick={() => onSubmitForm()} />
      </ButtonSize>
    </Body>
  );
};

export default PasswordValidation;
