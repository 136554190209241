import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import { useComplaintsDelegate } from './controllers/delegate';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #878787;
  letter-spacing: 0.06em;
`;

const blinkRed = keyframes`
  0%, 100% { color: initial; } /* Cor atual */
  50% { color: red; } /* Vermelho */
`;

interface ParagraphProps {
  blinking?: boolean;
}

const Paragraph = styled.p<ParagraphProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #878787;
  margin: 0px;
  min-width: max-content;
  ${({ blinking }) =>
    blinking &&
    css`
      animation: ${blinkRed} 0.5s infinite;
    `};
`;

const Complaints: FC = () => {
  const { t } = useTranslation();
  const { closed, opened } = useComplaintsDelegate();

  return (
    <Container>
      <Title>{t('components.PageHeader.Complaints.title')}</Title>
      <div>
        <Paragraph blinking={opened > 0} data-testid="complaints-quantity-opened">
          {opened} {t('components.PageHeader.Complaints.new')}
        </Paragraph>
        <Paragraph data-testid="complaints-quantity-closed">
          {closed} {t('components.PageHeader.Complaints.closed')}
        </Paragraph>
      </div>
    </Container>
  );
};

export default Complaints;
