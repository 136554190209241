import { FC } from 'react';
import styled from 'styled-components';

interface ICardButton {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSwitchedOn?: boolean;
  isDisabled?: boolean;
  children: JSX.Element;
  activeColor?: string;
  disabledColor?: string;
  borderColor?: string;
}

const CardButtonStyle = styled.div<{
  isDisabled: boolean;
  disabledColor: string;
  isSwitchedOn?: boolean;
  activeColor?: string;
  borderColor?: string;
}>`
  width: 130px;
  height: 76px;
  background: ${(props) => (props.isSwitchedOn ? props.activeColor : props.disabledColor)};
  border: 1px solid ${(props) => props.borderColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};

  ${(props) => (props.isDisabled ? '' : '&:hover {opacity: 0.9; }')};
`;

const Div = styled.div`
  padding: 8px 13px;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardButton: FC<ICardButton> = ({
  isSwitchedOn,
  onClick,
  children,
  isDisabled = false,
  activeColor,
  disabledColor = '#ffffff',
  borderColor,
}) => {
  const getBorderColor = () => {
    if (!isSwitchedOn) {
      return '#507083';
    }

    const defaultBorderColor = '#79BF1F';
    return isSwitchedOn && borderColor ? borderColor : defaultBorderColor;
  };

  return (
    <CardButtonStyle
      isSwitchedOn={isSwitchedOn}
      onClick={isDisabled ? undefined : onClick}
      isDisabled={isDisabled}
      activeColor={activeColor}
      disabledColor={disabledColor}
      borderColor={getBorderColor()}
    >
      <Div>{children}</Div>
    </CardButtonStyle>
  );
};

export default CardButton;
