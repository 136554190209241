import { createContext, FC, useContext, useEffect } from 'react';

import { PagePath } from '../../constants/pages';
import { IComplaintsContextDelegate, useComplaintsContextDelegate } from './controllers/delegate';

export const ComplaintsContext = createContext<
  Pick<
    IComplaintsContextDelegate,
    | 'closedComplaints'
    | 'forceUpdate'
    | 'openedComplaints'
    | 'unseenComplaintNotifications'
    | 'removeComplaintNotificationFromStorage'
  >
>({
  closedComplaints: [],
  forceUpdate: () => Promise.resolve(),
  openedComplaints: [],
  removeComplaintNotificationFromStorage: () => ({}),
  unseenComplaintNotifications: [],
});

ComplaintsContext.displayName = 'Complaints';

interface IComplaintsProvider {
  children: JSX.Element;
}

const ComplaintsProvider: FC<IComplaintsProvider> = ({ children }) => {
  const {
    currentPath,
    currentPathRef,
    openedComplaints,
    openedComplaintsRef,
    closedComplaints,
    closedComplaintsRef,
    setComplaintNotifications,
    forceUpdate,
    notificationsRef,
    subscribeToWebsocket,
    unsubscribeToWebsocket,
    unseenComplaintNotifications,
    syncComplaintsNotification,
    retrieveComplaintsFromApi,
    removeComplaintNotificationFromStorage,
  } = useComplaintsContextDelegate();

  useEffect(() => {
    subscribeToWebsocket();

    return () => {
      unsubscribeToWebsocket();
    };
  }, []);

  useEffect(() => {
    retrieveComplaintsFromApi();
  }, []);

  useEffect(() => {
    closedComplaintsRef.current = closedComplaints;
    syncComplaintsNotification();
  }, [closedComplaints]);

  useEffect(() => {
    notificationsRef.current = unseenComplaintNotifications;
  }, [unseenComplaintNotifications]);

  useEffect(() => {
    openedComplaintsRef.current = openedComplaints;
  }, [openedComplaints]);

  useEffect(() => {
    currentPathRef.current = currentPath;

    if (currentPath === `/${PagePath.COMPLAINTS}`) {
      setComplaintNotifications(new Array<string>());
      syncComplaintsNotification();
    }
  }, [currentPath]);

  return (
    <ComplaintsContext.Provider
      value={{
        closedComplaints,
        forceUpdate,
        openedComplaints,
        removeComplaintNotificationFromStorage,
        unseenComplaintNotifications,
      }}
    >
      {children}
    </ComplaintsContext.Provider>
  );
};

export const useComplaintsContext = () => useContext(ComplaintsContext);

export default ComplaintsProvider;
