import { ComplaintEventType } from '../constants/complaints';
import { LastMileEventType } from '../constants/lastMile';
import { OrderEventType } from '../constants/order';
import { RestaurantEventType } from '../constants/restaurants';
import { ComplaintsResponse } from './complaints';
import { LastMileDelivery } from './lastMile';
import { OrderResponse } from './orders';
import { Restaurant } from './restaurants';

export enum WebsocketTopic {
  ORDER_EVENT = 'orderEvent',
  STORE_EVENT = 'storeEvent',
  LAST_MILE_EVENT = 'lastMileEvent',
  COMPLAINTS_EVENT = 'complaintsEvent',
}

export interface WebsocketEvent {
  eventName: OrderEventType | RestaurantEventType | LastMileEventType | ComplaintEventType;
  payload: OrderResponse | Restaurant | LastMileDelivery | ComplaintsResponse;
  timestamp: string;
}

export interface NotifyLastMileUpdate extends WebsocketEvent {
  eventName: LastMileEventType;
  payload: LastMileDelivery;
}

export interface NotifyOrderQueue extends WebsocketEvent {
  eventName: OrderEventType;
  payload: OrderResponse;
}

export interface RestaurantEvent extends WebsocketEvent {
  eventName: RestaurantEventType;
  payload: Restaurant;
}

export interface NotifyComplaintEvent extends WebsocketEvent {
  eventName: ComplaintEventType;
  payload: ComplaintsResponse;
}
