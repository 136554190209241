import { Delegate } from '../../../@types/handlers/delegate';
import usePageHeaderConnector from './connector';

interface IPageHeaderDelegate {
  /**
   * If restaurant is configured to allow managers to pause the service
   */
  allowPauseService: boolean;

  /**
   * If restaurant is configured to allow managers use the auto fire feature
   */
  allowAutoFire: boolean;
}

type PageHeaderConnector = ReturnType<typeof usePageHeaderConnector>;

export class PageHeaderDelegate
  extends Delegate<PageHeaderConnector>
  implements IPageHeaderDelegate
{
  get allowPauseService(): boolean {
    return this.connector.allowPauseService;
  }

  get allowAutoFire(): boolean {
    return this.connector.allowAutoFire;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const usePageHeaderDelegate = () => new PageHeaderDelegate(usePageHeaderConnector());
