import { ReactElement } from 'react';

import { Delegate } from '../../../@types/handlers/delegate';
import { useModalContextConnector } from './connector';

export interface IModalContextDelegate {
  /**
   * Get message to show in modal
   */
  content: ReactElement<any, any> | undefined;

  /**
   * Get true if the modal will show or false otherwise
   */
  showModal: boolean;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal: (content?: ReactElement<any, any>, closeBtnColor?: string) => void;

  /**
   * Receives the color of the close button if it is different than black
   */
  closeBtnColor: string;
}

type ModalContextConnector = ReturnType<typeof useModalContextConnector>;

export class ModalContextDelegate
  extends Delegate<ModalContextConnector>
  implements IModalContextDelegate
{
  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string): void => {
    this.connector.setContent(content);
    this.connector.setCloseBtnColor(closeBtnColor || '#000000');
  };

  get closeBtnColor(): string {
    return this.connector.closeBtnColor;
  }

  get content(): ReactElement<any, any> | undefined {
    return this.connector.content;
  }

  get showModal(): boolean {
    return !!this.connector.content;
  }
}

export const useModalContextDelegate = () => new ModalContextDelegate(useModalContextConnector());
