import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useFloatingCardContext } from '../../../../../contexts/FloatingCardContext';

const useSettingsButtonConnector = () => {
  return {
    authContext: useAuthContext(),
    floatingCardContext: useFloatingCardContext(),
  };
};

export default useSettingsButtonConnector;
