import { MenuItem, MenuItemProps } from '@mui/material';

interface ItemProps extends MenuItemProps {}

export const ButtonMenuItem = ({ children, ...rest }: ItemProps) => {
  return (
    <MenuItem
      {...rest}
      style={{
        border: '1px solid #97B0BD',
        color: '#3B5A6B',
        fontFamily: 'Work Sans',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '26px',
        minHeight: '50px',
        whiteSpace: 'normal',
        ...rest.style,
      }}
    >
      {children}
    </MenuItem>
  );
};
