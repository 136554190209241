import { useComplaintsContext } from '../../../../../contexts/ComplaintsContext';

const useComplaintsConnector = () => {
  const { openedComplaints, closedComplaints } = useComplaintsContext();

  return {
    closedComplaints,
    openedComplaints,
  };
};

export default useComplaintsConnector;
