import { Quadrant } from '../@types/quadrant';
import { IOrderIssueSection } from '../components/OrderDetails/components/OrderIssueSection';
import { IOrderDetailsHeader } from '../components/orders/OrderDetailsHeader';
import { IOrderHeaderInfo } from '../components/orders/OrderHeaderInfo';
import { IOrderSummary } from '../components/orders/OrderSummary';
import { IRestaurantInfo } from '../pages/Prints/OrderDetailsPrint/components/RestaurantInfo';
import { OrderEventType } from './order';
import { OrderTypeColor } from './orderTypeColor';

export enum ButtonFunctionClick {
  CLICK_FIRST = 'onClickFirstButton',
  CLICK_SECOND = 'onClickSecondButton',
}

export const orderDetailsHeader: IOrderDetailsHeader = {
  channel: '',
  createdHour: '',
  customerName: '',
  customerPhone: '',
  displayOrderId: '',
  formattedAmountToBePaid: '',
  paymentMethod: '',
  quadrant: { name: '' },
  statusColor: OrderTypeColor.DEFAULT,
};

export const orderHeaderInfo: IOrderHeaderInfo = {
  address: '',
  channel: '',
  paymentMethod: '',
};

export const orderSummary: IOrderSummary = {
  cartSummary: [],
};

export const orderIssueSection: IOrderIssueSection = {
  event: OrderEventType.ORDER_CANCELLED,
  issueDescription: '-',
};

export const orderRestaurantInfo: IRestaurantInfo = {
  identifier: '-',
  info: '-',
};

export const emptyQuadrant: Quadrant = {
  name: '',
};
