import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ISvgIcon {
  children: ReactNode;
  color?: string;
  width: number;
  height: number;
}

const SvgIconWrapper = styled.div<{ color?: string; height: number; width: number }>`
  display: flex;
  svg {
    ${(props) => props.color && `fill: ${props.color};`}
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
  }
`;

const SvgIcon: FC<ISvgIcon> = ({ children, color, width, height }) => {
  return (
    <SvgIconWrapper color={color} height={height} width={width}>
      {children}
    </SvgIconWrapper>
  );
};

export default SvgIcon;
