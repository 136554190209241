import { FC, useEffect } from 'react';

interface IPrintPage {
  iframeId: string;
  src: string;
  onLoad: () => void;
  onReload: () => void;
}

/**
 * When using this component, if you want to print a given page, you will need to add the:
 *
 * `parent.postMessage('print-page-loaded');`
 *
 * After this page is loaded. The `postMessage` action will be listened by the `handleMessage` method
 * and it will trigger the `onLoaded` method from props.
 */
const PrintPage: FC<IPrintPage> = ({ iframeId, src, onLoad, onReload }) => {
  const handleMessage = (event: MessageEvent<WindowPostMessageOptions>): void => {
    if ((event.data as string) === `print-page-loaded__${iframeId}`) {
      onLoad();
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => onReload(), [src]);

  return <iframe id={iframeId} src={src} style={{ display: 'none' }} />;
};

export default PrintPage;
