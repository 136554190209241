import { Delegate } from '../../../../../@types/handlers/delegate';
import { ComplaintStatus } from '../../../../../constants/complaints';
import useComplaintsConnector from './connector';

interface IComplaintsDelegate {
  /**
   * Closed complaints
   */
  closed: number;

  /**
   * Opened complaints
   */
  opened: number;
}

type ComplaintsConnector = ReturnType<typeof useComplaintsConnector>;

export class ComplaintsDelegate
  extends Delegate<ComplaintsConnector>
  implements IComplaintsDelegate
{
  get closed(): number {
    return this.connector.closedComplaints.filter(({ status }) => status === ComplaintStatus.CLOSED)
      .length;
  }

  get opened(): number {
    return this.connector.openedComplaints.filter(({ status }) => status === ComplaintStatus.OPEN)
      .length;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useComplaintsDelegate = () => new ComplaintsDelegate(useComplaintsConnector());
