import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDriversContext } from '../../../../../contexts/DriversContext';
import { useModalContext } from '../../../../../contexts/ModalContext';
import { useOrdersContext } from '../../../../../contexts/OrdersContext';
import { useSettingsContext } from '../../../../../contexts/SettingsContext';
import { useSidebarContext } from '../../../../../contexts/SidebarContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import ordersService from '../../../../../services/OrdersService';

const useOrderDetailsFooterConnector = () => {
  return {
    authContext: useAuthContext(),
    driversContext: useDriversContext(),
    modalContext: useModalContext(),
    ordersContext: useOrdersContext(),
    ordersService,
    settingsContext: useSettingsContext(),
    sidebarContext: useSidebarContext(),
    toastContext: useToastContext(),
  };
};

export default useOrderDetailsFooterConnector;
