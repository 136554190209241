import { ReactElement } from 'react';

import { ActualETAResponse } from '../../../../../@types/eta';
import { Delegate } from '../../../../../@types/handlers/delegate';
import { OrderTypeColor } from '../../../../../constants/orderTypeColor';
import { ToastTypes } from '../../../../../constants/toasts';
import { IFloatingCardPosition } from '../../../../../contexts/FloatingCardContext/components/GenericFloatingCard';
import useDeliveryTimeConnector from './connector';

interface IDeliveryTimeDelegate {
  /**
   * Actual ETA object for a restaurant
   */
  actualEtaInfo: ActualETAResponse | null;

  /**
   * ETA for a restaurant
   */
  eta: number | null;

  /**
   * Get actual eta color based on settings context
   *
   * @param {number | undefined} actualEta actual eta value to calculate
   * @returns {string} color hex code
   */
  getActualEtaColor(actualEta: number | undefined): string;

  /**
   * Get actual eta formatted value
   *
   * @param {number | undefined} actualEta actual eta value to calculate
   * @returns {string} eta string value formatted
   */
  getActualEtaValue(actualEta: number | undefined): string;

  /**
   * Handle when the floating card should be opened or closed
   * @param {ReactElement<any, any> | undefined} content content to be show in floating card
   * @param {IFloatingCardPosition} position the floating card position on the screen
   * @param {string[]} dependencies ist of ids as dependency to not close the floating card
   */
  handleFloatingCard(
    content?: ReactElement<any, any>,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ): void;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;

  /**
   * Call service to update the ETA
   *
   * @param {number} eta eta number to update
   * @param {string} token permission token to authorize the update
   * @return {boolean} true if update was made with success or false otherwise
   */
  updateEta(eta: number, token: string): Promise<boolean>;

  /**
   * Retrieve eta settings in restaurant
   *
   * @return {NodeJS.Timer | undefined} setTimeout timer reference or undefined on error
   */
  retrieveEtaSettings(): Promise<NodeJS.Timer | undefined>;
}

type DeliveryTimeConnector = ReturnType<typeof useDeliveryTimeConnector>;

export class DeliveryTimeDelegate
  extends Delegate<DeliveryTimeConnector>
  implements IDeliveryTimeDelegate
{
  get actualEtaInfo(): ActualETAResponse | null {
    return this.connector.settingsContext.actualEtaInfo;
  }

  get eta(): number | null {
    return this.connector.settingsContext.eta;
  }

  getActualEtaColor = (actualEta: number | undefined): string => {
    if (!actualEta) return OrderTypeColor.DEFAULT;

    if (this.connector.settingsContext.colors) {
      const { order } = this.connector.settingsContext.colors;

      if (!order) return OrderTypeColor.DEFAULT;

      if (actualEta <= order.green.max) {
        return OrderTypeColor.SUCCESS;
      } else if (actualEta <= order.yellow.max) {
        return OrderTypeColor.WARNING;
      } else {
        return OrderTypeColor.DANGER;
      }
    }

    return OrderTypeColor.DEFAULT;
  };

  getActualEtaValue = (actualEta: number | undefined): string => {
    return actualEta ? `${actualEta}'` : '-';
  };

  handleFloatingCard = (
    content?: ReactElement<any, any>,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ) => {
    return this.connector.floatingCardContext.handleFloatingCard(content, position, dependencies);
  };

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string) => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  retrieveEtaSettings = async (): Promise<NodeJS.Timer | undefined> => {
    const interval = 60000;
    await this.getEtaSettings();

    return setInterval(() => this.getEtaSettings(), interval);
  };

  updateEta = async (eta: number, token: string): Promise<boolean> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const body = {
        eta,
      };

      await this.connector.settingsService.updateEtaByRestaurant(
        regionCode,
        brand,
        restaurantId,
        token,
        body,
      );
      this.connector.settingsContext.setEta(eta);
      this.handleModal();

      return true;
    } catch (err) {
      this.connector.toastContext.addToast(
        'components.PageHeader.DeliveryTime.EtaList.updateEtaListToastError',
        ToastTypes.ERROR,
      );
      return false;
    }
  };

  private getEtaSettings = async (): Promise<void> => {
    try {
      const { regionCode, brand, restaurantId } = this.connector.authContext.user!;

      const eta: number = await this.connector.settingsService.getEtaByRestaurant(
        regionCode,
        brand,
        restaurantId,
      );

      this.connector.settingsContext.setEta(eta);
    } catch {
      return;
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useDeliveryTimeDelegate = () => new DeliveryTimeDelegate(useDeliveryTimeConnector());
