import { Pause, PlayArrowRounded } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PermissionTokenActions } from '../../../../constants/auth';
import { StatusModalType } from '../../../../constants/modalType';
import { RestaurantServiceStatus } from '../../../../constants/restaurants';
import PasswordValidation from '../../../PasswordValidation';
import CardButton from '../../../buttons/CardButton';
import ResponseStatusModal from '../../../modals/ResponseStatusModal';
import PauseServiceModal from './components/PauseServiceModal';
import { useServiceActiveDelegate } from './controllers/delegate';

const Div = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span<{ isSwitchedOn: boolean }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => (props.isSwitchedOn ? '#387F49' : '#D14545')};
  width: 81px;
  letter-spacing: 0.06em;
`;

const CardButtonContainer = styled.div`
  margin-left: 20px;
`;

const ServiceActive: FC = () => {
  const { t } = useTranslation();

  const { pos, serviceStatus, handleModal, resumeService } = useServiceActiveDelegate();

  const handleCardClick = async () => {
    switch (serviceStatus) {
      case RestaurantServiceStatus.RESTAURANT_AVAILABLE:
        return handlePauseService();
      case RestaurantServiceStatus.RESTAURANT_PAUSED:
        return handleResumeService();
      default:
        return Promise.resolve();
    }
  };

  const handlePauseService = (): void => {
    const openPauseModal = (token: string): void =>
      handleModal(<PauseServiceModal token={token} />);

    handleModal(
      <PasswordValidation
        title={t('components.PageHeader.ServiceActive.PasswordValidation.titleToPause')}
        action={PermissionTokenActions.PAUSE_RESTAURANT}
        onSuccess={openPauseModal}
      />,
    );
  };

  const handleResumeService = async (): Promise<void> => {
    await resumeService();

    handleModal(
      <ResponseStatusModal
        message={t('components.PageHeader.ServiceActive.successOnResume')}
        onClick={() => handleModal()}
        type={StatusModalType.SUCCESS}
      />,
      '#FFFFFF',
    );
  };

  const isServiceAvailable: boolean =
    serviceStatus === RestaurantServiceStatus.RESTAURANT_AVAILABLE;

  const isDisabled = !pos || serviceStatus === RestaurantServiceStatus.RESTAURANT_PAUSED_BY_CORP;

  return (
    <CardButtonContainer>
      <CardButton
        onClick={handleCardClick}
        isDisabled={isDisabled}
        isSwitchedOn={!isServiceAvailable}
        borderColor={!isServiceAvailable ? '#387F49' : '#D14545'}
        activeColor={'#D1FAE5'}
        disabledColor={'#FFE7E7'}
      >
        <Div>
          <Title isSwitchedOn={!isServiceAvailable} data-testid="service-active">
            {!isServiceAvailable
              ? t('components.PageHeader.ServiceActive.title')
              : t('components.PageHeader.ServiceActive.titlePaused')}
          </Title>
          {isServiceAvailable ? (
            <Pause sx={{ color: '#507083', height: '32px', width: '32px' }} />
          ) : (
            <PlayArrowRounded sx={{ color: '#387F49', fontSize: '40px' }} />
          )}
        </Div>
      </CardButton>
    </CardButtonContainer>
  );
};

export default ServiceActive;
