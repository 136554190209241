import { DriverResponse } from '../@types/drivers';
import { GenericTableData, GenericTableDataOptions } from '../@types/genericTable';
import { OrderEvent, OrderResponse } from '../@types/orders';
import { CancelOption, OrderIssue, SettingsColorsResponse } from '../@types/settings';
import { channelNamesObject, ChannelResponses } from '../constants/delivery';
import { DeliveryMode } from '../constants/deliveryMode';
import { OrderTypeColor } from '../constants/orderTypeColor';
import { DateFormat, dateTimeUtils } from './DateTimeUtils';

interface IOrderHistoryUtils {
  /**
   * Calculate color to be shown
   *
   * @param {SettingsColorsResponse} colorConfig configuration of colors to be shown when a specific amount of time is reached between order accepted and current time
   * @param {number} totalOrderTimeSeconds total amount of time in seconds
   * @returns {OrderTypeColor} color to be shown
   */
  calculateColor(
    colorConfig: SettingsColorsResponse,
    totalOrderTimeSeconds: number,
  ): OrderTypeColor;

  /**
   * Format a data into an object of type GenericTableData
   *
   * @param {string | number | JSX.Element} data information to be converted to GenericTableData
   * @returns {GenericTableData} generic table data object
   */
  createGenericData(data: string | number | JSX.Element): GenericTableData;

  /**
   * Format created at date string to match dd/mm hh:mm:ss
   *
   * @param {string} createdAt order creation date string
   * @returns {GenericTableData} generic table data with the formatted date string
   */
  formatCreatedAt(createdAt: string): GenericTableData;

  /**
   * Format driver information returning first and last name as string
   *
   * @param {DriverResponse[]} drivers drivers information
   * @param {OrderResponse} order order response
   * @returns {GenericTableData} generic table data with the driver name formatted as string
   */
  formatDriverName(drivers: DriverResponse[], order: OrderResponse): GenericTableData;

  /**
   * Format issue information as string
   *
   * @param {OrderIssue[]} options order issues information
   * @param {string[] | undefined} issue issue messages and issue id
   * @returns {GenericTableData} generic table data with the issue message as string
   */
  formatIssue(options: OrderIssue[], issue?: string[]): GenericTableData;

  /**
   * Format reason information as string
   *
   * @param {CancelOption[]} options order reason information
   * @param {string[] | undefined} reason messages and reason id
   * @returns {GenericTableData} generic table data with the issue message as string
   */
  formatReason(options: CancelOption[], reason?: string[]): GenericTableData;

  /**
   * Receives array of order events and calculates time since first event until last event
   *
   * @param {OrderEvent[]} events amount of currency as string
   * @returns {GenericTableData} generic table data with the value information with currency symbol
   */
  totalOrderTime(events: OrderEvent[]): GenericTableData;
}

const dateUtils = dateTimeUtils();

export class OrderHistoryUtils implements IOrderHistoryUtils {
  calculateColor = (
    colorConfig: SettingsColorsResponse,
    totalOrderTimeSeconds: number,
  ): OrderTypeColor => {
    const secondsInMinute = 60;

    if (totalOrderTimeSeconds >= colorConfig.order.red.min * secondsInMinute) {
      return OrderTypeColor.DANGER;
    } else if (totalOrderTimeSeconds >= colorConfig.order.yellow.min * secondsInMinute) {
      return OrderTypeColor.WARNING;
    }

    return OrderTypeColor.SUCCESS;
  };

  createGenericData = (
    data: string | number | JSX.Element,
    options?: GenericTableDataOptions,
  ): GenericTableData => ({
    data,
    options,
  });

  formatChannel = (channel: string): GenericTableData => {
    return this.createGenericData(channelNamesObject[channel as ChannelResponses] || channel);
  };

  formatCreatedAt = (createdAt: string): GenericTableData => {
    return this.createGenericData(
      dateUtils.formatDate(new Date(createdAt), DateFormat.DD_MM_HH_MM_SS),
    );
  };

  formatDriverName = (drivers: DriverResponse[], order: OrderResponse): GenericTableData => {
    if (order.lastMileDeliveryId && order.lastMileProvider) {
      return this.createGenericData(order.lastMileProvider);
    }

    if (order.deliveryMode === DeliveryMode.MANAGED_EXTERNALLY) {
      return this.createGenericData(
        channelNamesObject[order.channel as ChannelResponses] || order.channel,
      );
    }

    const driver = drivers.find((item) => item.identifier === order.driverId);

    return this.createGenericData(driver ? `${driver.firstName} ${driver.lastName}` : '-');
  };

  formatIssue = (options: OrderIssue[], issue?: string[]): GenericTableData => {
    if (!issue) return this.createGenericData('-');

    const orderIssue = options.find((item) => item.id === issue[0])?.en ?? issue[0];

    return this.createGenericData(orderIssue ?? '-');
  };

  formatReason = (options: CancelOption[], issue?: string[]): GenericTableData => {
    if (!issue) return this.createGenericData('-');

    const issueReason = options.find((item) => item.id === issue[0])?.en ?? issue[0];

    return this.createGenericData(issueReason);
  };

  totalOrderTime = (events: OrderEvent[]): GenericTableData => {
    const firstEventTime = events[0].time;
    const lastEventTime = events[events.length - 1].time;

    return this.createGenericData(
      dateUtils.timerCalculator(new Date(firstEventTime), new Date(lastEventTime)),
    );
  };
}

export const orderHistoryUtils = () => new OrderHistoryUtils();
