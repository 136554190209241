import { DriverResponse } from '../@types/drivers';
import { LastMileDelivery } from '../@types/lastMile';
import { OrderDetailsFormatted, OrderEvent, OrderResponse } from '../@types/orders';
import { OrdersQuantities } from '../@types/ordersManagement';
import { SettingsColorsResponse } from '../@types/settings';
import { ColorConfigKeys } from '../constants/colorConfig';
import { channelNamesObject, ChannelResponses } from '../constants/delivery';
import { OrderEventType } from '../constants/order';
import { OrderTypeColor } from '../constants/orderTypeColor';
import { ICard } from '../pages/OrdersManagement/components/Card';
import { dateTimeUtils } from './DateTimeUtils';
import { moneyUtils } from './MoneyUtils';
import { ordersUtils } from './OrdersUtils';

interface IOrdersManagementUtils {
  /**
   * Format order response to card details
   *
   * @param {OrderResponse} order order to be formatted
   * @param {OrderEventType} baseEvent order base event to calculate timer and color
   * @param {SettingsColorsResponse} colorConfig color configuration for restaurant
   * @param {ColorConfigKeys} colorKey color configuration key to format order
   * @param {DriverResponse[]} drivers list of possible driver for order
   *
   * @returns {ICard} card formatted
   */
  formatToCard(
    order: OrderResponse,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
    drivers: DriverResponse[],
  ): ICard;

  /**
   * Format card details for pooling on kanban - only calculate timer and color
   *
   * @param {ICard} card card to be formatted
   * @param {OrderEventType} baseEvent order base event to calculate timers and color
   * @param {SettingsColorsResponse} colorConfig color configuration for restaurant
   * @param {ColorConfigKeys} colorKey color configuration key to format order
   *
   * @returns {ICard} card formatted
   */
  formatToCardPooling(
    card: ICard,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
    drivers: DriverResponse[],
  ): ICard;

  /**
   * Format order response to order details formatted
   *
   * @param {OrderResponse} order order to be formatted
   * @param {OrderEventType} baseEvent order base event to calculate timer and color
   * @param {SettingsColorsResponse} colorConfig color configuration for restaurant
   * @param {ColorConfigKeys} colorKey color configuration key to format order
   *
   * @returns {OrderDetailsFormatted} order details formatted
   */
  formatToOrderDetailsFormatted(
    order: OrderResponse,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
  ): OrderDetailsFormatted;

  /**
   * Get quantities of dangers, warnings and success cards based on the colors
   *
   * @param {ICard[]} cards orders cards to quantify in the header of the column
   * @returns {OrdersQuantities} quantities calculated
   */
  getKanbanHeaderQuantities(cards: ICard[]): OrdersQuantities;
}

export class OrdersManagementUtils implements IOrdersManagementUtils {
  formatToCard = (
    order: OrderResponse,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
    drivers: DriverResponse[],
    lastMile?: LastMileDelivery,
  ): ICard => {
    const formatted = this.formatOrderResponseToCard(order);

    const driver = drivers.find(({ identifier }) => identifier === order.driverId);

    return {
      driver,
      lastMile,
      ...formatted,
      ...this.formatToCardPooling(formatted, baseEvent, colorConfig, colorKey),
    };
  };

  formatToCardPooling = (
    card: ICard,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
  ): ICard => {
    return {
      ...card,
      ...this.calculateColor(card.events, baseEvent, colorConfig, colorKey),
      ...this.calculateTimers(card.events, baseEvent),
    };
  };

  formatToOrderDetailsFormatted = (
    order: OrderResponse,
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
  ): OrderDetailsFormatted => {
    if (baseEvent === OrderEventType.ORDER_PRE_SCHEDULED) return { ...order };

    return {
      ...order,
      ...this.calculateColor(order.events, baseEvent, colorConfig, colorKey),
      ...this.calculateTimers(order.events, baseEvent),
    };
  };

  getKanbanHeaderQuantities = (cards: ICard[]): OrdersQuantities => {
    const getOrdersQuantity = (cards: ICard[], colorType: OrderTypeColor) =>
      cards.filter((o) => o.statusColor === colorType).length;

    return {
      danger: getOrdersQuantity(cards, OrderTypeColor.DANGER),
      success: getOrdersQuantity(cards, OrderTypeColor.SUCCESS),
      warning: getOrdersQuantity(cards, OrderTypeColor.WARNING),
    };
  };

  private calculateColor = (
    events: OrderEvent[],
    baseEvent: OrderEventType,
    colorConfig: SettingsColorsResponse,
    colorKey: ColorConfigKeys,
  ): { statusColor: OrderTypeColor } => {
    const dateUtils = dateTimeUtils();
    const orderUtil = ordersUtils();

    if (!events.length) throw new Error('Order has no events registered');

    let statusColor: OrderTypeColor;

    if (
      baseEvent === OrderEventType.ORDER_CANCELLED ||
      baseEvent === OrderEventType.ORDER_FINALIZED
    ) {
      statusColor = OrderTypeColor.CANCELLED;
    } else {
      const secondsInMinute = 60;

      if (baseEvent === OrderEventType.ORDER_DELIVERED) {
        const totalOrderTimeSeconds = dateUtils.timeDifference(
          new Date(orderUtil.findEventTime(events, baseEvent)),
          new Date(orderUtil.findEventTime(events, OrderEventType.ORDER_ACCEPTED)),
          'seconds',
        );

        if (totalOrderTimeSeconds >= colorConfig.order.red.min * secondsInMinute) {
          statusColor = OrderTypeColor.DANGER;
        } else if (totalOrderTimeSeconds >= colorConfig.order.yellow.min * secondsInMinute) {
          statusColor = OrderTypeColor.WARNING;
        } else {
          statusColor = OrderTypeColor.SUCCESS;
        }
      } else {
        const now = new Date();

        const columnTime = dateUtils.timeDifference(
          now,
          new Date(orderUtil.findEventTime(events, baseEvent)),
          'seconds',
        );
        const acceptedTime = dateUtils.timeDifference(
          now,
          new Date(orderUtil.findEventTime(events, OrderEventType.ORDER_ACCEPTED)),
          'seconds',
        );

        const columnColorConfig = colorConfig.kanban[colorKey];

        if (colorConfig.order.green.max * secondsInMinute >= acceptedTime) {
          if (columnColorConfig.green.max * secondsInMinute >= columnTime) {
            statusColor = OrderTypeColor.SUCCESS;
          } else if (columnColorConfig.yellow.max * secondsInMinute >= columnTime) {
            statusColor = OrderTypeColor.WARNING;
          } else {
            statusColor = OrderTypeColor.DANGER;
          }
        } else if (colorConfig.order.yellow.max * secondsInMinute >= acceptedTime) {
          if (columnColorConfig.yellow.max * secondsInMinute >= columnTime) {
            statusColor = OrderTypeColor.WARNING;
          } else {
            statusColor = OrderTypeColor.DANGER;
          }
        } else {
          statusColor = OrderTypeColor.DANGER;
        }
      }
    }

    return {
      statusColor,
    };
  };

  private calculateTimers = (
    events: OrderEvent[],
    baseEvent: OrderEventType,
  ): { timerAccepted: string; timerColumn: string } => {
    const dateUtils = dateTimeUtils();
    const orderUtil = ordersUtils();
    const position = 2;

    //will not contain accepted event if it is in ORDER_PRE_SCHEDULED step or if it was canceled when it was still in ORDER_PRE_SCHEDULED
    const containsAcceptedEvent = events.some(
      (event) => event.name === OrderEventType.ORDER_ACCEPTED,
    );

    //orders MANAGED_EXTERNALLY that is closed with issues by aggregators, could not contain ORDER_IN_DELIVERY event
    const containsOnDeliveryEvent = events.some(
      (event) => event.name === OrderEventType.ORDER_IN_DELIVERY,
    );

    let lastEventToTrack = OrderEventType.ORDER_ACCEPTED;

    if (!events.length) throw new Error('Order has no events registered');

    switch (baseEvent) {
      case OrderEventType.ORDER_ACCEPTED:
        lastEventToTrack = OrderEventType.ORDER_ACCEPTED;
        break;
      case OrderEventType.ORDER_FINALIZED:
        lastEventToTrack = events[events.length - position].name;
        break;
      case OrderEventType.ORDER_IN_PREPARATION:
      case OrderEventType.DRIVER_ASSIGNED_TO_ORDER:
      case OrderEventType.ORDER_PREPARED:
        lastEventToTrack = OrderEventType.ORDER_IN_PREPARATION;
        break;
      case OrderEventType.ORDER_CANCELLED:
        lastEventToTrack = containsOnDeliveryEvent
          ? OrderEventType.ORDER_IN_DELIVERY
          : OrderEventType.ORDER_IN_PREPARATION;
        break;
      case OrderEventType.ORDER_IN_DELIVERY:
      case OrderEventType.ORDER_DELIVERED:
        lastEventToTrack = OrderEventType.ORDER_IN_DELIVERY;
        break;
      default:
        throw new Error('Invalid Event Type');
    }

    const acceptedEventTime = containsAcceptedEvent
      ? orderUtil.findEventTime(events, OrderEventType.ORDER_ACCEPTED)
      : '';
    const baseEventTime = orderUtil.findEventTime(events, lastEventToTrack);

    return {
      timerAccepted: acceptedEventTime
        ? dateUtils.timerCalculator(new Date(acceptedEventTime), new Date())
        : '',
      timerColumn: dateUtils.timerCalculator(new Date(baseEventTime), new Date()),
    };
  };

  private formatOrderResponseToCard = (order: OrderResponse): ICard => {
    const {
      events,
      channel,
      payment,
      customer,
      quadrant,
      deliveryId,
      rbiNumberId,
      instructions,
      displayOrderId,
      deliveryAddress,
      lastMileDeliveryId,
      lastMileDeliveryStatus,
    } = order;

    const getInstructionQuantity = (): number =>
      [instructions, deliveryAddress.instructions].reduce((acc, curr) => (curr ? acc + 1 : acc), 0);

    const orderFormatted: ICard = {
      actionIcon: undefined,
      amountToBePaid: moneyUtils().moneyFormatter(payment.total.amount, payment.total.currency),
      channel: channelNamesObject[channel as ChannelResponses] || channel,
      customerAddress: `${deliveryAddress.formattedAddress}${
        deliveryAddress.subpremise ? `, ${deliveryAddress.subpremise}` : ''
      }`,
      customerName: customer.name,
      deliveryId,
      deliveryMode: order.deliveryMode,
      displayOrderId,
      events,
      instructionQuantity: getInstructionQuantity(),
      lastMileDeliveryId,
      lastMileDeliveryStatus,
      onActionClick: () => Promise.resolve(),
      onClick: () => ({}),
      orderId: rbiNumberId,
      paymentMethod: payment.paymentMethod,
      quadrant: quadrant?.name ?? '',
      statusColor: OrderTypeColor.SUCCESS,
      timerAccepted: '-',
    };

    return orderFormatted;
  };
}

export const ordersManagementUtils = () => new OrdersManagementUtils();
