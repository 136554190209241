import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse, Method } from 'axios';
import { v4 } from 'uuid';

let authToken: string | null;

interface IRequestHeader {
  'x-correlation-id'?: string;
  authorization_action?: string;
  authorization?: string;
}

export abstract class HTTPClient {
  public static setAuthToken = (token: string | null) => {
    authToken = token;
  };

  protected http: AxiosInstance;

  constructor(baseURL: string) {
    this.http = axios.create({
      baseURL,
    });
  }

  protected delete<R>(url: string, headers?: IRequestHeader): Promise<AxiosResponse<R>> {
    return this.baseRequest<undefined, R>('DELETE', url, undefined, headers);
  }

  protected get<R>(url: string, headers?: IRequestHeader): Promise<AxiosResponse<R>> {
    return this.baseRequest<undefined, R>('GET', url, undefined, headers);
  }

  protected patch<T, R>(url: string, data: T, headers?: IRequestHeader): Promise<AxiosResponse<R>> {
    return this.baseRequest<T, R>('PATCH', url, data, headers);
  }

  protected post<T, R>(url: string, data?: T, headers?: IRequestHeader): Promise<AxiosResponse<R>> {
    return this.baseRequest<T, R>('POST', url, data, headers);
  }

  protected put<T, R>(url: string, data: T, headers?: IRequestHeader): Promise<AxiosResponse<R>> {
    return this.baseRequest<T, R>('PUT', url, data, headers);
  }

  private baseRequest<T, R>(
    method: Method,
    url: string,
    data?: T,
    headers?: IRequestHeader,
  ): Promise<AxiosResponse<R>> {
    return this.http.request({
      data,
      //...headers has to be able to overwrite ...this.getAuthHeader in case of header objects with the same fields
      headers: { ...this.getAuthHeader(), ...this.createCorrelationId(), ...headers },
      method,
      url,
    });
  }

  private createCorrelationId(): AxiosRequestHeaders {
    const correlationId = v4();

    return { 'x-correlation-id': correlationId };
  }

  private getAuthHeader(): AxiosRequestHeaders | undefined {
    return authToken
      ? {
          authorization: `Bearer ${authToken}`,
        }
      : undefined;
  }
}
