import { Delegate } from '../../../../../@types/handlers/delegate';
import { SelectOption } from '../../../../../@types/input';
import { EtaValues } from '../../../../../@types/settings';
import { ToastTypes } from '../../../../../constants/toasts';
import useEtaListConnector from './connector';

export interface IEtaListDelegate {
  /**
   * Get valid ETA values for a given restaurant and set on state variable
   *
   */
  getEtaValues(): Promise<void>;

  /**
   * Get valid ETA values on state variable
   */
  get etaList(): SelectOption[];
}

type EtaListConnector = ReturnType<typeof useEtaListConnector>;

export class EtaListDelegate extends Delegate<EtaListConnector> implements IEtaListDelegate {
  getEtaValues = async (): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const response: EtaValues = await this.connector.settingsService.getEtaValues(
        regionCode,
        brand,
        restaurantId,
      );

      const etaList: SelectOption[] = response.etaValues?.map((eta) => ({
        id: `${eta}`,
        title: `${eta} ${this.connector.translate(
          'components.PageHeader.DeliveryTime.EtaList.inputMinutesLabel',
        )}`,
      }));

      this.connector.setEtaList(etaList);
    } catch (err) {
      this.connector.toastContext.addToast(
        'components.PageHeader.DeliveryTime.EtaList.getEtaValuesToastError',
        ToastTypes.ERROR,
      );
    }
  };

  get etaList(): SelectOption[] {
    return this.connector.etaList;
  }
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useEtaListDelegate = () => new EtaListDelegate(useEtaListConnector());
