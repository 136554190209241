import { useCallback, useState } from 'react';

import { IToast } from '../../../@types/toast';
import { ToastTypes } from '../../../constants/toasts';

export const useToastContextConnector = () => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const addToastCallback = (updateToastFunction: (message: string, type: ToastTypes) => void) =>
    useCallback(updateToastFunction, [setToasts]);

  return {
    addToastCallback,
    setToasts,
    toasts,
  };
};
