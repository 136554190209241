import React, { FC } from 'react';
import styled from 'styled-components';

import { FormattedCartItems } from '../../@types/orders';

export interface IOrderSummary {
  cartSummary: FormattedCartItems[];
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FontStyleItem = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #5a5a5a;
`;

const FontStyleSubitem = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #3b5a6b;
`;

const ItemNameSpan = styled(FontStyleItem)`
  max-width: 60%;
  color: #000000;
`;

const PriceSpan = styled(FontStyleItem)`
  font-size: 18px;
`;

const SubitemContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 20px;
  gap: 19px;

  background: #dae7ee;
  border-radius: 4px;
`;

const EmptyLine = styled.div`
  margin-bottom: 12px;
`;

const OrderSummary: FC<IOrderSummary> = ({ cartSummary }) => {
  const showSubItemsContainer = (menuSelections: FormattedCartItems[]): boolean =>
    menuSelections.some(({ quantity }) => quantity > 0);

  return (
    <FlexColumn id="flex-column">
      {cartSummary.map((item) => (
        <React.Fragment key={item.dmpId}>
          {item.quantity > 0 && (
            <Item id="wrapper">
              <ItemNameSpan>{`${item.quantity}x ${item.name}`}</ItemNameSpan>
              <PriceSpan>{item.formattedPrice}</PriceSpan>
            </Item>
          )}
          {showSubItemsContainer(item.menuSelections) && (
            <SubitemContainer id="subitem-container">
              {item.menuSelections.map(
                (subitem) =>
                  !!subitem.quantity && (
                    <Item key={subitem.dmpId}>
                      <FontStyleSubitem>
                        {subitem.quantity}x {subitem.name}
                      </FontStyleSubitem>
                      <PriceSpan>{subitem.formattedPrice}</PriceSpan>
                    </Item>
                  ),
              )}
            </SubitemContainer>
          )}
        </React.Fragment>
      ))}
      <EmptyLine />
    </FlexColumn>
  );
};

export default OrderSummary;
