export enum LastMileDeliveryStatus {
  MATCHING = 'MATCHING',
  MATCHED = 'MATCHED',
  PICKING = 'PICKING',
  IN_DELIVERY = 'IN_DELIVERY',
  FINISHED = 'FINISHED',
  NO_DRIVERS_FOUND = 'NO_DRIVERS_FOUND',
  CANCELLED = 'CANCELLED',
}

export enum LastMileProviders {
  UBER_DIRECT = 'UberDirect',
  CATCHER = 'Catcher',
  STUART = 'Stuart',
}

export enum LastMileEventType {
  LAST_MILE_DELIVERY_UPDATED = 'LAST_MILE_DELIVERY_UPDATED',
}
