import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  GenericTableData,
  GenericTableHeader,
  GenericTableRow,
} from '../../../../@types/genericTable';
import { OrderResponse } from '../../../../@types/orders';
import GenericTable from '../../../../components/GenericTable';
import { translationBaseDir } from '../../../../constants/historyTable';
import { HistoryTableType } from '../../../../constants/order';
import { OrderTypeColor } from '../../../../constants/orderTypeColor';
import { EmptyTable } from '../EmptyTable';
import { ShowOrderDetails } from '../ShowOrderDetails';
import { useGenericHistoryTableDelegate } from './controllers/delegate';

interface IGenericHistoryTable {
  orders: OrderResponse[] | null;
  tableType: HistoryTableType;
  onClick: (orderId: string) => void;
}

const CardStyle = styled.div<{ statusColor: OrderTypeColor }>`
  border-left: 9px solid ${(props) => props.statusColor};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: -webkit-fill-available;
  padding-left: 37px;
  display: flex;
  align-items: center;
`;

const FontWeight600 = styled.span`
  font-weight: 600;
`;

const TextEllipsis = styled.div`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

export const GenericHistoryTable: FC<IGenericHistoryTable> = ({ orders, tableType, onClick }) => {
  const { t } = useTranslation();

  const { formattedList, formatOrders, getOrderColor, loading } = useGenericHistoryTableDelegate();

  useEffect(() => {
    if (orders == null) return;
    formatOrders(orders, tableType);
  }, [orders]);

  const rowOrderIdColor = (orderId: string, displayOrderId: string): GenericTableData => ({
    data: (
      <CardStyle statusColor={getOrderColor(orderId)}>
        <FontWeight600>{displayOrderId}</FontWeight600>
      </CardStyle>
    ),
  });

  const rowOrderId = (displayOrderId: string): GenericTableData => ({
    data: <FontWeight600>{displayOrderId}</FontWeight600>,
  });

  const rowIcon = (orderId: string, onClick: (orderId: string) => void): GenericTableData => {
    return { data: <ShowOrderDetails orderId={orderId} onClick={onClick} /> };
  };

  const rowEllipsis = (value: string): GenericTableData => ({
    data: <TextEllipsis>{value}</TextEllipsis>,
  });

  const formatListData = (status: HistoryTableType): GenericTableRow[] => {
    const secondElement = 2;
    const eighthElement = 8;
    const ninthElement = 9;

    const formatted = formattedList
      ? formattedList.map((row) => {
          return {
            data: [
              status === HistoryTableType.DELIVERED
                ? rowOrderIdColor(row.data[0].data as string, row.data[1].data as string)
                : rowOrderId(row.data[1].data as string),
              ...row.data.slice(secondElement, eighthElement),
              rowEllipsis(row.data[eighthElement].data as string),
              ...row.data.slice(ninthElement),
              rowIcon(row.data[0].data as string, onClick),
            ],
          };
        })
      : [];
    return formatted;
  };

  const genericHeaders: GenericTableHeader[] = [
    { title: t(`${translationBaseDir}.Order`), width: '130' },
    { title: t(`${translationBaseDir}.Client`), width: '150' },
    { title: t(`${translationBaseDir}.Value`), width: '100' },
    { title: t(`${translationBaseDir}.PaidWith`), width: '130' },
    { title: t(`${translationBaseDir}.Channel`), width: '120' },
    { position: 'center', title: t(`${translationBaseDir}.Quadrant`), width: '80' },
    { position: 'center', title: t(`${translationBaseDir}.OrderedAt`), width: '170' },
    { title: t(`${translationBaseDir}.Driver`), width: '150' },
    { title: t(`${translationBaseDir}.DeliveryTime`), width: '115' },
  ];

  const cancelledHeaders: GenericTableHeader[] = [
    ...genericHeaders,
    { title: t(`${translationBaseDir}.Reason`), width: '170' },
    { title: '', width: '40' },
  ];

  const closedIssuesHeaders: GenericTableHeader[] = [
    ...genericHeaders,
    { title: t(`${translationBaseDir}.Issue`), width: '170' },
    { title: '', width: '40' },
  ];

  const deliveredHeaders: GenericTableHeader[] = [...genericHeaders, { title: '', width: '40' }];

  const tableFormatSelector: Record<
    HistoryTableType,
    { headers: GenericTableHeader[]; rows: GenericTableRow[] }
  > = {
    [HistoryTableType.CANCELLED]: {
      headers: cancelledHeaders,
      rows: formatListData(HistoryTableType.CANCELLED),
    },
    [HistoryTableType.DELIVERED]: {
      headers: deliveredHeaders,
      rows: formatListData(HistoryTableType.DELIVERED),
    },
    [HistoryTableType.CLOSED_ISSUES]: {
      headers: closedIssuesHeaders,
      rows: formatListData(HistoryTableType.CANCELLED),
    },
  };

  return (
    <GenericTable
      headers={tableFormatSelector[tableType].headers}
      rows={tableFormatSelector[tableType].rows}
      emptyTable={<EmptyTable />}
      loading={loading}
    />
  );
};
