import { useSettingsContext } from '../../../../../contexts/SettingsContext';

const usePosStatusConnector = () => {
  const { pos } = useSettingsContext();

  return {
    pos,
  };
};

export default usePosStatusConnector;
