import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useModalContext } from '../../../../../contexts/ModalContext';
import { useSettingsContext } from '../../../../../contexts/SettingsContext';
import { useToastContext } from '../../../../../contexts/ToastContext';
import settingsService from '../../../../../services/SettingsService';

const useServiceActiveConnector = () => {
  return {
    authContext: useAuthContext(),
    modalContext: useModalContext(),
    settingsContext: useSettingsContext(),
    settingsService,
    toastContext: useToastContext(),
  };
};

export default useServiceActiveConnector;
