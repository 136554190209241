import { FC, ReactNode, startTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PagePath } from '../../../constants/pages';
import SvgIcon from '../../SvgIcon';

export interface INavBarItem {
  title: string;
  path: PagePath;
  icon: ReactNode;
  isSvg?: boolean;
  selected?: boolean;
}

const Base = styled.a`
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-end;
  height: 94px;
  width: 172px;
  display: flex;
  border: 1px solid #ececec;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Title = styled.div<{ selected?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${(props) => (props.selected ? '#507083' : '#97B0BD')};
  text-align: center;
  letter-spacing: 0.06em;
`;

const SelectedBar = styled.div`
  border-radius: 50px 50px 0 0;
  height: 9px;
  background: #3b5a6b;
`;

const svgSize = 30;
const muiSize = 34;

const NavBarItem: FC<INavBarItem> = ({ icon, title, path, selected = false, isSvg = false }) => {
  const navigate = useNavigate();

  const redirectToPage = () => {
    startTransition(() => {
      navigate(`/${path}`, { replace: true, state: undefined });
    });
  };

  return (
    <Base onClick={redirectToPage}>
      <Body data-testid={`${path}-nav-item`}>
        <SvgIcon
          children={icon}
          width={isSvg ? svgSize : muiSize}
          height={isSvg ? svgSize : muiSize}
          color={selected ? '#507083' : '#97B0BD'}
        />
        <Title selected={selected}>{title}</Title>
      </Body>
      {selected && <SelectedBar />}
    </Base>
  );
};

export default NavBarItem;
