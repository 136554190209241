import { Delegate } from '../../../@types/handlers/delegate';
import { useDatePickerConnector } from './connector';

type DatePickerConnector = ReturnType<typeof useDatePickerConnector>;

interface IDatePickerDelegate {
  getLocalizedLanguage: () => Locale;
}

export class DatePickerDelegate
  extends Delegate<DatePickerConnector>
  implements IDatePickerDelegate
{
  public getLocalizedLanguage = (): Locale => {
    const { language } = this.connector.languageContext;
    const localizedLanguage = this.connector.dateTimeUtils.getLocalizedLanguage(language);

    return localizedLanguage;
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useDatePickerDelegate = () => new DatePickerDelegate(useDatePickerConnector());
