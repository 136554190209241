import { Phone } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as DriverIcon } from '../../../../assets/icons/driver.svg';
import { LastMileDeliveryStatus, LastMileProviders } from '../../../../constants/lastMile';
import { LastMileModalType } from '../../../../constants/modalType';
import LastMileModal from '../../../../pages/OrdersManagement/components/LastMileModal';
import { DateFormat, dateTimeUtils } from '../../../../utils/DateTimeUtils';
import SmallLoading from '../../../SmallLoading';
import SvgIcon from '../../../SvgIcon';
import { useLastMileTrackerDelegate } from './controllers/delegate';

export interface ILastMileTracker {
  lastMileDeliveryId: string;
  provider: LastMileProviders;
  orderId: string;
  lastMileDeliveryStatus: LastMileDeliveryStatus;
}

const Container = styled.div`
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
`;

const Title = styled.div`
  display: flex;
  color: #3b5a6b;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.18px;
`;

const TitleInline = styled(Title)`
  white-space: nowrap;
`;

const Description = styled.div`
  color: #787878;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
`;

const Button = styled.button`
  color: #dd3712;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const ButtonGray = styled(Button)`
  color: #3b5a6b;
`;

const Separator = styled.span`
  height: 1px;
  width: 100%;
  background: #e0e0e0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

const Icon = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 300px;
`;

const TextEllipsis = styled.div`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Inline = styled.div`
  display: inline-flex;
`;

const LastMileTracker: FC<ILastMileTracker> = ({
  lastMileDeliveryId,
  provider,
  orderId,
  lastMileDeliveryStatus,
}) => {
  const { t } = useTranslation();

  const { cancelLastMileDelivery, handleModal, tryAgain, getLastMileById, lastMileDetails } =
    useLastMileTrackerDelegate();

  const { formatDate } = dateTimeUtils();

  useEffect(() => {
    getLastMileById(lastMileDeliveryId);
  }, [lastMileDeliveryStatus]);

  const handleCancel = async (orderId: string): Promise<void> => {
    handleModal(
      <LastMileModal
        provider={provider}
        type={LastMileModalType.CANCEL_DRIVER}
        onBackClick={() => handleModal()}
        onConfirmClick={() => cancelLastMileDelivery(orderId)}
      />,
    );
    getLastMileById(lastMileDeliveryId);
  };

  const handleCancelAssigningDriver = async (orderId: string): Promise<void> => {
    await cancelLastMileDelivery(orderId);
    getLastMileById(lastMileDeliveryId);
  };

  const assigningDriver = () => (
    <Container data-testid="last-mile-assigning">
      <Icon>
        <TitleInline>
          {t('pages.OrderManagement.OrderDetails.lastMile.assigning.title')}
        </TitleInline>
        <SmallLoading />
      </Icon>
      <Description>
        {t('pages.OrderManagement.OrderDetails.lastMile.assigning.description')}
      </Description>
      <Separator />
      <Button onClick={() => handleCancelAssigningDriver(orderId)}>
        {t('pages.OrderManagement.OrderDetails.lastMile.assigning.cancel')}
      </Button>
    </Container>
  );

  const noDriversFound = () => (
    <Container data-testid="last-mile-no-drivers">
      <Title>{t('pages.OrderManagement.OrderDetails.lastMile.noDrivers.title')}</Title>
      <Description>
        {t('pages.OrderManagement.OrderDetails.lastMile.noDrivers.description')}
      </Description>
      <Separator />
      <ButtonContainer>
        <Button onClick={() => handleCancelAssigningDriver(orderId)}>
          {t('pages.OrderManagement.OrderDetails.lastMile.noDrivers.cancel')}
        </Button>
        <ButtonGray onClick={() => tryAgain(orderId, provider, lastMileDeliveryId)}>
          {t('pages.OrderManagement.OrderDetails.lastMile.noDrivers.tryAgain')}
        </ButtonGray>
      </ButtonContainer>
    </Container>
  );

  const driverAssigned = (cancellable: boolean) =>
    lastMileDetails && (
      <Container data-testid="last-mile-assigned">
        <Title>
          <Inline>
            <Icon>
              <SvgIcon children={<DriverIcon />} width={18} height={18} color="#97B0BD" />
              <TextEllipsis>{lastMileDetails.courier?.fullName}</TextEllipsis>({provider})
            </Icon>
          </Inline>
        </Title>
        <Description>
          <Icon>
            <Phone sx={{ color: '#92B0BD', fontSize: '12px' }} />
            {lastMileDetails.courier?.phoneNumber}{' '}
            {lastMileDetails.dropoffEta && (
              <>
                {t('pages.OrderManagement.OrderDetails.lastMile.driverAssigned.eta')}:
                {lastMileDetails.dropoffEta
                  ? formatDate(parseISO(lastMileDetails.dropoffEta), DateFormat.HH_MM)
                  : ''}
              </>
            )}
          </Icon>
        </Description>
        {cancellable && (
          <>
            <Separator />
            <Button onClick={() => handleCancel(orderId)}>
              {t('pages.OrderManagement.OrderDetails.lastMile.driverAssigned.cancelAssignment')}
            </Button>
          </>
        )}
      </Container>
    );

  const chooseAppropriateComponent = (
    status: LastMileDeliveryStatus | undefined,
  ): JSX.Element | null => {
    switch (status) {
      case LastMileDeliveryStatus.MATCHING:
        return assigningDriver();
      case LastMileDeliveryStatus.NO_DRIVERS_FOUND:
        return noDriversFound();
      case LastMileDeliveryStatus.MATCHED:
      case LastMileDeliveryStatus.PICKING:
        return driverAssigned(true);
      case LastMileDeliveryStatus.IN_DELIVERY:
      case LastMileDeliveryStatus.FINISHED:
        return driverAssigned(false);
      default:
        return null;
    }
  };

  return <>{chooseAppropriateComponent(lastMileDeliveryStatus)}</>;
};

export default LastMileTracker;
