import { useToastContext } from '../../../../contexts/ToastContext';
import { printUtils } from '../../../../utils/PrintUtils';

const useGenericPrintButtonConnector = () => {
  return {
    printUtils: printUtils(),
    toastContext: useToastContext(),
  };
};

export default useGenericPrintButtonConnector;
