import { Delivery, UnformattedDelivery } from '../@types/delivery';
import { HTTPClient } from '../@types/handlers/httpClient';
import { OrderResponse } from '../@types/orders';
import { env } from '../constants/env';
import { ordersUtils } from '../utils/OrdersUtils';

export interface IDeliveryService {
  /**
   * Get array of orders with the same deliveryId whereas the deliveryId is equal to the input of the function
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId deliveryId that is going to group the orders
   * @param {string} deliveryId deliveryId that is going to group the orders
   */
  getOrdersByDeliveryId(
    country: string,
    brand: string,
    restaurantId: string,
    deliveryId: string,
  ): Promise<Delivery>;

  /**
   * Calculate the best route of the orders from a delivery
   *
   * @param {string} country Country code in ALPHA-2 ISO code
   * @param {string} brand Brand code. Example: BK
   * @param {string} restaurantId deliveryId that is going to group the orders
   * @param {string} deliveryId deliveryId that is going to group the orders
   */
  calculateRoute(
    country: string,
    brand: string,
    restaurantId: string,
    deliveryId: string,
  ): Promise<google.maps.DirectionsResult>;
}

const ordersUtil = ordersUtils();

class DeliveryService extends HTTPClient implements IDeliveryService {
  async calculateRoute(
    country: string,
    brand: string,
    restaurantId: string,
    deliveryId: string,
  ): Promise<google.maps.DirectionsResult> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/deliveries/${deliveryId}/route`;
    const response = await this.get<google.maps.DirectionsResult>(url);

    return response.data;
  }

  async getOrdersByDeliveryId(
    country: string,
    brand: string,
    restaurantId: string,
    deliveryId: string,
  ): Promise<Delivery> {
    const url = `/${country}/${brand}/restaurants/${restaurantId}/deliveries/${deliveryId}`;
    const response = await this.get<UnformattedDelivery>(url);

    const orderDetails: OrderResponse[] = response.data.orderDetails.map((order) =>
      ordersUtil.formatToOrderResponse(order),
    );

    return { ...response.data, orderDetails };
  }
}

export default new DeliveryService(env.deliveryServiceAPI);
