import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { IFloatingCardPosition } from '../../../../../contexts/FloatingCardContext/components/GenericFloatingCard';
import useSettingsButtonConnector from './connector';

interface ISettingsButtonDelegate {
  /**
   * Handle when the floating card should be opened or closed
   * @param {ReactElement<any, any> | undefined} content content to be show in floating card
   * @param {IFloatingCardPosition} position the floating card position on the screen
   * @param {string[]} dependencies list of ids as dependency to not close the floating card
   */
  handleFloatingCard(
    content?: ReactElement<any, any>,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ): void;

  /**
   * logout user
   * @returns {Promise<boolean>} returns true to success or false on error
   */
  logout(): Promise<boolean>;
}

type SettingsButtonConnector = ReturnType<typeof useSettingsButtonConnector>;

export class SettingsButtonDelegate
  extends Delegate<SettingsButtonConnector>
  implements ISettingsButtonDelegate
{
  handleFloatingCard = (
    content?: ReactElement<any, any>,
    position?: IFloatingCardPosition,
    dependencies?: string[],
  ) => {
    return this.connector.floatingCardContext.handleFloatingCard(content, position, dependencies);
  };

  logout = async (): Promise<boolean> => {
    return this.connector.authContext.logout();
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useSettingsButtonDelegate = () =>
  new SettingsButtonDelegate(useSettingsButtonConnector());
