import { useRef, useState } from 'react';

import { getWSClient } from '../../../@types/handlers/websocketClient';
import { useAuthContext } from '../../AuthContext';

export const useWebsocketContextConnector = () => {
  const [retryConn, setRetryConn] = useState<boolean>(false);

  const retryConnRef = useRef(retryConn);

  return {
    authContext: useAuthContext(),
    getWSClient,
    retryConn,
    retryConnRef,
    setRetryConn,
  };
};
