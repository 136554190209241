import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FilterDate from '../../../components/FilterDate/FilterDate';
import { HistoryTableType } from '../../../constants/order';

interface IHistoryHeader {
  activeButton: HistoryTableType;
  filterDate: Date;
  totalDelivered: number;
  totalCancelled: number;
  totalCloseWithIssues: number;
  setViewTable: (status: HistoryTableType) => void;
  setFilterDate: (date: Date) => void;
}

const Base = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 21px 16px 42px;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.03em;
  color: #507083;
`;

const DescriptionSpan = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const ValueSpan = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.03em;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const BaseButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 4px;
  cursor: pointer;
  width: 140px;
  height: 73px;
  border: 1px solid #97b0bd;

  color: ${(props) => (props.isSelected ? '#ffffff' : '#507083')};

  background: ${(props) =>
    props.isSelected
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 53.12%), #507083'
      : '#ffffff'};
`;

const ButtonLeft = styled(BaseButton)`
  border-radius: 8px 0px 0px 8px;
`;

const ButtonMiddle = styled(BaseButton)`
  border-radius: 0px;
`;

const ButtonRight = styled(BaseButton)`
  border-radius: 0px 8px 8px 0px;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  margin-left: auto;
`;

const historyHeaderStatus: Record<HistoryTableType, string> = {
  [HistoryTableType.CANCELLED]: 'pages.History.Header.cancelled',
  [HistoryTableType.DELIVERED]: 'pages.History.Header.delivered',
  [HistoryTableType.CLOSED_ISSUES]: 'pages.History.Header.closedWithIssues',
};

export const HistoryHeader: FC<IHistoryHeader> = ({
  activeButton,
  filterDate,
  totalDelivered,
  totalCancelled,
  totalCloseWithIssues,
  setViewTable,
  setFilterDate,
}) => {
  const { t } = useTranslation();

  const [subtitle, setSubtitle] = useState(`${t('pages.History.Header.delivered')}`);

  const setHeaderInfo = (status: HistoryTableType): void => {
    setViewTable(status);
    setSubtitle(t(historyHeaderStatus[status]));
  };

  const leftButton = (
    type: HistoryTableType,
    onClick: (status: HistoryTableType) => void,
  ): JSX.Element => {
    const onHandleClick = () => onClick(HistoryTableType.DELIVERED);

    return (
      <ButtonLeft
        onClick={onHandleClick}
        isSelected={type === HistoryTableType.DELIVERED}
        data-testid="delivered-button"
      >
        <ValueSpan>{totalDelivered}</ValueSpan>
        <DescriptionSpan>{t('pages.History.Header.delivered')}</DescriptionSpan>
      </ButtonLeft>
    );
  };

  const middleButton = (
    type: HistoryTableType,
    onClick: (status: HistoryTableType) => void,
  ): JSX.Element => {
    const onHandleClick = () => onClick(HistoryTableType.CLOSED_ISSUES);

    return (
      <ButtonMiddle
        onClick={onHandleClick}
        isSelected={type === HistoryTableType.CLOSED_ISSUES}
        data-testid="closed-issues-button"
      >
        <ValueSpan>{totalCloseWithIssues}</ValueSpan>
        <DescriptionSpan>{t('pages.History.Header.closedIssues')}</DescriptionSpan>
      </ButtonMiddle>
    );
  };

  const rightButton = (
    type: HistoryTableType,
    onClick: (status: HistoryTableType) => void,
  ): JSX.Element => {
    const onHandleClick = () => onClick(HistoryTableType.CANCELLED);

    return (
      <ButtonRight
        onClick={onHandleClick}
        isSelected={type === HistoryTableType.CANCELLED}
        data-testid="canceled-button"
      >
        <ValueSpan>{totalCancelled}</ValueSpan>
        <DescriptionSpan>{t('pages.History.Header.cancelled')}</DescriptionSpan>
      </ButtonRight>
    );
  };

  const filter = (): JSX.Element => {
    const onChange = (date: Date | null) => setFilterDate(date ?? new Date());

    return <FilterDate filterDate={filterDate} onChange={onChange} />;
  };

  return (
    <Base>
      <Title>
        {`${t('pages.History.Header.title')}  | `}
        <b>{subtitle}</b>
      </Title>
      <ToolbarContainer>
        {filter()}
        <Buttons>
          {leftButton(activeButton, setHeaderInfo)}
          {middleButton(activeButton, setHeaderInfo)}
          {rightButton(activeButton, setHeaderInfo)}
        </Buttons>
      </ToolbarContainer>
    </Base>
  );
};
