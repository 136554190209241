import { FC } from 'react';
import styled from 'styled-components';

import { CompensationItem } from '../../@types/compensation';
import CompensationRow from './components/CompensationRow';

interface ICompensation {
  menuSelections: CompensationItem[];
  onChange: (itemDmpId: string, action: 'add' | 'sub', subDmpId?: string) => void;
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
`;

const Wrapper = styled(FlexColumn)`
  width: 100%;
`;

const SubitemContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 20px;
  gap: 19px;

  background: #dae7ee;
  border-radius: 4px;

  #input-label {
    color: #3b5a6b;
  }
`;

const Compensation: FC<ICompensation> = ({ menuSelections, onChange }) => {
  return (
    <>
      {menuSelections.map((item, index) =>
        item.menuSelections.length > 0 ? (
          <Wrapper data-testid={`compensation-item-${index}`} key={item.dmpId}>
            <CompensationRow
              item={item}
              setCompensationItemQuantity={(action) => onChange(item.dmpId, action)}
            />
            <SubitemContainer data-testid={`compensation-subitems-${index}`}>
              {item.menuSelections.map((subitem) => (
                <CompensationRow
                  key={subitem.dmpId}
                  item={subitem}
                  setCompensationItemQuantity={(action) =>
                    onChange(item.dmpId, action, subitem.dmpId)
                  }
                />
              ))}
            </SubitemContainer>
          </Wrapper>
        ) : (
          <CompensationRow
            key={item.dmpId}
            item={item}
            setCompensationItemQuantity={(action) => onChange(item.dmpId, action)}
          />
        ),
      )}
    </>
  );
};

export default Compensation;
