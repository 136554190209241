import { Logout, Public } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ISettingsCard {
  logout: () => void;
  languageSelector: () => void;
}

const Body = styled.div`
  min-width: 220px;
  height: 205px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 32px;
`;

const ButtonName = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const ButtonDiv = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;
  justify-content: flex-start;
  width: 127px;
  height: 26px;
  font-family: Work Sans;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 37px;
  margin-left: 36px;
`;

const SettingsCard: FC<ISettingsCard> = ({ logout, languageSelector }) => {
  const { t } = useTranslation();

  return (
    <Body>
      <Title>{t('components.PageHeader.Settings.title')}</Title>
      <Div>
        <ButtonDiv onClick={languageSelector}>
          <Public sx={{ color: '#507083', fontSize: '20px' }} />
          <ButtonName>{t('components.PageHeader.Settings.languageButton')}</ButtonName>
        </ButtonDiv>
        <ButtonDiv onClick={logout}>
          <Logout sx={{ color: '#507083', fontSize: '20px' }} />
          <ButtonName>{t('components.PageHeader.Settings.logoutButton')}</ButtonName>
        </ButtonDiv>
      </Div>
    </Body>
  );
};

export default SettingsCard;
