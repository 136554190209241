import { createContext, FC, useContext, useEffect, useMemo } from 'react';

import { RestaurantCapacity } from '../../constants/restaurants';
import { ISettingsContextDelegate, useSettingsContextDelegate } from './controllers/delegate';

export const SettingsContext = createContext<
  Pick<
    ISettingsContextDelegate,
    | 'actualEtaInfo'
    | 'allowLastMile'
    | 'allowAutoFire'
    | 'allowPauseService'
    | 'allowReducedArea'
    | 'autoFire'
    | 'autoPrepare'
    | 'autoPrepareRef'
    | 'colors'
    | 'eta'
    | 'pos'
    | 'preScheduledInterval'
    | 'restaurantCapacity'
    | 'serviceStatus'
    | 'lastMileSettings'
    | 'setAutoFire'
    | 'setAutoPrepare'
    | 'setEta'
    | 'setRestaurantCapacity'
  >
>({
  actualEtaInfo: null,
  allowAutoFire: false,
  allowLastMile: false,
  allowPauseService: false,
  allowReducedArea: false,
  autoFire: false,
  autoPrepare: false,
  autoPrepareRef: { current: false },
  colors: null,
  eta: null,
  lastMileSettings: null,
  pos: false,
  preScheduledInterval: [],
  restaurantCapacity: RestaurantCapacity.NORMAL,
  serviceStatus: null,
  setAutoFire: () => ({}),
  setAutoPrepare: () => ({}),
  setEta: () => ({}),
  setRestaurantCapacity: () => Promise.resolve(false),
});

SettingsContext.displayName = 'Settings';

interface ISettingsProvider {
  children: JSX.Element;
}

const SettingsProvider: FC<ISettingsProvider> = ({ children }) => {
  const {
    actualEtaInfo,
    allowAutoFire,
    allowLastMile,
    allowPauseService,
    allowReducedArea,
    autoFire,
    autoPrepare,
    autoPrepareRef,
    colors,
    eta,
    lastMileSettings,
    pos,
    preScheduledInterval,
    restaurantCapacity,
    restaurantCapacityRef,
    retrieveColors,
    retrieveActualEta,
    retrievePreScheduledIntervals,
    retrieveRestaurantSettings,
    serviceStatus,
    setAutoFire,
    setAutoPrepare,
    setEta,
    setRestaurantCapacity,
    subscribeToWebsocket,
    unsubscribeToWebsocket,
  } = useSettingsContextDelegate();

  useEffect(() => {
    restaurantCapacityRef.current = restaurantCapacity;
  }, [restaurantCapacity]);

  useEffect(() => {
    autoPrepareRef.current = autoPrepare;
  }, [autoPrepare]);

  useEffect(() => {
    retrieveColors();
    retrievePreScheduledIntervals();

    let actualEtaInterval: NodeJS.Timer;
    let restaurantSettingsInterval: NodeJS.Timer;

    retrieveActualEta().then((response) => {
      actualEtaInterval = response!;
    });

    retrieveRestaurantSettings().then((response) => {
      restaurantSettingsInterval = response!;
    });

    subscribeToWebsocket();

    return () => {
      clearInterval(actualEtaInterval);
      clearInterval(restaurantSettingsInterval);
      unsubscribeToWebsocket();
    };
  }, []);

  const contextProps = useMemo(() => {
    return {
      actualEtaInfo,
      allowAutoFire,
      allowLastMile,
      allowPauseService,
      allowReducedArea,
      autoFire,
      autoPrepare,
      autoPrepareRef,
      colors,
      eta,
      lastMileSettings,
      pos,
      preScheduledInterval,
      restaurantCapacity,
      serviceStatus,
      setAutoFire,
      setAutoPrepare,
      setEta,
      setRestaurantCapacity,
    };
  }, [
    actualEtaInfo,
    allowAutoFire,
    allowLastMile,
    allowPauseService,
    allowReducedArea,
    autoFire,
    autoPrepare,
    autoPrepareRef,
    colors,
    eta,
    lastMileSettings,
    pos,
    preScheduledInterval,
    restaurantCapacity,
    serviceStatus,
    setEta,
    setRestaurantCapacity,
  ]);

  return <SettingsContext.Provider value={contextProps}>{children}</SettingsContext.Provider>;
};

export const useSettingsContext = () => useContext(SettingsContext);

export default SettingsProvider;
