import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompensationItem } from '../../../../../../../@types/compensation';
import { SelectOption } from '../../../../../../../@types/input';
import { OrderResponse } from '../../../../../../../@types/orders';
import { useAuthContext } from '../../../../../../../contexts/AuthContext';
import { useModalContext } from '../../../../../../../contexts/ModalContext';
import ordersService from '../../../../../../../services/OrdersService';
import settingsService from '../../../../../../../services/SettingsService';
import { ordersUtils } from '../../../../../../../utils/OrdersUtils';

const useIssueListConnector = () => {
  const { t: translate } = useTranslation();

  const [issueSelected, setIssueSelected] = useState<string>('');
  const [issueList, setIssueList] = useState<SelectOption[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderResponse | null>(null);
  const [compensations, setCompensations] = useState<CompensationItem[]>([]);

  return {
    authContext: useAuthContext(),
    compensations,
    issueList,
    issueSelected,
    modalContext: useModalContext(),
    orderDetails,
    ordersService,
    ordersUtils: ordersUtils(),
    setCompensations,
    setIssueList,
    setIssueSelected,
    setOrderDetails,
    settingsService,
    translate,
  };
};

export default useIssueListConnector;
