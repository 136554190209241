import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { LastMileDelivery } from '../../../../../@types/lastMile';
import { LastMileProviders } from '../../../../../constants/lastMile';
import { ToastTypes } from '../../../../../constants/toasts';
import useLastMileTrackerConnector from './connector';

type LastMileTrackerConnector = ReturnType<typeof useLastMileTrackerConnector>;

interface ILastMileTrackerDelegate {
  /**
   * Cancel a last-mile delivery for a given order
   *
   *  @param {string} orderId id of the order to cancel the last-mile
   * @returns {void}
   */
  cancelLastMileDelivery(orderId: string): Promise<void>;

  /**
   * Details of the current last-mile
   */
  lastMileDetails: LastMileDelivery | null;

  /**
   * Handle to show the modal
   *
   *  @param {ReactElement<any, any>} content modal react element
   * @returns {void}
   */
  handleModal(content?: ReactElement<any, any>): void;

  /**
   * Cancel the first delivery and then create a new one
   *
   * @param {string} orderId order id to get the driver
   * @param {string} lastMileDeliveryId last mile delivery id
   * @param {LastMileProviders} provider provider
   */
  tryAgain(orderId: string, provider: LastMileProviders, lastMileDeliveryId: string): Promise<void>;
}

export class LastMileTrackerDelegate
  extends Delegate<LastMileTrackerConnector>
  implements ILastMileTrackerDelegate
{
  get lastMileDetails(): LastMileDelivery | null {
    return this.connector.deliveryContext.lastMileDetails;
  }

  cancelLastMileDelivery = async (orderId: string): Promise<void> => {
    this.handleModal();
    this.connector.deliveryContext.setLoading(true);

    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    try {
      await this.connector.ordersService.cancelLastMileDelivery(
        regionCode,
        brand,
        restaurantId,
        orderId,
      );

      await this.connector.deliveryContext.getOrderById(orderId);
    } catch {
      this.connector.toastContext.addToast(
        'pages.OrderManagement.OrderDetails.lastMile.errors.errorOnCancelAssign',
        ToastTypes.ERROR,
      );
    }
  };

  getLastMileById = async (lastMileId: string): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

    try {
      const lastMile = await this.connector.ordersService.getLastMileDeliveryById(
        regionCode,
        brand,
        restaurantId,
        lastMileId,
      );

      this.connector.deliveryContext.setLastMileDetails(lastMile);
    } catch {
      return;
    }
  };

  handleModal = (content?: ReactElement<any, any>): void => {
    return this.connector.modalContext.handleModal(content);
  };

  tryAgain = async (
    orderId: string,
    provider: LastMileProviders,
    lastMileDeliveryId: string,
  ): Promise<void> => {
    const { brand, regionCode, restaurantId } = this.connector.authContext.user!;
    this.connector.deliveryContext.setLoading(true);

    try {
      await this.connector.ordersService.cancelLastMileDelivery(
        regionCode,
        brand,
        restaurantId,
        orderId,
      );

      await this.connector.ordersService.createLastMileDelivery(
        regionCode,
        brand,
        restaurantId,
        orderId,
        provider,
      );

      await this.getLastMileById(lastMileDeliveryId);

      await this.connector.deliveryContext.getOrderById(orderId);
    } catch {
      this.connector.toastContext.addToast(
        'pages.OrderManagement.OrderDetails.lastMile.errors.errorOnCreate',
        ToastTypes.ERROR,
      );
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useLastMileTrackerDelegate = () =>
  new LastMileTrackerDelegate(useLastMileTrackerConnector());
