import { FC, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import styled from 'styled-components';

import PrivateRoute from './components/Layouts/PrivateRoute';
import LoggedProviders from './components/routes/LoggedProviders';
import { PagePath } from './constants/pages';
import { useAuthContext } from './contexts/AuthContext';
import { OrdersHistory } from './pages/OrdersHistory';
import PreOrders from './pages/PreOrders';

const Base = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

// pages
const Login = lazy(() => import('./pages/Login'));

const OrdersManagement = lazy(() => import('./pages/OrdersManagement'));

const PageNotFound = lazy(() => import('./pages/PageNotFound'));

const RestaurantMap = lazy(() => import('./pages/RestaurantMap'));

const Drivers = lazy(() => import('./pages/Drivers'));

const DriverApp = lazy(() => import('./pages/DriverApp'));

const CashFlow = lazy(() => import('./pages/CashFlow'));

const Complaints = lazy(() => import('./pages/Complaints'));

const OrderDetailsPrint = lazy(() => import('./pages/Prints/OrderDetailsPrint'));

const DriverCashFlowPrint = lazy(() => import('./pages/Prints/DriverCashFlowPrint'));

const ComplaintDetailsPrint = lazy(() => import('./pages/Prints/ComplaintDetailsPrint'));

const LoggedRoutes: FC = () => (
  <RouterRoutes>
    <Route
      path={PagePath.ORDERS_MANAGEMENT}
      element={<PrivateRoute element={<OrdersManagement />} />}
    />
    <Route path={PagePath.PRE_ORDERS} element={<PrivateRoute element={<PreOrders />} />} />
    <Route path={PagePath.MAP} element={<PrivateRoute element={<RestaurantMap />} />} />
    <Route path={PagePath.DRIVERS} element={<PrivateRoute element={<Drivers />} />} />
    <Route path={PagePath.DRIVER_APP} element={<PrivateRoute element={<DriverApp />} />} />
    <Route path={PagePath.HISTORY} element={<PrivateRoute element={<OrdersHistory />} />} />
    <Route path={PagePath.COMPLAINTS} element={<PrivateRoute element={<Complaints />} />} />
    <Route path={PagePath.CASHFLOW} element={<PrivateRoute element={<CashFlow />} />} />
    <Route path={`${PagePath.PRINT_ORDER_DETAILS}/:orderId`} element={<OrderDetailsPrint />} />
    <Route path={`${PagePath.PRINT_DRIVER_CASHFLOW}/:shift`} element={<DriverCashFlowPrint />} />
    <Route
      path={`${PagePath.PRINT_COMPLAINT_DETAILS}/:complaintId`}
      element={<ComplaintDetailsPrint />}
    />
    <Route path={PagePath.LOGIN} element={<Navigate to={`/${PagePath.ORDERS_MANAGEMENT}`} />} />
    <Route path="/" element={<Navigate to={`/${PagePath.ORDERS_MANAGEMENT}`} />} />
    <Route path={'*'} element={<PageNotFound />} />
  </RouterRoutes>
);

const OtherRoutes: FC = () => (
  <BrowserRouter>
    <Base>
      <RouterRoutes>
        <Route path={PagePath.LOGIN} element={<Login />} />
        <Route path="*" element={<Navigate to={PagePath.LOGIN} />} />
      </RouterRoutes>
    </Base>
  </BrowserRouter>
);

const Routes: FC = () => {
  const { isLoading, user } = useAuthContext();

  return isLoading ? (
    <></>
  ) : user ? (
    <LoggedProviders>
      <LoggedRoutes />
    </LoggedProviders>
  ) : (
    <OtherRoutes />
  );
};

export default Routes;
