import { FC } from 'react';

import Routes from './Routes';
import AuthProvider from './contexts/AuthContext';
import LanguageProvider from './contexts/LanguageContext';

const App: FC = () => (
  <AuthProvider>
    <LanguageProvider>
      <Routes />
    </LanguageProvider>
  </AuthProvider>
);

export default App;
