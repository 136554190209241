import { ChannelNames } from '../@types/delivery';

export enum ChannelResponses {
  APP = 'app',
  GOOGLE_FOOD_ORDERING = 'googleFoodOrdering',
  GRUBHUB = 'grubhub',
  KIOSK = 'kiosk',
  UBER = 'uber',
  UBER_EATS = 'ubereats',
  WAITR = 'waitr',
  WEB = 'web',
  DOORDASH = 'doordash',
  GLOVO = 'glovo',
  MARKETPLACE = 'marketplace',
  JUST_EAT = 'justeat',
}

export const channelNamesObject: ChannelNames = {
  [ChannelResponses.APP]: 'App',
  [ChannelResponses.DOORDASH]: 'Doordash',
  [ChannelResponses.GOOGLE_FOOD_ORDERING]: 'Google Food Ordering',
  [ChannelResponses.GRUBHUB]: 'Grubhub',
  [ChannelResponses.KIOSK]: 'Kiosk',
  [ChannelResponses.UBER]: 'Uber',
  [ChannelResponses.UBER_EATS]: 'Uber Eats',
  [ChannelResponses.WAITR]: 'Waitr',
  [ChannelResponses.WEB]: 'Web',
  [ChannelResponses.GLOVO]: 'Glovo',
  [ChannelResponses.MARKETPLACE]: 'Marketplace',
  [ChannelResponses.JUST_EAT]: 'Just Eat',
};

export const marketplaceChannels: ChannelResponses[] = [
  ChannelResponses.APP,
  ChannelResponses.KIOSK,
  ChannelResponses.WEB,
  ChannelResponses.MARKETPLACE,
];
