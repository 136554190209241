import { ReactElement, useState } from 'react';

export const useModalContextConnector = () => {
  const [content, setContent] = useState<ReactElement<any, any> | undefined>(undefined);
  const [closeBtnColor, setCloseBtnColor] = useState<string>('#000000');

  return {
    closeBtnColor,
    content,
    setCloseBtnColor,
    setContent,
  };
};
