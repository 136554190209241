import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useSidebarContext } from '../../../../../contexts/SidebarContext';
import ordersService from '../../../../../services/OrdersService';
import { dateTimeUtils } from '../../../../../utils/DateTimeUtils';
import { moneyUtils } from '../../../../../utils/MoneyUtils';

export const usePreOrderCardConnector = () => {
  const { handleSidebar } = useSidebarContext();
  const { user } = useAuthContext();

  return {
    dateUtils: dateTimeUtils(),
    handleSidebar,
    moneyUtils: moneyUtils(),
    ordersService,
    user,
  };
};
