import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as CreditCard } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as MoneyPayment } from '../../../assets/icons/money-payment.svg';
import SvgIcon from '../../../components/SvgIcon';
import { channelNamesObject, ChannelResponses } from '../../../constants/delivery';
import { PaymentMethod } from '../../../constants/paymentMethod';

interface IPayment {
  formattedAmountToBePaid: string;
  paymentMethod: string;
  channel: string;
}

const PaymentStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #507083;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 6px;
`;

const ChannelStyle = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #878787;
  margin-top: 2px;
  overflow-wrap: break-word;
`;

const Flex = styled.div`
  align-items: center;
  justify-content: center;
  width: 124px;
`;

const Payment: FC<IPayment> = ({ formattedAmountToBePaid, channel, paymentMethod }) => {
  return (
    <Flex>
      <PaymentStyle>
        <Icon>
          <SvgIcon
            children={
              (paymentMethod as PaymentMethod) === PaymentMethod.CASH ? (
                <MoneyPayment />
              ) : (
                <CreditCard />
              )
            }
            width={20}
            height={15}
            color="#97B0BD"
          />
        </Icon>
        <span data-testid="total-amount">{formattedAmountToBePaid}</span>
      </PaymentStyle>
      <ChannelStyle>
        {channel
          ? channelNamesObject[channel as ChannelResponses]
            ? channelNamesObject[channel as ChannelResponses]
            : channel
          : '-'}
      </ChannelStyle>
    </Flex>
  );
};

export default Payment;
