import { ReactElement } from 'react';

import { Delegate } from '../../../../../@types/handlers/delegate';
import { RestaurantServiceStatusUpdate } from '../../../../../@types/restaurants';
import { RestaurantServiceStatus } from '../../../../../constants/restaurants';
import { ToastTypes } from '../../../../../constants/toasts';
import useServiceActiveConnector from './connector';

interface IServiceActiveDelegate {
  /**
   * POS status
   */
  pos: boolean;

  /**
   * Service Status for restaurant
   */
  serviceStatus: RestaurantServiceStatus | null;

  /**
   * Handle to open or close modal
   *
   * @param {ReactElement<any, any> | undefined} content child component to be rendered inside the modal
   * @param {string | undefined} closeBtnColor color to closeButton icon if it is different than black
   */
  handleModal(content?: ReactElement<any, any>, closeBtnColor?: string): void;

  /**
   * Resume the Restaurant Service
   */
  resumeService(): void;
}

type ServiceActiveConnector = ReturnType<typeof useServiceActiveConnector>;

export class ServiceActiveDelegate
  extends Delegate<ServiceActiveConnector>
  implements IServiceActiveDelegate
{
  get pos(): boolean {
    return this.connector.settingsContext.pos;
  }

  get serviceStatus(): RestaurantServiceStatus | null {
    return this.connector.settingsContext.serviceStatus;
  }

  handleModal = (content?: ReactElement<any, any>, closeBtnColor?: string) => {
    return this.connector.modalContext.handleModal(content, closeBtnColor);
  };

  resumeService = async (): Promise<void> => {
    try {
      const { brand, regionCode, restaurantId } = this.connector.authContext.user!;

      const body: RestaurantServiceStatusUpdate = {
        status: RestaurantServiceStatus.RESTAURANT_AVAILABLE,
      };

      await this.connector.settingsService.resumeRestaurantService(
        regionCode,
        brand,
        restaurantId,
        body,
      );
    } catch (_) {
      this.connector.toastContext.addToast(
        'components.PageHeader.ServiceActive.errorOnResume',
        ToastTypes.ERROR,
      );
    }
  };
}

/* istanbul ignore next */ //ignore the next function in coverage report
export const useServiceActiveDelegate = () =>
  new ServiceActiveDelegate(useServiceActiveConnector());
