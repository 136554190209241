import { createContext, FC, useContext, useEffect } from 'react';

import { IDeliveryContextDelegate, useDeliveryContextDelegate } from './controllers/delegate';

export const DeliveryContext = createContext<
  Pick<
    IDeliveryContextDelegate,
    | 'deliveryId'
    | 'lastMileDetails'
    | 'loading'
    | 'ordersDetails'
    | 'getOrdersByDeliveryId'
    | 'getLastMileDelivery'
    | 'getOrderById'
    | 'setLoading'
    | 'setLastMileDetails'
    | 'setOrderDetails'
  >
>({
  deliveryId: null,
  getLastMileDelivery: () => Promise.resolve(),
  getOrderById: () => Promise.resolve(),
  getOrdersByDeliveryId: () => Promise.resolve(),
  lastMileDetails: null,
  loading: false,
  ordersDetails: [],
  setLastMileDetails: () => ({}),
  setLoading: () => ({}),
  setOrderDetails: () => ({}),
});

DeliveryContext.displayName = 'Delivery';

interface IDeliveryProvider {
  children: JSX.Element;
}

const DeliveryProvider: FC<IDeliveryProvider> = ({ children }) => {
  const {
    deliveryId,
    lastMileDetails,
    loading,
    ordersDetails,
    ordersDetailsRef,
    getOrdersByDeliveryId,
    getOrderById,
    subscribeToWebsocket,
    unsubscribeToWebsocket,
    setLoading,
    setLastMileDetails,
    getLastMileDelivery,
    setOrderDetails,
  } = useDeliveryContextDelegate();

  useEffect(() => {
    subscribeToWebsocket();

    return () => {
      unsubscribeToWebsocket();
    };
  }, []);

  useEffect(() => {
    ordersDetailsRef.current = ordersDetails;
  }, [ordersDetails]);

  return (
    <DeliveryContext.Provider
      value={{
        deliveryId,
        getLastMileDelivery,
        getOrderById,
        getOrdersByDeliveryId,
        lastMileDetails,
        loading,
        ordersDetails,
        setLastMileDetails,
        setLoading,
        setOrderDetails,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export const useDeliveryContext = () => useContext(DeliveryContext);

export default DeliveryProvider;
