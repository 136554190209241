import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Languages } from './constants/languages';
import {
  ca,
  de_DE,
  en,
  en_CH,
  en_GB,
  es_ES,
  eu_ES,
  fr_FR,
  gl_ES,
  pt_BR,
  pt_PT,
} from './translation';

type LanguageResources = Record<Languages, { translation: any }>;

const resources: Partial<LanguageResources> = {
  ca: {
    translation: ca,
  },
  de_DE: {
    translation: de_DE,
  },
  en: {
    translation: en,
  },
  en_CH: {
    translation: en_CH,
  },
  en_GB: {
    translation: en_GB,
  },
  es_ES: {
    translation: es_ES,
  },
  eu_ES: {
    translation: eu_ES,
  },
  fr_FR: {
    translation: fr_FR,
  },
  gl_ES: {
    translation: gl_ES,
  },
  pt_BR: {
    translation: pt_BR,
  },
  pt_PT: {
    translation: pt_PT,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  lng: navigator.language,
  resources,
});
